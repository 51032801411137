import "./custom.scss";
import "./index.css";
import React,{createContext,useState,useEffect,lazy,Suspense} from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./utils/reduxcomponents/store";
import TagManager from "react-gtm-module";
import Error from './components/Error';
import Shimmer from "./components/shimmerComponents/Shimmer"

import HomePage from "./components/homepagecomponents/HomePage";
import Login from './components/accountinfo/Login';
import Registration from './components/accountinfo/Registration';
import UserDashboardMain from './components/userdashboard/UserDashboardMain';
import UserDashboardBody from "./components/userdashboard/UserDashboardBody";
import Reports from "./components/userdashboard/dashReport/Reports";
import Projects from "./components/userdashboard/Projects";
import Stores from "./components/userdashboard/dashStores/Stores";
import MainPage from "./components/MainPage";
import ReportList from "./components/userdashboard/dashReport/ReportList";
import ReportListDetail from "./components/userdashboard/dashReport/ReportListDetail";
import DetailsProject from "./components/userdashboard/dashReport/DetailsProject";
import AccountInfoMain from "./components/accountinfo/AccountInfoMain";
import DashProfile from "./components/userdashboard/dashprofile/DashProfile";
import ForgotPassword from "./components/accountinfo/ForgotPassword";
import ResetPassword from "./components/accountinfo/ResetPassword";
import DashChangePassword from "./components/userdashboard/dashChangePassword/DashChangePassword";
import DashProjectSetup from "./components/userdashboard/dashProjectsetup/DashProjectSetup";
import Invoices from "./components/userdashboard/dashInvoice/Invoices";
import ReportStore from "./components/userdashboard/dashReport/StoreLists";
import StoreLists from "./components/userdashboard/dashReport/StoreLists";
import CaseStudyMain from "./components/sidePages/casestudy/CaseStudyMain";
import Manyavar from "./components/sidePages/casestudy/Manyavar";
import BlogsMain from "./components/sidePages/blogs/BlogsMain";
import CheckMarketGap from "./components/sidePages/blogs/CheckMarketGap";
import RetailersAspect from "./components/sidePages/blogs/RetailersAspect";
import StockAvailability from "./components/sidePages/blogs/StockAvailability";
import AuditStore from "./components/sidePages/blogs/AuditStore";
import GenuineReview from "./components/sidePages/blogs/GenuineReview";
import ConsumerInsight from "./components/sidePages/blogs/ConsumerInsight";
import JMC from "./components/sidePages/casestudy/JMC";
import Shott from "./components/sidePages/casestudy/Shott";
import TermsandCondition from "./components/sidePages/terms&condition/TermsandCondition";
import RefundPolicy from "./components/sidePages/refundPolicy/RefundPolicy";
import PrivacyPolicy from "./components/sidePages/privacyPolicy/PrivacyPolicy";
import FrequentyAsked from "./components/sidePages/faqs/FrequentyAsked";
import ContactUs from "./components/sidePages/contactus/ContactUs";
import Questionnaire from "./components/userdashboard/dashQuestionnaire/QuestionnaireMain";
import QuestionnaireInner from "./components/userdashboard/dashQuestionnaire/QuestionnaireInner";
import AssignFormMain from "./components/userdashboard/dashAssignFormtoStore/AssignFormMain";
import ReportDashboard from "./components/userdashboard/dashReport/ReportDashboard";
import ReportBrowser from "./components/userdashboard/dashReport/ReportBrowser";
import ReportStoreBrowser from "./components/userdashboard/dashReport/ReportStoreBrowser";
import ReportAIInsight from "./components/userdashboard/dashReport/ReportAIInsight";
import ReportAIInsightModal from "./components/userdashboard/dashReport/ReportAIInsightModal";
import AIInsightsBlog from "./components/sidePages/blogs/AIInsightsBlog";
import NpsInsightBlog from "./components/sidePages/blogs/NpsInsightBlog";
import StrategicPlan from "./components/sidePages/blogs/StrategicPlan";
import Shaadi from "./components/sidePages/casestudy/Shaadi";
import Bata from "./components/sidePages/casestudy/Bata";
import Byjus from "./components/sidePages/casestudy/Byjus";
import Cars24 from "./components/sidePages/casestudy/Cars24";
import Giva from "./components/sidePages/casestudy/Giva";
import Simpolo from "./components/sidePages/casestudy/Simpolo";
import AuSmallFinance from "./components/sidePages/casestudy/AuSmallFinance";
import ReportStoreTrends from "./components/userdashboard/dashReport/ReportStoreTrends";
import StoreBrowserReport from "./components/userdashboard/dashReport/StoreBrowserReport";
import ReportStoreBrowserDetails from "./components/userdashboard/dashReport/ReportStoreBrowserDetails";
import MainTemplatePage from "./components/sidePages/templates/MainTemplatePage";
import CustomerSupportSatisfaction from "./components/sidePages/templates/CustomerSupportSatisfaction";
import NetPromoterScore from "./components/sidePages/solutions/NetPromoterScore";
import CustomerSatisfactionScore from "./components/sidePages/solutions/CustomerSatisfactionScore";
import CustomerEffortScore from "./components/sidePages/solutions/CustomerEffortScore";
import PricingPage from "./components/sidePages/pricing/PricingPage";
import PreviewSection from "./components/userdashboard/preview/PreviewSection";
import ClientUserList from "./components/userdashboard/dashclientUser/ClientUserList";
import Survey from "./components/userdashboard/surveySection/Survey";

// const HomePage = lazy(() => import("./components/homepagecomponents/HomePage"));
// const Login = lazy(() => import('./components/accountinfo/Login'));
// const Registration = lazy(() => import('./components/accountinfo/Registration'));
// const UserDashboardMain = lazy(() => import('./components/userdashboard/UserDashboardMain'));
// const UserDashboardBody = lazy(() => import("./components/userdashboard/UserDashboardBody"));
// const Reports = lazy(() => import("./components/userdashboard/dashReport/Reports"));
// const Projects = lazy(() => import("./components/userdashboard/Projects"));
// const Stores = lazy(() => import("./components/userdashboard/dashStores/Stores"));
// const MainPage = lazy(() => import("./components/MainPage"));
// const ReportList = lazy(() => import("./components/userdashboard/dashReport/ReportList"));
// const ReportListDetail = lazy(() => import("./components/userdashboard/dashReport/ReportListDetail"));
// const DetailsProject = lazy(() => import("./components/userdashboard/dashReport/DetailsProject"));
// const AccountInfoMain = lazy(() => import("./components/accountinfo/AccountInfoMain"));
// const DashProfile = lazy(() => import("./components/userdashboard/dashprofile/DashProfile"));
// const ForgotPassword = lazy(() => import("./components/accountinfo/ForgotPassword"));
// const ResetPassword = lazy(() => import("./components/accountinfo/ResetPassword"));
// const DashChangePassword = lazy(() => import("./components/userdashboard/dashChangePassword/DashChangePassword"));
// const DashProjectSetup = lazy(() => import("./components/userdashboard/dashProjectsetup/DashProjectSetup"));
// const Invoices = lazy(() => import("./components/userdashboard/dashInvoice/Invoices"));
// const ReportStore = lazy(() => import("./components/userdashboard/dashReport/StoreLists"));
// const StoreLists = lazy(() => import("./components/userdashboard/dashReport/StoreLists"));
// const CaseStudyMain = lazy(() => import("./components/sidePages/casestudy/CaseStudyMain"));
// const Manyavar = lazy(() => import("./components/sidePages/casestudy/Manyavar"));
// const BlogsMain = lazy(() => import("./components/sidePages/blogs/BlogsMain"));
// const CheckMarketGap = lazy(() => import("./components/sidePages/blogs/CheckMarketGap"));
// const RetailersAspect = lazy(() => import("./components/sidePages/blogs/RetailersAspect"));
// const StockAvailability = lazy(() => import("./components/sidePages/blogs/StockAvailability"));
// const AuditStore = lazy(() => import("./components/sidePages/blogs/AuditStore"));
// const GenuineReview = lazy(() => import("./components/sidePages/blogs/GenuineReview"));
// const ConsumerInsight = lazy(() => import("./components/sidePages/blogs/ConsumerInsight"));
// const JMC = lazy(() => import("./components/sidePages/casestudy/JMC"));
// const Shott = lazy(() => import("./components/sidePages/casestudy/Shott"));
// const TermsandCondition = lazy(() => import("./components/sidePages/terms&condition/TermsandCondition"));
// const RefundPolicy = lazy(() => import("./components/sidePages/refundPolicy/RefundPolicy"));
// const PrivacyPolicy = lazy(() => import("./components/sidePages/privacyPolicy/PrivacyPolicy"));
// const FrequentyAsked = lazy(() => import("./components/sidePages/faqs/FrequentyAsked"));
// const ContactUs = lazy(() => import("./components/sidePages/contactus/ContactUs"));
// const Questionnaire = lazy(() => import("./components/userdashboard/dashQuestionnaire/QuestionnaireMain"));
// const QuestionnaireInner = lazy(() => import("./components/userdashboard/dashQuestionnaire/QuestionnaireInner"));
// const AssignFormMain = lazy(() => import("./components/userdashboard/dashAssignFormtoStore/AssignFormMain"));
// const ReportDashboard = lazy(() => import("./components/userdashboard/dashReport/ReportDashboard"));
// const ReportBrowser = lazy(() => import("./components/userdashboard/dashReport/ReportBrowser"));
// const ReportStoreBrowser = lazy(() => import("./components/userdashboard/dashReport/ReportStoreBrowser"));
// const ReportAIInsight = lazy(() => import("./components/userdashboard/dashReport/ReportAIInsight"));
// const ReportAIInsightModal = lazy(() => import("./components/userdashboard/dashReport/ReportAIInsightModal"));
// const AIInsightsBlog = lazy(() => import("./components/sidePages/blogs/AIInsightsBlog"));
// const NpsInsightBlog = lazy(() => import("./components/sidePages/blogs/NpsInsightBlog"));
// const StrategicPlan = lazy(() => import("./components/sidePages/blogs/StrategicPlan"));
// const Shaadi = lazy(() => import("./components/sidePages/casestudy/Shaadi"));
// const Bata = lazy(() => import("./components/sidePages/casestudy/Bata"));
// const Byjus = lazy(() => import("./components/sidePages/casestudy/Byjus"));
// const Cars24 = lazy(() => import("./components/sidePages/casestudy/Cars24"));
// const Giva = lazy(() => import("./components/sidePages/casestudy/Giva"));
// const Simpolo = lazy(() => import("./components/sidePages/casestudy/Simpolo"));
// const AuSmallFinance = lazy(() => import("./components/sidePages/casestudy/AuSmallFinance"));
// const ReportStoreTrends = lazy(() => import("./components/userdashboard/dashReport/ReportStoreTrends"));
// const StoreBrowserReport = lazy(() => import("./components/userdashboard/dashReport/StoreBrowserReport"));
// const ReportStoreBrowserDetails = lazy(() => import("./components/userdashboard/dashReport/ReportStoreBrowserDetails"));
// const MainTemplatePage = lazy(() => import("./components/sidePages/templates/MainTemplatePage"));
// const CustomerSupportSatisfaction = lazy(() => import("./components/sidePages/templates/CustomerSupportSatisfaction"));
// const NetPromoterScore = lazy(() => import("./components/sidePages/solutions/NetPromoterScore"));
// const CustomerSatisfactionScore = lazy(() => import("./components/sidePages/solutions/CustomerSatisfactionScore"));
// const CustomerEffortScore = lazy(() => import("./components/sidePages/solutions/CustomerEffortScore"));
// const PricingPage = lazy(() => import("./components/sidePages/pricing/PricingPage"));
// const PreviewSection = lazy(() => import("./components/userdashboard/preview/PreviewSection"));
// const ClientUserList = lazy(() => import("./components/userdashboard/dashclientUser/ClientUserList"));
// const Survey = lazy(() => import("./components/userdashboard/surveySection/Survey"));

// clarity.init('jtcn9xgwfp');
const tagManagerArgs = {
  gtmId: 'GTM-PLKTVFCF'
};
TagManager.initialize(tagManagerArgs);

export const AppContext = createContext();

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
    errorElement: <Error />,

    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/case-studies",
        element: <CaseStudyMain />
      },
      {
        path: "/case-studies/manyavar",
        element: <Manyavar />
      },
      {
        path: "/case-studies/nps-measured-for-a-retail-client",
        element: <Bata/>
      },
      {
        path: "/case-studies/online-matrimonial-nps-measurement",
        element: <Shaadi/>
      },
      {
        path: "/case-studies/nps-measured-for-edtech-global-enterprise",
        element: <Byjus/>
      },
      {
        path: "/case-studies/nps-measured-for-second-hand-automobile-company",
        element: <Cars24/>
      },
      {
        path: "/case-studies/nps-mesurement-for-jewellery-brand",
        element: <JMC />
      },
      {
        path: "/case-studies/csat-measured-for-jewellery-brand",
        element: <Giva/>
      },
      {
        path: "/case-studies/csat-measurement-for-entertainment-company",
        element: <Shott />
      },
      {
        path: "/case-studies/nps-measurement-for-home-construction-company-stores",
        element: <Simpolo/>
      },
      {
        path: "/case-studies/nps-measurement-for-bank",
        element: <AuSmallFinance/>
      },
      {
        path: "/pricing",
        element: <PricingPage/>
      },
      {
        path: "/blog",
        element: <BlogsMain />
      },
      {
        path: "/terms-and-conditions",
        element: <TermsandCondition/>
      },
      {
        path: "/Net-promoter-Score",
        element: <NetPromoterScore/>
      },
      {
        path: "/Customer-Satisfaction-Score",
        element: <CustomerSatisfactionScore/>
      },
      {
        path: "/Customer-Effort-Score",
        element: <CustomerEffortScore/>
      },
      {
        path: "/refund-policy",
        element: <RefundPolicy/>
      },
      {
        path: "/frequently-asked-questions",
        element: <FrequentyAsked/>
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>
      },
      {
        path: "/contact-us",
        element: <ContactUs/>
      },
      {
        path: "/blog/AI-for-Deeper-Customer-Insights",
        element: <AIInsightsBlog/>
      },
      {
        path: "/blog/Leveraging-Monthly-NPS-Insights-Across-Cities",
        element: <NpsInsightBlog/>
      },
      {
        path: "/blog/How-NPS-Measurement-Software-Transforms-Customer-Feedback",
        element: <StrategicPlan/>
      },
      {
        path: "/blog/Market-Gap-in-Products-For-Your-Online-and-Offline-Stores",
        element: <CheckMarketGap />
      },
      {
        path: "/blog/Are-Retailers-Meeting-Your-Branding-Guidelines",
        element: <RetailersAspect />
      },
      {
        path: "/blog/Discrepancies-in-Stock-Availability-at-Your-Retail-Stores",
        element: <StockAvailability />
      },
      {
        path: "/blog/A-Quicker-Way-To-Audit-Your-Stores",
        element: <AuditStore />
      },
      {
        path: "/blog/Genuine-Customer-Reviews-Increase-Sales-Traction-Online",
        element: <GenuineReview />
      },
      {
        path: "/blog/Quick-Insights-On-Your-Target-Consumers-About-Your-Product-Or-Price",
        element: <ConsumerInsight />
      },
      {
        path: "/templates",
        element: <MainTemplatePage/>
      },
      {
      path : "/templates/:url",
      element: <CustomerSupportSatisfaction/>
      },
    ]
  },
  {
    path: "/account-info",
    element: <AccountInfoMain />,
    errorElement: <Error />,
    children: [
      {
        path: "/account-info/login",
        element: <Login />,
      },
      {
        path: "/account-info/registration",
        element: <Registration />,
      },
      {
        path: "/account-info/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/account-info/reset-password",
        element: <ResetPassword />,
      },
    ]
  },
  {
    path: "/userdashboardmain",
    element: <UserDashboardMain />,
    errorElement: <Error />,
    children: [
      {
        path: "/userdashboardmain",
        element: <UserDashboardBody />
      },

      {
        path: "/userdashboardmain/reports",
        element: <Reports />,
      },
      {
        path: "/userdashboardmain/reports/dashboard",
        element: <ReportDashboard />,
      },
      {
        path: "/userdashboardmain/reports/reportbrowser",
        element: <ReportBrowser />,
      },
      {
        path: "/userdashboardmain/reports/storebrowser",
        element: <ReportStoreBrowser />,
      },
      {
        path: "/userdashboardmain/reports/storebrowser/trends",
        element : <ReportStoreBrowserDetails/>,
        children : [
          {
            path: "/userdashboardmain/reports/storebrowser/trends",
            element: <ReportStoreTrends/>,
          },
          {
            path: "/userdashboardmain/reports/storebrowser/trends/storereports",
            element: <StoreBrowserReport/>,
          },
      ]
      },
     
      {
        path: "/userdashboardmain/reports/aiinsights",
        element: <ReportAIInsight />,
      },
      {
        path: "/userdashboardmain/reports/aiinsightmodal/:id",
        element: <ReportAIInsightModal />,
      },
      {
        path: "/userdashboardmain/questionnaire",
        element: <Questionnaire />,
      },
      {
        path: "/userdashboardmain/questionnaire_inner",
        element: <QuestionnaireInner />,
      },
      // {
      //   path: "/userdashboardmain/previewsection",
      //   element: <PreviewSection/>,
      // },
      {
        path: "/userdashboardmain/assignformtostore",
        element: <AssignFormMain />,
      },
      {
        path: "/userdashboardmain/detailsproject",
        element: <DetailsProject />,
      },
      {
        path: "/userdashboardmain/reportslist",
        element: <ReportList />,
      },
      {
        path: "/userdashboardmain/reportstoreslist",
        element: <StoreLists />,
      },
      {
        path: "/userdashboardmain/reportslistdetail/:id",
        element: <ReportListDetail />,
      },
      {
        path: "/userdashboardmain/projects",
        element: <Projects />,
      },
      {
        path: "/userdashboardmain/stores",
        element: <Stores />,
      },
      {
        path: "/userdashboardmain/invoices",
        element: <Invoices />,
      },
      {
        path: "/userdashboardmain/profile",
        element: <DashProfile />,
      },
      {
        path: "/userdashboardmain/client-user",
        element: <ClientUserList/>
      },
      {
        path: "/userdashboardmain/changepassword",
        element: <DashChangePassword />,
      },
      {
        path: "/userdashboardmain/projectsetup",
        element: <DashProjectSetup />,
      },

    ]
  },
  {
    path: "userdashboard/previewsection",
    element: <PreviewSection/>
  },
  {
    path: "/servey/store_map/:storemapid/:id",
    element: <Survey/>
  },
])
function App() {
  return (
    <div>
      <Provider store={store}>
        <AppContext.Provider>
          {/* <Suspense fallback={<Shimmer/>}> */}
        <RouterProvider router={appRouter} />
        {/* </Suspense> */}
        </AppContext.Provider>
      </Provider>
    </div>
  );
}

export default App;


