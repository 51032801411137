import React from 'react';
import { Row, Col, Container } from "react-bootstrap";
import "../../styles/howitworks.css";
import { Link } from 'react-router-dom';


export default function HowitWorks() {
    return (
        <div className='howit_works_home'>
            <Container fluid>
                <Row className=' homepage_sec_one_row'>
                    <Col md='12' className='popular_products'>
                        <h5>Enabling your customer to talk to you. </h5>
                        <p>
                            With real time feedback management system at FloorInsights NPS Survey tool, win your customer again by getting real time insights on the feedback submitted by the customer at your store. The real time alerts set up by you can now be translated to connect with disappointed customers and help yourself understand their pain areas. Also, incentivize your brand promoters.
                            All at real time.

                        </p>
                        <p>Call us or write to us get a demo for the same.</p>
                        <Col md='12' className='banner-btn-container'>
                            <div className='banner-btn-hwitwrks' style={{ textAlign: "left" }}>
                                {/* <Link
                                    to=""
                                    style={{ textDecoration: "none" }}
                                >
                                    Request a Demo
                                </Link> */}
                                  <a
                                    href="https://calendly.com/sourabh-narsaria-floorwalk"
                                    target='_blank'
                                    rel="noreferrer"
                                    style={{ textDecoration: "none" }}
                                >
                                    Request a Demo
                                </a>
                            </div>
                            <div className='banner-btn-hwitwrks' style={{ textAlign: "left" }}>
                                 <Link
                                    to="/account-info/registration"
                                    style={{
                                      textDecoration: "none",
                                        backgroundColor: "#007dc1",
                                        color: "white",
                                    }}
                                >
                                      Get Started
                                </Link>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
