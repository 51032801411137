import { useEffect, useState } from "react";
import "../../../styles/reportstorebrowser.css"
import { Table } from "react-bootstrap";
import { getColor } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import loadingimg from "../../../images/loading.avif";
import base_url from "../../../utils/config";
import { poststoreBrowserGo } from "../../../utils/services/PostdataHandle";

export default function ReportStoreBrowser() {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const [formData, setFormData] = useState({
        city: "",
        percentageTo : "",
        percentageFrom : "",
    });
    const [errorMessages, setErrorMessages] = useState({
        filterError: "",
    });
    const [showYearList, setShowYearList] = useState(false);
    const [filteredData, setFilteredData] = useState(null);
    const [searchingBtn,setSearchingBtn] = useState(false);
    const {data : storeData,isLoading, getApiData, error} = useFetchinFunction();
    const {data : yearData, isLoading: isYearLoading, getApiData: getYearApiData, error:YearError} = useFetchinFunction();
    const userId = localStorage.getItem("user_id");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevState => ({
            ...prevState,
            filterError: ""
        }));
    };

    useEffect(()=>{
        getApiData("client/store?lastStoreId");
        getYearApiData("client/questionnaire_year_list");
    },[])

    const handleNavigation = async (storeId) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };
            const options = {
                method: 'GET',
                headers: headers
            };
            const response = await fetch(base_url+`client/store/${storeId}/questionnaire_types_list`,options);
            const data = await response.json();
            if (data) {
                const questionnaireId = data[0].id;
                navigate("/userdashboardmain/reports/storebrowser/trends", {
                    state: {
                        storeId,
                        questionnaireId,
                    }
                });
            }else {
                alert("Something went wrong, please try after sometime");
            }
        } catch (error) {
            console.error("Error fetching questionnaire data:", error);
        }
    };
    
    
        const handleYearClick = (year) => {
            // const downloadUrl = base_url+`/client/quuestionnaire_wise_xlsx_report?year=${year}`; // Adjust this URL according to your backend logic
            // window.location.href = downloadUrl;
            setShowYearList(false);
        };

        const handleGo = () => {
            const { city, percentageFrom, percentageTo } = formData;

            if ((percentageFrom && !percentageTo) || (!percentageFrom && percentageTo)) {
                setErrorMessages({
                    ...errorMessages,
                    filterError: "Please enter both percentage fields.",
                });
                return;
            }
            if (!city && !percentageFrom && !percentageTo) {
                setErrorMessages(prevState => ({
                    ...prevState,
                    filterError: "Please enter or select at least one value for filtering."
                }));
                return;
            }
    
            setErrorMessages({ filterError: "" });
            setSearchingBtn(true);
            let payload = {
                percent_from: percentageFrom,
                percent_to: percentageTo,
                selected_city: city,
                store_code: "",
            }
            const promise = poststoreBrowserGo(payload, "client/filter_stores");
            promise
              .then((response) => response.json())
              .then((data) => {
                setFilteredData(data);
              })
              .catch((error) => {
                console.error("Error:", error);
              })
              .finally(() => {
               setSearchingBtn(false);
              });
        }
        const handleClear = () => {
            getApiData("client/store?lastStoreId");
            setFormData({
                city : "",
                percentageFrom : "",
                percentageTo : "",
               })
               setFilteredData(null)
        }
    return (
        <>
        <div className="store_brow_main">
            <div className="store_brow_left_box">
                {/* <div>
                    <p className="registration_form_content">
                        <label>Store Code</label>
                        <input type="text" name="questionnaire_name" value={formData.questionnaire_name} onChange={handleChange} />
                        {errorMessages.questionnaire_nameError && (
                            <span className="error_message">{errorMessages.questionnaire_nameError}</span>
                        )}
                    </p>

                </div> */}
                <div className='report_upperpart'>
                    <p className="registration_form_content">
                        <label>City</label>
                        <select name='city'
                            onChange={handleChange}
                            value={formData.city}
                        >
                            <option value="">Select City</option>
                            {storeData?.city_list?.length>0 && storeData?.city_list.map(city=>{
                                return(
                                    <option value={city?.id} key={city?.id}>{city?.name}</option>
                                )
                            })}

                        </select>
                    </p>
                </div>
                <div >
                    <label className='report_date'>Percentage</label>
                    <div style={{ display: "flex", justifyContent: "flex-start", gap: ".5rem" }}>
                        <p className="registration_form_content">
                            <input type="number" maxLength={2} name="percentageFrom" value={formData.percentageFrom} onChange={handleChange} />
                        </p>
                        <p>to</p>
                        <p className="registration_form_content">
                            <input type="number" maxLength={3} name="percentageTo" value={formData.percentageTo} onChange={handleChange} />
                        </p>
                    </div>
                </div>

                <div style={{marginTop:"1rem"}}>  
                    {searchingBtn ?
                    <button className="proof_tag_btn" style={{backgroundColor:"#007DC1",color:"white",}} onClick = {handleGo}><i className="fa fa-spinner fa-spin"></i></button>
                    :
                    <button className="proof_tag_btn" style={{backgroundColor:"#007DC1",color:"white",}} onClick = {handleGo}>Go</button>
                    } 
                    
                </div>
                <div style={{marginTop:"1rem"}}>
                    <button className="proof_tag_btn" style={{backgroundColor:"#007DC1",color:"white",}} onClick={handleClear}>Clear</button>
                </div>
            </div>
        <div className="store_brow_right_box">
                     <div>
                    <p className="registration_form_content dwnload_container">
                        <label>Download</label>
                        <button 
                        onClick={() => setShowYearList(prev => !prev)}
                        className="proof_tag_btn download_btn" 
                        style={{backgroundColor:"#007DC1",color:"white",}}><i className="fa-solid fa-download"></i> Questionnaire Wise Store score <i className="fa-solid fa-chevron-down"></i></button>
                         {showYearList && (
                            <ul className="year-list">
                                {yearData?.questionnnaire_year_list?.map(year => (
                                    <a  
                                    key={year}
                                    href={base_url+`/client/questionnaire_wise_xlsx_report?year=${year}&user=${userId}`}
                                    onClick={() => handleYearClick(year)}
                                    >
                                    <li className="year-item">
                                        {year}
                                    </li>
                                    </a>
                                ))}
                            </ul>
                        )}
                    </p>
                   
                
                </div>
        </div>
        </div>
        {errorMessages.filterError && (
                        <div className="error_message" style={{ color: "red",}}>
                            {errorMessages.filterError}
                        </div>
                    )}
        <div style={{marginTop:"3rem"}} className="table-responsive">
              <Table className="text-center table_border dash_tab_table report_table_mobile table-hover table-responsive">
                    <thead className="dash_tab_table_head">
                        <tr>
                            <th className="fw-bold table_border">#</th>
                            <th className="fw-bold table_border">Name</th>
                            <th className="fw-bold table_border">Address</th>
                            <th className="fw-bold table_border">City</th>
                            <th className="fw-bold table_border">Rank</th>
                            <th className="fw-bold table_border">Total Score Till Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan={7}>
                                    <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
                                </td>
                            </tr>
                        ) : filteredData ? (
                            filteredData.length > 0 ? (
                                filteredData.map((store, index) => (
                                    <tr
                                        style={{ cursor: "pointer" }}
                                        onClick={()=>handleNavigation(store?.id)}
                                        key={store?.id}
                                    >
                                        <td className="table_border">{index + 1}</td>
                                        <td className="table_border">{store?.name}</td>
                                        <td className="table_border">{store?.address}</td>
                                        <td className="table_border">{store?.city?.name}</td>
                                        <td className="table_border">{store?.get_store_rank}</td>
                                        <td
                                            className={[
                                                "table_border",
                                                getColor(store?.get_total_percentage?.color),
                                            ].join(" ")}
                                        >
                                            {store?.get_total_percentage?.score}
                                            {store?.get_total_percentage?.score ? "%" : ""}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7}>No data found for the selected filters</td>
                                </tr>
                            )
                        ) : storeData?.stores_list?.length > 0 ? (
                            storeData?.stores_list.map((store, index) => (
                                <tr
                                    style={{ cursor: "pointer" }}
                                    onClick={()=>handleNavigation(store?.id)}
                                    key={store?.id}
                                >
                                    <td className="table_border">{index + 1}</td>
                                    <td className="table_border">{store?.name}</td>
                                    <td className="table_border">{store?.address}</td>
                                    <td className="table_border">{store?.city?.name}</td>
                                    <td className="table_border">{store?.get_store_rank}</td>
                                    <td
                                        className={[
                                            "table_border",
                                            getColor(store?.get_total_percentage?.color),
                                        ].join(" ")}
                                    >
                                        {store?.get_total_percentage?.score}
                                        {store?.get_total_percentage?.score ? "%" : ""}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={7}>No data for now</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
</>
    )
}