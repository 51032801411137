import "../../../styles/quessectionrows.css";
import { useState, useRef, useEffect } from "react";
import { Table, Alert } from "react-bootstrap";
import AddQuestionModal from "./AddQuestionModal";
import { useFetchAuthCondition } from "../../../utils/hooks/useFetchAuthCondition";
import loadingimg from "../../../images/loading.avif";
import { useDispatch, useSelector } from "react-redux";
import base_url from "../../../utils/config";
import { deleteQuestionData, deleteSectionData, postQuestionSequenceChange } from "../../../utils/services/PostdataHandle";
import { getQuestionType, questionTypes } from "../../../utils/constant";

export default function SectionRows({
  questionnaire_type_id,
  questionnaireAddModalShow,
  setQuestionnaireAddModalShow,
  setEditSectionId,
  setModalTitle,
}) {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const sectionRedux = useSelector((store) => store.sectiondetail);
  const questionRedux = useSelector((store)=>store.questionadd);
  const [expandedReportListRows, setExpandedReportListRows] = useState({});
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const token = localStorage.getItem("token");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedSectionId, setSelectedSectionId] = useState(null);
  const [selectedQuestionId, setSelectedQuestionId] = useState(null);

  const {
    data: sectionData,
    setData: setSectionData,
    isLoading: isSectionDataLoading,
    error: errorMessage,
  } = useFetchAuthCondition(
    `client/questionnaire/${questionnaire_type_id}/section`,
    [sectionRedux,questionRedux]
  );
 
  const toggleReportListRow = (sectionId, event) => {
    const classList = event.target.classList;
    const isExcludedButton =
      classList.contains("proof_tag_btn") ||
      classList.contains("fa-pen-to-square") ||
      classList.contains("fa-xmark");

    if (!isExcludedButton) {
      setExpandedReportListRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [sectionId]: !prevExpandedRows[sectionId],
      }));
    }
  };

  const handleAddQuestion = (sectionId) => {
    console.log("5333",sectionId)
    setSelectedSectionId(sectionId);
    setAddQuestionModal(true);
  };

  const handleEdit = (sectionId) => {
    setEditSectionId(sectionId);
    setModalTitle("Edit Section");
    setQuestionnaireAddModalShow(true);
  };

  const handleDelete = async (sectionId) => {
    const promise = deleteSectionData(`client/section/${sectionId}/`);
    promise
      .then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          setSectionData((prevSectionData) =>
            prevSectionData.filter((section) => section.id !== sectionId)
          );
          setShowAlert(true);
          setMessage("Section was Deleted successfully");
          setTimeout(() => {
            setShowAlert(false);
          }, 1500);
        } else {
          const data = await response.json();
          if (data?.non_field_errors) {
            setShowAlert(true);
            setMessage(data?.non_field_errors[0]);
            setTimeout(() => {
              setShowAlert(false);
            }, 1500);
          } else {
            setShowAlert(true);
            setMessage("Something Went wrong,Failed to delete section");
            setTimeout(() => {
              setShowAlert(false);
            }, 1500);
          }
        }
      })
      .catch(() => {
        setShowAlert(true);
        setMessage("Something Went wrong,Failed to delete section");
        setTimeout(() => {
          setShowAlert(false);
        }, 1500);
      });
  };
  const handleQuestionDelete = async (questionId) => {
    const promise = deleteQuestionData(`client/questions/${questionId}`);
    promise
      .then(async (response) => {
        if (response.status === 200 || response.status === 204) {
          setSectionData((prevSectionData) =>
            prevSectionData.map((section) => ({
              ...section,
              questions: section.questions.filter(
                (question) => question.id !== questionId
              ),
            }))
          );
          setShowAlert(true);
          setMessage("Question was deleted successfully");
          setTimeout(() => {
            setShowAlert(false);
          }, 1500);
        } else {
          const data = await response.json();
          if (data?.non_field_errors) {
            setShowAlert(true);
            setMessage(data?.non_field_errors[0]);
            setTimeout(() => {
              setShowAlert(false);
            }, 1500);
          } else {
            setShowAlert(true);
            setMessage("Something went wrong, failed to delete question");
            setTimeout(() => {
              setShowAlert(false);
            }, 1500);
          }
        }
      })
      .catch(() => {
        setShowAlert(true);
        setMessage("Something went wrong, failed to delete question");
        setTimeout(() => {
          setShowAlert(false);
        }, 1500);
      });
  };
  const handleQuestionEdit = (questionId,sectionId) => {
    setSelectedQuestionId(questionId); 
    setSelectedSectionId(sectionId)
    setAddQuestionModal(true);
  }
  const handleDragStart = (e, sectionId, index) => {
    dragItem.current = { sectionId, index };
  };

  const handleDragEnter = (e, sectionId, index) => {
    dragOverItem.current = { sectionId, index };
  };

  const handleDragEnd = () => {
    const { sectionId: dragSectionId, index: dragIndex } = dragItem.current;
    const { sectionId: dropSectionId, index: dropIndex } = dragOverItem.current;

    if (dragSectionId === dropSectionId && dragIndex !== dropIndex) {
      const updatedSections = [...sectionData];
      const section = updatedSections.find((s) => s.id === dragSectionId);
      const draggedQuestion = section.questions[dragIndex];
      section.questions.splice(dragIndex, 1);
      section.questions.splice(dropIndex, 0, draggedQuestion);

      section.questions.forEach((q, i) => {
        q.sequence = i + 1;
      });

      setSectionData(updatedSections);

      // payload for updating sequences for api
      const updatedQuestionOrder = section.questions.map((q) => ({
        id: q.id,
        sequence: q.sequence,
      }));

      const payload = {
        newsequence: {
          "newsequence" : updatedQuestionOrder
        },
      };
      postQuestionSequenceChange("client/update_sequence/", payload)
        .then(response => console.log(response))
        .catch(error => console.error('Error updating sequence:', error));
    }

    dragItem.current = null;
    dragOverItem.current = null;
  };

  return (
    <>
      {showAlert && (
        <Alert
          variant={
            message === "Section was Deleted successfully" || message === "Question was deleted successfully"
              ? "success"
              : "danger"
          }
          onClose={() => setShowAlert(false)}
          dismissible
          style={{ textAlign: "center" }}
        >
          {message}
        </Alert>
      )}

      {isSectionDataLoading ? (
        <div className="getStarted">
          <img src={loadingimg} alt="Loading..." style={{ width: "200px" }} />
        </div>
      ) : sectionData && sectionData.length > 0 ? (
        sectionData.map((section) => (
          <div className="ques_sec_row_outer" key={section?.id}>
            <div
              className="ques_sec_row_main"
              onClick={(e) => toggleReportListRow(section?.id, e)}
            >
              <div className="ques_sec_row_main_left">
                <div>
                  <i
                    className={`fa-solid ${
                      expandedReportListRows[section.id]
                        ? "fa-chevron-down"
                        : "fa-chevron-right"
                    }`}
                  ></i>
                </div>
                <div>
                  {section?.sequence}- <span>{section?.name}</span>
                </div>
              </div>
              <div className="ques_sec_row_main_right">
                <div>
                  <span>{section?.questions.length}</span> {section?.questions.length>1 ? "Questions" : "Question"}
                </div>
                <button
                  className="proof_tag_btn"
                  onClick={() => handleEdit(section?.id)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button>
                <button
                  className="proof_tag_btn"
                  onClick={() => handleDelete(section?.id)}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
            {expandedReportListRows[section.id] && (
              <div className="expanded-row">
                <div
                  className="dash_report_table table-responsive"
                  style={{ marginTop: "1rem", paddingTop: "0rem" }}
                >
                  {/* <Table className="text-left store_report_table_mobile pricing_table"> */}
                  <Table className="text-left store_report_table_mobile">
                    <thead>
                      <tr className="ques_sec_row_thead">
                        <th className="fw-bold">Question</th>
                        <th className="fw-bold">Question Type</th>
                        <th className="fw-bold">Max. Marks</th>
                        <th className="fw-bold">
                          <button
                            className="proof_tag_btn"
                            onClick={()=>handleAddQuestion(section?.id)}
                          >
                            <i className="fa-solid fa-plus"></i>
                          </button>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {section?.questions && section?.questions.length > 0 ? (
                        section?.questions
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((question, index) => {
                            return (
                              <tr
                                className="ques_sec_row_tbody"
                                key={question?.id}
                                draggable
                                onDragStart={(e) =>
                                  handleDragStart(e, section?.id, index)
                                }
                                onDragEnter={(e) =>
                                  handleDragEnter(e, section?.id, index)
                                }
                                onDragEnd={handleDragEnd}
                                style={{ cursor: "move" }}
                              >
                                <td className="fw-bold">
                                  {question?.question_text}
                                </td>
                                <td className="fw-bold">
                                  {getQuestionType(question?.question_type.trim())}
                                </td>
                                <td className="fw-bold">
                                  {question?.max_marks}
                                </td>
                                <td
                                  className="fw-bold"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: ".5rem",
                                    // marginTop: "1rem",
                                  }}
                                >
                                  <button className="proof_tag_btn" onClick={()=> handleQuestionEdit(question?.id,section?.id)}>
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </button>
                                  <button className="proof_tag_btn" onClick={()=> handleQuestionDelete(question?.id)}>
                                    <i className="fa-solid fa-xmark"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Questions for now...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="add_ques_btn_box">
                  <button className="proof_tag_btn" onClick={()=>handleAddQuestion(section?.id)}>
                    <i className="fa-solid fa-plus"></i> Add Question
                  </button>
                </div>
              </div>
            )}
            <AddQuestionModal
              modalShow={addQuestionModal}
              setModalShow={setAddQuestionModal}
              sectionId = {selectedSectionId}
              questionId={selectedQuestionId}
              setSelectedQuestionId={setSelectedQuestionId}
            />
          </div>
        ))
      ) : (
        <div>
          <h5 style={{textAlign:"center", fontWeight:"bold"}}>No Section for now...</h5>
        </div>
      )}
    </>
  );
}
