import React, { useState, useEffect } from "react";


const OptionBuilder = ({ options: initialOptions, onChange }) => {
  // Default options
  const defaultOptions = [
    { sequence: 1, value: "Yes", marks: 1 },
    { sequence: 2, value: "No", marks: 0 },
  ];

  // Initialize state
  const [options, setOptions] = useState(initialOptions.length > 0 ? initialOptions : defaultOptions);

  // Notify changes when options change
  useEffect(() => {
    onChange(options);
  }, [options]);

  // Function to update options
  const updateOptions = (newOptions) => {
    setOptions(newOptions);
  };

  // Add a new option
  const addOption = () => {
    const lastItem = options[options.length - 1];
    updateOptions([
      ...options,
      {
        sequence: lastItem ? lastItem.sequence + 1 : 0,
        value: "",
        marks: 0,
      },
    ]);
  };

  // Delete an option
  const deleteOption = (index) => {
    updateOptions(options.filter((_, i) => i !== index));
  };

  // Set data for a specific index and key
  const setData = (index, key, data) => {
    updateOptions(
      options.map((o, i) => (i === index ? { ...o, [key]: data } : o))
    );
  };

  // Render rows
  const rows = options.length
    ? options.map((o, i) => (
        <tr key={i}>
          <td>
            <input
              className="form-control"
              type="number"
              name="sequence"
              value={o.sequence}
              onChange={(e) => setData(i, e.target.name, parseInt(e.target.value))}
            />
          </td>
          <td>
            <input
              className="form-control"
              type="text"
              name="value"
              value={o.value}
              onChange={(e) => setData(i, e.target.name, e.target.value)}
            />
          </td>
          <td>
            <input
              className="form-control"
              type="number"
              name="marks"
              value={o.marks}
              onChange={(e) => setData(i, e.target.name, parseInt(e.target.value))}
            />
          </td>
          <td>
            <button  className="proof_tag_btn" type="button" onClick={() => deleteOption(i)}>
            <i className="fa-solid fa-x"></i>
            </button>
          </td>
        </tr>
      )
    )
    : (
      <tr key="empty">
        <td colSpan={4} className="text-muted text-center">at least one option is needed</td>
      </tr>
    );

  return (
    <div className="form-group">
      <table className="table">
        <colgroup>
          <col style={{ width: "20%" }} />
          <col style={{ width: "50%" }} />
          <col style={{ width: "20%" }} />
          <col style={{ width: "10%" }} />
        </colgroup>
        <thead>
          <tr>
            <th>Sequence</th>
            <th>Value</th>
            <th>Marks</th>
            <th>
              <button type="button"  className="proof_tag_btn" onClick={addOption}>
              <i className="fa-solid fa-plus" ></i>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
};

export default OptionBuilder;
