import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shotcard from "../../../images/shotcard.jpg";
import jmccard from "../../../images/jmccard.jpg";
import cardimg from "../../../images/card.png";
import shadiimg from "../../../images/image1.png";

export default function CommonCards() {
    function truncateText(text) {
        if (text.length <= 120) {
          return text;
        }
        return text.substring(0, 120) + '...';
      }
  return (
    <Container fluid className='homepage_brand_container' style={{ marginTop: "2rem" }}>
    <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                <Link to="/case-studies/online-matrimonial-nps-measurement">
                    <div className='homepage_brand_img'>
                        <img src={shadiimg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: "205px" }} />
                    </div>
                    <div className='homepage_brand_text casestudy_brand_text'>
                        <div>
                            <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>Shaadi</h5>
                            <p style={{ margin: "0rem 1rem" }}>{truncateText("Shaadi.com, a leading online matrimonial platform, faced challenges in understanding and enhancing user satisfaction and loyalty. Despite having a large user base, the company struggled to identify specific areas for improvement and address user concerns effectively. The lack of actionable insights from user feedback hindered their ability to make data-driven decisions. To address these issues, Shaadi.com sought to measure their Net Promoter Score (NPS) and implement targeted strategies to improve overall user satisfaction and engagement.")}</p>
                            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                <button className="btn btn-md text-center btn-size create_ac_btn">Online Services</button>
                                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
            <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                    <Link to="/case-studies/nps-mesurement-for-jewellery-brand">
                        <div className='homepage_brand_img'>
                            <img src={jmccard} alt="jmcimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>JMC</h5>
                                <p style={{ margin: "0rem 1rem" }}>{truncateText("JMC, a leading jewelry brand, faced difficulties in accurately gauging and enhancing customer satisfaction and loyalty. Existing feedback mechanisms did not provide actionable insights, hindering JMC's ability to identify and address specific customer concerns. This lack of detailed understanding affected their ability to make informed decisions to improve the shopping experience. To overcome these challenges, JMC sought to measure their Net Promoter Score (NPS) and implement targeted strategies to enhance customer satisfaction and drive sales.")}
                                </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Retail</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
            <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                <Link to="/case-studies/csat-measurement-for-entertainment-company">
                    <div className='homepage_brand_img'>
                        <img src={shotcard} alt="shott_img" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px", height: "auto" }} />
                    </div>
                    <div className='homepage_brand_text casestudy_brand_text'>
                        <div>
                            <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>Shott</h5>
                            <p style={{ margin: "0rem 1rem" }}>{truncateText("Shott, a dynamic gaming and entertainment brand, aimed to enhance customer experience and satisfaction by measuring and improving their Net Promoter Score (NPS). By partnering with FloorInsights, Shott deployed advanced NPS measurement software, distributed surveys at key touchpoints, and analyzed data to identify areas for improvement. ")}</p>
                            <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                <button className="btn btn-md text-center btn-size create_ac_btn">Entertainment</button>
                                <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                    <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>

</Row>
</Container>
  )
}
