import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Bata-Franchise.jpg";
import bataLogo from "../../../images/Bata1_img.jpg";
import CommonCards from './CommonCards';
import { Helmet } from 'react-helmet';


export default function Bata() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
                <Helmet>
        <title>
        FloorInsights | NPS Measurement for Retail Client
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Learn how we assisted a retail client in measuring and improving customer experience through NPS surveys"
        />
        <meta
          name="keywords"
          content="retail client, NPS measurement, customer experience"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={bataLogo} alt="bata_logo" width={"200px"}/>
                <div className='lead manyavar_heading_text'>
                    <span>Bata, a leading global footwear brand, struggled to accurately gauge and improve customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights made it challenging to pinpoint specific areas needing enhancement, hindering their ability to make data-driven decisions to improve the shopping experience. The existing approach was not effectively capturing customer sentiments, leading to missed opportunities for increasing customer loyalty and sales. To address these challenges, Bata aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost overall customer satisfaction and business performance.
                    </span>
                </div>
                <h3 className='lead manyavar_heading'>Bata faced difficulties in improving customer satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to enhance customer loyalty and boost business performance.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={side1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={centerimg} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={side2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                The primary objective was to help Bata, a leading global footwear brand, measure its Net Promoter Score (NPS) and implement strategies to improve overall customer satisfaction. The goal was to understand customer sentiments better, identify areas for improvement, and increase customer loyalty and sales.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Initial Assessment:</b> <br/>
                                    - Conducted an initial assessment of Bata’s current customer feedback mechanisms and overall customer satisfaction levels.
                                    <br />
                                    - Set up the NPS measurement software provided by our SAAS company to collect and analyze customer feedback.
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                             <b> 2. NPS Survey Implementation:</b> <br/>
                                    - Developed and distributed an NPS survey to Bata’s customers through email, SMS, and in-store kiosks to gather feedback on their shopping experiences.
                                    <br />
                                    - Ensured the survey was easy to understand and complete, focusing on key aspects of the customer experience, including product quality, store ambiance, pricing, and customer service.
                                    <br/>
                                    <b> 3. Data Collection and Analysis:</b> <br/>
                                    - Collected responses over a three-month period to gather a significant amount of data for analysis.
                                    <br />
                                    - Used our NPS software to analyze the data, identifying promoters, passives, and detractors.
                                    <br/>
                                    - Examined feedback for common themes and specific issues raised by customers. <br/>
                                    <b>4. Actionable Insights:</b> <br/>
                                    - Provided Bata with detailed reports highlighting key areas of strength and weakness.
                                    <br />
                                    - Identified specific actions Bata could take to improve customer satisfaction, such as enhancing staff training, optimizing store layouts, and adjusting product pricing strategies.
                                    <br/>
                                    <b>5. Implementation of Improvements:</b> <br/>
                                    - Worked with Bata’s management to implement recommended changes based on the NPS feedback.
                                    <br />
                                    - Conducted training sessions for store staff to enhance customer service skills and address identified gaps.
                                    <br/>
                                    - Collaborated with the store operations team to refine store layouts and improve the overall shopping experience.
                                    <br/>
                                    - Worked with the pricing and merchandising teams to ensure competitive pricing and a broad product selection.
                                    <br/>
                                    <b>6. Continuous Monitoring:
                                    </b> <br/>
                                    - Set up a continuous feedback loop using our NPS software to regularly measure customer satisfaction and track the impact of implemented changes.
                                    <br />
                                    - Provided ongoing support to Bata to help them respond to new feedback and make continuous improvements.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Initial NPS Score:</b> <br/> 
                                    - Bata’s initial NPS score was 45, indicating a satisfactory level of customer satisfaction but with notable room for improvement.<br />
                                    <b> 2. Post-Implementation NPS Score:</b> <br/>
                                    - After six months of implementing the recommended changes,  
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                            Bata’s NPS score increased to 70, reflecting a substantial improvement in customer satisfaction. <br/>
                                           <b> 3. Customer Feedback:</b> <br />
                                           - Positive feedback increased, with many customers highlighting improvements in product quality, store ambiance, and customer service. <br/>
                                           - Negative feedback decreased, particularly regarding issues that were addressed, such as store layout and pricing. <br/>
                                           <b> 4. Business Impact:</b> <br />
                                           - Increased customer loyalty, evidenced by a higher number of repeat customers and positive reviews.<br/>
                                           - Improved sales figures, correlating with the increase in customer satisfaction and loyalty. <br/>
                                           - Enhanced brand reputation, leading to an increase in new customer visits driven by positive word-of-mouth referrals.
                                           <br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By leveraging our NPS measurement software and implementing targeted improvements based on customer feedback, Bata successfully enhanced its overall customer satisfaction and achieved a higher NPS score. This case study demonstrates the value of regular customer feedback and the positive impact of data-driven improvements on customer loyalty and business performance.</span>
                </div>
            </div>
         <CommonCards/>
        </div>
    )
}
