import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstPage: {
    inputs: {
      auditor_age_range: "",
      describe: "",
      occupation: [],
      income: [],
      car_cost: [],
      gender: [],
      interest_area: [],
    },
    selectedFile: [],
  },
  secondPage: {
    totalAuditsPerStore: {},
    totalAuditsPerStoreWithStoreId : {},
  }
};

const orderdataslice = createSlice({
  name: 'orderdata',
  initialState,
  reducers: {
    setFormData: (state, action) => {
      const { inputs } = action.payload;
      state.firstPage.inputs = { ...state.firstPage.inputs, ...inputs }
    },
    addFile: (state, action) => {
      state.firstPage.selectedFile.push(action.payload);
    },
  // setAuditCountData: (state, action) => {
  //   const { totalAuditsPerStore } = action.payload;
  //   state.secondPage.totalAuditsPerStore = {...totalAuditsPerStore };
  // },

  
  // setAuditCountDatawithId: (state, action) => {
  //   const { totalAuditsPerStoreWithStoreId } = action.payload;
  //   state.secondPage.totalAuditsPerStoreWithStoreId = {...totalAuditsPerStoreWithStoreId };
  // },
  setAuditCountDatawithId: (state, action) => {
    const { totalAuditsPerStoreWithStoreId } = action.payload;
    state.secondPage.totalAuditsPerStoreWithStoreId = {
      ...state.secondPage.totalAuditsPerStoreWithStoreId,
      ...totalAuditsPerStoreWithStoreId,
    };
  },
  removeStoreAuditCount: (state, action) => {
    const { storeId } = action.payload;
    state.secondPage.totalAuditsPerStoreWithStoreId = {
      ...state.secondPage.totalAuditsPerStoreWithStoreId,
    };
    delete state.secondPage.totalAuditsPerStoreWithStoreId[storeId];
  },
  
  clearStoreData: (state) => {
   state. firstPage = {
    inputs: {
      auditor_age_range: "",
      describe: "",
      occupation: [],
      income: [],
      car_cost: [],
      gender: [],
      interest_area: [],
    }  
  };
  state.secondPage.totalAuditsPerStore = {};
      state.secondPage.totalAuditsPerStoreWithStoreId = {};
  },
  removeFile: (state) => {
    state.firstPage.selectedFile = [];
  }, 
 
}
});

export const { setFormData,setAuditCountData,clearStoreData,setAuditCountDatawithId,removeFile,addFile,removeStoreAuditCount} = orderdataslice.actions;
export default orderdataslice.reducer;