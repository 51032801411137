import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import byjuscard from "../../../images/byjus1.png";
import cars24card from "../../../images/cars_24_img1.png";
import givacard from "../../../images/giva_img1.png";

export default function NewCommonCards() {
    function truncateText(text) {
        if (text.length <= 130) {
          return text;
        }
        return text.substring(0, 130) + "...";
      }
  return (
    <Container fluid className='homepage_brand_container' style={{ marginTop: "2rem" }}>
    <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measured-for-edtech-global-enterprise">
                <div className="homepage_brand_img">
                  <img
                    src={byjuscard}
                    alt="byjusimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Byju's
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "BYJU'S, a leading education technology company, faced challenges in accurately understanding and enhancing user satisfaction and engagement. The existing user feedback mechanisms were insufficient in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make informed, data-driven decisions to elevate the learning experience. To address these issues, BYJU'S aimed to measure their Net Promoter Score (NPS) and implement strategies to boost user satisfaction, loyalty, and engagement"
                      )}{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Apparel
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/csat-measured-for-jewellery-brand">
                <div className="homepage_brand_img">
                  <img
                    src={givacard}
                    alt="givaimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Giva
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Giva, a renowned jewelry brand, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to pinpoint specific areas needing enhancement. This deficiency hindered Giva's ability to make data-driven decisions to elevate the customer experience. To address these issues, Giva aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Online Services
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measured-for-second-hand-automobile-company">
                <div className="homepage_brand_img">
                  <img
                    src={cars24card}
                    alt="cars24img"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Cars 24
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Cars 24, a leading online platform for buying and selling pre-owned cars, faced challenges in accurately measuring and enhancing customer satisfaction and loyalty. Their existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make data-driven decisions to enhance the customer experience. To address these issues, Cars 24 aimed to measure their Net Promoter Score (NPS) and implement strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Apparel
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
</Container>
  )
}
