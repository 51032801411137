import React,{useEffect} from 'react'
import { Helmet } from 'react-helmet';

export default function RefundPolicy() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, []);
  return (
    <>
      <Helmet>
        <title>
        FloorInsights | Refund Policy - Returns and Refunds
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Learn about our refund policy and how to initiate a return or refund"
        />
        <meta
          name="keywords"
          content="refund policy, returns, refunds"
        />
      </Helmet>
    <div className="categoryname_banner indiv_blog_heading frequent_heading" style={{ height: "120px", marginTop: "5rem" }}>
      <h1>Refund and Cancellation Policy</h1>
    </div>
    <div className="blog_content_box">
    <p>FloorWalk Consultants Private Limited reserves all rights to accept, reject or cancel any orders placed by customers through our platforms. In case of cancellation or rejection on our part, any amount debited in this regard shall be refunded within 7 working days.</p>
    <p>After the successful completion of the buy transaction, there is a lock-in period of 24 hours and the customer can reinitiate the reverse transaction only within those 24 hours by emailing us at sourabh@floorwalk.in. Please note that the charges, fees, taxes and other standard overheads will apply to each of these transactions.</p>
    <p>If the transaction fails due to technical issues at our end, any amount debited from the customer’s account will be refunded (to the customer's bank account) within 4 to 5 working days. Any technical problems from the customer’s end that might affect the transaction must be managed by the customer.</p>
    <p style={{marginBottom:"3rem"}}>In Case of Fraudulent Transactions, the Customer whose account has been debited needs to File a Complaint (F.I.R) with the cyber cell and share a copy with us at contactus@floorwalk.in within 24 hours of the transaction. The company shall not bear any loss and if any refund needs to be initiated, it will be done at the Selling Price available on our App. The whole process might take 10 working days.</p>
    </div>
    </>
  )
}
