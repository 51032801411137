import { Link } from "react-router-dom";

export default function Template ({templateShow,setTemplateShow}) {
    function handleAllTemplates(){
      // navigate("/templates") // it didn't work due to position fixed
      // window.location.href = '/templates';  // will work but used Link to navigate
      setTemplateShow(false);
    
    }
  return(
    <div className="templet_dropdown">
    <div className="template_row">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div className="template_row">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div className="template_row">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div>
      <div className="template_down">
        <Link to="/templates" onClick={handleAllTemplates}><i class="fa-solid fa-list"></i>See all templates</Link>
        </div>
    </div>
    </div>
  )
  }