import { createSlice } from '@reduxjs/toolkit';

const stepSlice = createSlice({
  name: 'step',
  initialState: {
    currentStep: 0,
  },
  reducers: {
    setCurrentStepRedux: (state, action) => {
      state.currentStep = action.payload;
    },
    setCurrentSteptoZero: (state) => {
      state.currentStep = 0;
    },
  },
});

export const { setCurrentStepRedux,setCurrentSteptoZero } = stepSlice.actions;
export default stepSlice.reducer;
