// import React, { useEffect } from 'react';
// import { Row, Col, Container, Carousel, Modal } from "react-bootstrap";
// import "../../styles/homecontact.css";
// import img1 from "../../images/howitworks_1.webp";

// export default function HomeContacts() {


//   return (
//     <div className='homecontact_main'>
//       <h3>FloorInsights Contact details & Services here </h3>
//       <div className='homecontact_box'>
//         <div className='homecontact_small_box'>
//           <h5>See How it works</h5>
//           <div className='homecontact_small_box_img'>
//             <img src={img1} alt='img1' />
//           </div>
//           <div className='homecontact_heading'>
//               <p style={{marginBottom:"0rem"}}>Customer Support</p>
//             </div>
//             <div className='homecontact_text'>
//               <p style={{marginBottom:"0rem"}}>Customer Support Customer Support Customer Support </p>
//             </div>
//         </div>
//         <div className='homecontact_small_box'>
//           <h5>See How it works</h5>
//           <div className='homecontact_small_box_img'>
//             <img src={img1} alt='img1' />
//           </div>
//           <div className='homecontact_heading'>
//               <p style={{marginBottom:"0rem"}}>Customer Support</p>
//             </div>
//             <div className='homecontact_text'>
//               <p style={{marginBottom:"0rem"}}>Customer Support Customer Support Customer Support </p>
//             </div>
//         </div>
//         <div className='homecontact_small_box'>
//           <h5>See How it works</h5>
//           <div className='homecontact_small_box_img'>
//             <img src={img1} alt='img1' />
//           </div>
//           <div className='homecontact_heading'>
//               <p style={{marginBottom:"0rem"}}>Customer Support</p>
//             </div>
//             <div className='homecontact_text'>
//               <p style={{marginBottom:"0rem"}}>Customer Support Customer Support Customer Support </p>
//             </div>
//         </div>
//       </div>
//     </div>
//   )
// }


import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import "../../styles/homebrand.css";
import AIimage from "../../images/Artificial_Intelligence.avif";
import npsImage from "../../images/nps_img.jpg";
import strategicImg from "../../images/strategic_plan_img.jpg";

import { Link } from 'react-router-dom';

export function BrandDetails() {
    return (

      <Row className='homepage_brand blogs_main_box' style={{ paddingTop: "1rem" }}>
   
      <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blog/AI-for-Deeper-Customer-Insights">
                            <div className='homepage_brand_img'>
                            <img src={AIimage} alt="Aiimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} className='blog_img'/>
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem", }}> <b> Harnessing AI for Deeper Customer Insights: How Sentiment Analysis Revolutionizes NPS Measurement
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>In today's competitive market, understanding customer feedback is crucial for businesses to thrive. While traditional Net Promoter Score (NPS) provides valuable quantitative insights, it often misses the underlying emotions driving customer sentiments. This is where AI-driven sentiment analysis comes into play, offering a deeper, qualitative understanding of customer feedback</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blog/Leveraging-Monthly-NPS-Insights-Across-Cities">
                            <div className='homepage_brand_img'>
                                <img src={npsImage} alt="npsimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} className='blog_img'/>
                            </div>
                            <div className='homepage_brand_text  blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem" }}> <b> Achieving Continuous Improvement: Leveraging Monthly NPS Insights Across Cities, Regions, and Countries
                                    </b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>In the retail industry, customer satisfaction varies significantly across different geographical levels. To achieve consistent month-on-month improvement, it is essential to track and analyze NPS data not only at the store level but also across cities, regions, and countries. Our SaaS-based NPS measurement software provides the tools to gain these valuable insights, driving tailored strategies for diverse markets.</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <Link to="/blog/How-NPS-Measurement-Software-Transforms-Customer-Feedback">
                            <div className='homepage_brand_img'>
                                <img src={strategicImg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} className='blog_img'/>
                            </div>
                            <div className='homepage_brand_text blogs_main_box_text'>
                                <div>
                                    <h6 style={{ margin: "1rem", textTransform: "uppercase", lineHeight: "1.3rem",marginTop:"0rem" }}> <b> From Data to Delight: How NPS Measurement Software Transforms Customer Feedback into Strategic ActionsAvailability at Your Retail Stores</b></h6>
                                    <p style={{ margin: "0rem 1rem", height: "auto" }}>Customer feedback is a goldmine of insights, but turning this data into actionable strategies requires more than just collection and analysis. Our NPS measurement software transforms raw feedback into strategic actions that drive customer satisfaction and business growth. Here's how we help you turn data into delight.</p>
                                </div>
                            </div>
                        </Link>
                    </Col>
  </Row>
    )
}

export default function HomeBlogs() {
    return (
        <Container fluid className='homepage_brand_container'>
            <div className='homepage_brand_heading'>
                <h5>Suggested Reads</h5>
            </div>
            <BrandDetails />
        </Container>
    )
}
