import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ausmallfincard from "../../../images/au_small_finance_img.webp";
import piaggiocenter from "../../../images/ausmallfinance.jpg";
import piaggioside1 from "../../../images/piaggioside1.png";
import piaggioside2 from "../../../images/piaggioside2.png";
import CommonCards from './CommonCards';
import { Helmet } from 'react-helmet';

export default function AuSmallFinance() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
                   <Helmet>
        <title>
        FloorInsights | NPS Measurement for Bank
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Read how our NPS solution helped a bank enhance customer experience and loyalty through NPS measurement and improvement strategies"
        />
        <meta
          name="keywords"
          content="bank, NPS measurement, customer experience"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={ausmallfincard} alt="ausmallfin_logo" style={{width:"200px",margin:"2rem 0rem"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>AU Small Finance Bank faced difficulties in accurately measuring and improving customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights hindered their ability to identify specific areas needing enhancement in their services and processes. Consequently, AU Small Finance Bank struggled to make data-driven decisions to elevate the customer experience and support services. To address these issues, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance.
                    </span>
                </div>
                <h3 className='lead manyavar_heading'>AU Small Finance Bank struggled to enhance customer satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={piaggioside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={piaggiocenter} alt="" style={{ width: "100%", height: "250px" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={piaggioside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                AU Small Finance Bank aimed to enhance their customer experience and satisfaction. Their primary objective was to measure and improve their Net Promoter Score (NPS) to gain insights into customer loyalty, identify areas for improvement, and ultimately increase customer satisfaction and retention rates.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Implementation of NPS Measurement Software:</b> <br/>
                                    - AU Small Finance Bank partnered with FloorInsights to deploy our advanced NPS measurement software.
                                    <br />
                                    - The software was seamlessly integrated with AU Small Finance Bank's CRM and customer service   
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                            management systems, ensuring comprehensive data collection and analysis. <br/>
                                             <b> 2. Survey Distribution:</b> <br/>
                                    - NPS surveys were sent to customers at various touchpoints, including after opening an account, following loan disbursement, and after customer service interactions.
                                    <br />
                                    <b> 3. Data Analysis and Insights:</b> <br/>
                                    - The software aggregated and analyzed the NPS data, providing detailed reports and interactive dashboards.
                                    <br />
                                    - Key drivers of customer satisfaction and dissatisfaction were identified by segmenting feedback based on product type, customer demographics, and interaction history.
                                    <br/>
                                    <b>4. Actionable Recommendations:</b> <br/>
                                    - Based on the insights, we provided AU Small Finance Bank with specific, actionable recommendations, such as improving the account opening process, enhancing loan processing times, and streamlining customer service procedures.
                                    <br />
                                    - We also suggested personalized engagement strategies for different customer segments to foster loyalty and reduce churn.
                                    <br/>
                                    <b>5. Continuous Monitoring and Improvement:</b> <br/>
                                    - A continuous feedback loop was established to monitor changes in NPS over time and measure the impact of implemented changes.
                                    <br />
                                    - Regular check-ins and quarterly reviews ensured that AU Small Finance Bank stayed on track with their customer satisfaction goals and could adjust strategies as needed.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Improved NPS:</b> <br/> 
                                    - OOver six months, AU Small Finance Bank saw a significant increase in their NPS, rising from 35 to 65.<br />
                                    <b> 2. Enhanced Customer Satisfaction:</b> <br/>
                                    - Customer feedback highlighted improvements in account opening processes, loan processing times, and customer service efficiency.   
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           <b> 3. Increased Customer Retention:</b> <br />
                                           - The improved customer satisfaction led to a 20% increase in customer retention rates and an overall growth in customer base by 10%. <br/>
                                           <b> 4. Better Customer Insights:</b> <br />
                                           - The detailed insights from NPS data enabled AU Small Finance Bank to make data-driven decisions, leading to a more customer-centric approach in product development and customer support.<br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By partnering with FloorInsights, AU Small Finance Bank successfully transformed their customer experience, leading to higher satisfaction, increased loyalty, and a stronger market position.
                    </span>
                </div>
            </div>
           <CommonCards/>
        </div>
    )
}
