import React,{createContext,useState,useEffect} from 'react';
import Header from './headers/Header';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import base_url from '../utils/config';


export default function MainPage() {
   
    // useEffect(() => {
    //   document.title = "FloorWalk Market Place"   
    // }, []);

    return (
      <>
            <Header />
            <Outlet />
            <Footer />
      </>
    )
}
