import React,{useEffect} from 'react'
import HomeBanner from './HomeBanner'
import HowitWorks from './HowitWorks'
import HomeSolution from './HomeSolution'
import HomeBrand from './HomeBrand'
import base_url from '../../utils/config'
import HomeLogoSlider from './HomeLogoSlider'
import HomeImage from './HomeImage'
import HomeSurvey from './HomeSurvey'
import HomeMore from './HomeMore'
import HomeBlogs from './HomeBlogs'
import { Helmet } from 'react-helmet'

export default function HomePage() {
	useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
		// document.title = "FloorInsights | NPS Measurement Software | Calculate Your Net Promoter Score | FloorInsights"
    },[]);

  return (
	<div>
		 <Helmet>
        <title>
        FloorInsights | NPS Measurement Software | Calculate Your Net Promoter Score | FloorInsights
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Measure customer loyalty with our NPS measurement software. Get actionable insights and improve customer experience. Start calculating your Net Promoter Score today!"
        />
        <meta
          name="keywords"
          content="NPS, CSAt, Net promoter score, measure customer feedback"
        />
      </Helmet>
	  <HomeBanner/>
	  <HomeLogoSlider/>
	  <HowitWorks/>
	  <HomeImage/>
	  {/* <HomeSolution/> */}
	  <HomeSurvey/>
	  <HomeMore/>
	  <HomeBrand/>
	  <HomeBlogs/>
	</div>
  )
}
