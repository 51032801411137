import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import shotcard from "../../../images/shotcard.jpg";
import givacard from "../../../images/giva_img1.png";
import byjuscard from "../../../images/byjus1.png";
import cars24card from "../../../images/cars_24_img1.png";
import jmccard from "../../../images/jmccard.jpg";
import simpolocard from "../../../images/simpolo_img1.png";
import ausmallfincard from "../../../images/au_small_finance_img.webp";
import shadiimg from "../../../images/image1.png";
import "../../../styles/casestudy.css";
import Automobile from "./Automobile";
import cardimg from "../../../images/Bata1_img.jpg";
import OnlineServices from "./OnlineServices";
import Retail from "./Retail";
import Entertainment from "./Entertainment";
import FinancialInstitutes from "./FinancialInstitutes";
import { Helmet } from "react-helmet";

export default function CaseStudyMain() {
  const [activeTab, setActiveTab] = useState("All Case Studies");
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  function truncateText(text) {
    if (text.length <= 130) {
      return text;
    }
    return text.substring(0, 130) + "...";
  }

  return (
    <>
         <Helmet>
        <title>
        FloorInsights | NPS Case Studies - Success Stories
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Explore our collection of case studies showcasing successful NPS measurement and improvement strategies"
        />
        <meta
          name="keywords"
          content="NPS case studies, success stories, customer experience"
        />
      </Helmet>
      <div
        className="indiv_blog_heading"
        style={{ height: "120px",marginTop:"5rem" }}
      >
        <h1 style={{ fontSize: "2.5rem" }}>Case Studies</h1>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1.2rem",
          marginTop: "2rem",
          padding: "0rem 1rem",
        }}
        className="casestudy_tab_box"
      >
        <p onClick={() => setActiveTab("All Case Studies")}>All Case Studies</p>
        <p onClick={() => setActiveTab("FinancialInstitutes")}>Financial Institutes</p>
        <p onClick={() => setActiveTab("Online Services")}>Online Wedding Services</p>
        <p onClick={() => setActiveTab("Automobile")}>Automobile</p>
        <p onClick={() => setActiveTab("Retail")}>Retail</p>
        <p onClick={() => setActiveTab("Entertainment")}>Entertainment</p>
      </div>
      {activeTab === "All Case Studies" && (
        <Container fluid className="homepage_brand_container">
          <Row
            className="homepage_brand casestudy_box_container"
            style={{ paddingTop: "1rem" }}
          >
             <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/online-matrimonial-nps-measurement">
                <div className="homepage_brand_img">
                  <img
                    src={shadiimg}
                    alt="manyvarimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "205px",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Shaadi
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Shaadi.com, a leading online matrimonial platform, faced challenges in understanding and enhancing user satisfaction and loyalty. Despite having a large user base, the company struggled to identify specific areas for improvement and address user concerns effectively. The lack of actionable insights from user feedback hindered their ability to make data-driven decisions. To address these issues, Shaadi.com sought to measure their Net Promoter Score (NPS) and implement targeted strategies to improve overall user satisfaction and engagement."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem .3rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                      online wedding service 
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-mesurement-for-jewellery-brand">
                <div className="homepage_brand_img">
                  <img
                    src={jmccard}
                    alt="jmcimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      JMC
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      The challenge at hand was to elevate JMC's standing and
                      performance within the Indore jewelry market. To achieve
                      this objective, the focus was on...
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/csat-measurement-for-entertainment-company">
                <div className="homepage_brand_img">
                  <img
                    src={shotcard}
                    alt="shottimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Shott
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      Shott's challenge was to effectively benchmark against 22
                      competitors in the combined markets of Pune and
                      Hyderabad.Their objective was to...{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Entertainment
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
           
          </Row>
          <Row
            className="homepage_brand casestudy_box_container"
            style={{ paddingTop: "1rem" }}
          >
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measured-for-edtech-global-enterprise">
                <div className="homepage_brand_img">
                  <img
                    src={byjuscard}
                    alt="byjusimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Byju's
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "BYJU'S, a leading education technology company, faced challenges in accurately understanding and enhancing user satisfaction and engagement. The existing user feedback mechanisms were insufficient in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make informed, data-driven decisions to elevate the learning experience. To address these issues, BYJU'S aimed to measure their Net Promoter Score (NPS) and implement strategies to boost user satisfaction, loyalty, and engagement"
                      )}{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                       Education
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/csat-measured-for-jewellery-brand">
                <div className="homepage_brand_img">
                  <img
                    src={givacard}
                    alt="givaimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Giva
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Giva, a renowned jewelry brand, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to pinpoint specific areas needing enhancement. This deficiency hindered Giva's ability to make data-driven decisions to elevate the customer experience. To address these issues, Giva aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measured-for-second-hand-automobile-company">
                <div className="homepage_brand_img">
                  <img
                    src={cars24card}
                    alt="cars24img"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Cars 24
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Cars 24, a leading online platform for buying and selling pre-owned cars, faced challenges in accurately measuring and enhancing customer satisfaction and loyalty. Their existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make data-driven decisions to enhance the customer experience. To address these issues, Cars 24 aimed to measure their Net Promoter Score (NPS) and implement strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                       Automobile
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
          <Row
            className="homepage_brand casestudy_box_container"
            style={{ paddingTop: "1rem" }}
          >
             <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measured-for-a-retail-client">
                <div className="homepage_brand_img">
                  <img
                    src={cardimg}
                    alt="bataimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Bata
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Bata, a leading global footwear brand, struggled to accurately gauge and improve customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights made it challenging to pinpoint specific areas needing enhancement, hindering their ability to make data-driven decisions to improve the shopping experience. The existing approach was not effectively capturing customer sentiments, leading to missed opportunities for increasing customer loyalty and sales. To address these challenges, Bata aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost overall customer satisfaction and business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measurement-for-home-construction-company-stores">
                <div className="homepage_brand_img">
                  <img
                    src={simpolocard}
                    alt="simpoloimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Simpolo
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Simpolo, a leading tile manufacturer, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were insufficient in providing detailed, actionable insights, making it difficult to identify specific areas needing enhancement. This gap hindered Simpolo's ability to make data-driven decisions to elevate the customer experience and support services. To address these issues, Simpolo aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measurement-for-bank">
                <div className="homepage_brand_img">
                  <img
                    src={ausmallfincard}
                    alt="au_small_finance_img"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "210px",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      AU Small Finance
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "AU Small Finance Bank faced difficulties in accurately measuring and improving customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights hindered their ability to identify specific areas needing enhancement in their services and processes. Consequently, AU Small Finance Bank struggled to make data-driven decisions to elevate the customer experience and support services. To address these issues, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                      Financial Institutes
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
           
          </Row>
        </Container>
      )}
      {activeTab === "FinancialInstitutes" && <FinancialInstitutes/>}
      {activeTab === "Automobile" && <Automobile />}
      {activeTab === "Online Services" && <OnlineServices />}
      {activeTab === "Retail" && <Retail />}
      {activeTab === "Entertainment" && <Entertainment />}
    </>
  );
}
