import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export default function AuditStore() {
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
    return (

        <>
                    <Helmet>
        <title>
        FloorInsights | Streamline Store Audits with NPS Insights
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Learn how NPS insights can help you conduct faster and more effective store audits, ensuring consistency and excellence in customer experience."
        />
        <meta
          name="keywords"
          content="NPS, CSAt, Net promoter score, measure customer feedback"
        />
      </Helmet>
            <div className='categoryname_banner indiv_blog_heading' style={{ height: "120px",marginTop:"5rem" }}>
                <h1>Unveiling FloorWalk Marketplace: Revolutionizing On-Ground Research and Auditing (A Quicker Way To Audit Your Stores)
                </h1>
            </div>
            <div className='blog_content_box'>
                <p>In a world where understanding consumer behaviour and gaining insights into market trends is crucial for businesses, FloorWalk Marketplace emerges as a game-changer in the realm of research and audit solutions. With a staggering network of over 500,000 field force agents spread across the nation, FloorWalk offers a seamless, plug-and-play platform that swiftly delivers on-ground surveys and retail audits, making it easier than ever before.

                </p>
                <p>This innovative platform is a do-it-yourself research and mystery auditing solution that caters to the needs of diverse industries. It empowers managers by providing a straightforward process: they can handpick a specific service relevant to market research, auditing, or mystery shopping. By simply submitting essential details such as the product or service name, its description, field agent demographics, and a list of stores or locations, managers can swiftly set their projects live.
                </p>
                <p>What truly sets FloorWalk Marketplace apart is its efficient execution strategy. Once a project goes live, FloorWalk takes charge by engaging its network of field agents. These agents are carefully selected to match the criteria specified by the managers, ensuring that the data collected is accurate and reliable. Upon completion, comprehensive reports are shared directly on the managers' login dashboard, providing immediate access to valuable insights.


                </p>
                <p>One of the platform's most compelling features is its ability to streamline the entire process, from project initiation to data collection and reporting. The managers benefit not only from the speed of execution but also from the convenience of collating data from various regions on a single, user-friendly platform.</p>
                <p>Moreover, the FloorWalk marketplace offers an attractive proposition with its pricing structure. A flat rate is provided for each service, eliminating the need for prolonged negotiations. This approach allows managers the flexibility to conduct projects of any scale, whether big or small, whenever and wherever they desire. The affordability and flexibility afforded by FloorWalk Marketplace make it an ideal choice for businesses seeking actionable, cost-effective insights.

                </p>
                <p>The platform's user-friendly interface and intuitive design make it accessible to managers from diverse industries. Whether it's a retail giant aiming to assess customer satisfaction across multiple locations or a start-up looking to conduct a niche market survey, FloorWalk accommodates all requirements.
                </p>
                <p>In an ever-evolving market landscape, the ability to adapt swiftly to changing consumer preferences and market trends is a competitive advantage. With FloorWalk Marketplace, businesses can stay ahead of the curve by harnessing the power of on-ground insights, thereby fostering informed decision-making and sustained growth.</p>
                <p>As the business landscape continues to evolve, having access to such actionable data has become not just an advantage but a necessity, and FloorWalk excels in meeting this need.



                </p>

                {/* <p><b>Brands that have leveraged audit programs to close the market gap and reach underserved customers:
                </b></p> */}
                {/* <ul>
                    <Link to="/case-studies/manyavar" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside", marginBottom: ".5rem" }}> <b> Manyavar :</b> Apparel & Ethnic Wear Brand</li></Link>
                    <Link to="/case-studies/manyavar" style={{ textUnderlineOffset: "8px", color: "#007DC1" }}> <li style={{ listStyle: "inside" }}> <b> Manyavar :</b> Apparel & Ethnic Wear Brand</li></Link>
                </ul> */}
                {/* <p><b>Conclusion: </b> As a retail/sales manager, maintaining on-shelf availability is a vital part of our brand's success and customer satisfaction. Mystery shopping and auditing companies provide a proactive solution to monitor the adherence of partner retailers to product stocking and placement agreements. In today's competitive retail environment, this level of monitoring and compliance is essential for maintaining brand visibility and meeting customer demand.

                </p> */}
            </div>

            <div className='blog_button_container'>
                <button
                    className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    onClick={() =>
                        setModalShow(
                            true,
                        )
                    }
                >
                    Talk to a consultant
                </button>
                <Link to="/exploremarketplace">
                    <button
                        className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                    >
                        Explore Marketplace
                    </button>
                </Link>
            </div>
            <Modal
                show={modalShow}
                centered
                size='lg'
                style={{ marginTop: '40px' }}
                scrollable={true}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setModalShow(false)}>
                <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
                    onClick={() => setModalShow(false)}
                ><i className="fa-solid fa-x"></i></div>
                <iframe
                    frameBorder='0'
                    style={{
                        height: '500px',
                        width: '99%',
                        border: 'none',
                    }}
                    src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
            </Modal>
        </>
    )
}
