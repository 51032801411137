import React,{useEffect} from 'react';
import { Table, Button } from 'react-bootstrap';
import "../../../styles/pricing.css";

const PricingPage = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
      }, []);
  return (
    <>
     <div
        className="indiv_blog_heading"
        style={{ height: "120px", marginTop: "5rem" }}
      >
        <h1 style={{ fontSize: "2.5rem" }}>Pricing</h1>
      </div>
   
    <div className="container" style={{ marginTop: "5rem", marginBottom:"5rem" }}>
      <Table className='table-responsive pricing_table'>
        <thead>
          <tr>
            <th style={{ backgroundColor: "#f0faff", padding: "1rem 0rem" }}></th>
            <th style={{ backgroundColor: "#f0faff", padding: "1rem 0rem" }} >
                <div className='head_box'>
             <h5>Basic</h5> 
                <Button className="try-button">Try for Free</Button>
              </div>
            </th>
            <th style={{ backgroundColor: "#f0faff", padding: "1rem 0rem" }}>
                <div className='head_box'>
              <h5>Advanced</h5>
                <Button className="try-button">Try for Free</Button>
              </div>
            </th>
            <th style={{ backgroundColor: "#f0faff", padding: "1rem 0rem" }}>
                <div className='head_box'>
             <h5> Enterprise</h5>
                <Button  className="try-button">Try for Free</Button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
        <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Pricing</td>
          </tr>
          <tr>
            <td>30 days free trial</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Monthly plan</td>
            <td className='text-center'>500</td>
            <td className='text-center'>1200</td>
            <td className='text-center'>5000</td>
          </tr>
          <tr>
            <td>Yearly plan</td>
            <td className='text-center'>5000</td>
            <td className='text-center'>10000</td>
            <td className='text-center'>50000</td>
          </tr>
          {/* -------------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>No of Responses</td>
          </tr>
          <tr >
            <td>No of Questionnaire</td>
            <td className='text-center'>3</td>
            <td className='text-center'>10</td>
            <td className='text-center'>unlimited</td>
          </tr>
          <tr>
            <td>No. of Surveys</td>
            <td className='text-center'>2500 per month</td>
            <td className='text-center'>10000 per month</td>
            <td className='text-center'>unlimited</td>
          </tr>
          <tr>
            <td>No of Stores</td>
            <td className='text-center'>3</td>
            <td className='text-center'>20</td>
            <td className='text-center'>unlimited</td>
          </tr>
          <tr>
            <td>Questionnaire customization</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>AI Features</td>
          </tr>
          <tr>
            <td>Sentimental Analysis</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Word Cloud </td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>AI Summary</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Positive & Negative Keywords</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Real-Time Analysis </td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* -------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Reporting</td>
          </tr>
          <tr>
            <td>Month-on-month store improvement protocols</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Trend Analysis </td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Real time results</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Share survey data</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Data Trend  </td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>CX Overview Dashboard</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Storewise & Questionnaire level reports</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>All store reporting</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Print Reports </td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Download Reports (.PDF & .XLSX )</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* -------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Automation</td>
          </tr>
          <tr>
            <td>Custom Workflows</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Custom Alerts</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Notification Workflows</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Response Workflows</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
          {/* <td style={{overflow: "hidden", width: "280px", textAlign: "left", valign: "top", whitespace: "nowrap"}}>User & Respondent Notifications for All Responses</td> */}
          <td>User & Respondent Notifications for All Responses</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Creating dynamic links and segments</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Free Email notifications</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>WhatsApp notifications</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* ----------------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Customization & Branding</td>
          </tr>
          <tr>
            <td>Multilingual Surveys </td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Add your logo</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Progress bar</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Custom URL</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Remove FloorInsights footer</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* ------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Instant Support</td>
          </tr>
          <tr>
            <td>12/5 email support</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Phone support</td>
            <td className='text-center'><i class="fa-solid fa-xmark"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* ---------------------- */}
          <tr>
            <td colSpan="4" className="fw-bold" style={{padding:"1rem 0rem"}}>Team Management</td>
          </tr>
          <tr>
            <td>Admin Level</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          <tr>
            <td>Manager Level</td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
            <td className='text-center'><i className="fa-solid fa-check"></i></td>
          </tr>
          {/* ---------------- */}
        
        </tbody>
      </Table>
    </div>
    </>
  );
};

export default PricingPage;
