import { configureStore } from "@reduxjs/toolkit";
import orderdataslice from "../orderdataslice";
import draftDataSlice from "../draftDataSlice";
import stepSlice from "../stepSlice";
import sectionDetailSlice from "./sectionDetailSlice";
import questionAddSlice from "./questionAddSlice";
import clientUserAddSlice from "./clientUserAddSlice";

const store = configureStore({
    reducer: {
        orderdata: orderdataslice,
        draftData: draftDataSlice,
        step: stepSlice,
        sectiondetail: sectionDetailSlice,
        questionadd : questionAddSlice,
        clientuseradd : clientUserAddSlice,
    },
})

export default store;