import React, { useEffect } from 'react'
import { Row, Col, Container, Carousel, Modal } from "react-bootstrap";
import img1 from "../../images/graph_img1.png";
import gif1 from "../../images/nps.gif";
import "../../styles/homeimage.css";

export default function HomeImage() {


  return (
    <div className='home_image_main'>

      <div className='howit_works'>
        <h5>See How it works</h5>
        <div className='homeimage_box'>
          <div className='homeimage_sm_box'>
            <div className='homeimage_xs_box'>
              <h3>Create brand promoters</h3>
              <p>Management level escalation matrix to win your customer again and create brand promoters</p>
            </div>
            <div className='homeimage_xs_box'>
              <h3>Instant Acknowledgement </h3>
              <p>Create algorithms for addressing customer concerns and incentivization strategies for retention</p>
            </div>
          </div>
          <div className='homeimage_img_box'>
            <img src={img1} alt='img1' />
          </div>
          <div className='homeimage_sm_box'>
            <div className='homeimage_xs_box'>
              <h3>Hear voice of customers</h3>
              <p>Create an automated tool to hear voice of your customers and read it real time through FloorInsights</p>
            </div>
            <div className='homeimage_xs_box'>
              <h3>Define the customer sentiment</h3>
              <p>Bucket the data and created automated algos for reading consumer data and escalations</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
