import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import garmincard from "../../../images/garmaincard.png";
import vajorcard from "../../../images/vajorcard.png";
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";
import ausmallfincard from "../../../images/au_small_finance_img.webp";

export default function FinancialInstitutes() {
    function truncateText(text) {
        if (text.length <= 130) {
          return text;
        }
        return text.substring(0, 130) + "...";
      }
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }} md="4"
            >
              <Link to="/case-studies/nps-measurement-for-bank">
                <div className="homepage_brand_img">
                  <img
                    src={ausmallfincard}
                    alt="au_small_finance_img"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "210px",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      AU Small Finance
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "AU Small Finance Bank faced difficulties in accurately measuring and improving customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights hindered their ability to identify specific areas needing enhancement in their services and processes. Consequently, AU Small Finance Bank struggled to make data-driven decisions to elevate the customer experience and support services. To address these issues, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                      Financial Institutes
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
                </Row>
                
            </Container>
  )
}
