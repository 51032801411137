import React,{useEffect, useState} from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import { postQuestionnaireNameEdit } from '../../../utils/services/PostdataHandle';
import loadingImg from "../../../images/loading.avif";

const EditQuestionnaireModal = ({modalShow, setModalShow, questionnaireId, setSubmitData, setQuestionnaireId}) => {
    const [formData, setFormData] = useState({
        questionnaire_name: "",
      });
      const [errorMessage, setErrorMessage] = useState({
        questionnaire_nameError: "",
      });
      const [loadingButton,setLoadingButton] = useState(false);
      const {data:questionnaireNameData ,isLoading: loadingQuestionNameData, getApiData} = useFetchinFunction()
      console.log("15",questionnaireId)
      useEffect(()=>{
         if(questionnaireId){
            getApiData(`client/cleint_quesstionnaire/${questionnaireId}`)
         }
      },[questionnaireId])
      useEffect(()=>{
        if(questionnaireNameData){
            setFormData({
                questionnaire_name : questionnaireNameData?.name,
            })
            
        } 
      },[questionnaireNameData])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setErrorMessage({
          questionnaire_nameError: "",
        });
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      };

      const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.questionnaire_name) {
          setErrorMessage((prevErrors) => ({
            ...prevErrors,
            questionnaire_nameError: "Questionnaire name can't be empty",
          }));
        }
    
        if (formData.questionnaire_name) {
          let payload = {
            name: formData?.questionnaire_name,
          };
          setLoadingButton(true);
          postQuestionnaireNameEdit(`client/cleint_quesstionnaire/${questionnaireId}`, payload)
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              console.log("86666", data);
              setSubmitData(data);
              setFormData({
                questionnaire_name: "",
              });
            hideModal();
            })
            .catch((error) => {
              console.log(error);
              setErrorMessage("Something went wrong. Please try again later.");
            })
            .finally(() => {
              setLoadingButton(false);
            });
        }
      };
    
      function hideModal () {
        setModalShow(false);
        setQuestionnaireId(null);
      }
  return (
    <Modal
    show={modalShow}
    centered
    size="md"
    className="modal-dialog-scrollable p-6 modal_mobile"
    onHide={hideModal}
  >
    <div
     className="modal_inner_main"
    >
      <div
        style={{
            position: "absolute",
            right: "2rem",
            top: "2rem",
            cursor: "pointer",
          }}
        onClick={hideModal}
      >
        <i className="fa-solid fa-x"></i>
      </div>
      <form onSubmit={handleSubmit}>
        <Col>
          <Row>
            <h5 className="setup_regs_modal_heading">
              Edit Questionnaire Name
            </h5>
          </Row>
          {loadingQuestionNameData ?
          <Row>
<div className='getStarted'>
         <img src={loadingImg} alt="Loading..." style={{ width: "150px" }} />
     </div>
          </Row> 
          :
<>
<Row>
            <Col md="12">
              <p className="registration_form_content">
                <label>Questionnaire Name</label>
                <input
                  type="text"
                  name="questionnaire_name"
                  value={formData.questionnaire_name}
                  onChange={handleChange}
                />
                {errorMessage.questionnaire_nameError && (
                  <span className="error_message">
                    {errorMessage.questionnaire_nameError}
                  </span>
                )}
              </p>
            </Col>
          </Row>
          <div className="d-flex justify-content-center align-items-center">
            {loadingButton ? (
              <button
                className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                type="button"
                disabled
                style={{
                  fontSize: "1rem",
                  background: "#007DC1",
                  color: "white",
                }}
              >
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                  style={{ marginRight: ".5rem" }}
                ></span>
                Loading...
              </button>
            ) : (
              <button
                className="btn mt-3 btn-md text-center btn-size create_ac_btn"
                // onClick={handleSubmit}
              >
                Submit
              </button>
            )}
          </div>
</>
          }
        
        </Col>
      </form>
    </div>
  </Modal>
  )
}

export default EditQuestionnaireModal
