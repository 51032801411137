import React from 'react';
import "../../styles/shimmer.css";


function ShimmerTableRow() {
    return (
        <div className="shimmerContainertable">
            {Array(16).fill("  ").map((el, index) => {
                return (
                    <div className="cardShimmertable" key={index}>
                        <div className="cardShimmerUptable"></div>
                    </div>
                )
            })}
        </div>
    )
}

export default ShimmerTableRow;