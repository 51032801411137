import React from 'react'

export default function MobileUploadStore() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>
                <h4> <i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> Define the 'Where'</h4>
                <p>Add city locations and number of audits for each location to get an appropriate and distributed sample results</p>
            </div>
        </>
    )
}
