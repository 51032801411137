import React from 'react'

export default function ProjectDetail({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>Give your project an Identity</h2>
                <p>Specify the name, duration, industry and audience of the project. This information helps us understand he project better and select the best execution method</p>
            </div>
            <div className='dash_stepper_btn_container'>
                <button onClick={() => updateStep(currentStep + 1)} className='dash_stepper_btn_container_btn'>Next Step</button>
            </div>
        </div>
    )
}
