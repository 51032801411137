import { useEffect, useState } from "react";
import "../../../styles/reportstorebrowser.css"
import { Table,Tabs, Tab} from "react-bootstrap";
import DateTime from 'react-datetime';
import { getColor } from "../../../utils/constant";
import { useNavigate } from "react-router-dom";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import loadingimg from "../../../images/loading.avif"
import base_url from "../../../utils/config";

export default function ReportBrowser () {
    const navigate = useNavigate();
    const userId = localStorage.getItem("user_id");
    const [formData, setFormData] = useState({
        questionnaire: "",
        state:"",
        city:"",
    });
    
    const [selectedTab, setSelectedTab] = useState(null);
    const [showDownloadClick, setShowDownloadClick] = useState(false);
    const [triggerPrint, setTriggerPrint] = useState(false);
    const { data: mainData, getApiData, isloading } = useFetchinFunction();
    const {data:storeData, getApiData: getStoreData,isLoading: storeLoading} = useFetchinFunction();

    useEffect(() => {
        getApiData(`client/questionnaire_for_dashboard`);
        // getApiData(`client/questionnaire_types_for_dashboard`);
      }, []);

      useEffect(() => {
        if (mainData.length > 0) {
          setSelectedTab(mainData[0]?.questionnaire_type?.id);
        }
      }, [mainData]);

      useEffect(() => {
        if (selectedTab && mainData?.length > 0) {
            getStoreData(`client/report/questionnaire/${selectedTab}/store_reports`);
        }
      }, [selectedTab]);

    //   useEffect(() => {
    //     if (triggerPrint) {
    //         window.print();
    //         setTriggerPrint(false);  
    //     }
    // }, [selectedTab, triggerPrint]);

      const handleSelect = (eventKey) => {
        setSelectedTab(eventKey);
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setSelectedTab(value)
    };
const handleNavigation = (reportId) => {
    console.log("5444",reportId)
    // navigate("/userdashboardmain/reportslistdetail",{state:reportId})
    navigate(`/userdashboardmain/reportslistdetail/${reportId}`)
}

const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' }); 
    const year = dateObj.getFullYear();

    // Function to get ordinal suffix
    const getOrdinalSuffix = (day) => {
        if (day >= 11 && day <= 13) return 'th';
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
};

const handleDownLoadClick = (detail) => {
    setShowDownloadClick(false);
    // if (detail === "pdf") {
    //     setTriggerPrint(true);  
    // }
};
const sectionHeaders = Array.from(new Set(storeData?.flatMap(store => store.sections.map(sec => sec.section))));
    return(
        <div>
            <Tabs
        id="justify-tab-example"
        justify
        activeKey={selectedTab}
        onSelect={handleSelect}
        style={{ margin: "1rem 0rem 3rem 0rem"}}
      >
        {mainData?.slice(0,4)?.map((item) => (
          <Tab
            key={item?.questionnaire_type?.id}
            eventKey={item?.questionnaire_type?.id}
            title={item?.questionnaire_type?.name}
            // key={item?.id}
            // eventKey={item?.id}
            // title={item?.name}
          />
        ))}
      </Tabs>
           <div className="store_brow_main">
            <div className="store_brow_left_box" >
                <div>
                <p className="registration_form_content">
                        <label> Questionnaire:</label>
                        <select name='questionnaire'
                            onChange={handleChange}
                            value={formData.questionnaire}
                        >
                            {mainData && mainData.length>0 ?
                            mainData.map(ques=>{
                                return(
                                  <option value={ques?.questionnaire_type?.id} key={ques?.id}>{ques?.questionnaire_type?.name}</option>
                                )
                            })
                            :
                            null
                        }
                            
                            

                        </select>
                    </p>

                </div>
             
            </div>
        <div className="store_brow_right_box">
                   <div>
                    <p className="registration_form_content dwnload_container">
                        <label>Download</label>
                        <button 
                        onClick={() => setShowDownloadClick(!showDownloadClick)}
                        className="proof_tag_btn download_btn" 
                        style={{backgroundColor:"#007DC1",color:"white",display:"flex",justifyContent:"space-between",alignItems:"center"}}><i className="fa-solid fa-download"></i> {storeData?.length} Reports <i className="fa-solid fa-chevron-down"></i></button>
                        
                          {showDownloadClick &&  <ul className="year-list">
                                    <a  
                                    href={base_url+`client/questionnaire/${selectedTab}/report_browser_filtered_xlsx_report?user=${userId}`}
                                    onClick={() => handleDownLoadClick("summary")}
                                    style={{textDecoration:"none"}}
                                    >
                                    <li className="year-item" style={{fontWeight:"normal",display:"flex",flexDirection:"column"}}>
                                        <b>Summary Excel</b>
                                        <span>Download summary of all reports...</span>
                                    </li>
                                    </a>
                                    <a  
                                    href={base_url+`client/questionnaire/${selectedTab}/questionnaire_filtered_xlsx_report?user=${userId}`}
                                    onClick={() => handleDownLoadClick("detailed")}
                                    style={{textDecoration:"none"}}
                                    >
                                    <li className="year-item" style={{fontWeight:"normal",display:"flex",flexDirection:"column"}}>
                                        <b>Detailed Excel</b>
                                        <span>Download all reports...</span>
                                    </li>
                                    </a>
                                    {/* <a  
                                    onClick={() => handleDownLoadClick("pdf")}
                                    >
                                    <li className="year-item" style={{fontWeight:"normal",display:"flex",flexDirection:"column"}}>
                                        <b>Print as PDF</b>
                                        <span>Trigger your browser...</span>
                                    </li>
                                    </a> */}
                               
                            </ul>}
                    </p>
                   
                
                </div>
        </div>
        </div>

<div style={{ marginTop: "3rem" }}>
                {storeLoading ? (
                      <div className='getStarted'>
                      <img src={loadingimg} alt="Loading..." style={{ width: "200px" }} />
                  </div>
                ) : (
                    storeData?.length > 0 ? (
                        <Table className="text-center table_border dash_tab_table report_table_mobile table-hover">
                            <thead className='dash_tab_table_head'>
                                <tr>
                                    <th className="fw-bold table_border">Name</th>
                                    <th className="fw-bold table_border">Date</th>
                                    <th className="fw-bold table_border">Total Score</th>
                                    {sectionHeaders.map((section, index) => (
                                        <th key={index} className="fw-bold table_border">{section}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                           
                                   {storeData?.map((store) => (
                                        <tr key={store.audit_store_id} style={{ cursor: "pointer" }} onClick={()=>handleNavigation(store.audit_store_id)}>
                                            <td className='table_border'>{store.store_name}</td>
                                            <td className='table_border'>{formatDate(store.report_date)}</td>
                                            <td className={['table_border', getColor(store.total_score.color)].join(' ')}>
                                                {store.total_score.percentage}%
                                            </td>
                                            {sectionHeaders.map((section) => {
                                                const secData = store.sections.find(sec => sec.section === section);
                                                return (
                                                    <td key={section} className={['table_border', getColor(secData?.color || 0)].join(' ')}>
                                                        {secData?.percentage ? `${secData.percentage}%` : 'N/A'}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))
}
                            </tbody>
                        </Table>
                    )
                    :
                    (<p>No Data for now</p>)
                )}
            </div>
        </div>
    )
}