import { createSlice } from '@reduxjs/toolkit';

const questionAddSlice = createSlice({
  name: 'questionAdd',
  initialState: [],
  reducers: {
    addQuestionData: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { addQuestionData} = questionAddSlice.actions;
export default questionAddSlice.reducer;
