
// --------------------------------------------------------------------------------------------------------

import React, { useEffect, useState, useRef } from 'react';
import { Col, Table } from 'react-bootstrap';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';

export default function QuestionReport({ reportId }) {
    const {data:reportSummary,getApiData: getReportSummaryData,isLoading} = useFetchinFunction()
    const {data:sectionData,getApiData: getSectionData} = useFetchinFunction()
    const {data:answerData,getApiData: getAnswerData} = useFetchinFunction()
    useEffect(() => {
        if (reportId) {
            getSectionData(`client/store_report/${reportId}/section`);
            getAnswerData(`client/store_report/${reportId}/answer`);
            getReportSummaryData(`client/store_report/${reportId}/report_section`);
        }

    }, [reportId])
   
    return (
        
        <div>
            {sectionData && sectionData.map((item) => {
                const correspondingSummary = reportSummary.find((summary) => summary.section === item?.id);
                return (
                    <div style={{ marginTop: "2rem" }}>
                        <div className='report_details_result_box'>
                            <h6> <span style={{ marginRight: "1rem" }}>{item?.sequence}.</span>{item?.name}</h6>
                        </div>
                        <div className='table-responsive'>
                        <Table className='report_question_table'>
                            <thead>
                                {item?.questions.length > 0 ?
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                        <th>Marks</th>
                                        <th>Max. Marks</th>
                                    </tr> :
                                    <tr>
                                        <th>#</th>
                                        <th>Question</th>
                                        <th>Answer</th>
                                    </tr>
                                }

                            </thead>
                            <tbody>
                                {item?.questions.length > 0 ? item?.questions.map((question) => {
                                    const correspondingAnswer = answerData.find((answer) => answer.question === question.id);
                                    return (
                                        <tr key={question.id}>
                                            <td>{question.sequence}</td>
                                            <td>{question.question_text}</td>
                                            <td>{correspondingAnswer ? correspondingAnswer.answer_text : 'N/A'}</td>
                                            <td>{correspondingAnswer ? correspondingAnswer.marks_obtained : 'N/A'}</td>
                                            <td>{question.max_marks}</td>
                                        </tr>
                                    );
                                }) : <tr>
                                    <td style={{ visibility: "hidden" }}>random</td>
                                    <td colSpan="3">No questions here...</td>
                                </tr>}

                            </tbody>
                        </Table>
                        </div>
                       
                    </div>


                )
            })}

        </div >
    );
}


