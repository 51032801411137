
export const OccupationData = [
  "STUDENT",
  "SERVICE",
  "SELF_EMPLOYED",
  "BUSINESS",
  "UNEMPLOYED",
  "RETIRED",
]
export function getOccupation(value) {
  switch (value) {
    case "STUDENT":
      return "Student";
    case "SERVICE":
      return "Service";
    case "SELF_EMPLOYED":
      return "Self Employed";
    case "BUSINESS":
      return "Business";
    case "UNEMPLOYED":
      return "Unemployed";
    case "RETIRED":
      return "Retired";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return `unknown occupation ${value} - ${typeof value}`;
  }
}
export const AgeData = [
  "18 and above",
  "18 - 25",
  "25 - 40",
  "40 - 55",
  "55 and above"
]

export const IncomeRangeData = [
  "0",
  "1",
  "2",
  "3",
  "4",

]
export function getIncomeText(income) {
  switch (income) {
    case "0":
    case 0:
      return "Less than 1 LPA";
    case "1":
    case 1:
      return "1 to 3 LPA";
    case "2":
    case 2:
      return "3 to 8 LPA";
    case "3":
    case 3:
      return "8 to 15 LPA";
    case "4":
    case 4:
      return "15+ LPA";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return "";
  }
}
export const CarCostList = [
  "1",
  "2",
  "3",
  "4",
  "5"
];
export function getCarCost(cost) {
  switch (cost) {
    case "1":
    case 1:
      return "Less than 3 lacs";
    case "2":
    case 2:
      return "3 lacs – 5 lacs";
    case "3":
    case 3:
      return "5 lacs – 10 lacs";
    case "4":
    case 4:
      return "10 lacs – 15 lacs";
    case "5":
    case 5:
      return "15 lacs and above";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return "";
  }
}

export const GenderList = [
  "M",
  "F",
  "T",
  "N",
];
export function getGender(value) {
  switch (value) {
    case "M":
      return "male";
    case "F":
      return "female";
    case "T":
      return "trans person";
    case "N":
      return "non-binary";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return `unknown gender ${value} - ${typeof value}`;
  }
}


export const InterestAreaList = [
  "ADVERTISING",
  "AGRICULTURE",
  "ARCHITECTURE",
  "AVIATION",
  "BANKING",
  "BUSINESS",
  "REAL_ESTATE",
  "DESIGN",
  "SCIENCE",
  "HEALTH_CARE",
  "HIGHER_EDUCATION",
  "FINANCE",
  "RETAIL",
  "GAMES",
  "MUSIC",
  "READING",
  "FITNESS",
  "FOOD_AND_DRINK",
  "ARTS_AND_MUSIC",
  "TRAVEL",
  "VEHICLES",
  "BEAUTY",
  "FASHION_AND_ACCESSORIES",
  "SPORTS",
  "TECHNOLOGY",
  "OTHERS",
];
export function getInterestArea(value) {
  switch (value) {
    case "ADVERTISING":
      return "Advertising";
    case "AGRICULTURE":
      return "Agriculture";
    case "ARCHITECTURE":
      return "Architecture";
    case "AVIATION":
      return "Aviation";
    case "BANKING":
      return "Banking";
    case "BUSINESS":
      return "Business";
    case "REAL_ESTATE":
      return "Real Estate";
    case "DESIGN":
      return "Design";
    case "SCIENCE":
      return "Science";
    case "HEALTH_CARE":
      return "Health Care";
    case "HIGHER_EDUCATION":
      return "Higher Education";
    case "FINANCE":
      return "Finance";
    case "RETAIL":
      return "Retail";
    case "GAMES":
      return "Games";
    case "MUSIC":
      return "Music";
    case "READING":
      return "Reading";
    case "FITNESS":
      return "Fitness";
    case "FOOD_AND_DRINK":
      return "Food And Drink";
    case "ARTS_AND_MUSIC":
      return "Arts and Music";
    case "TRAVEL":
      return "Travel";
    case "VEHICLES":
      return "Vehicles";
    case "BEAUTY":
      return "Beauty";
    case "FASHION_AND_ACCESSORIES":
      return "Fashion and Accessories";
    case "SPORTS":
      return "Sports";
    case "TECHNOLOGY":
      return "Technology";
    case "OTHERS":
      return "Others";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return `unknown interest area ${value} - ${typeof value}`;
  }
}
export function getColor(value){
	switch(value){
	case "0":
	case 0:
		return "rating-na";
	case "1":
	case 1:
		return "rating-bad";
	case "2":
	case 2:
		return "rating-poor";
	case "3":
	case 3:
		return "rating-average";
	case "4":
	case 4:
		return "rating-good";
	case "5":
	case 5:
		return "rating-excellent";
	case "":
	case null:
	case undefined:
		return "";
	default:
		return "";
	}
}

export const questionTypes = [
  { value: "OPINION_SCALE", label: "Opinion Scale" },
  { value: "PLAIN_TEXT", label: "Plain Text" },
  { value: "MUTEX", label: "Mutually Exclusive" },
  { value: "MULTIPLE_CHOICE", label: "Multiple Choice" },
  { value: "RATING", label: "Rating" },
  { value: "EMAIL", label: "Email" },
  { value: "PHONE_NUMBER", label: "Phone Number" },
  { value: "DATE", label: "Date" },
  { value: "SINGLESELECT", label:"Single Select"}
];

export function getQuestionType(value) {
  switch (value) {
    case "OPINION_SCALE":
      return "Opinion Scale";
    case "PLAIN_TEXT":
      return "Plain Text";
    case "MUTEX":
      return "Mutually Exclusive";
    case "MULTIPLE_CHOICE":
      return "Multiple Choice";
    case "RATING":
      return "Rating";
    case "EMAIL":
      return "Email";
    case "PHONE_NUMBER":
      return "Phone Number";
    case "DATE":
      return "Date";
    case "SINGLESELECT":
      return "Single Select";
    case "":
    case null:
    case undefined:
      return "";
    default:
      return `unknown question data ${value} - ${typeof value}`;
  }
}