import React, { useState } from 'react';
import { Col, Row, Container, Alert } from 'react-bootstrap';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import base_url from '../../utils/config';
import { postResetPasswordData } from '../../utils/services/PostdataHandle';
import { Helmet } from 'react-helmet';

export default function ResetPassword() {
    const navigate = useNavigate();
    const userId = localStorage.getItem("user_id");
    const [modalShow, setModalShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        password: '',
        cpassword: '',
    });
    const [errorMessages, setErrorMessages] = useState({
        passwordError: '',
        cpasswordError: '',
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [matchErrorMessage, setMatchErrorMessage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setErrorMessages(prevErrors => ({
            ...prevErrors,
            [`${name}Error`]: ''
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(false);
        if (!formData.password) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                passwordError: "Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    passwordError: ""
                }));
                setHasError(false);
              }, 1200)
        }
        if (!formData.cpassword) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                cpasswordError: "confirm Password can't be empty"
            }));
            setHasError(true);
            setTimeout(() => {
                setErrorMessages(prevErrors => ({
                    ...prevErrors,
                    cpasswordError: ""
                }));
                setHasError(false);
              }, 1200)
        }
        if (formData.cpassword && formData.password !== formData.cpassword) {
            setMatchErrorMessage(true);
            setTimeout(() => {
                setMatchErrorMessage(false);
            }, 1700)
        }

        if (formData.password && formData.password === formData.cpassword) {
            setLoading(true)
            const requestData = {
                password: formData.password,
            };
            const promise = postResetPasswordData(requestData, "client/verify_and_forgot_password_set_api/");
            promise.then(response => response.json())
                .then(data => {
                    setHasError(false);
                    setShowAlert(true);
                    setTimeout(() => {
                        setShowAlert(false);
                        setLoading(false);
                    }, 1700)
                    setErrorMessage(data);
                    if (data.detail === "Password Changed") {
                        setTimeout(() => {
                            navigate("/userdashboardmain");
                        }, 1000);
                    }

                    setFormData({
                        password: '',
                        cpassword: '',
                    });
                })
                .catch(error => {
                    console.error('Error:', error);
                }).finally(() => {
                    setLoading(false);
                });
        }
    }
    return (
        <>
          <Helmet>
        <title>
        FloorInsights | Reset Password - Create a New Password
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Create a new password to secure your account"
        />
        <meta
          name="keywords"
          content="reset password, password reset, account security"
        />
      </Helmet>
            {showAlert && (<Alert variant={errorMessage.detail === "Password Changed" ? "success" : "danger"} onClose={() => setShowAlert(false)} dismissible style={{ textAlign: "center" }}>
                {errorMessage.detail}
            </Alert>
            )}
            <div className="login_logo" style={{marginTop:"5rem"}}>
                <Link to="/">
                    <img
                        src="https://marketplace.floorwalk.in/logo.png"
                        alt=""
                    />
                </Link>
                <h6>Give us your details </h6>
            </div>
            <section className="login-area">
                <Container>
                    <form className="form" onSubmit={handleSubmit}>
                        <Row>
                            <Col md="12" className="d-flex justify-content-center">
                                <div className="login-box">
                                    <p className="form-control login_form_control">
                                        <label htmlFor="login_password">New Password<span>*</span></label>
                                        <input
                                            type="password"
                                            placeholder="*******"
                                            name='password'
                                            value={formData.password}
                                            onChange={handleChange}
                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                        />
                                        {errorMessages.passwordError && (
                                            <span className="error_message">{errorMessages.passwordError}</span>
                                        )}
                                    </p>
                                    <p className="form-control login_form_control">
                                        <label htmlFor="login_password">Confirm Password<span>*</span></label>
                                        <input
                                            type="password"
                                            placeholder="*******"
                                            name='cpassword'
                                            value={formData.cpassword}
                                            onChange={handleChange}
                                            className={hasError ? "login_input error_border" : "login_input success_border"}
                                        />
                                        {errorMessages.cpasswordError && (
                                            <span className="error_message">{errorMessages.cpasswordError}</span>
                                        )}
                                    </p>
                                    {matchErrorMessage && <span style={{ color: "#ff6f6f", fontSize: ".9rem" }}>Password and Confirm Password doesn't match</span>}
                                    {loading ?
                                        <div className="login_btn_container">
                                            <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                                Loading...
                                            </button>
                                        </div>
                                        :
                                        <div className="login_btn_container">
                                            <button
                                                className="btn mb-4 mt-3 btn-md text-center btn-size create_ac_btn"
                                            >
                                                Reset Password
                                            </button>
                                        </div>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Container>
            </section>
        </>
    )
}
