import React, {useRef } from 'react';
import { Modal } from 'react-bootstrap';
import base_url from '../../../utils/config';
import {  postUploadStoreData } from '../../../utils/services/PostdataHandle';
import loading from "../../../images/loading.avif";
import "../../../styles/dashboardbody.css";

const BulkModal = ({uploading, setUploading, uploadSheetError, setUploadSheetError, bulkStoreModalShow, setBulkStoreModalShow, isFileAdded, setIsFileAdded}) => {
    const fileInputRef = useRef(null);
    const existingClient = localStorage.getItem("client_id");
    
	
   
    const uploadButtonClicked = () => {
		if (isFileAdded) {
			alert("please remove this file to upload another")
		}
		if (!isFileAdded) {
			fileInputRef.current.click();
		}
	};
	const uploadFile = (e) => {
		if (fileInputRef.current.files.length > 1) {
			alert("You can only upload 1 attachments at once");
			return;
		}
		for (let toUploadFile of fileInputRef.current.files) {
			setUploading(true);
			const promise = postUploadStoreData(toUploadFile, existingClient);
			promise.then(response => {
				if (!response.ok) {
					return response.json().then(data => {
						const errorMessage = data.non_field_errors[0];
						setUploadSheetError(errorMessage);
						let timeout = setTimeout(() => {
							setUploadSheetError("");
						}, 3000)
						return () => clearTimeout(timeout);
					});
				}
				// this code is like this because when it is successful, we don't get anything in response
				setUploading(false);
				setIsFileAdded(true);
				setTimeout(() => {
					setIsFileAdded(false);
				}, 1700)
				setBulkStoreModalShow(false);
			})
				.catch(error => {
					setUploading(false);

				}).finally(() => {
					setUploading(false);
				})
		}
	}
  return (
    <Modal
    show={bulkStoreModalShow}
    centered
    size='lg'
    className='modal-dialog-scrollable p-6 modal_mobile'
    onHide={() => setBulkStoreModalShow(false)}
>
    <div>
        <h5 style={{ padding: "1rem 2rem .5rem 2rem" }}>Import Store</h5>
        <div style={{ position: "absolute", right: "2rem", top: "1rem", cursor: "pointer" }} onClick={() => setBulkStoreModalShow(false)}><i className="fa-solid fa-x"></i>
        </div>
    </div>

    <input
        type="file"
        multiple
        onChange={uploadFile}
        ref={fileInputRef}
        disabled={uploading}
        style={{ display: 'none' }}
    />

    <div className="list-group" style={{ padding: "1rem" }}>
        <div className="list-group-item text-muted">
            <p className="text-center">
                <button
                    onClick={uploadButtonClicked}
                    type="button" className="btn create_ac_btn btn-sm upload_btn"> Upload sheet</button>
                &nbsp;&nbsp;
                <a className="btn btn-default btn-sm" style={{ border: "1px solid gray" }}
                    // href={base_url + `client/mp/store/import/client_sample`}
                    href={base_url + `client/store_sample_download`}
                >
                    Download sample sheet
                </a>
            </p>
            <div>
                {uploading ? <div style={{ display: "flex", justifyContent: "center" }}><img src={loading} alt="Loading" width="120px" /></div> : null}
                {uploadSheetError ? <p style={{ textAlign: "center", color: "red" }}>{uploadSheetError}</p> : null}
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <ol style={{ display: "inline-block" }}>
                    <li>
                        1. Only .xlsx format is supported
                    </li>
                    <li>
                        2. All code must be unique
                    </li>
                    <li>
                        3. All cities name must be the same as per in the portal
                    </li>
                    <li>
                        4. Only 99 stores can be added at a time.
                    </li>
                    <li>
                        5. Please add the City name in the Excel as per the form it is available on the portal.
                    </li>
                    <li>
                        6. No spelling mistakes will be accepted in the City name format.
                    </li>
                    <li>
                        7.  No spaces will be allowed in the State name format.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</Modal>
  )
}

export default BulkModal
