import React from 'react';
import Marquee from 'react-fast-marquee';
import img1 from '../../images/logoSlider/Amazon.png';
import img2 from '../../images/logoSlider/Asset 50.png';
import img3 from '../../images/logoSlider/Caratlane-logo.png';
import img4 from '../../images/logoSlider/KPMG.png';
import img5 from '../../images/logoSlider/Manyavar.png';
import img6 from '../../images/logoSlider/PWC.png';
import img7 from '../../images/logoSlider/Porsche.png';
import img8 from '../../images/logoSlider/Vivo.png';
import img9 from '../../images/logoSlider/cars24_logo.png';
import img10 from '../../images/logoSlider/garmin_logo.png';
import img11 from '../../images/logoSlider/hyundai.png';
import img12 from '../../images/logoSlider/oyo_logo.png';
import img13 from '../../images/logoSlider/whitehatjr_logo.png';
import img14 from '../../images/logoSlider/zomato_logo.png';
import "../../styles/homelogoslider.css";

function HomeLogoSlider() {
    const logoArr = [
        {
            name: img1,
            altName: "amazon",
        },
        {
            name: img2,
            altName: "TCI",
        },
        {
            name: img3,
            altName: "caratelane",
        },
        {
            name: img4,
            altName: "KPMG",
        },
        {
            name: img5,
            altName: "manyavar",
        },
        {
            name: img6,
            altName: "PWC",
        },
        {
            name: img7,
            altName: "porsche",
        },
        {
            name: img8,
            altName: "vivo",
        },
        {
            name: img9,
            altName: "cars24",
        },
        {
            name: img10,
            altName: "garmin",
        },
        {
            name: img11,
            altName: "hyundai",
        },
        {
            name: img12,
            altName: "OYO",
        },
        {
            name: img13,
            altName: "whitehatjr",
        },
        {
            name: img14,
            altName: "zomato",
        },

    ]
    return (
                <Marquee direction="left" speed={100} delay={5} className='logosliderbox'>
                    {logoArr.map(item => {
                        return(
                        <div className="image_wrapper" key={item?.altName}>
                            <img src={item?.name} alt={item?.altName} />
                        </div>
                        )
                    })}
                </Marquee>
    );
}

export default HomeLogoSlider;
