
import { Col,Modal, Row } from "react-bootstrap";


const AddEditStoreModal = ({
    storeModalShow,
    handleClose,
  handleSubmit,
  handleChange,
  formData,
  errorMessages,
  hasError,
  countryData,
  stateData,
  cityData,
  selectedStoreId,
  loadingButton,
  handleUpdateStore
}) => {
  return (
    <Modal
          show={storeModalShow}
          centered
          size="lg"
          className="modal-dialog-scrollable p-6 modal_mobile"
          onHide={handleClose}
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 3rem",
              border: "none",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <i className="fa-solid fa-x"></i>
            </div>
            <form onSubmit={handleSubmit}>
              <Col>
                <Row>
                  <h5 className="setup_regs_modal_heading">
                    {selectedStoreId ? "Edit Store" : "Add a Store"}
                  </h5>
                </Row>
                <Row>
                  <Col md="3">
                    <p className="registration_form_content">
                      <label>
                        Country<span>*</span>
                      </label>
                      <select
                        name="country"
                        onChange={handleChange}
                        className={hasError ? "error_border" : "success_border"}
                        value={formData.country}
                      >
                        <option value="">Select</option>
                        {countryData.map((item) => (
                          <option
                            key={item.countryCode}
                            value={item.countryCode}
                          >
                            {item.countryName}
                          </option>
                        ))}
                      </select>
                      {errorMessages.countryError && (
                        <span className="error_message">
                          {errorMessages.countryError}
                        </span>
                      )}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="registration_form_content">
                      <label>
                        State<span>*</span>
                      </label>
                      <select
                        name="state"
                        onChange={handleChange}
                        className={hasError ? "error_border" : "success_border"}
                        value={formData.state}
                      >
                        <option value="">Select</option>
                        {/* {stateData.map((item) => ( <option key={item.stateCode} value={item.stateCode} >{item.stateName}</option> ))} */}
                        {Array.isArray(stateData) &&
                          stateData.map((item) => (
                            <option key={item.stateCode} value={item.stateCode}>
                              {item.stateName}
                            </option>
                          ))}
                      </select>
                      {errorMessages.stateError && (
                        <span className="error_message">
                          {errorMessages.stateError}
                        </span>
                      )}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="registration_form_content">
                      <label>
                        City<span>*</span>
                      </label>
                      <select
                        onChange={handleChange}
                        className={hasError ? "error_border" : "success_border"}
                        value={formData.city}
                        name="city"
                      >
                        <option value="">Select</option>
                        {cityData.map((city) => (
                          <option key={city.id} value={city.id}>
                            {" "}
                            {city.name}{" "}
                          </option>
                        ))}
                      </select>
                      {errorMessages.cityError && (
                        <span className="error_message">
                          {errorMessages.cityError}
                        </span>
                      )}
                    </p>
                  </Col>
                  <Col md="3">
                    <p className="registration_form_content">
                      <label>Pincode</label>
                      <input
                        type="number"
                        name="pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                      />
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <p className="registration_form_content">
                      <label>
                        Store Name <span>*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        className={hasError ? "error_border" : "success_border"}
                        onChange={handleChange}
                      />
                      {errorMessages.nameError && (
                        <span className="error_message">
                          {errorMessages.nameError}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <p className="registration_form_content">
                      <label>
                        Store Address<span>*</span>
                      </label>
                      <input
                        type="text"
                        name="address"
                        value={formData.address}
                        className={hasError ? "error_border" : "success_border"}
                        onChange={handleChange}
                      />
                      {errorMessages.addressError && (
                        <span className="error_message">
                          {errorMessages.addressError}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <p className="registration_form_content">
                      <label>Store Contact Number</label>
                      <input
                        type="tel"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </p>
                  </Col>
                  <Col md="8">
                    <p className="registration_form_content">
                      <label>Website/Google Maps map_location_link Link</label>
                      <input
                        type="text"
                        name="map_location_link"
                        onChange={handleChange}
                        value={formData.map_location_link}
                      />
                    </p>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center">
                  {loadingButton ? (
                    <button
                      className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                      type="button"
                      disabled
                      style={{
                        fontSize: "1rem",
                        background: "#007DC1",
                        color: "white",
                      }}
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: ".5rem" }}
                      ></span>
                      Loading...
                    </button>
                  ) : selectedStoreId ? (
                    <button
                      className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                      onClick={handleUpdateStore}
                    >
                      Save Changes
                    </button>
                  ) : (
                    <button
                      className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </Col>
            </form>
          </div>
        </Modal>
  )
}

export default AddEditStoreModal
