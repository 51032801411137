import React,{useEffect} from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import manyavarlogo from "../../../images/manyavar_logo_case.png";
import manyavar from "../../../images/manyavar-logo.png";
import mahindra from "../../../images/Mahindra-Logo-new.png";
import porsche from "../../../images/new-porsche.png";
import manyvarimg from "../../../images/Manyawar_img.png";
import mahindraimg from "../../../images/mahindra1_img.png";
import porscheimg from "../../../images/porsche_img.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";

export default function Manyavar() {
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
                <img src={manyavarlogo} alt="manyavar_logo" />
                <h3 className='lead manyavar_heading'>Measuring Consumer Experience Through Mystery Shopping</h3>
                <div className='lead manyavar_heading_text'>
                    <span>Manyavar & Mohey are India’s own brand in ethnic wear and tradtional festive clothing. </span>
                    <span> The objective of this activity was to check the selling skills and performance of the franchises for
                        Manyavar outlets.</span>
                </div>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                        <img src="https://website.floorwalk.in/pages/image/case-study/individual/manyavar11.png" alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                        <img src="https://website.floorwalk.in/pages/image/case-study/individual/manyavar13.png" alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                        <img src="https://website.floorwalk.in/pages/image/case-study/individual/manyavar12.png" alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{padding:"0rem 1rem 1rem 1rem"}}>
                                    Manyavar & Mohey are India’s own brand in ethnic wear and traditional festive clothing. The objective of this activity was to check the selling skills and performance of the franchises for Manyavar outlets. The franchise staff was being audited on the lines of their selling skills and product knowledge. The motive was to understand whether the staff is compatible enough to understand all customer queries and stand by the brand reputation.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{padding:"0rem 1rem 1rem 1rem",display:"flex",flexDirection:"column",gap:"1rem"}}>
                                  <span> <b>The approach adopted to successfully deliver the scope of work was as under</b> <br /> Staff grooming, soft selling skills, product display and showroom ambience, along with the overall store management and sentimental analysis from customer side. All the major norms set by the brand were checked during the audit process.</span>
                                  <span>  <b>The challenges we faced were:</b> The project consisted visits at every store twice in every month, which made it difﬁcult to manage the frequency and buffer in days between the two visit.</span>
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{padding:"0rem 1rem 1rem 1rem"}}>
                                    In the ﬁrst month, Certain stores the staff energy level and selling skills was a major problem and to address that the staff was given formal training on the outcomes of mystery audit reports. The scores increased next month on the same parameters.

                                    In the second month, More intense scenarios were created and staff situation handling skills were tested.

                                    The stores were audited on a monthly basis on weekdays and weekends separately to understand approach on peak and non-peak hours..
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <Container fluid className='homepage_brand_container' style={{marginTop:"2rem"}}>
                <Row className='homepage_brand'>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                    <Link to="/case-studies/manyavar">
                        <div className='homepage_brand_img'>
                            <img src={manyvarimg} alt="manyvarimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }} />
                        </div>
                        <div className='homepage_brand_text'>
                            <div>
                                <img src={manyavar} alt="manyvar_logo" style={{ marginLeft: "1rem" }} />
                                <h6 style={{ margin: "1rem" }}> <b> Apparel & Ethnic Wear Brand</b></h6>
                                <p style={{ margin: "0rem 1rem" }}>Manyavar & Mohey are India’s own brand in ethnic wear and tradtional festive clothing. The objective of this activity was to check... </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Apparel</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <div className='homepage_brand_img'>
                            <img src={mahindraimg} alt="mahindraimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }}/>
                        </div>
                        <div className='homepage_brand_text'>
                            <div>
                                <img src={mahindra} alt="mahindra_logo" style={{ marginLeft: "1rem" }} />
                                <h6 style={{ margin: "1rem" }}> <b>Automobile Brand</b></h6>
                                <p style={{ margin: "0rem 1rem" }}>Mahindra & Mahindra Limited is an Indian multinational car manufacturing corporation. The objective of this activity was to check the infrastructure of... </p>
                                 <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Automobile</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
                        <div className='homepage_brand_img'>
                            <img src={porscheimg} alt="porscheimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px" }}/>
                        </div>
                        <div className='homepage_brand_text'>
                            <div>
                                <img src={porsche} alt="porsche_logo" style={{ marginLeft: "1rem" }} />
                                <h6 style={{ margin: "1rem" }}> <b>Luxury Car Brand</b></h6>
                                <p style={{ margin: "0rem 1rem" }}>Porsche India Pvt Ltd is a division of the German automobile manufacturer Porsche and the Volkswagen Group India. The objective of this activity... </p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Automobile</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
        </div>
    )
}
