import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Table, Row, ProgressBar } from "react-bootstrap";
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from "recharts";
import loadingimg from "../../../images/loading.avif";

export default function Storedetail({ storeReportDetail, reportSummary, isLoading }) {
  const [performance, setPerformance] = useState("");
  useEffect(() => {
    if (storeReportDetail?.audit_store_percentage >= 95) {
      setPerformance("Excellent");
    } else if (
      storeReportDetail?.audit_store_percentage >= 80 &&
      storeReportDetail?.audit_store_percentage <= 94
    ) {
      setPerformance("Good");
    } else if (
      storeReportDetail?.audit_store_percentage >= 70 &&
      storeReportDetail?.audit_store_percentage <= 79
    ) {
      setPerformance("Average");
    } else if (
      storeReportDetail?.audit_store_percentage >= 50 &&
      storeReportDetail?.audit_store_percentage <= 69
    ) {
      setPerformance("Poor");
    } else if (
      storeReportDetail?.audit_store_percentage >= 0 &&
      storeReportDetail?.audit_store_percentage <= 50
    ) {
      setPerformance("Bad");
    }
  }, [storeReportDetail]);

  const formatDate = (timestamp) => {
    const dateObj = new Date(timestamp);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString("default", { month: "short" });
    const year = dateObj.getFullYear();

    const getOrdinalSuffix = (day) => {
      if (day >= 11 && day <= 13) return "th";
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };
  return (
    <>
    {isLoading ?
    <div className='getStarted'>
    <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
</div>
  :
  <>
   <Row className="report_details_flexbox">
        <Col md="6" className="report_details_result_box">
          {/* <div > */}
          <h6>Report Details</h6>
          <table className="table table-striped">
            <tbody>
              <tr>
                <td>
                  Client:{" "}
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.storemap?.store?.client?.first_name ? storeReportDetail?.storemap?.store?.client?.first_name : "---"}
                  </b>
                </td>
              </tr>
             
              <tr>
                <td>
                  Store:{" "}
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.storemap?.store?.name ? storeReportDetail?.storemap?.store?.name : "---"}
                  </b>
                </td>
              </tr>
              
              <tr>
                <td>
                  Questionnaire Type:{" "}
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.storemap?.questionnaire?.questionnaire_type ? storeReportDetail?.storemap?.questionnaire?.questionnaire_type : "---"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Address:{" "}
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.storemap?.store?.address ? storeReportDetail?.storemap?.store?.address : "---"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Report Date:{" "}
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.report_date ? formatDate(storeReportDetail?.report_date): "---"}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Overall Experience:
                  <b style={{ marginLeft: "2rem" }}>
                    {storeReportDetail?.store_report_percentage ? storeReportDetail?.store_report_percentage+ "%" : "---"} 
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
          {/* </div> */}
        </Col>
        <Col md="6" className="report_details_result_box">
          {/* <div className='report_details_result_box'> */}
          <h6>Overall Experience</h6>
          <div className="report_details_result_box_flex">
            <Col md="7">
              <div
                className="semi_donut"
                style={{
                  "--percentage": storeReportDetail?.store_report_percentage,
                  "--fill": "rgb(234,255,153)",
                }}
              >
                <p style={{ color: "black" }}>
                  {" "}
                  {storeReportDetail?.store_report_percentage} %
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <h5>
                  <b>{performance}</b>
                </h5>
              </div>
            </Col>
            <Col md="5" className="report_colorful_chart">
              <Table style={{ height: "320px" }}>
                <tbody>
                  <tr style={{ padding: "1rem" }}>
                    <td style={{ backgroundColor: "rgb(193,255,153)" }}>
                      <b>Excellent</b>
                    </td>
                    <td style={{ backgroundColor: "rgb(193,255,153)" }}>
                      95% and above
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "rgb(234,255,153)" }}>
                      <b>Good</b>
                    </td>
                    <td style={{ backgroundColor: "rgb(234,255,153)" }}>
                      80% - 94%
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "rgb(255,255,153)" }}>
                      <b>Average</b>
                    </td>
                    <td style={{ backgroundColor: "rgb(255,255,153)" }}>
                      70% - 80%
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "rgb(255,235,153)" }}>
                      <b>Poor</b>
                    </td>
                    <td style={{ backgroundColor: "rgb(255,235,153)" }}>
                      50% - 70%{" "}
                    </td>
                  </tr>
                  <tr>
                    <td style={{ backgroundColor: "rgb(255,194,153)" }}>
                      <b>Bad</b>
                    </td>
                    <td style={{ backgroundColor: "rgb(255,194,153)" }}>
                      0% - 50%
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </div>
          {/* </div> */}
        </Col>
      </Row>
      <Row className="report_details_flexbox">
        <Col md="12" className="report_details_result_box">
          {/* <div > */}
          <h6>Report Summary</h6>
          <Table className="report_question_table ">
            <thead>
              <tr>
                <th>#</th>
                <th>Section</th>
                <th>Score</th>
              </tr>
            </thead>
            <tbody>
              {reportSummary.length > 0 ? (
                reportSummary.map((report) => {
                  return (
                    <tr key={report?.id}>
                      <td>{report?.id}</td>
                      <td>{report?.section_name}</td>
                      <td style={{ display: "flex", alignItems: "center",justifyContent:"start",gap:".5rem" }}>
                        <span>{Math.round(report?.marks_percentage)}%</span>
                       
                        <ProgressBar
                          now={Math.round(report?.marks_percentage)}
                          style={{
                            width: "300px",
                            marginLeft: "10px",
                            backgroundColor: "#eee", // Background of the unfilled part
                          }}
                        >
                          <div
                            style={{
                              width: `${Math.round(report?.marks_percentage)}%`,
                              backgroundColor: "#3498db", // filled part
                              // height: "100%",
                            }}
                          />
                        </ProgressBar>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="3">No Report here...</td>
                </tr>
              )}
            </tbody>
          </Table>
          {/* </div> */}
        </Col>
      </Row>
  </>
  }
     
    </>
  );
}
