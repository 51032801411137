import React, { useState,useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { BsFillTelephoneFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { FaMapMarkerAlt } from 'react-icons/fa';
import "../styles/footer.css";
// import { AppContext } from './MainPage';
import { AppContext } from '../App';

const Footer = () => {
	
	return (
		<div>

			<footer className='footer1'>
				<Container>
					<div className='row footer_container'>
						<Col md="3">
							<div className='d-flex'>
								<div className='f-links'>
									<h5>Product Categories</h5>
									
								</div>
							</div>
						</Col>
						<Col md="3">
							<div className='d-flex'>
								<div className='f-links'>
									<h5>Resources</h5>
									<ul className='list-unstyled'>
										<li>
											<a href='https://floorwalk.in/about-floorwalk-mystery-shopping.html' target="_blank">
												<i className='las la-angle-right'></i>
												About Us
											</a>
										</li>
										<li>
											<Link to="/contact-us">
												<i className='las la-angle-right'></i>
												Contact Us
												</Link>
										</li>
										{/* <li>
											<a href='https://floorwalk.in/frequently-asked-questions_mystery_client.html' target="_blank">
												<i className='las la-angle-right'></i>
												FAQs
											</a>
										</li> */}
										<li>
											<Link to="/privacy-policy">
												<i className='las la-angle-right'></i>
												Privacy Policy
												</Link>
										</li>
										<li>
										<Link to="/refund-policy">
												<i className='las la-angle-right'></i>
												Refund Policy
											</Link>
										</li>
										<li>
											<Link to="/terms-and-conditions">
												<i className='las la-angle-right'></i>
												Terms & Conditions
												</Link>
										</li>
									</ul>
								</div>
							</div>
						</Col>
						<Col md="3">
							<div className=' d-flex'>
								<div className='f-links'>
									<h5>Reads</h5>
									<ul className='list-unstyled'>
										<li>
										<Link to='/blog'>
												<i className='las la-angle-right'></i>
												Blogs
											</Link>
										</li>
										<li>
											<Link to="/case-studies">
												<i className='las la-angle-right'></i>
												Case Studies
												</Link>
										</li>
									</ul>
								</div>
							</div>
						</Col>
						<Col md='3'>
							<div className='f-links'>
								<h5>Get In Touch</h5>
								<ul className='list-unstyled'>
									<li>
										{/* <a href='/'> */}
											<FaMapMarkerAlt />{' '}
											FloorWalk Consultants Private Limited
											<span className='d-block'>
												AltF Empire Square, MR-1, Unit 1, 3rd Floor, JMD Empire Square Near Sikanderpur Metro Station,
												Opp. Metro Pillar No. 71, MG Road Gurugram, Haryana - 122002
											</span>
										{/* </a> */}
									</li>
									<li>
										<a href='tel:+91-783-606-6777'>
											<BsFillTelephoneFill />{' '}
											+91-783-606-6777
										</a>
									</li>
									<li>
										<a href='mailto: contactus@floorwalk.in'>
											<BsFillEnvelopeFill />{' '}
											contactus@floorwalk.in
										</a>
									</li>
								</ul>
							</div>
						</Col>
					</div>
				</Container>
			</footer>
		</div>
	);
}


export default Footer;
