import React, { useEffect } from "react";
import { Row, Col, Container, Carousel, Modal } from "react-bootstrap";
import img1 from "../../images/survey_img5.png";
import img2 from "../../images/survey_img2.jpeg";
import img3 from "../../images/survey_img3.jpeg";
import img4 from "../../images/survey_img4.jpeg";
import "../../styles/homemore.css";

export default function HomeMore() {
  return (
    <div style={{ padding: "0 10%", marginTop: "5rem" }}>
      <div className="howit_works">
        <h5>What is more in our NPS survey platform?</h5>
        <div className="survey_more_box_wrapper">
          <div className="survey_more_main_container">
            <h4>
              Unlocking Store-Level Insights for NPS: Elevate Customer
              Experience with Precision
            </h4>
            <div className="survey_more_box">
              <div className="survey_more_img_box">
                <img src={img4} alt="survey_img1" />
                <p>
                  In the competitive landscape of retail, understanding customer
                  satisfaction at a granular level is crucial for success. As a
                  SaaS-based company specializing in NPS measurement, we empower
                  businesses to gain deep store-level insights into their Net
                  Promoter Score (NPS), enabling them to make informed decisions
                  and enhance overall consumer satisfaction. Here's how our NPS
                  measurement software helps stores harness actionable insights:
                </p>
              </div>
              <div className="survey_more_sm_box">
                <p>
                  {" "}
                  <h6 style={{ fontWeight: "bold" }}>
                    Why Store-Level NPS Insights Matter
                  </h6>
                  <div className="survey-more_list_box">
                    <ol>
                      <li>1. Localized Understanding:</li>
                      <ul>
                        <li>
                          {" "}
                          Each store has unique characteristics that influence
                          customer experiences, from location and layout to
                          staff interactions and local consumer preferences.
                        </li>
                        <li>
                          Store-level NPS insights allow you to capture these
                          localized nuances, providing a clearer picture of what
                          drives satisfaction and loyalty at each specific
                          outlet.
                        </li>
                      </ul>
                      <li>2. Targeted Improvements:</li>
                      <ul>
                        <li>
                          {" "}
                          By understanding NPS at the store level, you can
                          identify specific strengths and weaknesses of
                          individual locations.
                        </li>
                        <li>
                          {" "}
                          This targeted approach ensures that you can implement
                          tailored strategies that address the distinct needs of
                          each store, leading to more effective improvements.
                        </li>
                      </ul>
                    </ol>
                    <ol>
                      <li>3. Benchmarking and Best Practices:</li>
                      <ul>
                        <li>
                          {" "}
                          Store-level insights enable benchmarking against other
                          stores within the same chain, revealing
                          high-performing locations and their best practices.
                        </li>
                        <li>
                          {" "}
                          These insights can be leveraged to replicate
                          successful strategies across other stores, driving
                          overall network performance.
                        </li>
                      </ul>
                    </ol>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="survey_more_main_container">
            <h4>
              Achieve Month-on-Month Improvement with Store-Level NPS Insights:
              A Game Changer for Retail Excellence
            </h4>
            <div className="survey_more_box reverse_box">
            <div className="survey_more_sm_box">
                <p>
                  {" "}
                  <h6 style={{ fontWeight: "bold" }}>
                  Why Month-on-Month Store-Level NPS Insights Matter
                  </h6>
                  <div className="survey-more_list_box">
                    <ol>
                      <li> 1.	Proactive Customer Experience Management:</li>
                      <ul>
                        <li>
                          {" "}
                          	Regular monthly insights allow for proactive adjustments based on recent customer feedback, ensuring your strategies are always aligned with current customer sentiments.
                        </li>
                        <li>
                        This agility helps in addressing emerging issues promptly, preventing them from impacting long-term customer satisfaction.
                        </li>
                      </ul>
                      <li>2.Track Progress and Measure Impact:</li>
                      <ul>
                        <li>
                          {" "}
                          Monitoring NPS at the store level on a month-to-month basis enables you to track the effectiveness of improvement initiatives.
                        </li>
                        <li>
                          {" "}
                          By comparing monthly data, you can gauge the impact of changes and fine-tune strategies for better results.
                        </li>
                      </ul>
                    </ol>
                    <ol>
                      <li>3.Foster a Culture of Continuous Improvement:</li>
                      <ul>
                        <li>
                          {" "}
                          Month-on-month insights encourage a culture of ongoing enhancement, where each store strives to better its performance continually.
                        </li>
                        <li>
                          {" "}
                          This approach fosters a competitive spirit among stores, driving overall improvement in the retail network.
                        </li>
                      </ul>
                    </ol>
                  </div>
                </p>
              </div>
              <div className="survey_more_img_box">
                <img src={img2} alt="survey_img1" />
                <p>
                In the dynamic world of retail, continuous improvement is key to staying ahead of customer expectations. To achieve consistent growth in customer satisfaction and loyalty, gaining store-level insights into your Net Promoter Score (NPS) on a monthly basis is crucial. As a leading SaaS provider specializing in NPS measurement, we offer powerful tools that help you track and drive month-on-month improvements, transforming customer experiences across your store network.
                </p>
              </div>
            </div>
          </div>
          <div className="survey_more_main_container">
            <h4>
              Driving Month-on-Month Improvement Across City, Region, and
              Country Levels with NPS Insights
            </h4>
            <div className="survey_more_box">
              <div className="survey_more_img_box">
                <img src={img3} alt="survey_img1" />
                <p>Achieving consistent month-on-month improvement in customer satisfaction and loyalty requires a comprehensive understanding of customer feedback across various geographical levels. As a leading SaaS provider of NPS measurement software, we enable businesses to gain actionable insights into their Net Promoter Score (NPS) at city, region, and country levels. Our powerful platform supports a multi-layered approach, helping you drive continuous improvement in customer experiences and satisfaction.</p>
              </div>
              <div className="survey_more_sm_box">
                <p>
                  {" "}
                  <h6 style={{ fontWeight: "bold" }}>
                  Why Multi-Level NPS Insights Matter
                  </h6>
                  <div className="survey-more_list_box">
                    <ol>
                      <li>1. Holistic View of Customer Satisfaction:</li>
                      <ul>
                        <li>
                          {" "}
                          Understanding NPS at different geographical levels—city, region, and country—provides a complete picture of customer satisfaction trends.
                        </li>
                        <li>
                        This holistic view allows you to identify broad patterns and localized issues, ensuring your strategies address both macro and micro-level customer experiences.
                        </li>
                      </ul>
                      <li>2. Tailored Strategies for Diverse Markets:</li>
                      <ul>
                        <li>
                          {" "}
                          Customer expectations and experiences can vary significantly between cities, regions, and countries.
                        </li>
                        <li>
                          {" "}
                          Multi-level insights enable you to tailor your strategies to the unique needs and preferences of different markets, driving more effective improvements.
                        </li>
                      </ul>
                    </ol>
                    <ol>
                      <li>3.	Effective Resource Allocation:</li>
                      <ul>
                        <li>
                          {" "}
                          Insights at various levels help in identifying which areas need more attention and resources.
                        </li>
                        <li>
                          {" "}
                          This targeted approach ensures efficient use of resources, maximizing the impact of your efforts across the entire network.
                        </li>
                      </ul>
                    </ol>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <div className="survey_more_main_container">
            <h4>
              Harnessing AI-Driven Sentiment Analysis for Enhanced Customer
              Emotion Insights in NPS
            </h4>
            <div className="survey_more_box reverse_box">
            <div className="survey_more_sm_box">
                <p>
                  {" "}
                  <h6 style={{ fontWeight: "bold" }}>
                  Why AI-Driven Sentiment Analysis Matters
                  </h6>
                  <div className="survey-more_list_box">
                    <ol>
                      <li> 1.	Uncover Hidden Emotional Drivers:</li>
                      <ul>
                        <li>
                          {" "}
                          Customer feedback often contains emotional undertones that influence satisfaction but are not captured by numerical NPS scores alone.
                        </li>
                        <li>
                        AI-driven sentiment analysis detects and interprets these underlying emotions, providing a more comprehensive understanding of customer experiences.
                        </li>
                      </ul>
                      <li>2.	Enhance Customer Experience Management:</li>
                      <ul>
                        <li>
                          {" "}
                          By analyzing sentiment, businesses can identify not just what customers are saying but how they feel about their experiences.
                        </li>
                        <li>
                          {" "}
                          This emotional insight allows for more empathetic and effective customer experience management, leading to higher satisfaction and loyalty.
                        </li>
                      </ul>
                    </ol>
                    <ol>
                      <li>3.	Prioritize Actions Based on Emotional Impact:</li>
                      <ul>
                        <li>
                          {" "}
                          Sentiment analysis highlights the aspects of customer feedback that evoke strong positive or negative emotions.
                        </li>
                        <li>
                          {" "}
                          This prioritization helps businesses focus on areas that significantly impact customer feelings, driving more impactful improvements.
                        </li>
                      </ul>
                    </ol>
                  </div>
                </p>
              </div>
              <div className="survey_more_img_box">
                <img src={img1} alt="survey_img1" />
                <p>
                In today’s customer-centric world, understanding the emotions and sentiments behind customer feedback is crucial for elevating satisfaction and loyalty. Traditional NPS measurement provides valuable quantitative data, but integrating AI-driven sentiment analysis uncovers deeper insights into customer emotions. As a SaaS-based NPS provider, we leverage advanced AI technologies to transform your customer feedback into actionable insights, driving improved Net Promoter Scores (NPS) and overall consumer satisfaction.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
