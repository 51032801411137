import React,{useEffect, useState} from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import { postAddClientUser } from '../../../utils/services/PostdataHandle';
import { addClientUserData } from '../../../utils/reduxcomponents/clientUserAddSlice';
import { useDispatch } from 'react-redux';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';

const ClientUserAddModal = ({modalShow,setModalShow,editUserId,modalTitle,setMessage,setShowalert}) => {
    const token = localStorage.getItem("token");
    const clientId = localStorage.getItem("client_id");
    const [hasError, setHasError] = useState(false);
    const dispatch = useDispatch();
  const [loadingButton, setLoadingButton] = useState(false);
  const {data:userDetailData, getApiData, error:userDetailError} = useFetchinFunction()
    const [formData, setFormData] = useState({
      full_name: "",
      email: "",
      password: "",
      isAdmin : false,
      isActive : false,
      receive_email_notification : false,
    });
    const [errorMessages, setErrorMessages] = useState({
      full_nameError: "",
      emailError: "",
      passwordError : "",
    });

    useEffect(()=>{
      console.log(editUserId)
        if(editUserId){
            getApiData(`client/client_user/${editUserId}`);
        }else{
            setFormData({})
        }
          },[editUserId,modalShow])

    useEffect(() => {
        if (userDetailData) {
            console.log("3777777777", userDetailData, userDetailError)
            setFormData({
                full_name: userDetailData?.full_name,
                email: userDetailData?.user?.email,
                // password: userDetailData?.user?.password,
                isAdmin: userDetailData?.is_client_admin || false,
                isActive: userDetailData?.user?.is_active || false,
                receive_email_notification: userDetailData?.receive_email_notification || false,
              });
            }
    }, [userDetailData]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        console.log(name, value, type, checked);
    
        setFormData((prevState) => ({
          ...prevState,
          [name]: type === "checkbox" ? checked : value,
        }));
        setErrorMessages((prevErrors) => ({
          ...prevErrors,
          [`${name}Error`]: "",
        }));
      };
    
      const handleSubmit = (e) => {
        console.log(formData);
        e.preventDefault();
        let hasErrors = false;
    
        // if (!formData.section_name) {
        //   setErrorMessages((prevErrors) => ({
        //     ...prevErrors,
        //     section_nameError: "Section name can't be empty",
        //   }));
        //   hasErrors = true;
        // }
        // if (!formData.sequence) {
        //   setErrorMessages((prevErrors) => ({
        //     ...prevErrors,
        //     sequenceError: "Sequence can't be empty",
        //   }));
        //   hasErrors = true;
        // }
    
        // if (hasErrors) {
        //   setHasError(true);
        //   setTimeout(() => {
        //     setErrorMessages({
        //       section_nameError: "",
        //       sequenceError: "",
        //     });
        //     setHasError(false);
        //   }, 1500);
        //   return;
        // }
    
        let payload = {
          client: clientId,
          email: formData?.email,
          full_name: formData?.full_name,
          is_active: formData?.isActive,
          is_client_admin : formData?.isAdmin,
          password: formData?.password ? formData?.password : "",
          receive_email_notification: formData?.receive_email_notification
        };
    
        setLoadingButton(true);
        postAddClientUser(
          `client/client_user/${editUserId ? `${editUserId}` : ""}`,payload,token)
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
        
            setFormData({});
            setModalShow(false);
            dispatch(addClientUserData(data));
            setShowalert(true);
            setMessage(editUserId ? "User Details is Editted Successfully" :"User details is Added Successfully");
            setTimeout(()=>{
               setShowalert(false);
               setMessage("");
            },1500)
          })
          .catch((error) => {
            setShowalert(true);
            setMessage("Something went wrong. Please try again later.");
            setTimeout(()=>{
              setShowalert(false);
              setMessage("");
           },1500)
          })
          .finally(() => {
            setLoadingButton(false);
          });
      };
    
  return (
    <Modal
    show={modalShow}
    centered
    size="lg"
     dialogClassName="modal-dialog-scrollable"
        className=' p-6 modal_mobile'
    onHide={() => setModalShow(false)}
  >
    <div
    className="modal_inner_main"
    >
      <div
        style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }}
        onClick={() => setModalShow(false)}
      >
        <i className="fa-solid fa-x"></i>
      </div>
      <form onSubmit={handleSubmit}>
        <Col>
          <Row>
            <h5 className="setup_regs_modal_heading">{modalTitle}</h5>
          </Row>
          <Row>
            <Col md="12">
              <p className="registration_form_content">
                <label>Full Name</label>
                <input type="text" name="full_name" value={formData.full_name} onChange={handleChange} />
                {errorMessages.full_nameError && (
                  <span className="error_message">{errorMessages.full_nameError}</span>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p className="registration_form_content">
                <label>Email Address</label>
                <input type="text" name="email" value={formData.email} onChange={handleChange} />
                {errorMessages.emailError && (
                  <span className="error_message">{errorMessages.emailError}</span>
                )}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p className="registration_form_content">
                <label>Password</label>
                <input type="text" name="password" value={formData.password} onChange={handleChange} />
                {errorMessages.passwordError && (
                  <span className="error_message">{errorMessages.passwordError}</span>
                )}
              </p>
            </Col>
          </Row>
        <Row>
          <Col md="12">
            <p className="registration_form_content">
                <label style={{borderBottom:"1px solid #ddd",paddingBottom:".3rem",marginBottom:".3rem"}}>Admin?</label>
                <input type="checkbox" name="isAdmin" checked={formData.isAdmin} onChange={handleChange} />
            </p>
        </Col>
        </Row>
        <Row>
          <Col md="12">
            <p className="registration_form_content">
                <label style={{borderBottom:"1px solid #ddd",paddingBottom:".3rem",marginBottom:".3rem"}}>Active?</label>
                <input type="checkbox" name="isActive" checked={formData.isActive} onChange={handleChange} />
            </p>
        </Col>
        </Row>
        <Row>
          <Col md="12">
            <p className="registration_form_content">
                <label style={{borderBottom:"1px solid #ddd",paddingBottom:".3rem",marginBottom:".3rem"}}>Receive Email Notification?</label>
                <input type="checkbox" name="receive_email_notification" checked={formData.receive_email_notification} onChange={handleChange} />
            </p>
        </Col>
        </Row>
          <div className="d-flex justify-content-center align-items-center">
            {loadingButton ? (
              <button
                className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                type="button"
                disabled
                style={{ fontSize: "1rem", background: "#007DC1", color: "white" }}
              >
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                Loading...
              </button>
            ) : (
              <button className="btn mt-3 btn-md text-center btn-size create_ac_btn">
                Save
              </button>
            )}
          </div>
        </Col>
      </form>
    </div>
  </Modal>
  )
}

export default ClientUserAddModal
