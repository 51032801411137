import React, { useEffect, useState } from 'react';
import { Table, Tabs, Tab } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getColor } from '../../../utils/constant';
import { Outlet } from 'react-router-dom';
import "../../../styles/reportstorebrowser.css";
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import loadingimg from "../../../images/loading.avif";

function ReportStoreBrowserDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const [key, setKey] = useState('trends');
    let localStoreId = localStorage.getItem("storeId");
    let localQuesId = localStorage.getItem("questionnaireId");
    let storeId = location.state?.storeId;
    let questionnaireId = location?.state?.questionnaireId;
   
    useEffect(() => {
        if (location.pathname === '/userdashboardmain/reports/storebrowser/trends') {
            setKey('trends');
        } else if (location.pathname === '/userdashboardmain/reports/storebrowser/trends/storereports') {
            setKey('reports');
        }
    }, [location.pathname]);

    const {data:storeData,getApiData:getStoreData, isLoading: isStoreLoading} = useFetchinFunction();
   

    useEffect(()=>{
     localStoreId ? getStoreData(`client/store/${localStoreId}`) : getStoreData(`client/store/${storeId}`);
    //   getQuestionnaireData(`client/store/${storeId}/questionnaire_types_list`);
    },[])

    const handleSelect = (key) => {
        setKey(key);
        if (key === 'trends') {
            navigate('/userdashboardmain/reports/storebrowser/trends', {state: {
                storeId : storeId? storeId : localStoreId,
                questionnaireId : questionnaireId ? questionnaireId : localQuesId,
            }});
        } else if (key === 'reports') {
            navigate('/userdashboardmain/reports/storebrowser/trends/storereports',{state: {
                // storeId,
                // questionnaireId,
                storeId : storeId? storeId : localStoreId,
                questionnaireId : questionnaireId ? questionnaireId : localQuesId,
            }});
        }
    };

    return (
        <>
        {isStoreLoading ?
            <div className='getStarted'>
            <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
        </div>
          :
        <div style={{ marginTop: "3rem" }}>     
            <Table className="text-center table_border dash_tab_table report_table_mobile table-responsive">
                <thead className='dash_tab_table_head'>
                    <tr>
                        {/* <th className="fw-bold table_border">Code</th> */}
                        <th className="fw-bold table_border">Name</th>
                        <th className="fw-bold table_border">Address</th>
                        <th className="fw-bold table_border">City</th>
                        <th className="fw-bold table_border">Total Score Till Date</th>
                    </tr>
                </thead>
                <tbody>
                    {isStoreLoading ?
                   <tr>
                   <td colSpan={7}>
                       <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
                   </td>
               </tr>
                    :
                    <tr>
                        {/* <td className='table_border'>ST001</td> */}
                        <td className='table_border'>{storeData?.name}</td>
                        <td className='table_border'>{storeData?.address}</td>
                        <td className='table_border'>{storeData?.city?.name}</td>
                        <td className={['table_border', getColor(storeData?.get_total_percentage?.color)].join(' ')}>{storeData?.get_total_percentage?.score}%</td>
                    </tr>
                    }
                    
                </tbody>
            </Table>
            <div className='tab_contaioner'>
                <Tabs
                    id="justify-tab-example"
                    className="custom-tabs"
                    justify
                    activeKey={key}
                    onSelect={handleSelect}
                >
                    <Tab eventKey="trends" title="Trends" className="custom-tab"></Tab>
                    <Tab eventKey="reports" title="Reports" className="custom-tab"></Tab>
                </Tabs>
                <div className='remaining_tab'>

                </div>
            </div>
            <Outlet />
        </div>
                }
                </>
    );
}

export default ReportStoreBrowserDetails;
