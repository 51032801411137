import React,{useEffect} from 'react';
import DashboardSidebar from './DashboardSidebar';
import DashboardHeader from './dashheader/DashboardHeader';
import { Outlet } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import Error from '../Error';
import "../../styles/dashboardSidebar.css";
import { Helmet } from 'react-helmet';

export default function UserDashboardMain() {
    const existingUser = (localStorage.getItem('user_id'));
    const token = localStorage.getItem("token");
    // useEffect(() => {
    //     document.title = "FloorInsights | NPS Measurement Software | Calculate Your Net Promoter Score | FloorInsights"   
    //   }, []);
    
    return (
        <>
        <Helmet>
        <title>
        FloorInsights | NPS Measurement Software | Calculate Your Net Promoter Score | FloorInsights
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Measure customer loyalty with our NPS measurement software. Get actionable insights and improve customer experience. Start calculating your Net Promoter Score today!"
        />
        <meta
          name="keywords"
          content="NPS, CSAt, Net promoter score, measure customer feedback"
        />
      </Helmet>
        {existingUser && token ? 
        <div style={{ display: "flex", position: "relative" }}>
            <Col md="2" className='dash_sidebar_bigscrn'>
                <DashboardSidebar />
            </Col>
            <Col md="10" className='dash_sidebar_bigscrn'>
                <div className='dashbodymain'>
                    <Outlet />
                </div>
            </Col>
            <Col md="12" className='dash_sidebar_smallscrn' style={{width:"100%"}}>
                <DashboardHeader/>
                <div style={{ padding: "1rem",paddingTop:"1rem" }}>
                    <Outlet />
                </div>
            </Col>
        </div>
        : <Error />}
        </>
    )
    
}
 // existingUser ? 
 //  : <Error />