import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import { Table, Form, Button } from 'react-bootstrap';
import { getQuestionType } from '../../../utils/constant';
import "../../../styles/previewsection.css";
import loadingImg from "../../../images/loading.avif";
import { postSurveySubmit } from '../../../utils/services/PostdataHandle';
import { useFetchinFunctionwithoutAuth } from '../../../utils/hooks/useFetchinFunctionwithoutAuth';

const Survey = () => {
    const location = useLocation();
    const {storemapid} = useParams();
    const [questionnaireId, setQuestionnaireId] = useState(null);
    const { data: sectionData, getApiData, isLoading } = useFetchinFunctionwithoutAuth();
    const [expandedReportListRows, setExpandedReportListRows] = useState({});
    const [selectedRating, setSelectedRating] = useState(-1);
    const [hoverRating, setHoverRating] = useState(-1);
    const [answers, setAnswers] = useState({});
    const [loadingButton,setloadingButton] = useState(false);

    useEffect(() => {
        // const params = new URLSearchParams(location.search);
        // let questionnaireId = params.get('questionnaireId');

        if (storemapid) {
            getApiData(`client/store_map/${storemapid}/store_report_get`);
           
        }
      // }, [location]);
    }, []);

    useEffect(() => {
        if (sectionData && sectionData.length > 0) {
            const initialExpandedState = sectionData.reduce((acc, section) => {
                acc[section.id] = true;
                return acc;
            }, {});
            setExpandedReportListRows(initialExpandedState);
        }
    }, [sectionData]);

    const toggleReportListRow = (sectionId, event) => {
        const classList = event.target.classList;
        const isExcludedButton =
            classList.contains("proof_tag_btn") ||
            classList.contains("fa-pen-to-square") ||
            classList.contains("fa-xmark");

        if (!isExcludedButton) {
            setExpandedReportListRows((prevExpandedRows) => ({
                ...prevExpandedRows,
                [sectionId]: !prevExpandedRows[sectionId],
            }));
        }
    };

    const handleInputChange = (questionId, selectedValues) => {
        const question = sectionData
            .flatMap(section => section.questions)
            .find(q => q.id === parseInt(questionId));
    
        if (question && question.question_type === "MULTIPLE_CHOICE") {
            const selectedOptions = selectedValues.split(',').filter(value => value !== '');
            const totalMarks = selectedOptions.reduce((acc, value) => {
                const option = question.question_data.options.find(opt => opt.value === value);
                return acc + (option ? option.marks : 0);
            }, 0);
    
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [questionId]: {
                    ...prevAnswers[questionId],
                    answer_text: selectedValues,
                    marks_obtained: totalMarks
                }
            }));
        } else {
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                [questionId]: {
                    ...prevAnswers[questionId],
                    answer_text: selectedValues
                }
            }));
        }
    };
    

const handleSubmit = () => {
    setloadingButton(true);
    const payload = {
        store_map_id: +storemapid,
        answers: Object.keys(answers).map(questionId => {
            const question = sectionData
                .flatMap(section => section.questions)
                .find(q => q.id === parseInt(questionId));

            let marks_obtained = 0;
            if (question.question_type === "MULTIPLE_CHOICE") {
                marks_obtained = answers[questionId].marks_obtained || 0;
            }
            if (question.question_type === "MUTEX") {
                // console.log("402",answers[questionId].answer_text)
                const selectedOption = question.question_data.options.find(option => option.value == answers[questionId].answer_text);
                // console.log("404", selectedOption)
                marks_obtained = selectedOption ? selectedOption.marks : 0;
            } else if (['PLAIN_TEXT', 'EMAIL', 'DATE', 'PHONE_NUMBER', 'SINGLESELECT'].includes(question.question_type)) {
                marks_obtained = question.max_marks;
            } 
            else if (question.question_type === "OPINION_SCALE"){
                // console.log("382",answers[questionId].answer_text);
                const selectedOption = question.question_data.options.find(option => option.value == answers[questionId].answer_text);
                // marks_obtained = +answers[questionId].answer_text
                marks_obtained = selectedOption ? selectedOption.marks : 0;
            } else if (question.question_type === "RATING"){
                // console.log("382",answers[questionId].answer_text);
                const selectedOption = question.question_data.options.find(option => option.value == answers[questionId].answer_text);
                // console.log("387", selectedOption)
                marks_obtained = selectedOption ? selectedOption.marks : 0;
            }else {
                marks_obtained = answers[questionId].marks_obtained || 0;
            }

            return {
                question: parseInt(questionId),
                answer_text: answers[questionId].answer_text,
                marks_obtained
            };
        })
    };

    console.log("Payload:", payload);
    const promise = postSurveySubmit(payload, `client/store_map/${storemapid}/store_report/submit`);
    promise
      .then((response) => response.json())
      .then((data) => {
        if(!data.error){
            setAnswers({});
            setSelectedRating(-1);
            setHoverRating(-1);
            getApiData(`client/store_map/${storemapid}/store_report_get`);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
       setloadingButton(false);
      });
    
};

    const renderAnswerInput = (question) => {
        switch (question?.question_type) {
            case "PLAIN_TEXT":
                return (
                  <p className="project_setup1_content">
                    <textarea
                        placeholder='type your answer here'
                        rows={1}
                        value={answers[question.id]?.answer_text || ''}
                        onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                    </p>
                );
            case "MUTEX":
                return (
                  <p className="registration_form_content">
                    <select
                        name="question_type"
                        value={answers[question.id]?.answer_text || ''}
                        // onChange={(e) => handleInputChange(question.id, e.target.value, parseInt(e.target.selectedOptions[0].value))}
                        onChange={(e) => handleInputChange(question.id, e.target.value, parseInt(e.target.selectedOptions[0].value))}
                    >
                        <option value="">Select...</option>
                        {Array.isArray(question?.question_data?.options) && question?.question_data?.options.map((option, index) => (
                            // <option key={index} value={option.marks}>
                            <option key={index} value={option.value}>
                                {option.value}
                            </option>
                        ))}
                    </select>
                    </p>
                );
            case "MULTIPLE_CHOICE":
                return (
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", gap:"2rem",flexWrap:"wrap"}}>
                        {Array.isArray(question?.question_data?.options) && question?.question_data?.options.map((option, index) => (
                            <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:".5rem"}}>
                                <input
                                    type="checkbox"
                                    id={`multiselect_${question.id}_${index}`}
                                    name={`multiselect_${question.id}`}
                                    style={{height:"20px", width:"20px"}} 
                                    // checked={answers[question.id]?.answer_text.split(',').includes(option.value)}
                                    checked={answers[question.id]?.answer_text?.split(',').includes(option.value) || false}
                                    onChange={(e) => {
                                        let selectedOptions = answers[question.id]?.answer_text ? answers[question.id].answer_text.split(',') : [];
                                        if (e.target.checked) {
                                            selectedOptions.push(option.value);
                                        } else {
                                            selectedOptions = selectedOptions.filter(o => o !== option.value);
                                        }
                                        handleInputChange(question.id, selectedOptions.join(','));
                                    }}
                                />
                                <label htmlFor={`multiselect_${question.id}_${index}`} style={{ cursor: "pointer" }}>{option.value}</label>
                            </p>
                        ))}
                    </div>
                );
            
            case "SINGLESELECT":
                return (
                    <div style={{display:"flex", justifyContent:"flex-start", alignItems:"center", gap:"2rem",flexWrap:"wrap"}}>
                        {Array.isArray(question?.question_data?.options) && question?.question_data?.options.map((option, index) => (
                                 <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:".5rem",}}>
                                <input
                                    type="radio"
                                    id={`singleselect_${question.id}_${index}`}
                                    name={`singleselect_${question.id}`}
                                    style={{ height: "20px", width: "20px" }}
                                    value={option.value}
                                    checked={answers[question.id]?.answer_text === option.value}
                                    onChange={(e) => handleInputChange(question.id, option.value, option.marks)}
                                />
                                <label htmlFor={`singleselect_${question.id}_${index}`} style={{ cursor: "pointer" }}>{option.value}</label>
                            </p>
                        ))}
                    </div>
                );
            // case "RATING":
            //     return (
            //         <div className="rating">
            //             {[...Array(5)].map((_, index) => (
            //                 <i
            //                     key={index}
            //                     className={`fa-star ${
            //                         hoverRating !== null
            //                             ? index <= hoverRating
            //                                 ? "fa-solid fa-star gold"
            //                                 : "fa-regular fa-star"
            //                             : index <= selectedRating
            //                                 ? "fa-solid fa-star gold"
            //                                 : "fa-regular fa-star"
            //                     }`}
            //                     onMouseEnter={() => setHoverRating(index)}
            //                     onMouseLeave={() => setHoverRating(null)}
            //                     onClick={() => {
            //                         setSelectedRating(index);
            //                         // handleInputChange(question.id, `${index + 1} asdvh vhj`, index + 1);
            //                         handleInputChange(question.id, index + 1, index + 1);
            //                     }}
            //                 ></i>
            //             ))}
            //         </div>
            //     );
            case "RATING":
    return (
        <div className="rating">
            {[...Array(5)].map((_, index) => (
                <i
                    key={index}
                    className={`fa-star ${
                        answers[question.id]?.hoverRating !== null
                            ? index < answers[question.id]?.hoverRating
                                ? "fa-solid fa-star gold"
                                : "fa-regular fa-star"
                            : index < answers[question.id]?.selectedRating
                                ? "fa-solid fa-star gold"
                                : "fa-regular fa-star"
                    }`}
                    onMouseEnter={() => setAnswers(prevAnswers => ({
                        ...prevAnswers,
                        [question.id]: {
                            ...prevAnswers[question.id],
                            hoverRating: index + 1
                        }
                    }))}
                    onMouseLeave={() => setAnswers(prevAnswers => ({
                        ...prevAnswers,
                        [question.id]: {
                            ...prevAnswers[question.id],
                            hoverRating: null
                        }
                    }))}
                    onClick={() => setAnswers(prevAnswers => ({
                        ...prevAnswers,
                        [question.id]: {
                            ...prevAnswers[question.id],
                            selectedRating: index + 1,
                            answer_text: (index + 1).toString()
                        }
                    }))}
                />
            ))}
        </div>
    );

            // case "OPINION_SCALE":
            //     return (
            //         <Form.Range
            //         // onChange={(e) => handleInputChange(question.id, `${e.target.value} lsla`, parseInt(e.target.value))} />
            //         onChange={(e) => handleInputChange(question.id, e.target.value, parseInt(e.target.value))} />
            //     );
            case "OPINION_SCALE":
    return (
        <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Form.Range
                value={answers[question.id]?.answer_text || 0}
                onChange={(e) => handleInputChange(question.id, e.target.value, parseInt(e.target.value))}
                min={0} 
                max={10}
            />
            <span>{answers[question.id]?.answer_text || 0}</span> {/* Display the selected range value */}
        </div>
    );

            case "EMAIL":
                return (
                  <p className="registration_form_content">
                    <input
                        placeholder="johndoe@gmail.com"
                        type="email"
                        value={answers[question.id]?.answer_text || ''}
                        onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                    </p>
                );
            case "DATE":
                return (
                  <p className="registration_form_content">
                    <input
                        type="date"
                        value={answers[question.id]?.answer_text || ''}
                        onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                    </p>
                );
            case "PHONE_NUMBER":
                return (
                  <p className="registration_form_content">
                    <input
                        type="number"
                        className="audit_count_numberbox"
                        value={answers[question.id]?.answer_text || ''}
                        placeholder="9876543211"
                        onChange={(e) => handleInputChange(question.id, e.target.value)}
                    />
                    </p>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ margin: "5rem" }}>
            {isLoading ? (
                <div className='getStarted'>
                    <img src={loadingImg} alt="Loading..." style={{ width: "200px" }} />
                </div>
            ) : 
            sectionData && sectionData.length > 0 ? (
                sectionData.map((section) => (
                    <div className="ques_sec_row_outer" key={section?.id}>
                        <div
                            className="ques_sec_row_main"
                            onClick={(e) => toggleReportListRow(section?.id, e)}
                            style={{ padding: "1rem" }}
                        >
                            <div className="ques_sec_row_main_left">
                                <div>
                                    {section?.sequence}- <span>{section?.name}</span>
                                </div>
                            </div>
                            <div className="ques_sec_row_main_right">
                                <div>
                                    <span>{section?.questions.length}</span>{" "}
                                    {section?.questions.length > 1 ? "Questions" : "Question"}
                                </div>
                                <div>
                                    <i
                                        className={`fa-solid ${
                                            expandedReportListRows[section.id]
                                                ? "fa-plus"
                                                : "fa-minus"
                                        }`}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        {expandedReportListRows[section.id] && (
                            <div className="expanded-row">
                                <div
                                    className="dash_report_table table-responsive"
                                    style={{ marginTop: "1rem", paddingTop: "0rem" }}
                                >
                                    <Table className="text-left store_report_table_mobile  pricing_table">
                                        <thead>
                                            <tr className="ques_sec_row_thead">
                                                <th className="fw-bold">Question</th>
                                                <th className="fw-bold">Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {section?.questions && section?.questions.length > 0 ? (
                                                section?.questions
                                                    .sort((a, b) => a.sequence - b.sequence)
                                                    .map((question) => {
                                                        return (
                                                            <tr
                                                                className="ques_sec_row_tbody"
                                                                key={question?.id}
                                                            >
                                                                <td className="fw-bold">
                                                                    {question?.question_text}
                                                                </td>
                                                                <td className="fw-bold">
                                                                    {renderAnswerInput(question)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                            ) : (
                                                <tr>
                                                    <td colSpan={2} className="text-center">
                                                        No Question Answers for now...
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        )}
                    </div>
                ))
                
            ) : (
                <div>
                    <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                        No Section for now...
                    </h5>
                </div>
            )}
           
             <div className="d-flex justify-content-center align-items-center">
            {sectionData && sectionData.length > 0 ?
           
            loadingButton ? (
              <button
                className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                type="button"
                disabled
                style={{ fontSize: "1rem", background: "#007DC1", color: "white" }}
              >
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                Loading...
              </button>
            ) :
             (
              <button className="btn mt-3 btn-md text-center btn-size create_ac_btn" onClick={handleSubmit}>
                Submit
              </button>
            )
         
            :
            null}
            </div>
           
        </div>
    );
};

export default Survey;
