import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../../styles/dashprofile.css";
import { Col, Row, Alert } from 'react-bootstrap';
import base_url from "../../../utils/config";
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import { postClientProfileData } from '../../../utils/services/PostdataHandle';
import loadingImg from "../../../images/loading.avif";

export default function DashProfile() {
    const navigate = useNavigate();
    const existingUser = localStorage.getItem('user_id');
    const [countryData, setCountryData] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        brand: "",
        username: "",
        mobile_number: "",
        first_name: "",
        last_name: "",
        gst: "",
        address: "",
        country: '',
        state: '',
        city: '',
        pincode: '',
        logo_url: '',
        brand_logo_url: '',
        company_website_url: '',
        receive_email_notification: false,
    });
    const [errorMessages, setErrorMessages] = useState({
        brandError: '',
        usernameError: '',
        mobile_numberError: '',
        first_nameError: '',
        last_nameError: '',
        gstError: '',
        addressError: '',
        countryError: '',
        stateError: '',
        cityError: '',
        pincodeError: '',
        logo_urlError: '',
        brand_logo_urlError: '',
        company_website_urlError: '',
        // receive_email_notification: false,

    });

    const [AlertMessage, setAlertMessage] = useState(false);
    const [FailAlertMessage, setFailAlertMessage] = useState(false);
    const [hasError, setHasError] = useState(false);
    const token = localStorage.getItem("token");
    useEffect(() => {
        getcountryApiData();
        getProfileData("client/client_profile_registration/");
    }, [])
  
    useEffect(() => {
        if (AlertMessage || FailAlertMessage) {
            window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        }
    }, [AlertMessage, FailAlertMessage])

    const getProfileData = async (url) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                console.log("955555",result)
                setFormData({
                    brand: result?.brand_name,
                    username:result?.email,
                    mobile_number: result?.mobile_number,
                    first_name: result?.first_name,
                    last_name: result?.last_name,
                    gst:result?.gst,
                    address: result?.address,
                    country: result?.city?.country,
                    state: result?.city?.state,
                    city: result?.city?.id,
                    pincode: result?.pincode,
                    logo_url: result?.logo_url,
                    brand_logo_url: result?.brand_logo_url,
                    company_website_url: result?.company_website_url,
                    receive_email_notification: result?.receive_email_notification,
                });
                if (result?.city?.country) {
                    fetchStateData(result.city.country);
                }
                if (result?.city?.state) {
                    fetchCityData(result.city.state)
                    .then((cities) => {
                        setCityData(cities);
                    })
                    .catch((error) => {
                        console.error('Error fetching city data:', error);
                    });
                }
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }finally {
            setIsLoading(false); 
          }
    };
    const handleChange = (e) => {
        // const { name, value } = e.target;
        // console.log(name, value)
        // setFormData((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        // }));
        const { name, value, type, checked } = e.target;
        console.log(name, value);

        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
        setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [`${name}Error`]: '',
        }));

        // if (name === 'gst') {
        //     setErrorMessages((prevErrors) => ({
        //         ...prevErrors,
        //         gstError: '',
        //     }));
        // }
        if (name === 'state') {
            fetchCityData(value)
                .then((cities) => {
                    setCityData(cities);
                })
                .catch((error) => {
                    console.error('Error fetching city data:', error);
                });
        }
        if (name === 'country') {
            fetchStateData(value)
                .then((states) => {
                    // setStateData(states);
                    const stateDataArray = Object.entries(states).map(([stateCode, stateName]) => ({
                        stateCode,
                        stateName
                    }));
                    setStateData(stateDataArray)
                })
                .catch((error) => {
                    console.error('Error fetching state data:', error);
                });
        }
    };

    const validateGSTNumber = (gstNumber) => {
        const gstPattern = /^(0[1-9]|[1-2][0-9]|3[0-8])[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
        return gstPattern.test(gstNumber);
    };

    const handleSubmit = (e) => {
        console.log("in profileee", formData)
        e.preventDefault();
        setHasError(false);

        if (!formData.first_name) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                first_nameError: "First name can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    first_nameError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (!formData.brand) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                brandError: "Company name can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    brandError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (!formData.mobile_number) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                mobile_numberError: "Mobile can't be empty",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    mobile_numberError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        const isValidMobileNumber = /^[0-9]{10}$/.test(formData.mobile_number);

        if (!formData.mobile_number || !isValidMobileNumber) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                mobile_numberError: "Mobile number should be a 10-digit number",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    mobile_numberError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        if (formData.gst && !validateGSTNumber(formData.gst)) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                gstError: "Invalid GST Number",
            }));
            setTimeout(() => {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    gstError: "",
                }));
            }, 1700)
            setHasError(true);
        }

        // if (
        //     formData.first_name &&
        //     formData.brand &&
        //     formData.username &&
        //     formData.mobile_number &&
        //     isValidMobileNumber 

        //     // (!formData.gst || validateGSTNumber(formData.gst))
        // ) {
        const requestData = {
            first_name: formData.first_name,
            last_name: formData.last_name,
            brand_name: formData.brand,
            mobile_number: formData.mobile_number,
            gst: formData.gst,
            address: formData.address,
            user: existingUser,
            // country: formData.country,
            // state: formData.state,
            city_id: +formData.city,
            pincode: formData.pincode,
            logo_url: formData.logo_url,
            brand_logo_url: formData.brand_logo_url,
            company_website_url: formData.company_website_url,
            receive_email_notification: formData.receive_email_notification,

        };
        console.log(requestData)
        const promise = postClientProfileData(requestData);
        promise
            .then((response) => {
                if (response.status === 200 || response.status === 204) {
                    getProfileData("client/client_profile_registration/");
                    setHasError(false);
                    setAlertMessage(true);
                    setTimeout(() => {
                        setAlertMessage(false);
                    }, 1500);
                } else {
                    setFailAlertMessage(true);
                    setTimeout(() => {
                        setFailAlertMessage(false);
                    }, 1500);
                }
            })
            .catch((error) => {
                setFailAlertMessage(true);
                setTimeout(() => {
                    setFailAlertMessage(false);
                }, 1500);
            });
        // }
    };
    const getcountryApiData = async () => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };
            const response = await fetch(base_url + "client/country_list/", options);
            if (response.ok) {
                const result = await response.json();
                const countryDataArray = Object.entries(result).map(([countryCode, countryName]) => ({
                    countryCode,
                    countryName
                }));
                setCountryData(countryDataArray);
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }
    };
    // -------------------------------FETCHING STATES WITH COUNTRY CODE--------------------
    const fetchStateData = async (countryCode) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };
            const response = await fetch(`${base_url}client/state_list/${countryCode}/`, options);
            if (response.ok) {
                const result = await response.json();
                const stateDataArray = Object.entries(result).map(([stateCode, stateName]) => ({
                    stateCode,
                    stateName
                }));
                setStateData(stateDataArray)
                // return result;
            } else {
                console.error('API request failed:', response.status, response.statusText);
                return [];
            }
        } catch (error) {
            console.error('An error occurred while fetching state data:', error);
            return [];
        }
    };
    // -------------------------------FETCHING CITIES WITH STATE CODE--------------------

    const fetchCityData = async (stateCode) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json',
            }
            const options = {
                method: "GET",
                headers: headers,
            }
            const response = await fetch(`${base_url}client/city_list/${stateCode}`, options);
            if (response.ok) {
                const result = await response.json();
                return result;
            } else {
                console.error('API request failed:', response.status, response.statusText);
                return [];
            }
        } catch (error) {
            console.error('An error occurred while fetching city data:', error);
            return [];
        }
    };
    return (
        <div>
            {AlertMessage ? (
                <Alert variant="success" onClose={() => setAlertMessage(false)} dismissible style={{ textAlign: "center" }}>
                    Your Profile is updated successfully
                </Alert>
            ) : null}
            {FailAlertMessage ? (
                <Alert variant="danger" onClose={() => setFailAlertMessage(false)} dismissible style={{ textAlign: "center" }}>
                    Your Profile is not updated
                </Alert>
            ) : null}

            <div className='profile_detail_box'>
                {isLoading ?
                    <div className='getStarted'>
                        <img src={loadingImg} alt="Loading..." style={{ width: "300px" }} />
                    </div>
                    :
                    <form onSubmit={handleSubmit}>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>First Name<span>*</span></label>
                                    <input
                                        type="text"
                                        name='first_name'
                                        value={formData.first_name}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.first_nameError && (
                                        <span className="error_message">{errorMessages.first_nameError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Last Name</label>
                                    <input
                                        type="text"
                                        name='last_name'
                                        value={formData.last_name}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Brand Name<span>*</span></label>
                                    <input
                                        type="text"
                                        name='brand'
                                        value={formData.brand}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.brandError && (
                                        <span className="error_message">{errorMessages.brandError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>GST Number</label>
                                    <input
                                        type="text"
                                        name='gst'
                                        value={formData.gst}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.gstError && (
                                        <span className="error_message">{errorMessages.gstError}</span>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Company Address</label>
                                    <input
                                        type="text"
                                        name='address'
                                        value={formData.address}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                            <Col md="2" className='bigscrn_mobilenumber'>
                                <p className="registration_form_content">
                                    <label htmlFor="registration_mobile_number">Mobile<span>*</span></label>
                                    <select name="country_codes" id="country_codes">
                                        <option value="+91">+91</option>
                                    </select>
                                </p>
                            </Col>
                            <Col md="4" className='bigscrn_mobilenumber'>
                                <p className="registration_form_content">
                                    <label style={{ visibility: "hidden" }}>Mobile</label>
                                    <input
                                        type="tel"
                                        value={formData.mobile_number}
                                        name='mobile_number'
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.mobile_numberError && (
                                        <span className="error_message">{errorMessages.mobile_numberError}</span>
                                    )}
                                </p>
                            </Col>
                            {/* ------------------------------------------MOBILE VIEW------------------------------------------- */}
                            <Col md="6" className='smallscrn_mobilenumber'>
                                <label>Mobile <span style={{ color: "red" }}>*</span></label>
                                <p className="registration_form_content" style={{ flexDirection: "row" }}>
                                    <select name="country_codes" id="country_codes" style={{ width: "20%", padding: "0px", paddingLeft: "5px" }}>
                                        <option value="+91">+91</option>
                                    </select>
                                    <input
                                        type="tel"
                                        value={formData.phone}
                                        style={{ width: "80%" }}
                                        name='phone'
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.phoneError && (
                                        <span className="error_message">{errorMessages.phoneError}</span>
                                    )}
                                </p>
                            </Col>
                            {/* ------------------------------------------------------------------------------------------------------------ */}
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Country<span>*</span></label>
                                    <select name='country' onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.country} >
                                        <option value="">Select</option>
                                        {countryData.map((item) => (
                                            <option key={item.countryCode} value={item.countryCode} >{item.countryName}</option>
                                        ))}
                                    </select>
                                    {errorMessages.countryError && (
                                        <span className="error_message">{errorMessages.countryError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>State<span>*</span></label>
                                    <select name='state' onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.state} >
                                        <option value="">Select</option>
                                        {Array.isArray(stateData) && stateData.map((item) => (
                                            <option key={item.stateCode} value={item.stateCode}>{item.stateName}</option>))}
                                    </select>
                                    {errorMessages.stateError && (
                                        <span className="error_message">{errorMessages.stateError}</span>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>City<span>*</span></label>
                                    <select onChange={handleChange} className={hasError ? "error_border" : "success_border"} value={formData.city} name='city'>
                                        <option value="">Select</option>
                                        {cityData.map((city) => (
                                            <option key={city.id} value={city.id} > {city.name} </option>
                                        ))}
                                    </select>
                                    {errorMessages.cityError && (
                                        <span className="error_message">{errorMessages.cityError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Pincode</label>
                                    <input type="number" name="pincode" value={formData.pincode} onChange={handleChange} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Logo URL<span>*</span></label>
                                    <input
                                        type="text"
                                        name='logo_url'
                                        value={formData.logo_url}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.logo_urlError && (
                                        <span className="error_message">{errorMessages.logo_urlError}</span>
                                    )}
                                </p>
                            </Col>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Brand Logo URL</label>
                                    <input
                                        type="text"
                                        name='brand_logo_url'
                                        value={formData.brand_logo_url}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.brand_logo_urlError && (
                                        <span className="error_message">{errorMessages.brand_logo_urlError}</span>
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Company Website URL<span>*</span></label>
                                    <input
                                        type="text"
                                        name='company_website_url'
                                        value={formData.company_website_url}
                                        onChange={handleChange}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                    {errorMessages.cityError && (
                                        <span className="error_message">{errorMessages.company_website_urlError}</span>
                                    )}
                                </p>
                            </Col>

                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Receive Email Notification</label>
                                    <input type="checkbox" name="receive_email_notification" checked={formData.receive_email_notification} onChange={handleChange} />
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <p className="registration_form_content">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        name='username'
                                        value={formData.username}
                                        onChange={handleChange}
                                        disabled={true}
                                        style={{ color: "gray" }}
                                        className={hasError ? "error_border" : "success_border"}
                                    />
                                </p>
                            </Col>
                        </Row>
                        <button
                            className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
                        >
                            Update Details
                        </button>
                    </form>
                }
            </div>
        </div>
    )
}
