import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { postAddQuesionnaireSection } from "../../../utils/services/PostdataHandle";
import base_url from "../../../utils/config";
import { useDispatch } from "react-redux";
import { addSectionDetailData } from "../../../utils/reduxcomponents/sectionDetailSlice";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import loadingImg from "../../../images/loading.avif";

export default function AddSectionModal({
  modalShow,
  setModalShow,
  questionnaire_type_id,
  editSectionId,
  modalTitle,
  setShowAlert,
  setMessage,
  setEditSectionId,
}) {
    const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const [formData, setFormData] = useState({
    section_name: "",
    sequence: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    section_nameError: "",
    sequenceError: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const {data:sectionEditData, isLoading: initialLoading, getApiData} = useFetchinFunction()

  useEffect(() => {
    if (editSectionId) {
    getApiData(`client/section/${editSectionId}/`)
    } else {
      setFormData({
        section_name: "",
        sequence: "",
      });
    }
    setErrorMessages({
      section_nameError: "",
      sequenceError: "",
    });
    setErrorMessage("")
}, [ editSectionId]);

useEffect(()=>{
if(sectionEditData){
  setFormData({
    section_name: sectionEditData?.name,
    sequence : sectionEditData?.sequence,
   })
}
},[sectionEditData])


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    console.log(name, value, type, checked);

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
    setErrorMessage("")
  };

  const handleSubmit = (e) => {
    console.log(formData);
    e.preventDefault();
    let hasErrors = false;

    if (!formData.section_name) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        section_nameError: "Section name can't be empty",
      }));
      hasErrors = true;
    }
    if (!formData.sequence) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        sequenceError: "Sequence can't be empty",
      }));
      hasErrors = true;
    }

    if (hasErrors) {
      setHasError(true);
      setTimeout(() => {
        setErrorMessages({
          section_nameError: "",
          sequenceError: "",
        });
        setHasError(false);
      }, 1500);
      return;
    }

    let payload = {
      name: formData.section_name,
      sequence: +formData.sequence,
      questionnaire: questionnaire_type_id,
    };

    setLoadingButton(true);
    postAddQuesionnaireSection(
      `client/section${editSectionId ? `/${editSectionId}/` : ""}`,
      payload,
      token
    )
      .then((response) => {
        if (response.status === 400) {
          return response.json().then((data) => {
            console.log("1499999",data.detail);
            setErrorMessage(data?.detail);
            throw new Error(data.detail);
          });
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log("86666", data);
          setFormData({
            section_name: "",
            sequence: "",
          });
          dispatch(addSectionDetailData(data));
          hideModal();
          setShowAlert(true);
          setMessage(editSectionId ? "Section is Editted Successfully" :"Section is Added Successfully");
          setTimeout(()=>{
           setShowAlert(false);
           setMessage("");
          },1500)
        }
       
      )
      .catch((error) => {
        console.log(error);
        setShowAlert(true);
        setMessage("Something went wrong. Please try again later.", error.message);
        setTimeout(()=>{
          setShowAlert(false);
          setMessage("");
         },1500)
      })
      .finally(() => {
        setLoadingButton(false);
      });
  };

  function hideModal () {
    setModalShow(false);
    setEditSectionId(null);
  }
  return (
    <Modal
      show={modalShow}
      centered
      size="md"
      className="modal-dialog-scrollable p-6 modal_mobile"
      onHide={hideModal}
    >
      <div
        style={{
          width: "100%",
          padding: "2rem 3rem",
          border: "none",
          position: "relative",
        }}
      >
        <div
          style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }}
          onClick={hideModal}
        >
          <i className="fa-solid fa-x"></i>
        </div>
        <form onSubmit={handleSubmit}>
          <Col>
            <Row>
              <h5 className="setup_regs_modal_heading">{modalTitle}</h5>
            </Row>
            {initialLoading ? 
            <Row>
<div className='getStarted'>
         <img src={loadingImg} alt="Loading..." style={{ width: "150px" }} />
     </div>
          </Row> 
          :
          <>
            <Row>
              <Col md="12">
                <p className="registration_form_content">
                  <label>Sequence</label>
                  <input type="number" name="sequence" value={formData.sequence} onChange={handleChange} />
                  {errorMessages.sequenceError && (
                    <span className="error_message">{errorMessages.sequenceError}</span>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p className="registration_form_content">
                  <label>Section Name</label>
                  <input type="text" name="section_name" value={formData.section_name} onChange={handleChange} />
                  {errorMessages.section_nameError && (
                    <span className="error_message">{errorMessages.section_nameError}</span>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p className="registration_form_content">
                  {errorMessage && (
                    <span className="error_message">{errorMessage}</span>
                  )}
                </p>
              </Col>
            </Row>
            <div className="d-flex justify-content-center align-items-center">
              {loadingButton ? (
                <button
                  className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                  type="button"
                  disabled
                  style={{ fontSize: "1rem", background: "#007DC1", color: "white" }}
                >
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                  Loading...
                </button>
              ) : (
                <button className="btn mt-3 btn-md text-center btn-size create_ac_btn">
                  Submit
                </button>
              )}
            </div>
            </>
}
          </Col>
        </form>
      </div>
    </Modal>
  );
}
