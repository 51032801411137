import React, { useEffect } from "react";
import "../../../styles/templateindividual.css"; // Import your CSS file
import image from "../../../images/Artificial_Intelligence.avif";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Accordion, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useFetchinFunctionwithoutAuth } from "../../../utils/hooks/useFetchinFunctionwithoutAuth";
import { useFetch } from "../../../utils/hooks/useFetch";
import base_url from "../../../utils/config";
import Shimmer from "../../shimmerComponents/Shimmer";
import ShimmerIndividualProduct from "../../shimmerComponents/ShimmerIndividualProduct";

function CustomerSupportSatisfaction() {
  const navigate = useNavigate();
  const location = useLocation();
  const templateId = location.state;
  const {data:templateDetailData,getApiData, isLoading:isDetailLoading} = useFetchinFunctionwithoutAuth();
  const {data:templateData, isLoading, error} = useFetch(base_url+`client/templates_list/`);

  
  useEffect(()=>{
    if(templateId){
      getApiData(`client/template_data/${templateId}`)
    }  
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  },[templateId])

  function handleNavigation(templateId,url) {
    navigate(`/templates/${url}`,{state:templateId});
}
  return (
    <>
      <Helmet>
        <title>
        FloorInsights | Customer Support Satisfaction Survey
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Measure customer satisfaction with our support services"
        />
        <meta
          name="keywords"
          content="customer support, satisfaction survey"
        />
      </Helmet>
      {isDetailLoading ?
      <ShimmerIndividualProduct/>
      :
<>
<div className="indi_temp_container">
        <div className="indi_temp_text-section">
          <h1>{templateDetailData?.template_title}</h1>
          <p>
           {templateDetailData?.template_description}
          </p>
          <div className="template-btn-container">
            <div
              className="template-btn-hwitwrks"
              style={{ textAlign: "left" }}
            >
              <a
                href="https://calendly.com/sourabh-narsaria-floorwalk"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                Preview Template
              </a>
            </div>
            <div
              className="template-btn-hwitwrks"
              style={{ textAlign: "left" }}
            >
              <Link
                to="/account-info/registration"
                style={{
                  textDecoration: "none",
                  backgroundColor: "#007dc1",
                  color: "white",
                }}
              >
                Use This Template
              </Link>
            </div>
          </div>
        </div>
        <div className="indi_temp_image-section">
          <img src={templateDetailData?.template_url} alt="Customer Support" />
          {/* <div className="overlay">Text on Hover </div> */}
          <div className="overlay"> </div>
        </div>
      </div>
     
      <div className="template_below_box">
        <h1>{templateDetailData?.features_title_1}</h1>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_1_subtitle_1}</h3>
          <p>{templateDetailData?.feature_title_1_subtitle_1_description} </p>
        </div>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_1_subtitle_2}</h3>
          <p>{templateDetailData?.feature_title_1_subtitle_2_description}</p>
        </div>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_1_subtitle_3}</h3>
          <p>{templateDetailData?.feature_title_1_subtitle_3_description}</p>
        </div>

        {/* ============== */}

        <h1>{templateDetailData?.features_title_2}</h1>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_2_subtitle_1}</h3>
          <p>{templateDetailData?.feature_title_2_subtitle_2_description} </p>
        </div>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_2_subtitle_3}</h3>
          <p>{templateDetailData?.feature_title_2_subtitle_3_description}</p>
        </div>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_1_subtitle_3}</h3>
          <p>{templateDetailData?.feature_title_1_subtitle_3_description}</p>
        </div>
        <div className="template_below_textbox">
          <h3>{templateDetailData?.feature_title_2_subtitle_4}</h3>
          <p>{templateDetailData?.feature_title_2_subtitle_4_description}</p>
        </div>
      </div>
      <div className="template_accordian">
        <h1>Features of Yes No Questionnaire Template</h1>
        <div className="temp_accordian_holder">
          <Accordion className="temp_accordian">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  {" "}
                  WHY IS MYSTERY SHOPPING HELPFUL?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Mystery Shopping allows companies to uncover what their
                      clients think of their brand. It is a good way to generate
                      insights about the quality of the service provided. It
                      offers the management a method to efficiently evaluate
                      their business practices, service protocol, and employee
                      training from the perspective of their consumer.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHY DO COMPANIES USE MYSTERY SHOPPING?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Companies use mystery shopping to understand and improve
                      the typical customer behavior at its locations and
                      throughout their organization. Companies often reward
                      positive customer experiences and use mystery shopper's
                      evaluations to improve their service and product quality.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHAT TYPES OF COMPANIES USE MYSTERY SHOPPING?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Consumer-based companies, manufacturers companies all use
                      mystery shopping. These includes both start-ups and
                      well-established companies, restaurants, hotels, resorts,
                      banks and financial service providers. The following are
                      other industries that uses mystery shopping too:
                    </p>
                  </li>
                  <ul className="product_detail_border_ul">
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        1. Automotive service facilities
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        2. Luxury products retailers
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        3. Computer/electronics companies
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        4. Advertising and promotions agencies
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>5. Seasonal retailers</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>6. Apartments</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        7. Convenience and gift stores
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>8. Home builders</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        9. Self-storage facilities
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>10. Research companies</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>11. Grocery stores</p>
                    </li>
                  </ul>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  ARE THERE DIFFERENT TYPES OF MYSTERY SHOPS?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      There are several form of mystery shopping such as on-site
                      mystery shopper visits where the mystery shopper directly
                      visits the company or industry to evaluate them secretly.
                      Some other common types of mystery shopping includes
                      employee-reward shops, competitive shops, telephone shops,
                      digital photo shops, internet service evaluations, onsite
                      operational, marketing and merchandising audits. There are
                      types or forms mystery shopping but above are the common
                      methods used.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  HOW DO YOU MONITOR PERFORMANCE?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Each individual shopper's scores are monitored on a
                      monthly basis. Any shopper that appears to be out of sync
                      with the average of the other shoppers doing the same
                      locations will receive additional guidance and coaching.
                      While in the field, we perform "ride alongs" with shoppers
                      that require more "one on one" assistance and coaching.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  HOW DO WE GET THE RESULTS OF OUR MYSTERY SHOPPER VISITS?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Clients receive a monthly report outlining each location
                      or branch's performance in a variety of categories. These
                      reports are available on a dedicated web site set up for
                      the companies. You will be able to adjust the available
                      trend reports, views and queries to fit your specific
                      needs. Also, there are email alerts to notify you of
                      surveys that are below standard.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHAT IS INCLUDED WITH MY BASIC PROGRAM FEE?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      1. Delivery of high-quality on-site mystery shops.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      2. Quick turn-around time of mystery shopping results
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      3. Reports and Analytics of the customer's feedback based
                      on Mystery Shopping.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      4. Instant e-mail notification to view reporting via the
                      Internet.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      5. Monthly summary reports to view trends (assessed
                      according to needs).
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
      <div className="template_last_box">
        <h1>More Templates</h1>
        <p>
          Liked this Yes or No Questionnaire Template? We have a comprehensive
          collection of survey, form, and questionnaire templates for you. You
          can also go through the recommendations below
        </p>
      </div>
      <div className="grid-container">
        {templateData?.filter(temp=>temp?.id !== templateId).sort(() => Math.random() - 0.5).slice(0, 4).map(temp=>{
          return(
            <div className="grid-item" key={temp?.id} onClick={()=>handleNavigation(temp?.id, temp?.template_name)}>
            <img src={temp?.template_url} alt={temp?.template_name} />
            <div className="overlay">Click for details</div>
          </div>
          )
        })}
      </div>
</>
      }
     
    </>
  );
}

export default CustomerSupportSatisfaction;
