import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Dropdown, Nav } from "react-bootstrap";
import "../../styles/header.css";
// import floorwalklogo from "../../src/images/floorwalk_logo.png";
import floorwalklogo from "../../../src/images/FloorInsightLogo.jpeg";
// import { useFetch } from "../../utils/hooks/useFetch";
import { useNavigate } from "react-router-dom";
// import base_url from "../../utils/config";
import HeaderMenu from "./HeaderMenu";
import TemplateSmallScreen from "./TemplateSmallScreen";
import Template from "./Template";
import floorwalklogosmall from "../../images/Logo_copy.png";

const Header1 = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);
  const [isIndex, setIsIndex] = useState(0);
  const [templateShow, setTemplateShow] = useState(false);
  const navigate = useNavigate();

  function handleClickSidebar() {
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
  }

  function handlesmallscreendropdown(index) {
    if (index === isIndex) {
      setIsIndex(0);
    } else {
      setIsIndex(index);
    }

  }
  function handleCategoryChange(categoryId, url) {
    localStorage.setItem('selectedCategoryId', categoryId);
    navigate(`/${url}`);
    setIsOpenSidebar(!isOpenSidebar);
    setIsIndex(0);
  }
  return (
    <section className="nav_bar">
      <Container fluid>
        <div className="nav_bar_main">
          <Col md="1" style={{marginLeft:"3%"}}>
            <div className="logo">
              <Link to="/">
                <img
                  src={floorwalklogo}
                  alt=""
                   className="logo_img_main"
                />
              </Link>
            </div>
            <i className="fa-solid fa-bars" onClick={handleClickSidebar}></i>
          </Col>
          <Col md="10" className="nav_lists">
             <HeaderMenu templateShow={templateShow} setTemplateShow={setTemplateShow}/>
          </Col>

        </div>
      </Container>

      {/*------------------------------------------SIDEBAR----------------------------------------------------*/}
      <div className="logosmall" >
        <Link to="/">
          <img
            src={floorwalklogo}
            alt="floorwalk_logo"
            width="100px"
          />
        </Link>
      </div>
      {/* <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'}> */}
      <div className={isOpenSidebar ? 'sidebar_nav_menu_container active' : 'sidebar_nav_menu_container'} onClick={(e) => {
        if (!e.target.classList.contains('sidebar_nav_menu_active')) {
          handleClickSidebar();
        }
      }}>
        <nav className={isOpenSidebar ? 'sidebar_nav_menu active' : 'sidebar_nav_menu'} onClick={(e) => e.stopPropagation()}>
          <ul className='sidebar_nav_menu_items'>
            <li className='sidebar_close_toggle' onClick={handleClickSidebar}>
              <i className="fa-solid fa-xmark"></i>
            </li>
            <ul className="sidebar_item_container" style={{ paddingLeft: "0rem" }}>

              <li style={{ display: "block", }} className="sidebar_nav_items" onClick={() => handlesmallscreendropdown(1)}><span className="sidebar_nav_items_li" > Templates </span>
                {isIndex === 1 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
                {isIndex === 1 &&
                <TemplateSmallScreen handleClickSidebar={handleClickSidebar}/>
                }
              </li>
              <li style={{ display: "block", }} className="sidebar_nav_items" onClick={() => handlesmallscreendropdown(2)}> <span className="sidebar_nav_items_li_resource"> Solutions</span>
                {isIndex === 2 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
                {isIndex === 2 &&
                  <ul >
                    <li className="sidebar_nav_items">
                      <Link to="/Net-promoter-Score" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Net promoter Score</Link>
                    </li>
                    <li className="sidebar_nav_items">
                      <Link to="/Customer-Satisfaction-Score " className="sidebar_nav_items_a" onClick={handleClickSidebar}>Customer Satisfaction Score</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/Customer-Effort-Score" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Customer Effort Score</Link>
                    </li>
                  </ul>}
              </li>
              <li style={{ display: "block", }} className="sidebar_nav_items" onClick={() => handlesmallscreendropdown(3)}> <span className="sidebar_nav_items_li_resource"> Resources</span>
                {isIndex === 3 ? <span><i className="fa-solid fa-chevron-up"></i></span> : <span><i className="fa-solid fa-chevron-down"></i></span>}
                {isIndex === 3 &&
                  <ul >
                    <li className="sidebar_nav_items">
                      {/* <a href="https://floorwalk.in/blog/" className="sidebar_nav_items_a" target="_blank">Blog</a> */}
                      <Link to="/blog" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Blog</Link>
                    </li>
                    <li className="sidebar_nav_items">
                      {/* <a href="https://floorwalk.in/case_studies.html" target="_blank" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Case Studies</a> */}
                      <Link to="/case-studies" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Case Studies</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/terms-and-conditions" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Terms & Conditions</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/refund-policy" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Refund Policy</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/privacy-policy" className="sidebar_nav_items_a" onClick={handleClickSidebar}>Privacy Policy</Link>
                    </li>
                    <li className="sidebar_nav_items">
                    <Link to="/frequently-asked-questions" className="sidebar_nav_items_a" onClick={handleClickSidebar}>FAQs</Link>
                    </li>
                  </ul>}
              </li>
              <li className="sidebar_nav_items sidebar_nav_items_li">
              <Link to="/pricing" className="sidebar_nav_items_a" onClick={handleClickSidebar} style={{ fontSize: ".9rem" }}>Pricing</Link>
              </li>
              <li className="sidebar_nav_items sidebar_nav_items_li">
              <Link to="/contact-us" className="sidebar_nav_items_a" onClick={handleClickSidebar} style={{ fontSize: ".9rem" }}>Contact Us</Link>
              </li>

            </ul>
          </ul>
        </nav>
      </div>
      {templateShow && 
     <Template templateShow={templateShow} setTemplateShow={setTemplateShow}/>
        }
    </section>
  );
};

export default Header1;

