import { useState, useEffect } from "react";
import { Col, Modal, Row, Alert } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddSectionModal from "./AddSectionModal";
import CopySectionModal from "./CopySectionModal";
import SectionRows from "./SectionRows";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import base_url from "../../../utils/config";
import { Link } from "react-router-dom";

export default function QuestionnaireInner() {
  const navigate = useNavigate();
  const location = useLocation();
  const clientId = localStorage.getItem("client_id");
  const token = localStorage.getItem("token");
  const questionnaire_type_id = location.state;
  const [questionnaireAddModalShow, setQuestionnaireAddModalShow] =
    useState(false);
  const [questionnaireCopyModalShow, setQuestionnaireCopyModalShow] =
    useState(false);
  const [editSectionId, setEditSectionId] = useState(null);
  const [modalTitle, setModalTitle] = useState("Add Section");
  const [showAlert, setShowAlert] = useState(false);
  const [message, setMessage] = useState("");
  const {data: questionnaireData,getApiData} = useFetchinFunction()


  const handleAddQuestionnaire = () => {
    setModalTitle("Add Section");
    setEditSectionId(null);
    setQuestionnaireAddModalShow(true);
  };

  const handleCopyQuestionnaire = () => {
    if(clientId){
      getApiData(`client/${clientId}/questionnaire_list`)
  }
  if(questionnaireData){
    setQuestionnaireCopyModalShow(true);
  }
    
  };
  console.log(questionnaire_type_id)
const handleNavigate = (questionnaire_type_id) =>{
  navigate("/userdashboard/previewsection" , { state: questionnaire_type_id }); 
}
  return (
    <div>
      <Row className="d-flex project_setup_storeadd">
        <Col md="4">
          <h3>
            <i className="fa-solid fa-bars-progress"></i> <b>Questionnaire</b>
          </h3>
        </Col>
        <Col md="2">
          <Link
            className="btn btn-md text-center btn-size create_ac_btn"
            style={{ fontSize: "14px" }}
            // to={{
            //   pathname: "/userdashboard/previewsection",
            //   state: questionnaire_type_id
            // }}
            to={`/userdashboard/previewsection?questionnaireId=${questionnaire_type_id}`}
           target="_blank"
          // onClick={()=>handleNavigate(questionnaire_type_id)}
          >
            Preview Section
          </Link>
        </Col>
        <Col md="2">
          <button
            className="btn btn-md text-center btn-size create_ac_btn"
            style={{ fontSize: "14px" }}
            onClick={handleAddQuestionnaire}
          >
            <i className="fa-solid fa-plus"></i> Add Section
          </button>
        </Col>
        <Col md="2">
          <button
            className="btn btn-md text-center btn-size create_ac_btn"
            style={{ fontSize: "14px" }}
            onClick={handleCopyQuestionnaire}
          >
            <i className="fa-solid fa-file"></i> Copy Sections
          </button>
        </Col>
        <Col md="2">
          <a
            className="btn btn-md text-center btn-size create_ac_btn"
            style={{ fontSize: "14px" }}
            href={base_url + `client/questionnaire/${questionnaire_type_id}/export_questionnaire`}
          >
            <i className="fa-solid fa-download"></i> Export
          </a>
        </Col>
        <AddSectionModal
          modalShow={questionnaireAddModalShow}
          setModalShow={setQuestionnaireAddModalShow}
          questionnaire_type_id={questionnaire_type_id}
          editSectionId={editSectionId}
          setEditSectionId={setEditSectionId}
          modalTitle={modalTitle}
          setShowAlert={setShowAlert}
          setMessage={setMessage}
        />
        <CopySectionModal
          modalShow={questionnaireCopyModalShow}
          setModalShow={setQuestionnaireCopyModalShow}
          questionnaire_type_id = {questionnaire_type_id}
          questionnaireData={questionnaireData}
          />
          </Row>
          
          {showAlert && (
        <Alert
          variant={
            message === "Section is Added Successfully" || message === "Section is Editted Successfully"
              ? "success"
              : "danger"
          }
          onClose={() => setShowAlert(false)}
          dismissible
          style={{ textAlign: "center" }}
        >
          {message}
        </Alert>
      )}
          <SectionRows
            questionnaire_type_id={questionnaire_type_id}
            questionnaireAddModalShow={questionnaireAddModalShow}
            setQuestionnaireAddModalShow={setQuestionnaireAddModalShow}
            setEditSectionId={setEditSectionId}
            setModalTitle={setModalTitle}
          />
        </div>
      );
    }
    