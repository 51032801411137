


import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import { Table, Form } from 'react-bootstrap';
import { getQuestionType } from '../../../utils/constant';
import "../../../styles/previewsection.css";
import loadingImg from "../../../images/loading.avif";


const PreviewSection = () => {
  const location = useLocation();
  const [questionnaireId, setQuestionnaireId] = useState(null);
  const { data: sectionData, getApiData, isLoading } = useFetchinFunction();
  const [expandedReportListRows, setExpandedReportListRows] = useState({});
  const [selectedRating, setSelectedRating] = useState(-1);
  const [hoverRating, setHoverRating] = useState(-1);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let questionnaireId = params.get('questionnaireId');

    if (questionnaireId) {
      setQuestionnaireId(questionnaireId);
      getApiData(`client/questionnaire/${questionnaireId}/section_preview`);
    }
  }, [location]);

  useEffect(() => {
    if (sectionData && sectionData.length > 0) {
      const initialExpandedState = sectionData.reduce((acc, section) => {
        acc[section.id] = true;
        return acc;
      }, {});
      setExpandedReportListRows(initialExpandedState);
    }
  }, [sectionData]);

  const toggleReportListRow = (sectionId, event) => {
    const classList = event.target.classList;
    const isExcludedButton =
      classList.contains("proof_tag_btn") ||
      classList.contains("fa-pen-to-square") ||
      classList.contains("fa-xmark");

    if (!isExcludedButton) {
      setExpandedReportListRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [sectionId]: !prevExpandedRows[sectionId],
      }));
    }
  };

//   const renderAnswerInput = (question) => {
//     // switch (question?.question_type) {
//         switch (question) {
//       case "PLAIN_TEXT":
//         return (
//             <p className="registration_form_content">
//                 <textarea placeholder='type your answer here'/>
//             </p>
//         )
//       case "MUTEX":
//         return (
//           <select>
//             {question?.options?.map((option, index) => (
//               <option key={index} value={option}>
//                 {option}
//               </option>
//             ))}
//           </select>
//         );
//       case "MULTI_CHOICE":
//         return (
//           <>
//             {question?.options?.map((option, index) => (
//               <div key={index}>
//                 <input type="checkbox" id={`multi_choice_${index}`} />
//                 <label htmlFor={`multi_choice_${index}`}>{option}</label>
//               </div>
//             ))}
//           </>
//         );
//       case "SINGLESELECT":
//         return (
//           <>
//             {question?.options?.map((option, index) => (
//               <div key={index}>
//                 <input
//                   type="radio"
//                   name={`singleselect_${question.id}`}
//                   id={`singleselect_${index}`}
//                 />
//                 <label htmlFor={`singleselect_${index}`}>{option}</label>
//               </div>
//             ))}
//           </>
//         );
//       case "RATING":
//         return (
//           <div className="rating">
//             {[...Array(5)].map((_, index) => (
//               <i
//                 key={index}
//                 className="fa-regular fa-star"
//                 onMouseEnter={(e) => {
//                   e.currentTarget.className = "fa-solid fa-star";
//                 }}
//                 onMouseLeave={(e) => {
//                   if (!e.currentTarget.classList.contains("selected")) {
//                     e.currentTarget.className = "fa-regular fa-star";
//                   }
//                 }}
//                 onClick={(e) => {
//                   document.querySelectorAll(".fa-star").forEach((star, i) => {
//                     star.className = i <= index ? "fa-solid fa-star selected gold" : "fa-regular fa-star";
//                   });
//                 }}
//               ></i>
//             ))}
//           </div>
//         );
//       case "OPINION_SCALE":
//         return <input type="range" min="1" max="10" />;
//       default:
//         return null;
//     }
//   };

const renderAnswerInput = (question) => {
    switch (question?.question_type) {
    //  switch (question) {
      case "PLAIN_TEXT":
        return (
        <p className="project_setup1_content">
            <textarea  placeholder='type your answer here' rows={1}/>
            </p>
    )
      case "MUTEX":
        return (
        <p className="registration_form_content">
        <select
        name="question_type"
      >
        <option value="">Select...</option>
        {Array.isArray(question?.question_data?.options) && question?.question_data?.options.length>0 && question?.question_data?.options.map((question, index) => (
          <option key={index} value={question.marks}>
            {question.value}
          </option>
        ))}
      </select>
      </p>
        );
      case "MULTIPLE_CHOICE":
        return (
          <>
             {Array.isArray(question?.question_data?.options) && question?.question_data?.options.map((option,index) => (
            <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:"1rem"}}>
            <input type="checkbox" style={{height:"20px", width:"20px"}} id={`multiselect_${question.id}_${index}`} name={`multiselect_${question.id}`}/>
            <label htmlFor={`multiselect_${question.id}_${index}`} style={{ cursor: "pointer" }}>{option.value}</label>
        </p>
          ))}
          </>
        );
      case "SINGLESELECT":
        return (
          <>
             {Array.isArray(question?.question_data?.options) && question?.question_data?.options.map((option,index) => (
            <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:"1rem"}}>
            {/* <input type="checkbox" style={{height:"20px", width:"20px"}} />
            <label>{option.value}</label>
        </p>
          ))} */}
            <input type="radio" id={`singleselect_${question.id}_${index}`} name={`singleselect_${question.id}`} style={{ height: "20px", width: "20px" }} />
            <label htmlFor={`singleselect_${question.id}_${index}`} style={{ cursor: "pointer" }}>{option.value}</label>
            </p>
          ))}
          </>
        );
        case "RATING":
            return (
              <div className="rating">
                {[...Array(5)].map((_, index) => (
                  <i
                    key={index}
                    className={`fa-star ${
                      hoverRating !== null
                        ? index <= hoverRating
                          ? "fa-solid fa-star gold"
                          : "fa-regular fa-star"
                        : index <= selectedRating
                        ? "fa-solid fa-star gold"
                        : "fa-regular fa-star"
                    }`}
                    onMouseEnter={() => setHoverRating(index)}
                    onMouseLeave={() => setHoverRating(null)}
                    onClick={() => setSelectedRating(index)}
                  ></i>
                ))}
              </div>
            );
      case "OPINION_SCALE":
        return  <Form.Range />;
    case "EMAIL":
            return (
                <p className="registration_form_content">
                <input placeholder="johndoe@gmail.com" type="email"/>
                </p>
            )
            case "DATE":
                return (
                    <p className="registration_form_content">
                    <input type="date"/>
                    </p>
                )
                case "PHONE_NUMBER":
                return (
                    <p className="registration_form_content">
                    <input type="number"  className="audit_count_numberbox"
                placeholder="9876543211"/>
                    </p>
                )
      default:
        return null;
    }
  };

  return (
    <div className='preview_section_outer'>
      {isLoading ?
       <div className='getStarted'>
       <img src={loadingImg} alt="Loading..." style={{ width: "200px" }} />
   </div>
      :
      sectionData && sectionData.length > 0 ? (
        sectionData.map((section) => (
          <div className="ques_sec_row_outer preview_sec_main" key={section?.id}>
            <div
              className="ques_sec_row_main"
              onClick={(e) => toggleReportListRow(section?.id, e)}
              style={{ padding: "1rem" }}
            >
              <div className="ques_sec_row_main_left">
                <div>
                  {section?.sequence}- <span>{section?.name}</span>
                </div>
              </div>
              <div className="ques_sec_row_main_right">
                <div>
                  <span>{section?.questions.length}</span>{" "}
                  {section?.questions.length > 1 ? "Questions" : "Question"}
                </div>
                <div>
                  <i
                    className={`fa-solid ${
                      expandedReportListRows[section.id]
                        ? "fa-plus"
                        : "fa-minus"
                    }`}
                  ></i>
                </div>
              </div>
            </div>
            {expandedReportListRows[section.id] && (
              <div className="expanded-row">
                <div
                  className="dash_report_table table-responsive"
                  style={{ marginTop: "1rem", paddingTop: "0rem" }}
                >
                  <Table className="text-left store_report_table_mobile pricing_table">
                    <thead>
                      <tr className="ques_sec_row_thead">
                        <th className="fw-bold">Question</th>
                        <th className="fw-bold">Answer</th>
                      </tr>
                    </thead>
                    <tbody>
                   
                    {/* <tr
                                className="ques_sec_row_tbody"
                               
                              >
                                <td className="fw-bold">
                                 PLAIN_TEXT
                                </td>
                                <td className="fw-bold">
                                  {renderAnswerInput("PLAIN_TEXT")}
                                </td>
                              </tr> */}
                      {section?.questions && section?.questions.length > 0 ? (
                        section?.questions
                          .sort((a, b) => a.sequence - b.sequence)
                          .map((question) => {
                            return (
                              <tr
                                className="ques_sec_row_tbody"
                                key={question?.id}
                              >
                                <td className="fw-bold">
                                  {question?.question_text}
                                </td>
                                <td className="fw-bold">
                                  {renderAnswerInput(question)}
                                </td>
                              </tr>
                            );
                          })
                          
                      ) : (
                        <tr>
                          <td colSpan={2} className="text-center">
                            No Question Answers for now...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        ))
      ) : (
        <div>
          <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
            No Section for now...
          </h5>
        </div>
      )}
    </div>
  );
};

export default PreviewSection;
