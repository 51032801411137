import { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { useFetchAuth } from "../../../utils/hooks/useFetchAuth";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import { postCopySection } from "../../../utils/services/PostdataHandle";
import { addSectionDetailData } from "../../../utils/reduxcomponents/sectionDetailSlice";
import { useDispatch } from "react-redux";

export default function CopySectionModal({ modalShow, setModalShow, questionnaire_type_id, questionnaireData }) {
   const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        questionnaire: "",
    });
    const [errorMessages, setErrorMessages] = useState({
        questionnaireError: "",
    });
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasError] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
 
 
    useEffect(() => {
        setFormData({
            questionnaire : ""
        });
        setErrorMessages({
            questionnaireError: "",
        });
       setErrorMessage("")
       
    }, [modalShow])
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        })
    ); 
    setErrorMessages(prevErrors => ({
        ...prevErrors,
        nameError: ""
    }));
    setHasError(false)
    };

    const handleSubmit = (e) => {
        console.log(formData);
        e.preventDefault();
        if (!formData.questionnaire) {
            setErrorMessages(prevErrors => ({
                ...prevErrors,
                section_nameError: "Section name can't be empty"
            }));
        }
        if (formData.questionnaire) {
            setLoadingButton(true);
            let questionnaireId = +formData.questionnaire
            let payload = {
                from_questionnaire_id : questionnaireId
            }
           
            const promise = postCopySection(`client/questionnaire/${questionnaire_type_id}/section/copy`,payload);
            promise
              .then((response) => response.json())
              .then((data) => {
                console.log(data);
                if(data?.non_field_errors){
                    setErrorMessage(data?.non_field_errors[0])
                    setTimeout(() => {
                        setErrorMessage("")
                    }, 1500);
                }else{
                    dispatch(addSectionDetailData(data));
                    setModalShow(false);
                    setFormData({
                        questionnaire : ""
                    });
                }
               
                
              })
              .catch((error) => {
                console.log(error);
                setErrorMessage(error.message);
                setTimeout(() => {
                    setErrorMessage("")
                }, 1500);
              })
              .finally(() => {
                setLoadingButton(false);
              });
          
        }
    };
    return (
        <Modal
            show={modalShow}
            centered
            size='md'
            className='modal-dialog-scrollable p-6 modal_mobile'
            onHide={() => setModalShow(false)}
        >
            <div
                style={{
                    width: '100%',
                    padding: "2rem 3rem",
                    border: 'none',
                    position: "relative"
                }}
            >
                <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }}
                    onClick={() => setModalShow(false)}
                >
                    <i className="fa-solid fa-x"></i>
                </div>
                <form onSubmit={handleSubmit}>
                    <Col>
                        <Row>
                            <h5 className='setup_regs_modal_heading'>
                                Copy Sections
                            </h5>
                        </Row>
                        <Row>
                            <Col md="12" className='report_upperpart'>
                                <p className="registration_form_content">
                                    <label>Questionnaires</label>
                                    <select name='questionnaire'
                                        onChange={handleChange}
                                        value={formData.questionnaire}
                                    >
                                        <option value="">Select Questionnaire</option>
                                     {questionnaireData && questionnaireData.length>0 ? questionnaireData.map((questionnaire)=>{
                                        return(
                                            <option key={questionnaire?.id} value={questionnaire?.id}>{questionnaire?.name}</option>
                                        )
                                     })
                                     : null
                                    }
                                      
                                    </select>
                                    {errorMessages.questionnaireError && (
                        <span className="error_message">
                          {errorMessages.questionnaireError}
                        </span>
                      )}
                        {errorMessage && (
                        <span className="error_message">
                          {errorMessage}
                        </span>
                      )}
                                </p>
                            </Col>
                        </Row>

                        <div className='d-flex justify-content-center align-items-center'>
                            {loadingButton ?
                                <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                    Loading...
                                </button> :
                                (
                                    <button
                                        className="btn mt-3 btn-md text-center btn-size create_ac_btn"
                                    // onClick={handleSubmit}
                                    >
                                        <i className="fa-solid fa-file"></i> Copy
                                    </button>
                                )}
                        </div>
                    </Col>
                </form>
            </div>

        </Modal>
    )
}