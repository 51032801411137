import { useEffect, useState } from "react";
import { Col, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { postAddQuesionnaire } from "../../../utils/services/PostdataHandle";
import base_url from "../../../utils/config";
import loadingimg from "../../../images/loading.avif";
import { useFetchAuth } from "../../../utils/hooks/useFetchAuth";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import { useFetchAuthCondition } from "../../../utils/hooks/useFetchAuthCondition";
import "../../../styles/dashstore.css"
import EditQuestionnaireModal from "./EditQuestionnaireModal";


export default function Questionnaire() {
  const navigate = useNavigate();
  const client_id = localStorage.getItem("client_id");
  const token = localStorage.getItem("token");
  const [questionnaireModalShow, setQuestionnaireModalShow] = useState(false);
  const [editModalShow, setEditModalShow] = useState(false);
  // const [questionnaireData, setQuestionnaireData] = useState([]);
  // const [loadingQuestionData, setLoadingQuestionData] = useState(false);
  const [formData, setFormData] = useState({
    questionnaire_name: "",
    questionnaire_type: "",
  });
  const [submitData, setSubmitData] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    questionnaire_nameError: "",
    questionnaire_typeError: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);
  const { data: dropdownQuestionData, isLoading: isDropdownLoading, getApiData } = useFetchinFunction();
  const {data:questionnaireData ,isLoading: loadingQuestionData} = useFetchAuthCondition(`client/${client_id}/questionnaire_id`,[submitData])
  const [questionnaireId, setQuestionnaireId] = useState(null);
 
  const handleAddQuestionnaire = () => {
    setFormData({
      questionnaire_name: "",
      questionnaire_type: "",
    });
    setErrorMessages({
      questionnaire_nameError: "",
      questionnaire_typeError: "",
    });
    setQuestionnaireModalShow(true);
    getApiData("client/predifine_questionnaire_type/")
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrorMessages({
      questionnaire_nameError: "",
      questionnaire_typeError: "",
    });
    setHasError(false);
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.questionnaire_name) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        questionnaire_nameError: "Questionnaire name can't be empty",
      }));
      setHasError(true);
    }
    if (!formData.questionnaire_type) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        questionnaire_typeError: "Questionnaire type can't be empty",
      }));
      setHasError(true);
    }

    if (formData.questionnaire_name && formData.questionnaire_type) {
      let payload = {
        client: +client_id,
        name: formData?.questionnaire_name,
        type: +formData?.questionnaire_type,
        is_default: false,
      };
      setloadingButton(true);
      postAddQuesionnaire("client/questionnaire_type/", payload, token)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("86666", data);
          setSubmitData(data);
          setFormData({
            questionnaire_name: "",
            questionnaire_type: "",
          });
          setQuestionnaireModalShow(false);
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage("Something went wrong. Please try again later.");
        })
        .finally(() => {
          setloadingButton(false);
        });
    }
  };

  const handleviewnavigate = (questionnaire_type_id) => {
    navigate("/userdashboardmain/questionnaire_inner", {
      state: questionnaire_type_id,
    });
  };

  const handleEdit = (questionnaire_type_id) => {
    console.log("126",questionnaire_type_id)
   setQuestionnaireId(questionnaire_type_id);
   setEditModalShow(true);
  };

  const modifyStatus = (status) => {
  return status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();
  }
  return (
    <div>
      <div className="d-flex project_setup_storeadd">
        <Col md="9">
          <h3>
            <b>Questionnaire</b>
          </h3>
        </Col>
        <Col md="3">
          <button
            className="btn btn-md text-center btn-size create_ac_btn"
            onClick={handleAddQuestionnaire}
          >
            <i className="fa-solid fa-plus"></i> Add Questionnaire
          </button>
        </Col>
        <Modal
          show={questionnaireModalShow}
          centered
          size="md"
          className="modal-dialog-scrollable p-6 modal_mobile"
          onHide={() => setQuestionnaireModalShow(false)}
        >
          <div
            style={{
              width: "100%",
              padding: "2rem 3rem",
              border: "none",
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2rem",
                top: "2rem",
                cursor: "pointer",
              }}
              onClick={() => setQuestionnaireModalShow(false)}
            >
              <i className="fa-solid fa-x"></i>
            </div>
            <form onSubmit={handleSubmit}>
              <Col>
                <Row>
                  <h5 className="setup_regs_modal_heading">
                    Add a Questionnaire
                  </h5>
                </Row>
                <Row>
                  <Col md="12">
                    <p className="registration_form_content">
                      <label>Questionnaire Name</label>
                      <input
                        type="text"
                        name="questionnaire_name"
                        value={formData.questionnaire_name}
                        onChange={handleChange}
                      />
                      {errorMessages.questionnaire_nameError && (
                        <span className="error_message">
                          {errorMessages.questionnaire_nameError}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="report_upperpart">
                    <p className="registration_form_content">
                      <label>Questionnaire Type</label>
                      <select
                        name="questionnaire_type"
                        onChange={handleChange}
                        value={formData.questionnaire_type}
                      >
                        <option>select...</option>
                        {dropdownQuestionData.map((question) => (
                          <option key={question?.id} value={question?.id}>
                            {question?.type}
                          </option>
                        ))}
                      </select>
                      {errorMessages.questionnaire_typeError && (
                        <span className="error_message">
                          {errorMessages.questionnaire_typeError}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center align-items-center">
                  {loadingButton ? (
                    <button
                      className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                      type="button"
                      disabled
                      style={{
                        fontSize: "1rem",
                        background: "#007DC1",
                        color: "white",
                      }}
                    >
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                        style={{ marginRight: ".5rem" }}
                      ></span>
                      Loading...
                    </button>
                  ) : (
                    <button
                      className="btn mt-3 btn-md text-center btn-size create_ac_btn"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  )}
                </div>
              </Col>
            </form>
          </div>
        </Modal>

        <Table
          className="text-center table_border dash_tab_table report_table_mobile table-responsive pricing_table"
          style={{ marginTop: "5rem" }}
        >
          <thead className="dash_tab_table_head">
            <tr>
              <th className="fw-bold table_border">Questionnaire Name</th>
              <th className="fw-bold table_border">Type</th>
              <th className="fw-bold table_border">Status</th>
              {/* <th className="fw-bold table_border">Edit Questionnaire Name</th> */}
              <th className="fw-bold table_border"></th>
              <th className="fw-bold table_border"></th>
            </tr>
          </thead>
          <tbody>
            {loadingQuestionData ? (
              <tr>
                <td colSpan={5}>
                  {" "}
                  <img
                    src={loadingimg}
                    alt="Loading..."
                    style={{ width: "150px" }}
                  />
                </td>
              </tr>
            ) : questionnaireData && questionnaireData.length > 0 ? (
              questionnaireData.map((item) => {
                return (
                  <tr key={item?.id}>
                    <td className="table_border fw-bold">{item?.name}</td>
                    {/* <td className="table_border fw-bold">{item?.type?.type}</td> */}
                    <td className="table_border fw-bold">{item?.questionnaire_type?.name}</td>
                    <td className="table_border">
                      {item?.status ? modifyStatus(item?.status) : "-"}
                    </td>
                    <td className="table_border">  <button
                  className="proof_tag_btn"
                  onClick={() => handleEdit(item?.id)}
                >
                  <i className="fa-solid fa-pen-to-square"></i>
                </button></td>

                    <td
                      className="table_border"
                      onClick={() => handleviewnavigate(item?.id)}
                      style={{
                        cursor: "pointer",
                        color: "#007DC1",
                        fontWeight: "bold",
                      }}
                    >
                      view
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No data for now</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {/* {questionnaireId ? */}
      <EditQuestionnaireModal modalShow={editModalShow} setModalShow={setEditModalShow} questionnaireId={questionnaireId} setQuestionnaireId={setQuestionnaireId} setSubmitData={setSubmitData}/>
      {/* : null} */}
    </div>
  );
}
