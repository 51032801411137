import React,{useEffect,useState} from 'react';
import { Container,Row,Col,Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../../styles/contact.css";
import { FaMapMarkerAlt } from 'react-icons/fa';
import { BsFillTelephoneFill, BsFillEnvelopeFill } from 'react-icons/bs';
import { Helmet } from 'react-helmet';

export default function ContactUs() {
    const [modalShow, setModalShow] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);
  return (
    <>
      <Helmet>
        <title>
        FloorInsights | Contact Us - Get in Touch
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Reach out to us for support, feedback, or general inquiries"
        />
        <meta
          name="keywords"
          content="contact us, get in touch, support"
        />
      </Helmet>
    <div className="indiv_blog_heading" style={{ height: "120px",marginTop:"5rem" }}>
      <h1 style={{ fontSize: "2.5rem" }}>Contact Us</h1>
    </div>
    <Container fluid className='homepage_brand_container' style={{ marginTop: "2rem", marginBottom:"2rem" }}>
    <Row className='homepage_brand casestudy_box_container' style={{paddingTop:"1rem"}}>
        <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
            <div className='contact_box'>
                <h5 style={{textAlign:"center"}}><span style={{fontSize:"1.1rem",lineHeight:"2rem"}}>I AM A </span><br /> <span style={{fontWeight:"bold",fontSize:"1.5rem",color:"#007dc1"}}>CLIENT</span></h5>
                  <h6 style={{textAlign:"center",fontWeight:"bold",fontSize:"1.1rem"}}>I want to get my stores audited</h6>
                  <p style={{textAlign:"center",fontSize:"1rem"}}>Tell us a few things about you and we will get to you ASAP</p>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1rem" }}>
                        <button className="btn btn-md text-center btn-size create_ac_btn"
                        onClick={() =>
                            setModalShow(
                                true,
                            )
                        }
                        >TALK TO US</button>
                    </div>
            </div>
        </Col>
        <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
        <div className='contact_box'>
            <h5 style={{textAlign:"center",color:"#007dc1",fontSize:"1.5rem",fontWeight:"bold"}}>Head Office</h5>
              <h6 style={{textAlign:"left",fontWeight:"bold",fontSize:"1rem",color:"#007dc1"}}><FaMapMarkerAlt />{' '} FloorWalk Consultants Pvt. Ltd.</h6>
              <p style={{textAlign:"left",fontSize:"1rem"}}>FloorWalk Consultants Private Limited <br />
              AltF Empire Square,<br />
              MR-1, Unit 1, 3rd Floor, JMD Empire Square <br />
              Near Sikanderpur Metro Station, Opp. Metro <br />
              Pillar No. 71, MG Road <br />
              Gurugram, Haryana - 122002 <br />
              </p>
              
        </div>
    </Col>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }}>
    <div className='contact_box'>
        <h5 style={{textAlign:"center",color:"#007dc1",fontSize:"1.5rem",fontWeight:"bold"}}>Branch Offices</h5>
<div>
          <h6 style={{textAlign:"left",fontWeight:"bold",fontSize:"1rem",color:"#007dc1"}}><FaMapMarkerAlt />{' '} FloorWalk Consultants Pvt. Ltd.</h6>
          <p style={{textAlign:"left",fontSize:".9rem"}}>215, B-zone business park, Dewas Naka, Indore 452010
        
          </p>
          </div>
          <div>
          <h6 style={{textAlign:"left",fontWeight:"bold",fontSize:"1rem",color:"#007dc1"}}><FaMapMarkerAlt />{' '} FloorWalk Consultants Pvt. Ltd.</h6>
          <p style={{textAlign:"left",fontSize:".9rem"}}>Eureka offices, 87, First Floor, Sawarkar Nagar, Chatrapati Sq. Nagpur- 440015
          </p>
          </div>
          <p>
         <a href='mailto: contactus@floorwalk.in' style={{fontSize:"1rem",color:"#007dc1",fontWeight:"600"}}><BsFillEnvelopeFill />{" "} contactus@floorwalk.in</a>							
          </p>
    </div>
</Col>
    </Row>
    <Row style={{justifyContent:"center"}} className='homepage_brand casestudy_box_container'>
    <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px",}} md="6">
    <div className='contact_box' style={{height:"auto"}}>
        <h5 style={{textAlign:"center",fontWeight:"bold",color:"#007dc1"}}>Client FAQs</h5>

            <div style={{display: "flex", justifyContent: "center", alignItems: "center", padding: "0rem 1rem",marginTop:"1rem" }}>
            <Link to="/frequently-asked-questions">
                <button className="btn btn-md text-center btn-size create_ac_btn"
                 >GET STARTED</button>
                 </Link>
            </div>
    </div>
</Col>
    </Row>
    <Modal
    show={modalShow}
    centered
    size='lg'
    style={{ marginTop: '40px' }}
    scrollable={true}
    className='modal-dialog-scrollable p-6 modal_mobile'
    onHide={() => setModalShow(false)}>
    <div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
        onClick={() => setModalShow(false)}
    ><i className="fa-solid fa-x"></i></div>
    <iframe
        frameBorder='0'
        style={{
            height: '500px',
            width: '99%',
            border: 'none',
        }}
        src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
</Modal>
</Container>
    </>
  )
}
