import React,{useEffect} from "react";
import { Helmet } from "react-helmet";

export default function TermsandCondition() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, []);
  return (
    <>
      <Helmet>
        <title>
        FloorInsights | Terms and Conditions - Legal Agreement
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Read our terms and conditions to understand our legal agreement"
        />
        <meta
          name="keywords"
          content="terms and conditions, legal agreement, terms of use"
        />
      </Helmet>
      <div className="categoryname_banner indiv_blog_heading frequent_heading" style={{ height: "120px",marginTop: "5rem" }}>
        <h1>Terms and Conditions</h1>
      </div>
      <div className="blog_content_box">
        <p style={{ marginBottom: "0rem" }}>
          <b>1. Definitions :</b> In these Terms and Conditions, the following
          words and phrases shall have the following meanings:
        </p>
        <ul>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.1.</span>
            <span> "Agreement" means these Terms and Conditions.</span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.2.</span>
            <span>
              {" "}
              "Customer" means the individual or entity who uses the SAAS
              product.
            </span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.3.</span>
            <span> "Product" means the SAAS product.</span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.4.</span>
            <span> "Service" means the services provided by the Product.</span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.5.</span>
            <span>
              {" "}
              "Term" means the period of time during which the Customer has
              access to and uses the Product.
            </span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 1.6.</span>
            <span>
              {" "}
              “Solution” means the services along with their prices provided by
              the Product and the Company
            </span>
          </li>
        </ul>
        <p style={{ marginBottom: "0rem" }}>
          <b>2. Use of Product :</b>
        </p>
        <ul>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 2.1.</span>
            <span>
              {" "}
              The Customer agrees to use the Product only for lawful purposes
              and in accordance with these Terms and Conditions.
            </span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 2.2.</span>
            <span>
              {" "}
              The Customer agrees that they authorize and permit the company to
              carry the services of the solutions purchased by the customer.
            </span>
          </li>
          <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 2.3.</span>
            <span> The Customer shall not : </span>
          </li>
          <ul>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.1.</span>
              <span>
                {" "}
                use the Product in any way that violates any applicable
                national, state, local, or international law or regulation.
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.2.</span>
              <span>
                {" "}
                use the Product for the purpose of exploiting, harming, or
                attempting to exploit or harm minors in any way by exposing them
                to in appropriate content, asking for personally identifiable
                information, or otherwise;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.3.</span>
              <span>
                use the Product to transmit, or procure the sending of, any
                advertising or promotional material, including any "junk mail"
                "chain letter," "spam," or any other similar solicitation;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.4.</span>
              <span>
                use the Product to impersonate or attempt to impersonate the
                Company, a Company employee, another user, or any other person
                or entity;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.5.</span>
              <span>
                use any robot, spider, or other automatic devices, process, or
                means to access the Product for any purpose, including
                monitoring or copying any of the material on the Product;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.6.</span>
              <span>
                introduce any viruses, Trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.7.</span>
              <span>
                attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Product, the server on which the
                Product is stored, or any server, computer, or database
                connected to the Product;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.8.</span>
              <span>
                attack the Product via a denial-of-service attack or a
                distributed denial-of-service attack;
              </span>
            </li>
            <li
              style={{
                listStyle: "none",
                fontSize: "16px",
                display: "flex",
                justifyContent: "flex-start",
                gap: ".5rem",
              }}
            >
              {" "}
              <span> 2.3.9.</span>
              <span>
                otherwise, attempt to interfere with the proper working of the
                Product.
              </span>
            </li>
          </ul>
          <li style={{ listStyle: "none", fontSize: "16px",  display: "flex",
          justifyContent: "flex-start",
          gap: ".5rem", }}>
            {" "}
            <span> 2.4.</span>
            <span> The Company reserves the right to terminate the Customer's access to the Product at any time, without notice, if the Customer
            violates these Terms and Conditions.</span>
          </li>
        </ul>
        <p style={{ marginBottom: "0rem" }}>
        <b>3.  Service :</b>
      </p>
      <ul>
      <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
      justifyContent: "flex-start",
      gap: ".5rem", }}>
        {" "}
        <span> 3.1.</span>
        <span> The Company agrees to provide the Service to the Customer for the solutions purchased on this product.</span>
      </li>
      <li style={{ listStyle: "none", fontSize: "16px",  display: "flex",
      justifyContent: "flex-start",
      gap: ".5rem", }}>
        {" "}
        <span> 3.2.</span>
        <span>
        The Company shall use reasonable efforts to ensure that the Product and Service are available to the Customer for the solutions
        purchased on this product.
        </span>
      </li>
      <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
      justifyContent: "flex-start",
      gap: ".5rem", }}>
        {" "}
        <span> 3.3.</span>
        <span>  The Company shall use reasonable efforts to respond to support requests submitted by the Customer in a timely manner.</span>
      </li>
      
    </ul>
    <p style={{ marginBottom: "0rem" }}>
    <b>4.  Payment :</b>
  </p>
  <ul>
  <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
  justifyContent: "flex-start",
  gap: ".5rem", }}>
    {" "}
    <span> 4.1.</span>
    <span> The Customer shall pay the Company the fee specified for the use of the Product.</span>
  </li>
  <li style={{ listStyle: "none", fontSize: "16px",  display: "flex",
  justifyContent: "flex-start",
  gap: ".5rem", }}>
    {" "}
    <span> 4.2.</span>
    <span>
    Payment for the Product shall be made in accordance with the payment terms specified by the Company.
    </span>
  </li>
  <li style={{ listStyle: "none", fontSize: "16px", display: "flex",
  justifyContent: "flex-start",
  gap: ".5rem", }}>
    {" "}
    <span> 4.3.</span>
    <span>  The Company may suspend access to the Product if payment is not received in accordance with the payment terms.</span>
  </li>
  
</ul>
<p style={{ marginBottom: "0rem" }}>
<b>5.  Intellectual Property :</b>
</p>
<ul>
<li style={{ listStyle: "none", fontSize: "16px", display: "flex",
justifyContent: "flex-start",
gap: ".5rem", }}>
{" "}
<span> 5.1.</span>
<span> The Company retains all rights, titles, and interests in and to the Product, including all intellectual property rights.</span>
</li>
<li style={{ listStyle: "none", fontSize: "16px",  display: "flex",
justifyContent: "flex-start",
gap: ".5rem", }}>
{" "}
<span> 5.2.</span>
<span>
The Customer shall not, directly or indirectly, copy, modify, create derivative works from, distribute, sell, transfer, or otherwise exploit any portion of the Product except as expressly permitted by these Terms and Conditions.
</span>
</li>

</ul>
<p style={{ marginBottom: "3rem" }}>
These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of India
</p>
      </div>
    </>
  );
}
