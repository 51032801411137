

// import { useState, useEffect } from 'react';
// import base_url from "../config";

// export function useFetchAuthCondition(url, dependency) {
//     const [data, setData] = useState([]);
//     const [isLoading, setIsLoading] = useState(false);
//     const [error, setError] = useState("");
//     const token = localStorage.getItem("token");

//     useEffect(() => {
//         getApiData(url);
//     }, Array.isArray(dependency) ? dependency : [dependency]);

//     const getApiData = async (url) => {
//         setIsLoading(true);
//         try {
//             const headers = {
//                 'Authorization': `Token ${token}`,
//                 'Content-Type': 'application/json'
//             };

//             const options = {
//                 method: 'GET',
//                 headers: headers
//             };

//             const response = await fetch(base_url + url, options);

//             if (response.ok) {
//                 const result = await response.json();
//                 setData(result);
//             } else {
//                 console.error('API request failed:', response.status, response.statusText);
//                 setError(response.statusText);
//             }
//         } catch (error) {
//             console.error('An error occurred while fetching the API:', error);
//             setError(error.message);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return { data, setData, isLoading, error };
// }


import { useState, useEffect } from 'react';
import base_url from "../config";

export function useFetchAuthCondition(url, dependencies = []) {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const token = localStorage.getItem("token");

    useEffect(() => {
        getApiData(url);
    }, dependencies);

    const getApiData = async (url) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setData(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
                setError(response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
            setError(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return { data, setData, isLoading, error };
}
