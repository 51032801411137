import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import jmccard from "../../../images/jmccard.jpg";
import cardimg from "../../../images/Bata1_img.jpg";
import givacard from "../../../images/giva_img1.png";
import simpolocard from "../../../images/simpolo_img1.png";

export default function Retail() {
    function truncateText(text) {
        if (text.length <= 130) {
          return text;
        }
        return text.substring(0, 130) + "...";
      }
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col className='homepage_brand_box casestudy_box' style={{ borderRadius: "7px" }} >
                    <Link to="/case-studies/nps-mesurement-for-jewellery-brand">
                        <div className='homepage_brand_img'>
                            <img src={jmccard} alt="jmcimg" style={{ borderTopLeftRadius: "7px", borderTopRightRadius: "7px",height:"auto" }} />
                        </div>
                        <div className='homepage_brand_text casestudy_brand_text'>
                            <div>
                                <h5 style={{ marginLeft: "1rem",fontWeight:"bold" }}>JMC</h5>
                                <p style={{ margin: "0rem 1rem" }}>The challenge at hand was to elevate JMC's standing and performance within the Indore jewelry market. To achieve this objective, the focus was on...</p>
                                <div style={{ marginTop: "1rem", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0rem 1rem" }}>
                                    <button className="btn btn-md text-center btn-size create_ac_btn">Retail</button>
                                    <div style={{ border: "2px solid black", padding: "3px 7px", borderRadius: "50%" }}>
                                        <i className="fa-solid fa-arrow-right" style={{ marginTop: "3px", fontSize: "1.2rem" }}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Link>
                    </Col>
                    <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/csat-measured-for-jewellery-brand">
                <div className="homepage_brand_img">
                  <img
                    src={givacard}
                    alt="givaimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Giva
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Giva, a renowned jewelry brand, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to pinpoint specific areas needing enhancement. This deficiency hindered Giva's ability to make data-driven decisions to elevate the customer experience. To address these issues, Giva aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}{" "}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
            >
              <Link to="/case-studies/nps-measurement-for-home-construction-company-stores">
                <div className="homepage_brand_img">
                  <img
                    src={simpolocard}
                    alt="simpoloimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Simpolo
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Simpolo, a leading tile manufacturer, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were insufficient in providing detailed, actionable insights, making it difficult to identify specific areas needing enhancement. This gap hindered Simpolo's ability to make data-driven decisions to elevate the customer experience and support services. To address these issues, Simpolo aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
                </Row>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
              md="4"
            >
              <Link to="/case-studies/nps-measured-for-a-retail-client">
                <div className="homepage_brand_img">
                  <img
                    src={cardimg}
                    alt="bataimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "auto",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Bata
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Bata, a leading global footwear brand, struggled to accurately gauge and improve customer satisfaction and loyalty due to insufficient feedback mechanisms. This lack of detailed, actionable insights made it challenging to pinpoint specific areas needing enhancement, hindering their ability to make data-driven decisions to improve the shopping experience. The existing approach was not effectively capturing customer sentiments, leading to missed opportunities for increasing customer loyalty and sales. To address these challenges, Bata aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost overall customer satisfaction and business performance."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem 1rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                        Retail
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
                    </Row>
                
            </Container>
  )
}
