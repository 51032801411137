import React, { useState, useEffect } from "react";
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts";
import { Link, useNavigate } from "react-router-dom";
import analysispic from "../../../images/analysis_pic.jpg";
import "../../../styles/reportaiinsight.css";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";

const dummyHandles = [
    { id: 1, name: "Handle 1" },
    { id: 2, name: "Handle 2" },
];

const dummyTweets = [
    {
        id: 1,
        sentiment_score: -1,
        report_summary: "Negative tweet summary 1",
        audit: { audit_cycle: { name: "Cycle 1" }, store: { name: "Store 1" } },
        audit_date: "2023-01-01T00:00:00Z",
    },
    {
        id: 2,
        sentiment_score: 0,
        report_summary: "Neutral tweet summary 1",
        audit: { audit_cycle: { name: "Cycle 1" }, store: { name: "Store 1" } },
        audit_date: "2023-01-01T00:00:00Z",
    },
    {
        id: 3,
        sentiment_score: 1,
        report_summary: "Positive tweet summary 1",
        audit: { audit_cycle: { name: "Cycle 1" }, store: { name: "Store 1" } },
        audit_date: "2023-01-01T00:00:00Z",
    },
];

const ReportAIinsights = () => {
    // const [loading, setLoading] = useState(false);
    // const [error, setError] = useState(false);
    // const [handles, setHandles] = useState([]);
    // const [selectedTwitterHandle, setSelectedTwitterHandle] = useState(null);

    // useEffect(() => {
    //     setLoading(true);
    //     setHandles(dummyHandles);
    //     setSelectedTwitterHandle(dummyHandles[0].id);
    //     setLoading(false);
    // }, []);
    const [selectedTwitterHandle, setSelectedTwitterHandle] = useState(null);
    const {data: handles, getApiData:getHandles, isloading:loading, error} = useFetchinFunction()

    useEffect(() => {
        getHandles("client/sentiment_text/handles")
    }, []);

    useEffect(()=>{
        const savedHandleId = localStorage.getItem("selectedTwitterHandle");
    if(handles && handles?.questionnaire && handles?.questionnaire?.length>0){
        const defaultHandleId = handles.questionnaire.sort((a, b) => b.id - a.id)[0].id;
        // setSelectedTwitterHandle(handles?.questionnaire[0].id);
        setSelectedTwitterHandle(savedHandleId ? parseInt(savedHandleId) : defaultHandleId);
    }
    },[handles])
    if (loading) {
        return (
            <p>Loading...</p>
        )
    }

    if (error) {
        return (
            <p>Error getting Reports feeds.</p>
        )
    }

    if (handles?.questionnaire && handles?.questionnaire.length === 0) {
        return (
            <p>No such data</p>
        )
    }

   const twitterHandleChanged = (e) => {
    setSelectedTwitterHandle(e.target.value)
		localStorage.setItem("selectedTwitterHandle", e.target.value);
	};

    return (
        <div className="ai_main_box">
            <p className="registration_form_content">
            <select
        style={{ width: "400px",}}
        name="selectedTwitterHandle"
        value={selectedTwitterHandle}
        onChange={twitterHandleChanged}
      >
        {handles && handles?.questionnaire && handles?.questionnaire.length>0 && handles?.questionnaire
          .sort((a, b) => b.id - a.id)
          .map((h) => (
            <option value={h.id} key={h.id}>
              {h.name}
            </option>
          ))}
      </select>
      </p>
            {selectedTwitterHandle && (
                <TwitterData handle={handles?.questionnaire && handles?.questionnaire.length>0 && handles?.questionnaire.find((h) => h.id === parseInt(selectedTwitterHandle))} selectedTwitterHandle={selectedTwitterHandle}/>
            )}
        </div>
    );
};

const TwitterData = ({ handle, selectedTwitterHandle }) => {
    const navigate = useNavigate();
    const [expandedSummaries, setExpandedSummaries] = useState({});
    const [visibleNegativeSummariesCount, setVisibleNegativeSummariesCount] = useState(2);
    const [visibleNeutralSummariesCount, setVisibleNeutralSummariesCount] = useState(2);
    const [visiblePositiveSummariesCount, setVisiblePositiveSummariesCount] = useState(2);
    const {data: tweets, getApiData:getTweetData, isloading: loading, error} = useFetchinFunction();

    useEffect(() => {
       getTweetData(`client/sentiment/store_report/${selectedTwitterHandle}`)
    }, [handle]);

    const toggleSummaryExpansion = (key) => {
        setExpandedSummaries((prev) => ({
            ...prev,
            [key]: !prev[key],
        }));
    };

    const loadMoreSummaries = (sentiment) => {
        if (sentiment === "negative") {
            setVisibleNegativeSummariesCount((prev) => prev + 2);
        } else if (sentiment === "neutral") {
            setVisibleNeutralSummariesCount((prev) => prev + 2);
        } else if (sentiment === "positive") {
            setVisiblePositiveSummariesCount((prev) => prev + 2);
        }
    };

    const formatTimestamp = (isoTimestamp) => {
        const dateObject = new Date(isoTimestamp);
        return dateObject.toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };

    const handleNavigation = (reportId) => {
        console.log({reportId})
        if(reportId){
            navigate("/userdashboardmain/reportslistdetail",{state:reportId})
        }
        }
    const renderSummary = (summary, index, sentiment) => {
        const key = `${sentiment}-${index}`;
        const isExpanded = expandedSummaries[key];
        const reportSummary = summary?.combined_plain_text;
        const truncatedSummary =
            reportSummary?.length > 100 ? `${reportSummary.substring(0, 100)}.....  ` : reportSummary;

        return (
            <div className="ai_summary_box" key={key}>
                <div className="ai_header_box" style={{ position: "relative" }}>
                    <div className="analyse_box">
                        <p>{summary?.storemap?.questionnaire?.name}</p>
                        <div>
                            <Link 
                            // to={`/aiinsights/analysis/${summary.id}`}
                            to={`/userdashboardmain/reports/aiinsightmodal/${summary?.id}`}
                            >
                                <img src={analysispic} alt="analysis_icon" style={{ width: "50px" }} />
                            </Link>
                        </div>
                    </div>
                    <p>{summary?.storemap?.store?.name}</p>
                </div>
                <p>
                    {isExpanded ? reportSummary : truncatedSummary}{" "}
                    {reportSummary?.length > 100 && (
                        <span onClick={() => toggleSummaryExpansion(key)} className="ai_see">
                            {isExpanded ? "See less" : "See more"}
                        </span>
                    )}
                </p>
                <p style={{ position: "relative" }}>
                    {formatTimestamp(summary?.report_date)}
                    <Link
                        // to={`/audit_store/${summary.id}`}
                        to={`/userdashboardmain/reportslistdetail/${summary?.id}`}
                        // onClick={()=>handleNavigation(summary?.id)}
                        className="ai_view_report_btn"
                    >
                        View Report
                    </Link>
                </p>
            </div>
        );
    };

 

    if (error) {
        return <h3>OOPss!! Something went wrong</h3>;
    }

    const positiveTweetCount = tweets.filter((t) => t.sentiment_score > 0)?.length;
    const neutralTweetCount = tweets.filter((t) => t.sentiment_score === 0)?.length;
    const negativeTweetCount = tweets.filter((t) => t.sentiment_score < 0)?.length;
    const positiveTweetSummaryArr = tweets.filter((t) => t.sentiment_score > 0);
    const neutralTweetSummaryArr = tweets.filter((t) => t.sentiment_score === 0);
    const negativeTweetSummaryArr = tweets.filter((t) => t.sentiment_score < 0);

    const chartData = [
        { name: "Negative", value: negativeTweetCount, color: "#E54535" },
        { name: "Positive", value: positiveTweetCount, color: "#77DD77" },
        { name: "Neutral", value: neutralTweetCount, color: "#EFDB7C" },
    ].filter((data) => data.value > 0);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : tweets && tweets.length > 0 ? (
                <div>
                    <div className="row ai_upper_box_main">
                        <div className="col-md-6">
                            <ResponsiveContainer width="100%" aspect={6 / 3}>
                                <PieChart>
                                    <Pie
                                        startAngle={360}
                                        endAngle={0}
                                        innerRadius={50}
                                        outerRadius={100}
                                        fill="#8884d8"
                                        data={chartData}
                                        label={(l) =>
                                            l.value === 1 ? l.value + " Report" : l.value + " Reports"
                                        }
                                    >
                                        {chartData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip
                                        formatter={(v) => (v === 1 ? v + " Report" : v + " Reports")}
                                    />
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="col-md-6">
                            <table className="table">
                                <tbody>
                                    <tr>
                                        <td className="text-right upper_table">
                                            <h1>
                                                {negativeTweetCount}{" "}
                                                <span>Negative Report</span>
                                            </h1>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right upper_table">
                                            <h1>
                                                {neutralTweetCount}{" "}
                                                <span>Neutral Report</span>
                                            </h1>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-right upper_table">
                                            <h1>
                                                {positiveTweetCount}{" "}
                                                <span>Positive Report</span>
                                            </h1>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
    
                    <div className="row ai_lower_main_box">
                        <div className="col-md-4 ai_lower_part_head">
                            <h2 className="head_neg">
                                <i className="fa-solid fa-arrow-down"></i> Negative Influence
                            </h2>
                            <p>
                                {negativeTweetCount} <span> Report</span>
                            </p>
                            {negativeTweetSummaryArr
                                .slice(0, visibleNegativeSummariesCount)
                                .map((summary, index) => (
                                    <div key={`negative-${index}`}>
                                        {renderSummary(summary, index, "negative")}
                                    </div>
                                ))}
                            {negativeTweetSummaryArr?.length >
                                visibleNegativeSummariesCount && (
                                <button onClick={() => loadMoreSummaries("negative")}>
                                    Load More
                                </button>
                            )}
                        </div>
                        <div className="col-md-4 ai_lower_part_head">
                            <h2 className="head_neu">
                                <i className="fa-solid fa-minus"></i> Neutral
                            </h2>
                            <p>
                                {neutralTweetCount} <span> Report</span>
                            </p>
                            {neutralTweetSummaryArr
                                .slice(0, visibleNeutralSummariesCount)
                                .map((summary, index) => (
                                    <div key={`neutral-${index}`}>
                                        {renderSummary(summary, index, "neutral")}
                                    </div>
                                ))}
                            {neutralTweetSummaryArr?.length >
                                visibleNeutralSummariesCount && (
                                <button onClick={() => loadMoreSummaries("neutral")}>
                                    Load More
                                </button>
                            )}
                        </div>
                        <div className="col-md-4 ai_lower_part_head">
                            <h2 className="head_posi">
                                <i className="fa-solid fa-arrow-up"></i> Positive Influence
                            </h2>
                            <p>
                                {positiveTweetCount} <span> Report</span>
                            </p>
                            {positiveTweetSummaryArr
                                .slice(0, visiblePositiveSummariesCount)
                                .map((summary, index) => (
                                    <div key={`positive-${index}`}>
                                        {renderSummary(summary, index, "positive")}
                                    </div>
                                ))}
                            {positiveTweetSummaryArr?.length >
                                visiblePositiveSummariesCount && (
                                <button onClick={() => loadMoreSummaries("positive")}>
                                    Load More
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <p>No reports are found for the selected handle.</p>
            )}
        </div>
    );
    
};

export default ReportAIinsights;
