import React from 'react'

export default function Review({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>Take a Final Look</h2>
                <p>Check the details carefully before proceeding to purchase the product. Once submitted the details cannot be changed. If you wish to edit details before paying, simply press the back button and modify the information</p>
            </div>
            <div className='dash_stepper_btn_container'>
                <button onClick={() => updateStep(currentStep + 1)} className='dash_stepper_btn_container_btn'>Next Step</button>
            </div>
        </div>
    )
}
