import React from 'react';
import { Col,Row,Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cardimg from "../../../images/card.png";
import indyversecard from "../../../images/indicard.jpg";
import shadiimg from "../../../images/image1.png";

export default function OnlineServices() {
    function truncateText(text) {
        if (text.length <= 130) {
          return text;
        }
        return text.substring(0, 130) + "...";
      }
  return (
    <Container fluid className='homepage_brand_container'>
                <Row className='homepage_brand' style={{paddingTop:"1rem"}}>
                <Col
              className="homepage_brand_box casestudy_box"
              style={{ borderRadius: "7px" }}
              md="4"
            >
              <Link to="/case-studies/online-matrimonial-nps-measurement">
                <div className="homepage_brand_img">
                  <img
                    src={shadiimg}
                    alt="manyvarimg"
                    style={{
                      borderTopLeftRadius: "7px",
                      borderTopRightRadius: "7px",
                      height: "205px",
                    }}
                  />
                </div>
                <div className="homepage_brand_text casestudy_brand_text">
                  <div>
                    <h5 style={{ marginLeft: "1rem", fontWeight: "bold" }}>
                      Shaadi
                    </h5>
                    <p style={{ margin: "0rem 1rem" }}>
                      {truncateText(
                        "Shaadi.com, a leading online matrimonial platform, faced challenges in understanding and enhancing user satisfaction and loyalty. Despite having a large user base, the company struggled to identify specific areas for improvement and address user concerns effectively. The lack of actionable insights from user feedback hindered their ability to make data-driven decisions. To address these issues, Shaadi.com sought to measure their Net Promoter Score (NPS) and implement targeted strategies to improve overall user satisfaction and engagement."
                      )}
                    </p>
                    <div
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "0rem .3rem",
                      }}
                    >
                      <button className="btn btn-md text-center btn-size create_ac_btn">
                      online wedding service 
                      </button>
                      <div
                        style={{
                          border: "2px solid black",
                          padding: "3px 7px",
                          borderRadius: "50%",
                        }}
                      >
                        <i
                          className="fa-solid fa-arrow-right"
                          style={{ marginTop: "3px", fontSize: "1.2rem" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
                </Row>
                
            </Container>
  )
}
