import React, { useEffect } from 'react'
import { Row, Col, Container, Carousel, Modal } from "react-bootstrap";
import img1 from "../../images/application_100.png";
import aiImg from "../../images/Ai_img.jpeg";
import dashImg from "../../images/dashboard_img.png";
import managementImg from "../../images/management_img.jpg";
import surveyImg from "../../images/survey_img.jpg";
import "../../styles/homesurvey.css";
import { style } from 'd3';

export default function HomeSurvey() {


  return (
    <div className='home_survey_main'>
      <div className='howit_works'>
        <h5>NPS survey features with FloorInsights</h5>
        <div className='survey_box_wrapper'>
        <div className='survey_box'>
            <div className='survey_sm_box'>
                <div className='survey_heading_box'>
                    <div className='img_icon'>
                    <img src={img1} alt='dynamic_listing_img'/>
                    </div>
                <h2> Creating Dynamic links and segments </h2>
                </div>
                <p>Create dynamic lists and segment your respondents easily based on any criteria. Get an in-depth analysis of your customer persona and manage follow-ups easily.</p>
            </div>
            <div className='survey_sm_box'>
            <div className='survey_heading_box'>
                    <div className='img_icon'>
                    <img src={aiImg} alt='ai_image'  style={{width:"80%"}}/>
                    </div>
                <h2>Conduct Sentiment Analysis & view Wordcloud </h2>
                </div>
                <p>Using AI analyze the text written and view the text generated in an actionable insight format. Pick out their insights and get a word cloud to have a clear & concise understanding of the loyalty factor.</p>
            </div>
        </div>
        <div  className='survey_box'>
            <div className='survey_sm_box'>
            <div className='survey_heading_box'>
                    <div className='img_icon'>
                    <img src={dashImg} alt='dashbpard_image' style={{width:"100%"}}/>
                    </div>
                <h2>Analyze real-time dynamic Dashboard </h2>
                </div>
                <p>Get instant insights on customer submission of NPS score and take distributed actions according to the workflows</p>
            </div>
            <div className='survey_sm_box'>
            <div className='survey_heading_box'>
                    <div className='img_icon'>
                    <img src={managementImg} alt='management_image'/>
                    </div>
                <h2>Close the loop with Response Management</h2>
                </div>
                <p>All your responses at one place. Assign responses as tickets to any employee in your organization. Check the status and track it right from the assignment, investigation, and resolution.</p>
            </div>
        </div>
        <div  className='survey_box'>
            <div className='survey_sm_box'>
            <div className='survey_heading_box'>
                    <div className='img_icon'>
                    <img src={surveyImg} alt='survey_image' />
                    </div>
                <h2>Set-up your NPS Survey Platform at one go </h2>
                </div>
                <p>Brand the NPS® survey with your logo, name, and style it to your heart’s fill. Add the email subject, body, and get a live preview of your Net Promoter Score survey email instantly. Share personalized follow-up questions separately for promoters, passives, and detractors.</p>
            </div>
            {/* <div className='survey_sm_box'>
                <h2>Close the loop with Response Management</h2>
                <p>All your responses at one place. Assign responses as tickets to any employee in your organization. Check the status and track it right from the assignment, investigation, and resolution.</p>
            </div> */}
        </div>
        </div>
      </div>
    </div>
  )
}
