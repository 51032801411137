import React, { useState } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import "../../styles/homeBanner.css";
import blogimg from "../../images/Blog E.jpg"

const HomeBanner = () => {
	const [modalShow, setModalShow] = useState(false);
	const handleViewDemo = (reportId) => {
        const url = "https://calendly.com/sourabh-narsaria-floorwalk";
        window.open(url, '_blank');

      }
	return (
		<div style={{marginTop:"10rem"}}>
			<section className='home-banner-area'>
				<div className='home-banner-content-box'>
					
								<div className='hero-box'>
									<h1>NPS Survey Platform </h1>
									<h5>Get real time consumer insights</h5>
									<p> FloorInsights, an AI enabled tool to measure your NPS, CSAT & CES. Get instant consumer feedback, set alerts for responding to consumer feedback and win your customer again. </p>

									{/* <Col md='12' className='banner-btn-container'> */}
									<div className='banner-btn-container'>
										{/* <div className='banner-btn' style={{textAlign:"left"}}>
											<Link 
											// to="/exploremarketplace"
											to="/"
												style={{ textDecoration: "none" }}
											// className="btn mb-5 mt-3 btn-md text-center btn-size" style={{background:"#007DC1",color:"white"}}
											>
												Explore MarketPlace
												</Link>
										</div> */}
										<div className='banner-btn'>
											<button
												style={{
													border: 'none',
													outline: 'none',
													backgroundColor: "#007dc1",
													color: "white",
												}}
												onClick={handleViewDemo}
												>
												Get a Demo
											</button>
										</div>
									</div>
								</div>
							{/* </Col>
							
						</Row>
					</Container> */}
					<Modal
						show={modalShow}
						centered
						size='lg'
						style={{ marginTop: '40px' }}
						scrollable={true}
						className='modal-dialog-scrollable p-6 modal_mobile'
						onHide={() => setModalShow(false)}>
						<div style={{ position: "absolute", right: "2rem", top: ".8rem", cursor: "pointer", }}
							onClick={() => setModalShow(false)}
						><i className="fa-solid fa-x"></i></div>
						<iframe
							frameBorder='0'
							style={{
								height: '500px',
								width: '99%',
								border: 'none',
							}}
							src='https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1'></iframe>
					</Modal>
				</div>
			</section>
		</div>
	);
};

export default HomeBanner;
