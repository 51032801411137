import { useState } from 'react';
import base_url from '../config';

export function useFetchinFunction() {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error,setError] = useState("");
    const token = localStorage.getItem("token");

    const getApiData = async (url) => {
        // const getApiData = async (url, token = null) => {
        setIsLoading(true);
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };
            // const headers = {
            //     'Content-Type': 'application/json',
            //     ...(token && { 'Authorization': `Token ${token}` })
            // };
            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
                setData(result);
            } else {
                console.error('API request failed:', response.status, response.statusText);
                setError(response.statusText)
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
            setError(error.message)
        } finally {
            setIsLoading(false);
        }
    };

    return { data, setData, isLoading, getApiData, error };
}
