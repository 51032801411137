import React, { useState, useEffect } from "react";
import { Accordion, Modal } from "react-bootstrap";
import "../../../styles/frequently.css";
import { Helmet } from "react-helmet";

export default function FrequentyAsked() {
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, []);
  return (
    <>
      <Helmet>
        <title>
        FloorInsights | FAQs - Frequently Asked Questions
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Find answers to commonly asked questions about our services and products"
        />
        <meta
          name="keywords"
          content="FAQs, frequently asked questions, help center"
        />
      </Helmet>
      <div
        className="indiv_blog_heading frequent_heading"
        style={{ height: "120px",marginTop: "5rem" }}
      >
        <h1>Frequently Asked Questions : For Brands</h1>
      </div>
      <div className="frequently_main_container">
        <div className="frequently_accordion">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  {" "}
                  WHY IS MYSTERY SHOPPING HELPFUL?
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Mystery Shopping allows companies to uncover what their
                      clients think of their brand. It is a good way to generate
                      insights about the quality of the service provided. It
                      offers the management a method to efficiently evaluate
                      their business practices, service protocol, and employee
                      training from the perspective of their consumer.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHY DO COMPANIES USE MYSTERY SHOPPING?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Companies use mystery shopping to understand and improve
                      the typical customer behavior at its locations and
                      throughout their organization. Companies often reward
                      positive customer experiences and use mystery shopper's
                      evaluations to improve their service and product quality.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHAT TYPES OF COMPANIES USE MYSTERY SHOPPING?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Consumer-based companies, manufacturers companies all use
                      mystery shopping. These includes both start-ups and
                      well-established companies, restaurants, hotels, resorts,
                      banks and financial service providers. The following are
                      other industries that uses mystery shopping too:
                    </p>
                  </li>
                  <ul className="product_detail_border_ul">
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        1. Automotive service facilities
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        2. Luxury products retailers
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        3. Computer/electronics companies
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        4. Advertising and promotions agencies
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>5. Seasonal retailers</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>6. Apartments</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        7. Convenience and gift stores
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>8. Home builders</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>
                        9. Self-storage facilities
                      </p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>10. Research companies</p>
                    </li>
                    <li className="product_detail_point">
                      <p style={{ fontSize: "14px" }}>11. Grocery stores</p>
                    </li>
                  </ul>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  ARE THERE DIFFERENT TYPES OF MYSTERY SHOPS?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      There are several form of mystery shopping such as on-site
                      mystery shopper visits where the mystery shopper directly
                      visits the company or industry to evaluate them secretly.
                      Some other common types of mystery shopping includes
                      employee-reward shops, competitive shops, telephone shops,
                      digital photo shops, internet service evaluations, onsite
                      operational, marketing and merchandising audits. There are
                      types or forms mystery shopping but above are the common
                      methods used.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  HOW DO YOU MONITOR PERFORMANCE?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Each individual shopper's scores are monitored on a
                      monthly basis. Any shopper that appears to be out of sync
                      with the average of the other shoppers doing the same
                      locations will receive additional guidance and coaching.
                      While in the field, we perform "ride alongs" with shoppers
                      that require more "one on one" assistance and coaching.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  HOW DO WE GET THE RESULTS OF OUR MYSTERY SHOPPER VISITS?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      Clients receive a monthly report outlining each location
                      or branch's performance in a variety of categories. These
                      reports are available on a dedicated web site set up for
                      the companies. You will be able to adjust the available
                      trend reports, views and queries to fit your specific
                      needs. Also, there are email alerts to notify you of
                      surveys that are below standard.
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
                {" "}
                <b style={{ fontSize: "14px", padding: ".4rem 0rem" }}>
                  WHAT IS INCLUDED WITH MY BASIC PROGRAM FEE?{" "}
                </b>
              </Accordion.Header>
              <Accordion.Body>
                <ul style={{ paddingLeft: "0rem", marginBottom: "0rem" }}>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      1. Delivery of high-quality on-site mystery shops.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      2. Quick turn-around time of mystery shopping results
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      3. Reports and Analytics of the customer's feedback based
                      on Mystery Shopping.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      4. Instant e-mail notification to view reporting via the
                      Internet.
                    </p>
                  </li>
                  <li className="product_detail_point">
                    <p style={{ fontSize: "14px" }}>
                      5. Monthly summary reports to view trends (assessed
                      according to needs).
                    </p>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="frequently_video">
          <p>
            Watch this 90 seconds video to know how can FloorWalk help you
            improve your Consumer Experience.
          </p>
          <iframe
            src="https://www.youtube.com/embed/yW7X1FdkS4A"
            title="floorwalk_frequently_asked"
          ></iframe>
          <button
            className="btn mb-5 mt-3 btn-md text-center btn-size create_ac_btn"
            onClick={() => setModalShow(true)}
          >
            Talk to a consultant
          </button>
        </div>
      </div>
      <Modal
        show={modalShow}
        centered
        size="lg"
        style={{ marginTop: "40px" }}
        scrollable={true}
        className="modal-dialog-scrollable p-6 modal_mobile"
        onHide={() => setModalShow(false)}
      >
        <div
          style={{
            position: "absolute",
            right: "2rem",
            top: ".8rem",
            cursor: "pointer",
          }}
          onClick={() => setModalShow(false)}
        >
          <i className="fa-solid fa-x"></i>
        </div>
        <iframe
          frameBorder="0"
          style={{
            height: "500px",
            width: "99%",
            border: "none",
          }}
          src="https://forms.zohopublic.com/floorwalk/form/MysteryShoppingServices/formperma/xW3CS_zHKW2DcCLHVZUplXsC1wLR_fITFv3kCFrBY9U?zf_rszfm=1"
        ></iframe>
      </Modal>
    </>
  );
}
