import React, { useState, useRef, useEffect } from 'react';
import { Col, Row, Container, Modal, Alert } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import base_url from '../../utils/config';
import { Helmet } from 'react-helmet';

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [otp, setOTP] = useState(['', '', '', '']);
  const otpInputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [otpErrorMessage, setOtpErrorMessage] = useState("");
  const [otpShowAlert, setOtpShowAlert] = useState(false);
  const [userId, SetUserId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errorMessages, setErrorMessages] = useState({
    emailError: '',
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(()=>{
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  },[]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    setErrorMessages(prevErrors => ({
      ...prevErrors,
      [`${name}Error`]: ''
    }));
  };
  const handleSubmit = (e) => {
    setLoading(false);
    e.preventDefault();
    if (!formData.email) {
      setErrorMessages(prevErrors => ({
        ...prevErrors,
        emailError: "email can't be empty"
      }));
      setHasError(true)
      setTimeout(() => {
        setErrorMessages(prevErrors => ({
          ...prevErrors,
          emailError: ""
        }));
        setHasError(false);
      }, 1200)
    }

    if (formData.email) {
      setLoading(true);
      const requestData = {
        email: formData.email,
      };
      fetch(base_url + "client/forgot_password_api/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
      })
        .then(response => response.json())
        .then(data => {
          setHasError(false);
          setShowAlert(true);
          setTimeout(() => {
            setShowAlert(false);
            setLoading(false);
          }, 1700)
          setErrorMessage(data);
          SetUserId(data.user);
          if (data.details === "OTP is Sent In Your Registered Mail !! ") {
            setModalShow(true);
          }
          // if (data && data.user) {
          //   // localStorage.setItem('user_id', data.user);
          //   // localStorage.setItem('token', data.token);
          //   // setTimeout(() => {
          //   //   navigate("/userdashboardmain");
          //   // }, 1000);
          // }
          setFormData({
            email: '',
          });
        })
        .catch(error => {
          console.error('Error:', error);
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  // --------------------------------OTP VERIFICATION------------------------------------------------------

  const handleOTPInput = (index, event) => {
    const value = event.target.value;
    const newOTP = [...otp];
    if (/^[0-9]*$/.test(value) && index < 3) {
      newOTP[index] = value;
      setOTP(newOTP);
      otpInputRefs[index + 1].current.focus();
    } else if (/^[0-9]*$/.test(value) && index === 3) {
      newOTP[index] = value;
      setOTP(newOTP);
    }
  };
  const handleInputKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      if (!otp[index] && index > 0) {
        const newOTP = [...otp];
        newOTP[index - 1] = '';
        setOTP(newOTP);
        otpInputRefs[index - 1].current.focus();
      }
    }
  };

  function handleOTP() {
    setLoading(true);
    const enteredOtp = otp.join("").toString();
    const requestData = {
      user: userId,
      otp: enteredOtp,
    };
    fetch(base_url + "client/verify_and_forgot_password_api/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
      .then(response => response.json())
      .then(data => {
        setHasError(false);
        setOtpShowAlert(true);
        setTimeout(() => {
          setOtpShowAlert(false);
        }, 1200)
        setOtpErrorMessage(data);
        if (data && data.user) {
          localStorage.setItem('user_id', data.user);
          localStorage.setItem('token', data.token);
          localStorage.setItem('client_id', data.client_id);
          setModalShow(false);
          navigate("/account-info/reset-password");
        }
      })
      .catch(error => {
        console.error('Error:', error);
      }).finally(() => {
        setLoading(false);
      });
  }
  return (
    <>
     <Helmet>
        <title>
        FloorInsights | Forgot Password - Reset Your Password
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Recover your password and regain access to your account"
        />
        <meta
          name="keywords"
          content="forgot password, password recovery, reset password"
        />
      </Helmet>
      {showAlert && (<Alert variant={errorMessage.details === "OTP is Sent In Your Registered Mail !! " ? "success" : "danger"} onClose={() => setShowAlert(false)} dismissible style={{ textAlign: "center" }}>
          {errorMessage.details}
        </Alert>
        )}
      <div className="login_logo" style={{marginTop:"5rem"}}>
        <Link to="/">
          <img
            src="https://marketplace.floorwalk.in/logo.png"
            alt=""
          />
        </Link>
        <h6>Give us your details </h6>
      </div>
      <section className="login-area">
        <Container>
          <form className="form" onSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <div className="login-box">
                  <p className="form-control login_form_control">
                    <label>Email<span>*</span></label>
                    <input
                      type="text"
                      placeholder="Email ID"
                      name='email'
                      value={formData.email}
                      onChange={handleChange}
                      className={hasError ? "login_input error_border" : "login_input success_border"}
                    />
                    {errorMessages.emailError && (
                      <span className="error_message">{errorMessages.emailError}</span>
                    )}
                  </p>

                  {loading ?
                    <div className="login_btn_container">
                      <button className="loginButton" type="button" disabled style={{ fontSize: ".8rem" }}>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                        Loading...
                      </button>
                    </div>
                    :
                    <div className="login_btn_container">
                      <button
                        className="loginButton"
                      >
                        Send OTP
                      </button>
                    </div>
                  }
                </div>
              </Col>
            </Row>
          </form>
          <Modal
            show={modalShow}
            centered
            size='lg'
            style={{ marginTop: '0px', background: "rgba(0,0,0,.7)", height: "100vh" }}
            scrollable={true}
            className='modal-dialog-scrollable p-6 modal_mobile'
            onHide={() => setModalShow(false)}
          >
            <div
              style={{
                width: '100%',
                height: "50vh",
                padding: "2rem 3rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                border: 'none',
              }}
            >
                <div style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }} onClick={() => setModalShow(false)}><i className="fa-solid fa-x"></i></div>
              {otpShowAlert && (<Alert variant={otpErrorMessage.detail === "OTP Verified !! Login Successfully" ? "success" : "danger"} onClose={() => setOtpShowAlert(false)} dismissible>
                {otpErrorMessage.detail}
              </Alert>
              )}
              <h4>Client Registered Successfully. </h4>
              <h4><b> Please Check Email & Enter the OTP for verification</b></h4>
              <div className="otp_box_container">
                {otp.map((digit, index) => (
                  <input
                    className="otp_box"
                    key={index}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleOTPInput(index, e)}
                    onKeyDown={(e) => handleInputKeyDown(index, e)}
                    ref={otpInputRefs[index]}
                  />
                ))}
              </div>
              {loading ?

                <button className="btn mb-5 btn-md text-center btn-size create_ac_btn" type="button" disabled style={{ fontSize: "1rem", background: "#007DC1", color: "white", }}>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                  Loading...
                </button>

                :

                <div className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                  onClick={handleOTP}
                >Enter otp</div>
              }

            </div>
          </Modal>
        </Container>
      </section>
    </>
  )
}
