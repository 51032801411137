import React from 'react'

export default function MobileProjectDetail() {
    return (
        <>
            <div className='dash_stepper_box_right_up' style={{padding:"2rem",boxShadow: "2px 4px 10px 2px rgba(0, 0, 0, 0.25)"}}>
                <h4> <i className="fa-solid fa-image" style={{marginRight:".2rem"}}></i> Give your project an Identity</h4>
                <p>Specify the name, duration, industry and audience of the project. This information helps us understand he project better and select the best execution method</p>
            </div>
        </>
    )
}
