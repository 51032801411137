import React, { useEffect, useState } from "react";
import StoreBrowserPerformance from "./StoreBrowserPerformance";
import StoreBrowserScorePerformancw from "./StoreBrowserScorePerformance";
import { useLocation, useParams } from "react-router-dom";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";

function ReportStoreTrends() {
  const location = useLocation();
  console.log(location)
  let storeId = location.state?.storeId;
  localStorage.setItem("storeId",storeId);
  let questionnaireId = location.state?.questionnaireId;
  localStorage.setItem("questionnaireId",questionnaireId);
  const [localQuestionnaireId,setLocalQuestionnaireId] = useState(null);
  const [localStoreId,setLocalStoreId] = useState(null);
  const [formData, setFormData] = useState({
    audit_cycle: "",
});
  const {data:dropdownhData,getApiData:getDropdownData} = useFetchinFunction();
  useEffect(()=>{
    if(localStorage.getItem("storeId")){
     setLocalStoreId(localStorage.getItem("storeId"))
    }
    if(localStorage.getItem("questionnaireId")){
      setLocalQuestionnaireId(localStorage.getItem("questionnaireId"))
     }
   },[])
   useEffect(()=>{
    if(localStoreId){
      getDropdownData(`client/store/${localStoreId}/questionnaire_types_list_for_comparison`)
    }
    
   },[localStoreId])

   useEffect(() => {
    if (dropdownhData && dropdownhData.length > 0 && localQuestionnaireId) {
      setFormData(prevState => ({
        ...prevState,
        audit_cycle: localQuestionnaireId
      }));
    }
  }, [dropdownhData, localQuestionnaireId]);
   const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
};
   return(
 <>
  <div style={{display:"flex",justifyContent:"flex-end"}}>
        <p className="registration_form_content" style={{width:"30%"}}>
                      <select name='audit_cycle'
                          onChange={handleChange}
                          value={formData.audit_cycle}
                      >
                          {dropdownhData && dropdownhData.length>0 && dropdownhData.map(item=>{
                            return(
                              <option 
                              // value={item?.predefined_questionnaire_type_id} 
                              // value={item?.id} 
                              value={item?.questionnaire_type_id}
                              key={item?.predefined_questionnaire_type_id}>{item?.predefined_questionnaire_type_name}</option>
                            )
                          })}
                      </select>
                  </p>
        </div>
 <StoreBrowserPerformance dropdownId = {formData?.audit_cycle}/>
 <StoreBrowserScorePerformancw dropdownId = {formData?.audit_cycle}/>
 </>
                )
    
  };

export default ReportStoreTrends;


