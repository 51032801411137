import React, { useState } from 'react';
import base_url from '../../../utils/config';

const ToggleSwitch = ({ id,setShowAlert, setAlertMessage,initialState, onToggle, onStoresToggle }) => {
//   const [isLive, setIsLive] = useState(false);
console.log("6",id,initialState)
const [isLive, setIsLive] = useState(initialState);

  const handleToggle = async () => {
    setIsLive(!isLive);
    const endpoint = isLive ?  `${base_url}client/archived_questionnaires/${id}/` : `${base_url}client/publish_questionnaires/${id}/`; 

    try {
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });

      if (!response.ok) {
        throw new Error('API request failed');
      }
      let data = await response.json();
      if(data){
      if(onToggle){
        onToggle();
      }
      if(onStoresToggle){
        onStoresToggle();
      }
        setShowAlert(true);
        setAlertMessage(data?.detail);
        setTimeout(() => {
          setAlertMessage("");
          setShowAlert(false);
        }, 1500);

      }
      
    } catch (error) {
      console.error('API request failed', error);
    }
  };

  return (
    <div className="toggle-switch" onClick={handleToggle}>
    <input type="checkbox" checked={isLive} readOnly />
    <span className="slider"></span>
    <span className="status">{isLive ? 'Archieve' : 'Live'}</span>
  </div>
  );
};

export default ToggleSwitch;
