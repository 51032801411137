import React, { useEffect, useState } from "react";
import {
  ResponsiveContainer,
  CartesianGrid,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  Text,
} from "recharts";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import { useLocation } from "react-router-dom";
import loadingimg from "../../../images/loading.avif";

function StoreBrowserPerformance({dropdownId}) {
  const location = useLocation();
  console.log(location)
  let storeId = location.state?.storeId;
  localStorage.setItem("storeId",storeId);
  let questionnaireId = location.state?.questionnaireId;
  localStorage.setItem("questionnaireId",questionnaireId);
  const [localStoreId,setLocalStoreId] = useState(null);
  const [localQuestionnaireId,setLocalQuestionnaireId] = useState(null);
   
const [data,setData] = useState([]);
const [loading,setLoading] = useState(false);
const {data:graphData,getApiData:getGraphData, isLoading} = useFetchinFunction();

   
    useEffect(()=>{
      if(localStorage.getItem("storeId")){
       setLocalStoreId(localStorage.getItem("storeId"))
      }
      if(localStorage.getItem("questionnaireId")){
       setLocalQuestionnaireId(localStorage.getItem("questionnaireId"))
      }
     },[])

    // useEffect(()=>{
    //   if(localStoreId && localQuestionnaireId){
    //     getGraphData(`client/report/questionnaire_type/${localQuestionnaireId}/store/${localStoreId}/marking_graph`);
    //   }
    // },[localStoreId,localQuestionnaireId])

    useEffect(() => {
      if ( localStoreId && (localQuestionnaireId || dropdownId)) {
        const quesIdentifier = dropdownId || localQuestionnaireId;
        getGraphData(`client/report/questionnaire_type/${quesIdentifier}/store/${localStoreId}/marking_graph`);
      }
    }, [localStoreId, localQuestionnaireId, dropdownId]);

    useEffect(()=>{
      setLoading(true);
  if(graphData){
    let chartData = [];
    let scoresLength = graphData?.questionnaire?.length;
    if(scoresLength){
      for(let i = 0; i < scoresLength; i++){
        let percentage = Math.round((graphData.scores[i]*100)/graphData.max_marks[i]);
        // let percentage = Math.round(( 27.0 *100)/54);
        chartData.push({name: graphData.questionnaire[i], score: percentage, color_code: graphData.color_codes[i]});
      }
    }
   
    setData(chartData);
    setLoading(false);
  }
    },[graphData])
    function getColorbyValue(value) {
        if (value >= 95) {
            return "#688833"; // Green
        }
        else if (value >= 80) {
            return "#4ca9d7"; // Blue
        }
        else if (value >= 70) {
            // return "#808080"; // Grey
            return "#dbb001"; //Yellow
        }
        else if (value >= 50) {
            return "#FF7F50"; // Orange
        }
        else {
            return "#CD5C5C"; // Red
        }
    }
    
   
    return ( 
      isLoading || loading ? 
        <div className='getStarted'>
        <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
    </div>
    : 
      (data && data.length > 0) ? 
      <div>
           <div className="branch_bar_box_head" style={{ borderBottom:"1px solid #ddd"}}>
        <h3 style={{fontSize:"1.5rem"}}>Store Performance</h3>
        {/* <div>
        <p className="registration_form_content">
                      <select name='audit_cycle'
                          // onChange={handleChange}
                          // value={formData.audit_cycle}
                      >
                          {dropdownhData && dropdownhData.length>0 && dropdownhData.map(item=>{
                            return(
                              <option value="cycle2" key={item?.predefined_questionnaire_type_id}>{item?.predefined_questionnaire_type_name}</option>
                            )
                          })}
                      </select>
                  </p>
        </div> */}
      </div>
          <ResponsiveContainer width="100%" height={320}>
            <BarChart data={data} margin={{ top: 35, right: 80, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name">
                <Label value="Questionnaire" offset={0} position="insideBottomRight" />
              </XAxis>
              <YAxis domain={[0, 100]} tickFormatter={(f) => f + "%"}>
                <Label value="Score" offset={0} angle={-90} position="left" />
              </YAxis>
              <Tooltip />
              <Legend />
              <Bar
                dataKey="score"
                barSize={20}
                label={(v) => <Text {...v}>{v.value === null ? "N/A" : v.value + "%"}</Text>}
                isAnimationActive={false}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getColorbyValue(entry["score"])} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
          </div>
      :
       <h5>No reports for this store (Only completed reports graph will show up here) </h5>
     );
}

export default StoreBrowserPerformance;