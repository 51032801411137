import "../../../styles/reportdashboard.css";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Label,
  Text,
} from "recharts";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import { useEffect, useState, useRef } from "react";
import { Tab, Tabs, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import fileDownload from "js-file-download";

export default function ReportDashboard() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(null);
  const [showBranchModal, setShowBranchModal] = useState(false);
  const [showCityModal, setShowCityModal] = useState(false);
  const [formData, setFormData] = useState({
    questionnaire: "",
});

  const chartRef = useRef(null); 
  const citychartRef = useRef(null); 
  const { data: mainData, getApiData, isloading } = useFetchinFunction();
  const {
    data: questionnaireScoreData,
    getApiData: getQuesScoreData,
    isLoading: quesScoreloading,
  } = useFetchinFunction();
  const {
    data: branchData,
    getApiData: getBranchData,
    isLoading: branchloading,
  } = useFetchinFunction();
  const {
    data: cityWiseData,
    getApiData: getCityData,
    isLoading: cityloading,
  } = useFetchinFunction();
  useEffect(() => {
    getApiData(`client/questionnaire_for_dashboard`);
  }, []);

  useEffect(() => {
    if (mainData.length > 0) {
      setSelectedTab(mainData[0]?.questionnaire_type?.id);
    }
  }, [mainData]);

  useEffect(() => {
    if (selectedTab && mainData?.length > 0) {
      let quesId = mainData.find(item => item?.questionnaire_type?.id === +selectedTab)?.id;
      getQuesScoreData(`client/dashboard/questionnaire_type/${selectedTab}/questionnaire_scores`);
      if(quesId){
        getBranchData(`client/dashboard/questionnaire_type/${selectedTab}/questionnaire/${quesId}/store_trends`)
        getCityData(`client/dashboard/questionnaire_type/${selectedTab}/questionnaire/${quesId}/city_trends`)
      }
      
    }
  }, [selectedTab, mainData]);

  const handleSelect = (eventKey) => {
    setSelectedTab(eventKey);
    setFormData(prevState => ({
      ...prevState,
      questionnaire: eventKey // Sync the dropdown with the selected tab
  }));
  };
  const data = [
    { name: "Category A", score: 97 },
    { name: "Category B", score: 85 },
    { name: "Category C", score: 65 },
    { name: "Category D", score: 45 },
  ];
  function getColorbyValue(value) {
    if (value >= 95) {
      return "#688833"; // Green
    } else if (value >= 80) {
      return "#4ca9d7"; // Blue
    } else if (value >= 70) {
      // return "#808080"; // Grey
      return "#dbb001"; //Yellow
    } else if (value >= 50) {
      return "#FF7F50"; // Orange
    } else {
      return "#CD5C5C"; // Red
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
        ...prevState,
        [name]: value
    }));
    setSelectedTab(value)
};
  const handleBranchModal = () => {
    setShowBranchModal(!showBranchModal);
    }
    const handleCityModal = () => {
      setShowCityModal(!showCityModal);
      }

    const handleDownloadBranch = () => {
      if (chartRef.current) {
        html2canvas(chartRef.current).then(canvas => {
          const imgData = canvas.toDataURL("image/png"); // Generate PNG image data
          
          // Create an anchor element
          const link = document.createElement('a');
          link.href = imgData;
          const columnNames = branchData.columns.join('-');
          link.download = `${columnNames} Branch Performance.jpg`;
          
          // Append the anchor to the body (necessary for Firefox)
          document.body.appendChild(link);
          
          // Trigger the download
          link.click();
          
          // Remove the anchor from the document
          document.body.removeChild(link);
        });
      }
    };

    const handleDownloadCity = () => {
      if (citychartRef.current) {
        html2canvas(citychartRef.current).then(canvas => {
          const imgData = canvas.toDataURL("image/png");
          const link = document.createElement('a');
          link.href = imgData;
          const columnNames = cityWiseData.columns.join('-');
          link.download = `${columnNames} City Wise Performance.jpg`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      }
    };
    

  const mappedBranchData = branchData?.data?.map(branch => ({
    name: branch[0]?.name || 'Unknown Branch',
    score: branch[1]?.[0]?.value || 0,
    color_code: branch[1]?.[0]?.color_code || 0,
  })) || []; 

  const mappedCityData =cityWiseData?.data?.map(city => ({
    name: city[0]?.name || 'Unknown city',
    score: city[1]?.[0]?.value || 0,
    color_code: city[1]?.[0]?.color_code || 0,
  })) || []; 

  const handleNavigation = (questionnaireId, storeId) =>{
    console.log("106666", questionnaireId,storeId)
    if(questionnaireId && storeId){
    localStorage.setItem("storeId",storeId);
    localStorage.setItem("questionnaireId",questionnaireId);
    console.log("Stored in localStorage", {
      storeId: localStorage.getItem("storeId"),
      questionnaireId: localStorage.getItem("questionnaireId"),
    });
    navigate("/userdashboardmain/reports/storebrowser/trends", {
      state: {
          storeId,
          questionnaireId,
      }
  })
}
  }
 
  return (
    <>
      <Tabs
        id="justify-tab-example"
        justify
        activeKey={selectedTab}
        onSelect={handleSelect}
        style={{ margin: "1rem 0rem 3rem 0rem" }}
      >
        {mainData?.slice(0,4)?.map((item) => (
          <Tab
            key={item?.questionnaire_type?.id}
            eventKey={item?.questionnaire_type?.id}
            title={item?.questionnaire_type?.name}
          />
        ))}
      </Tabs>
      <div className="report_dash_main_box">
        <h3>Questionnaire Score</h3>
        <div className="upper_chart_box">
          {quesScoreloading ?
          <p>Loading...</p>
        :
        questionnaireScoreData.length>0 ?
      questionnaireScoreData.map((score=>{
        return(
    <div className="semi_donut_box">
            <div
              className="report_dash_semi_donut"
              style={{ "--percentage": score?.get_total_percentage, "--fill": "rgb(61, 169, 64)" }}
            >
              <p style={{ color: "black" }}> {score?.get_total_percentage} %</p>
            </div>
            <p className="semi_donut_box_p">{score?.name}</p>
          </div>
        )
      }))
    :
    <p>No Score</p>}
        </div>
      </div>
      <div className="store_brow_main">
            <div className="store_brow_left_box" >
                <div>
                <p className="registration_form_content">
                        <label> Questionnaire:</label>
                        <select name='questionnaire'
                            onChange={handleChange}
                            value={formData.questionnaire}
                        >
                            {mainData && mainData.length>0 ?
                            mainData.map(ques=>{
                                return(
                                  <option value={ques?.questionnaire_type?.id} key={ques?.id}>{ques?.questionnaire_type?.name}</option>
                                )
                            })
                            :
                            null
                        }
                        </select>
                    </p>

                </div>
             
            </div>
        </div>
      <div className="branch_bar_box">
        <div className="branch_bar_box_head">
          <h3>Branch Performance</h3>
          <div>
            <button className="proof_tag_btn" onClick={handleBranchModal}>
              <i className="fa-solid fa-list"></i>
            </button>
            <button className="proof_tag_btn" style={{ marginLeft: ".5rem" }} onClick={handleDownloadBranch}>
              <i className="fa-solid fa-download"></i> Download
            </button>
          </div>
        </div>
        <div ref={chartRef}>
<ResponsiveContainer width="100%" height={320}>
          <BarChart
            data={mappedBranchData}
            margin={{ top: 35, right: 80, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              <Label value="Questionnaire" offset={0} position="insideBottomRight" />
            </XAxis>
            <YAxis domain={[0, 100]} tickFormatter={(f) => f + "%"}>
              <Label value="Score" offset={0} angle={-90} position="left" />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar
              dataKey="score"
              barSize={20}
              label={(v) => (
                <Text {...v}>{v.value === null ? "N/A" : v.value + "%"}</Text>
              )}
              isAnimationActive={false}
            >
              {mappedBranchData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColorbyValue(entry.score)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        </div>
       {showBranchModal &&
          <Modal
          show={showBranchModal}
          centered
          size="lg"
          className="modal-dialog-scrollable p-6 modal_mobile"
          onHide={() => setShowBranchModal(false)}
        >
          <div style={{ width: '100%', padding: "1rem", border: 'none' }}>
            <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", marginBottom: "1rem" }}
               onClick={() => setShowBranchModal(false)}
            >
              <i className="fa-solid fa-x"></i>
            </div>
            <div>
              <h5 className="setup_regs_modal_heading" style={{borderBottom:"1px solid #ddd", paddingBottom:"1rem"}}>Branch Performance</h5>
            </div>
            <table className="table table-striped">
          <thead>
            <tr>
              <th className="text-right">Branch Name</th>
              {branchData.columns.map((column) => (
                <th key={column} className="text-right">{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
           {/* {mappedBranchData?.map(item=>{
            return(
              <tr>
                <td className="text-right" style={{width:"20%"}}>{item?.name}</td>
                <td className="text-right" style={{ backgroundColor: getColorbyValue(item?.score), width:"50%"}}>{item?.score}%</td>
                <td style={{width:"20%"}}>
                    <Link to="/" className="proof_tag_btn text-right" style={{ marginLeft: ".5rem",textDecoration:"none", fontSize:".9rem", backgroundColor:"#007dc1",color:"white" }}>View</Link>
                  </td>
              </tr>
            )
           })} */}
            {branchData?.data.map(item=>{
            return(
              <tr>
                <td className="text-right" style={{width:"20%"}}>{item[0]?.name}</td>
                <td className="text-right" style={{ backgroundColor: getColorbyValue(item[1]?.[0]?.value), width:"50%"}}>{item[1]?.[0]?.value}%</td>
                {/* <td style={{width:"20%"}}>
                    <Link onClick={()=>handleNavigation(branchData?.questionnaire_type,item[0]?.id)}className="proof_tag_btn text-right" style={{ marginLeft: ".5rem",textDecoration:"none", fontSize:".9rem", backgroundColor:"#007dc1",color:"white" }}>View</Link>
                  </td> */}
              </tr>
            )
           })}
           
          </tbody>
        </table>
          </div>
        </Modal>}
      </div>
      <div className="branch_bar_box">
        <div className="branch_bar_box_head">
          <h3>City Wise Performance</h3>
          <div>
            <button className="proof_tag_btn" onClick={handleCityModal}>
              <i className="fa-solid fa-list"></i>
            </button>
            <button className="proof_tag_btn" style={{ marginLeft: ".5rem" }} onClick={handleDownloadCity}>
              <i className="fa-solid fa-download"></i> Download
            </button>
          </div>
        </div>
        <div ref={citychartRef}>
        <ResponsiveContainer width="100%" height={320}>
          <BarChart
            data={mappedCityData}
            margin={{ top: 35, right: 80, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name">
              <Label
                value="Questionnaire"
                offset={0}
                position="insideBottomRight"
              />
            </XAxis>
            <YAxis domain={[0, 100]} tickFormatter={(f) => f + "%"}>
              <Label value="Score" offset={0} angle={-90} position="left" />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar
              dataKey="score"
              barSize={20}
              label={(v) => (
                <Text {...v}>{v.value === null ? "N/A" : v.value + "%"}</Text>
              )}
              isAnimationActive={false}
            >
              {mappedCityData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={getColorbyValue(entry["score"])}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        </div>
        {showCityModal &&
          <Modal
          show={showCityModal}
          centered
          size="lg"
          className="modal-dialog-scrollable p-6 modal_mobile"
          onHide={() => setShowCityModal(false)}
        >
          <div style={{ width: '100%', padding: "1rem", border: 'none' }}>
            <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", marginBottom: "1rem" }}
               onClick={() => setShowCityModal(false)}
            >
              <i className="fa-solid fa-x"></i>
            </div>
            <div>
              <h5 className="setup_regs_modal_heading" style={{borderBottom:"1px solid #ddd", paddingBottom:"1rem"}}>City Wise Performance</h5>
            </div>
            <table className="table table-striped">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>City</th>
              {cityWiseData.columns.map((column) => (
                <th key={column} className="text-right" style={{width:"60%"}}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
           {/* {mappedCityData?.map(item=>{
            return(
              <tr>
                <td className="text-right">{item?.name}{item?.color_code}</td>
                <td className="text-right" style={{ backgroundColor: getColorbyValue(item?.score)}}>{item?.score}%</td>
              </tr>
            )
           })} */}
            {cityWiseData?.data?.map(item=>{
            return(
              <tr>
                <td className="text-right">{item[0]?.name}{item?.color_code}</td>
                <td className="text-right" style={{ backgroundColor: getColorbyValue(item[1]?.[0].value)}}>{item[1]?.[0].value}%</td>
              </tr>
            )
           })}
          </tbody>
        </table>
          </div>
        </Modal>}
      </div>
    </>
  );
}
