import { useState } from "react";
import { Col } from "react-bootstrap";
import SelectProduct from "./SelectProduct";
import ProjectDetail from "./ProjectDetail";
import UploadStore from "./UploadStore";
import YourAudit from "./YourAudit";
import Review from "./Review";
import ViewReport from "./ViewReport";
import "../../../styles/dashboardstepper.css";

export default function DashboardStepper() {
  const steps = [SelectProduct, ProjectDetail, UploadStore, YourAudit, Review, ViewReport];
  const labelArray = ['Create your first NPS form', 'Enter your store details', 'Assign a form to the store', 'Collect consumer feedback', 'Set alerts and responses to consumer feedbacks', 'View your NPS and win your consumer again'];
  const [currentStep, setCurrentStep] = useState(0);

  function updateStep(step) {
    setCurrentStep(step);
  }

  const StepComponent = steps[currentStep];

  return (
    <div className="dash_stepper_box">
      <Col md="4" className="dash_stepper_box_left">
        <div style={{ position: "relative" }}>
          {labelArray.map((item, index) => (
            <div className={(currentStep >= index ? " dash_selected" : "")} key={index} >
              <div className="dash_circleWrapper">
                <div className="dash_circle">{index + 1}</div>
                <p>{item}</p>
              </div>
            </div>
          ))}
          <div className='dash_line'></div>
        </div>
      </Col>

      <Col md="8">
        {StepComponent &&
          <StepComponent updateStep={updateStep} currentStep={currentStep} />
        }
      </Col>
    </div>
  );
}
