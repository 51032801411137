import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import shotcard from "../../../images/shotcard.jpg";
import shotcenter from "../../../images/shotcenter.jpg";
import shotside1 from "../../../images/shotside1.png";
import shotside2 from "../../../images/shotside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import amazonLogo from "../../../images/Amazon_logo.png";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';
import { Helmet } from 'react-helmet';

export default function Shott() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
               <Helmet>
        <title>
        FloorInsights | CSAT Measurement for Entertainment Company
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Read how we assisted an entertainment company in measuring and improving customer satisfaction through CSAT surveys"
        />
        <meta
          name="keywords"
          content="entertainment company, CSAT measurement, customer satisfaction"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={shotcard} alt="amazon_logo" style={{width:"200px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Shott, a dynamic gaming and entertainment brand, aimed to enhance customer experience and satisfaction by measuring and improving their Net Promoter Score (NPS). By partnering with FloorInsights, Shott deployed advanced NPS measurement software, distributed surveys at key touchpoints, and analyzed data to identify areas for improvement. 
                    </span>
                </div>
                <h3 className='lead manyavar_heading'>Shott partnered with FloorInsights to enhance customer experience by measuring and improving their Net Promoter Score (NPS). They used advanced NPS software, distributed surveys at key touchpoints, and analyzed data to identify areas for improvement. </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={shotside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={shotcenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={shotside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                Shott, a dynamic gaming and entertainment brand, aimed to enhance their customer experience and satisfaction. Their primary goal was to measure and improve their Net Promoter Score (NPS) to gain valuable insights into customer loyalty, identify areas for improvement, and boost overall consumer satisfaction.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Implementation of NPS Measurement Software:</b> <br/>
                                    - Shott partnered with our SaaS-based NPS company to deploy our advanced NPS measurement software. <br/>
                                    - The software was integrated with Shott's gaming platforms and customer relationship management (CRM) systems to ensure comprehensive data collection and analysis.
                                    <br />
                                    {isReadMoreApproach ?
                                        <><span>
                                             <b> 2. Survey Distribution:</b> <br/>
                                    - NPS surveys were taken from the customers at various touchpoints, including after gameplay sessions, following customer support interactions, and during major updates or new game releases.
                                    <br />
                                    <b> 3. Data Analysis and Insights:</b> <br/>
                                    - Our software collected and analyzed NPS data, providing detailed reports and interactive dashboards.
                                    <br />
                                    - We identified key drivers of customer satisfaction and dissatisfaction by segmenting feedback based on game type, user demographics, and interaction history. <br/>
                                    <b>4. Actionable Recommendations:</b> <br/>
                                    - Based on the insights, we provided Shott with targeted recommendations to enhance the gaming experience, such as improving game performance, adding new features, and enhancing customer support.
                                    <br />
                                    - We also suggested personalized engagement strategies for different customer segments to foster loyalty and reduce churn.
                                    <br/>
                                    <b>5. Continuous Monitoring and Improvement:</b> <br/>
                                    - A continuous feedback loop was established to monitor changes in NPS over time and measure the impact of implemented changes.
                                    <br />
                                    - Regular check-ins and quarterly reviews ensured that Shott remained focused on their customer satisfaction goals and could adjust strategies as needed.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() => setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Improved NPS:</b> <br/> 
                                    -  Over six months, Shott saw a significant increase in their NPS, rising from 45 to 70.<br />
                                    <b> 2. Enhanced Customer Satisfaction:</b> <br/>
                                    - Customer feedback highlighted notable improvements in game performance, new feature additions, and more responsive customer support.
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           <b> 3. Increased User Engagement:</b> <br />
                                           - The improved gaming experience led to a 30% increase in active users and a 20% increase in average session duration. <br/>
                                           <b> 4. Higher Retention Rates:</b> <br />
                                           - Enhanced customer satisfaction resulted in a 25% reduction in user churn and a significant increase in repeat engagement.
                                           <br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By partnering with FloorInsights, Shott successfully transformed their customer experience, leading to higher satisfaction and loyalty among their gaming community.
                    </span>
                </div>
            </div>
          <NewCommonCards/>
        </div>
    )
}
