import React,{useEffect} from 'react';
import "../../../styles/maintemplate.css"; 
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import loadingImg from "../../../images/loading.avif";
import { useFetch } from '../../../utils/hooks/useFetch';
import base_url from '../../../utils/config';

function MainTemplatePage() {
    const navigate = useNavigate();
    const {data:templateData, isLoading, error} = useFetch(base_url+`client/templates_list/`)

    useEffect(()=>{
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    },[])

    function handleNavigation(templateId,url) {
        navigate(`/templates/${url}`,{state:templateId});
    }

    return (
        <>
           <Helmet>
        <title>
        FloorInsights | Survey Templates - Ready-to-Use Templates
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Browse our collection of ready-to-use survey templates for various use cases"
        />
        <meta
          name="keywords"
          content="survey templates, ready-to-use templates, use cases"
        />
      </Helmet>
      <div
        className="indiv_blog_heading"
        style={{ height: "120px",marginTop:"5rem" }}
      >
        <h1 style={{ fontSize: "2.5rem" }}>Templates</h1>
      </div>
        <div style={{ marginTop: "3rem" }}>
            <div className="search-container">
                <input
                    type="text"
                    placeholder="Search templates..."
                    // value={searchQuery}
                    // onChange={handleSearchChange}
                    className="search-box"
                />
                <p>Showing {templateData?.length} Templates</p>
            </div>
            {isLoading ?
                    <div className='getStarted' style={{marginBottom:"5rem"}}>
                    <img src={loadingImg} alt="Loading..." style={{ width: "200px" }} />
                </div>
                :
            <div className="grid-container">
                    {
                    templateData?.map((template) => (
                        <div className="grid-item" key={template?.id} onClick={()=>handleNavigation(template?.id, template?.template_name)}>
                            <img src={template?.template_url} alt={template?.template_name} />
                            <div className="overlay">Click for details</div>
                        </div>
                    ))}
                </div>
}
        </div>
                  
        </>
    );
}

export default MainTemplatePage;
