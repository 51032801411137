import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import givacard from "../../../images/giva_img1.png";
import indicenter from "../../../images/Digital-Standee-at-Giva-Ambience-Mall.webp";
import indiside1 from "../../../images/indiside1.png";
import indiside2 from "../../../images/indiside2.png";
import NewCommonCards from './NewCommonCards';
import CommonCards from './CommonCards';
import { Helmet } from 'react-helmet';

export default function Giva() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
                <Helmet>
        <title>
        FloorInsights | CSAT Measurement for Jewellery Brand
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Discover how our CSAT solution helped a jewellery brand measure and enhance customer satisfaction"
        />
        <meta
          name="keywords"
          content="jewellery brand, CSAT measurement, customer satisfaction"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={givacard} alt="giva_logo" style={{width:"170px",margin:"2rem 0rem"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Giva, a renowned jewelry brand, faced challenges in accurately understanding and improving customer satisfaction and loyalty. The existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to pinpoint specific areas needing enhancement. This deficiency hindered Giva's ability to make data-driven decisions to elevate the customer experience. To address these issues, Giva aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance.</span>
                </div>
                <h3 className='lead manyavar_heading'>Giva struggled to enhance customer satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to boost customer satisfaction, retention rates, and overall business performance.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={indiside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={indicenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={indiside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                Giva, a renowned jewelry brand, aimed to enhance their customer experience and satisfaction. Their primary objective was to measure and improve their Net Promoter Score (NPS) to gain insights into customer loyalty, identify areas needing improvement, and ultimately increase customer satisfaction and retention rates.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Implementation of NPS Measurement Software:</b> <br/>
                                    - Giva partnered with our SaaS-based NPS company to deploy our advanced NPS measurement software.
                                    <br />
                                    - The software was seamlessly integrated with Giva's e-commerce platform and customer relationship  
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                            management (CRM) systems, ensuring comprehensive data collection and analysis. <br/>
                                             <b> 2. Survey Distribution:</b> <br/>
                                    - NPS surveys were taken from the customers at various touchpoints, including post-purchase, after customer service interactions, and following product delivery.
                                    <br />
                                    <b> 3. Data Analysis and Insights:</b> <br/>
                                    - The software aggregated and analyzed the NPS data, providing detailed reports and interactive dashboards.
                                    <br />
                                    - Key drivers of customer satisfaction and dissatisfaction were identified by segmenting feedback based on product type, purchase frequency, and customer demographics.
                                    <br/>
                                    <b>4. Actionable Recommendations:</b> <br/>
                                    - Based on the insights, we provided Giva with specific, actionable recommendations, such as enhancing product quality, improving packaging, and streamlining the return process.
                                    <br />
                                    - We also suggested personalized marketing strategies to engage promoters and convert detractors.
                                    <br/>
                                    <b>5. Continuous Monitoring and Improvement:</b> <br/>
                                    - A continuous feedback loop was established to monitor changes in NPS over time and measure the impact of implemented changes.
                                    <br />
                                    - Regular check-ins and quarterly reviews ensured that Giva stayed on track with their customer satisfaction goals and could adjust strategies as needed.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Improved NPS:</b> <br/> 
                                    - Over six months, Giva saw a significant increase in their NPS, rising from 50 to 75.<br />
                                    <b> 2. Enhanced Customer Satisfaction:</b> <br/>
                                    - Customer feedback highlighted improvements in product quality, packaging, and customer service efficiency.   
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           <b> 3. Increased Sales and Repeat Purchases:</b> <br />
                                           - Ihe improved customer satisfaction led to a 30% increase in repeat purchases and an overall sales growth of 20%. <br/>
                                           <b> 4. Better Customer Insights:</b> <br />
                                           - The detailed insights from NPS data enabled Giva to make data-driven decisions, leading to a more customer-centric approach in product development and marketing.<br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By partnering with FloorInsights, Giva successfully transformed their customer experience, leading to higher satisfaction, increased loyalty, and a stronger market position.
                    </span>
                </div>
            </div>
        <CommonCards/>
        </div>
    )
}
