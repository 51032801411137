import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cars24card from "../../../images/cars_24_img.webp";
import vajorcenter from "../../../images/Cars24 - Kolkata 1.jpg";
import vajorside1 from "../../../images/vajorside1.png";
import vajorside2 from "../../../images/vajorside2.png";
import CommonCards from './CommonCards';
import { Helmet } from 'react-helmet';

export default function Cars24() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
                    <Helmet>
        <title>
        FloorInsights | NPS Measurement for Second-Hand Automobile Company
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Read how our NPS solution helped a second-hand automobile company improve customer experience and loyalty"
        />
        <meta
          name="keywords"
          content="second-hand automobile, NPS measurement, customer loyalty"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={cars24card} alt="cars24_logo" style={{width:"180px",margin: "2rem 0rem"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>Cars 24, a leading online platform for buying and selling pre-owned cars, faced challenges in accurately measuring and enhancing customer satisfaction and loyalty. Their existing feedback mechanisms were inadequate in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make data-driven decisions to enhance the customer experience. To address these issues, Cars 24 aimed to measure their Net Promoter Score (NPS) and implement strategies to boost customer satisfaction, retention rates, and overall business performance.</span>
                </div>
                <h3 className='lead manyavar_heading'>Cars 24 struggled to enhance customer satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement strategies to boost customer satisfaction, retention rates, and overall business performance.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={vajorside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={vajorcenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={vajorside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                Cars 24, a leading online platform for buying and selling pre-owned cars, sought to enhance their customer experience and satisfaction. They aimed to measure and improve their Net Promoter Score (NPS) to gain better insights into customer loyalty, identify areas needing improvement, and ultimately drive higher customer satisfaction and retention rates.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Implementation of NPS Measurement Software:</b> <br/>
                                    - Cars 24 partnered with our SaaS-based NPS company to implement our comprehensive NPS measurement software.
                                    <br />
                                    - We integrated the software seamlessly with their existing customer relationship management (CRM) system, 
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                            ensuring real-time data capture and analysis. <br/>
                                             <b> 2. Data Collection:</b> <br/>
                                    - Surveys were deployed at multiple touchpoints in the customer journey, including post-purchase, after service appointments, and following customer support interactions.
                                    <br />
                                    <b> 3. Analysis and Insights:</b> <br/>
                                    - Our software aggregated and analyzed the NPS data, providing detailed reports and dashboards.
                                    <br />
                                    - We identified key drivers of customer satisfaction and dissatisfaction, segmenting feedback by demographics, location, and service type.
                                    <br/>
                                    <b>4. Actionable Recommendations:</b> <br/>
                                    - Based on the insights, we provided Cars 24 with specific, actionable recommendations to address areas of concern.
                                    <br />
                                    - Recommendations included improving the transparency of the buying process, enhancing post-purchase support, and offering additional services to increase customer convenience.
                                    <br/>
                                    <b>5. Continuous Monitoring and Improvement:</b> <br/>
                                    - We set up a continuous feedback loop, allowing Cars 24 to monitor changes in NPS over time and measure the impact of implemented changes.
                                    <br />
                                    - Regular check-ins and quarterly reviews ensured that Cars 24 stayed on track with their customer satisfaction goals.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Improved NPS:</b> <br/> 
                                    - Over six months, Cars 24 saw a significant increase in their NPS, rising from 30 to 55.<br />
                                    <b> 2. Enhanced Customer Satisfaction:</b> <br/>
                                    - Customer feedback highlighted improvements in key areas such as customer support responsiveness and overall transaction transparency.   
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           <b> 3. Higher Customer Retention:</b> <br />
                                           - Increased satisfaction led to higher customer retention rates, with repeat customers increasing by 20%. <br/>
                                           <b> 4. Informed Decision-Making:</b> <br />
                                           - The insights from the NPS data enabled Cars 24 to make informed decisions about service improvements and new offerings, leading to a more customer-centric approach.<br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By partnering with FloorInsights, Cars 24 successfully transformed their customer experience, leading to higher satisfaction and loyalty among their customers.
                    </span>
                </div>
            </div>
           <CommonCards/>
        </div>
    )
}
