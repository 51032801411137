

import React, { useEffect, useState } from "react";
import { Col, Row, Container, Alert, Table } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import { postAssignFormTo } from "../../../utils/services/PostdataHandle";
import "../../../styles/dashreport.css";
import Select from "react-select";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import { useFetchAuth } from "../../../utils/hooks/useFetchAuth";
import ToggleSwitch from "./ToggleSwitch";
import loadingimg from "../../../images/loading.avif";

export default function AssignFormMain() {
  const navigate = useNavigate();
  const clientId = localStorage.getItem("client_id");
  const [formData, setFormData] = useState({
    stores: [],
    questionnaire: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    storesError: "",
    questionnaireError: "",
  });
  const [showAlert, setShowAlert] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  // const { data: storesData } = useFetchAuth(`client/${clientId}/remaining_stores/`);
  const { data: storesData, getApiData: getStoreApiData } = useFetchinFunction();
  const [transformedStoresData, setTransformedStoresData] = useState([]);
  const { data: questionnaireData } = useFetchAuth(`client/${clientId}/questionnaire_list`);
  const { data: tableData, getApiData, isLoading } = useFetchinFunction();

  useEffect(()=>{
    handleTableApiCall();
    handleStoresApiCall();
  },[])
  useEffect(() => {
    if (storesData) {
      const transformedData = storesData.map((store) => ({
        value: store.id,
        label: store.name,
      }));
      setTransformedStoresData(transformedData);
    }
  }, [storesData]);
  useEffect(() => {
    if (alertMessage) {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
}, [alertMessage])
 
  function handleTableApiCall () {
    getApiData(`client/${clientId}/store_map_list/`);
  };
  function handleStoresApiCall () {
    getStoreApiData(`client/${clientId}/remaining_stores/`)
  }
  
  const handleChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.stores.length > 0 && formData.questionnaire) {
      let payload = {
        addStore: formData.stores,
        questionnaire: formData.questionnaire,
      };
      setLoadingButton(true);
      const promise = postAssignFormTo("client/store_map_questionnaire", payload);
      promise
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setFormData({
              questionnaire: "",
              stores: []
            });
            setShowAlert(true);
            setAlertMessage("Added Successfully");
            setTimeout(() => {
              setAlertMessage("");
              setShowAlert(false);
            }, 1500);

            handleTableApiCall();
          }
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setTimeout(() => {
            setErrorMessage("");
          }, 1500);
        })
        .finally(() => {
          setLoadingButton(false);
        });
    }
  };

  const handleFocus = (e) => {
    e.target.classList.add("input-focused");
  };

  const handleBlur = (e) => {
    e.target.classList.remove("input-focused");
  };

  //-------------------- Transform tableData----------------------

  const transformedTableData = tableData.reduce((acc, item) => {
    const questionnaire = item?.questionnaire;
    if (!acc[questionnaire.id]) {
      acc[questionnaire.id] = {
        questionnaireName: questionnaire?.name,
        stores: [],
        questionnaire_id: questionnaire?.id,
      };
    }
    acc[questionnaire?.id].stores.push({ name: item?.store?.name, store_id: item?.store?.id, live: item?.live, id : item?.id, link: item?.link });
    return acc;
  }, {});
  

// console.log("359",transformedTableData)
const isAnyStoreLive = Object.values(transformedTableData).some((data) =>
  data.stores.some((store) => store.live)
);

const handleLiveLinkClick = (link) => {
  const baseUrl = "https://nps.floorwalk.in/servey/store_map/";
  // const idPart = link.replace(baseUrl, "http://localhost:8000/survey");
  const idPart = link.replace(baseUrl, "");
  console.log("14444",idPart)
  if (window.location.hostname === "localhost") {
    // navigate(`/survey/${idPart}`);
    window.open(`/servey/store_map/${idPart}`, '_blank');
  } else {
    // window.location.href = link;
    window.open(link, '_blank');
  }
};
  return (
    <>
      {showAlert && (
        <Alert
          variant={alertMessage === "Added Successfully" || alertMessage === "Survey of this store is now PUBLISHED" || alertMessage === "Survey of this store is now ARCHIVED" ? "success" : "danger"}
          onClose={() => setShowAlert(false)}
          dismissible
          style={{ textAlign: "center" }}
        >
          {alertMessage}
        </Alert>
      )}
      <div className="login_logo">
        <h6>
          <b>Assign Form to Store </b>
        </h6>
      </div>
      <section className="login-area" style={{ paddingBottom: "0rem" }}>
        <Container>
          <form className="form" onSubmit={handleSubmit}>
            <Row>
              <Col md="12" className="d-flex justify-content-center">
                <div className="login-box changepasswrs_loginbox">
                  <p className="registration_form_content">
                    <label>Questionnaire</label>
                    <select
                      name="questionnaire"
                      onChange={(e) => handleChange("questionnaire", e.target.value)}
                      value={formData.questionnaire}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      style={{ height: "50px" }}
                    >
                      <option value="">Select Questionnaire...</option>
                      {questionnaireData &&
                        questionnaireData.length > 0 &&
                        questionnaireData.map((questionnaire) => (
                          <option key={questionnaire.id} value={questionnaire.id}>
                            {questionnaire.name}
                          </option>
                        ))}
                    </select>
                    {errorMessages.questionnaireError && (
                      <span className="error_message">
                        {errorMessages.questionnaireError}
                      </span>
                    )}
                  </p>
                  <p className="registration_form_content">
                    <label>Store</label>
                    <Select
                      name="stores"
                      options={transformedStoresData}
                      isMulti
                      onChange={(selectedOptions) =>
                        handleChange(
                          "stores",
                          selectedOptions.map((option) => option.value)
                        )
                      }
                      value={transformedStoresData.filter((option) =>
                        formData?.stores?.includes(option.value)
                      )}
                    />
                    {errorMessages.storesError && (
                      <span className="error_message">
                        {errorMessages.storesError}
                      </span>
                    )}
                  </p>
                  <div className="login_btn_container">
                    {loadingButton ? (
                      <button className="btn mb-4 mt-3 btn-md text-center btn-size create_ac_btn">
                        Loading...
                      </button>
                    ) : (
                      <button className="btn mb-4 mt-3 btn-md text-center btn-size create_ac_btn">
                        Add
                      </button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </section>
      <Table
        className="text-center table_border dash_tab_table report_table_mobile pricing_table table-responsive"
        style={{ marginTop: "0rem" }}
      >
        <thead className="dash_tab_table_head">
          <tr>
            <th className="fw-bold table_border">Questionnaire</th>
            <th className="fw-bold table_border">Stores</th>
            <th className="fw-bold table_border">Preview</th>
            <th className="fw-bold table_border">Actions</th>
            {isAnyStoreLive && <th className="fw-bold table_border">Live Link</th>}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={4}>
                <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
              </td>
            </tr>
          ) : Object.values(transformedTableData).length > 0 ? (
            Object.values(transformedTableData).map((data) => (
              <React.Fragment key={data?.id}>
                <tr>
                  <td rowSpan={data?.stores.length} className="table_border fw-bold">
                    {data?.questionnaireName}
                  </td>
                  <td className="table_border fw-bold">
                    {data?.stores[0]?.name}
                  </td>
                  <td rowSpan={data?.stores.length} className="table_border" style={{verticalAlign:"middle"}}>
                    <Link
                     
                       to={`/userdashboard/previewsection?questionnaireId=${data?.questionnaire_id}`}
                       target="_blank"
                      style={{ color: "#337ab7", textDecoration: "none", fontSize: ".8rem", fontWeight:"bold",}}
                    >
                      Preview
                    </Link>
                  </td>
                  <td className="table_border">
                    <ToggleSwitch id={data?.stores[0]?.id} setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} initialState={data?.stores[0]?.live} onToggle={handleTableApiCall} onStoresToggle={handleStoresApiCall}/>
                  </td>
                  {/* {data?.stores[0]?.live ?
                  <td className="table_border">
                    <a href={data?.stores[0]?.link} style={{textDecoration:"underline"}}>{data?.stores[0]?.link}</a>
                  </td>
                  :
                  null} */}
                   {data?.stores[0]?.live ? (
                <td className="table_border">
                  <Link
                  // target="_blank"
                    onClick={() => handleLiveLinkClick(data?.stores[0]?.link)}
                    style={{ textDecoration: "underline" }}
                  >
                    {data?.stores[0]?.link}
                  </Link>
                </td>
              ) : null}
                </tr>
                {data?.stores.slice(1).map((store) => (
                  <tr key={store?.id}>
                    <td className="table_border fw-bold">{store?.name}</td>
                    <td className="table_border">
                      <ToggleSwitch id={store?.id}  setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} initialState={store?.live} onToggle={handleTableApiCall} onStoresToggle={handleStoresApiCall}/>
                    </td>
                    {/* {store?.live ?
                    <td className="table_border">
                    <a href={store?.link} style={{textDecoration:"underline"}}>{store?.link}</a>
                  </td>
                  :null} */}
                   {store?.live ? (
                <td className="table_border">
                  <Link
                  // target="_blank"
                    onClick={() => handleLiveLinkClick(store?.link)}
                    style={{ textDecoration: "underline" }}
                  >
                    {store?.link}
                  </Link>
                </td>
              ) : null}
                  </tr>
                ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No Data...</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
