

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { clearStoreData, removeFile } from '../../utils/orderdataslice';
import "../../styles/dashboardSidebar.css";
import floorwalklogo from "../../../src/images/FloorInsightLogo.jpeg";
import GoogleTranslateWidget from "../GoogleTranslateWidget.js";

export default function DashboardSidebar() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectedMenu, setSelectedMenu] = useState("Dashboard");
    const [reportsOpen, setReportsOpen] = useState(false);

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes('previewsection')) {
            setSelectedMenu('Questionnaire');
            // setReportsOpen(true);
        }
        if (location.state === "profile") {
            setSelectedMenu("Profile")
        }
    }, [location]);

    function handleLogout() {
        dispatch(clearStoreData());
        dispatch(removeFile());
        localStorage.removeItem('user_id');
        localStorage.removeItem('token');
        localStorage.removeItem('client_id');
        localStorage.removeItem('orderId');
        navigate("/");
    }

    function handleLogo() {
        window.location.reload();
    }

    const handleMenuClick = (menuName) => {
        setSelectedMenu(menuName);
    };

    const toggleReports = () => {
        setReportsOpen(!reportsOpen);
    };

    return (
        <div className='dash_sidebar_container'>
            <div className="dash_side_logo" onClick={handleLogo}>
                <Link to="/">
                    <img src={floorwalklogo} alt="" />
                </Link>
            </div>
            <ul className='dash_side_list_box'>
                {/* <ul style={{ listStyleType: 'none', display: 'flex', alignItems: 'center' }}>
                    <Link to="/userdashboardmain/projectsetup" style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}>
                        <li>
                            <button className='btn create_ac_btn'>
                                <span><i className="fa-solid fa-plus"></i></span>
                            </button>
                        </li>
                        <li style={{ marginLeft: '20px' }}>
                            <GoogleTranslateWidget />
                        </li>
                    </Link>
                </ul> */}

                <Link to="/userdashboardmain"> <li
                    className={selectedMenu === 'Dashboard' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Dashboard')}
                >
                    <i className="fa-solid fa-clipboard"></i>Dashboard
                </li>
                </Link>
                <li className='dash_side_list_head'>ANALYZE</li>
                <li
                    // className={selectedMenu === 'Reports' ? 'selected-menu' : ''}
                    onClick={toggleReports}
                    className='report_dropdown'
                >
                    <i className="fa-solid fa-clipboard"></i>Reports 
                    {reportsOpen ?
                    <i className="fa-solid fa-chevron-down section_divider"></i>
                    
                    :
                    <i className="fa-solid fa-chevron-right section_divider"></i>
}
                </li>
                {reportsOpen && (
                    <ul className='sub-menu'>
                        <Link to="/userdashboardmain/reports/dashboard">
                            <li
                                className={selectedMenu === 'Report Dashboard' ? 'selected-menu' : ''}
                                onClick={() => handleMenuClick('Report Dashboard')}
                            >
                                <i className="fa-solid fa-file-alt"></i> Dashboard
                            </li>
                        </Link>
                        <Link to="/userdashboardmain/reports/storebrowser">
                            <li
                                className={selectedMenu === 'Store Browser' ? 'selected-menu' : ''}
                                onClick={() => handleMenuClick('Store Browser')}
                            >
                                <i className="fa-solid fa-file-alt"></i> Store Browser 
                            </li>
                        </Link>
                        <Link to="/userdashboardmain/reports/reportbrowser">
                            <li
                                className={selectedMenu === 'Report Browser' ? 'selected-menu' : ''}
                                onClick={() => handleMenuClick('Report Browser')}
                            >
                                <i className="fa-solid fa-file-alt"></i> Report Browser 
                            </li>
                        </Link>
                        <Link to="/userdashboardmain/reports/aiinsights">
                            <li
                                className={selectedMenu === 'AI insights' ? 'selected-menu' : ''}
                                onClick={() => handleMenuClick('AI insights')}
                            >
                                <i className="fa-solid fa-file-alt"></i> AI insights  
                            </li>
                        </Link>
                        
                    </ul>
                )}
                <Link to="/userdashboardmain/questionnaire">
                    <li
                        className={selectedMenu === 'Questionnaire' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Questionnaire')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Questionnaire
                    </li>
                </Link>
                <Link to="/userdashboardmain/client-user">
                    <li
                        className={selectedMenu === 'ClientUser' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('ClientUser')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Client User
                    </li>
                </Link>
                <Link to="/userdashboardmain/assignformtostore">
                    <li
                        className={selectedMenu === 'Assign form to store' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Assign form to store')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Assign form to store
                    </li>
                </Link>
                <li className='dash_side_list_head'>MANAGE</li>
                <Link to="/userdashboardmain/stores">
                    <li
                        className={selectedMenu === 'Stores' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Stores')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Stores
                    </li>
                </Link>
                <li className='dash_side_list_head'>PAYMENTS</li>
                <Link to="/userdashboardmain/invoices">
                    <li
                        className={selectedMenu === 'Invoices' ? 'selected-menu' : ''}
                        onClick={() => handleMenuClick('Invoices')}
                    >
                        <i className="fa-solid fa-clipboard"></i>Invoices
                    </li>
                </Link>
                <li className='dash_side_list_head'>User Details</li>
                <Link to="/userdashboardmain/profile"> <li
                    className={selectedMenu === 'Profile' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Profile')}
                ><i className="fa-solid fa-clipboard"></i>Profile</li></Link>
                <Link to="/userdashboardmain/changepassword"> <li
                    className={selectedMenu === 'Change Password' ? 'selected-menu' : ''}
                    onClick={() => handleMenuClick('Change Password')}
                ><i className="fa-solid fa-clipboard"></i>Change Password</li></Link>
                <li style={{ cursor: "pointer", color: "#ff6f6f", marginTop: "1.2rem" }} className='dash_side_list_head' onClick={handleLogout}> <b> Log Out </b></li>
            </ul>
        </div>
    );
}
