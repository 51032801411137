import { useEffect, useState } from "react";
import "../../../styles/reportstorebrowser.css"
import { Table } from "react-bootstrap";
import { getColor } from "../../../utils/constant";
import { useNavigate,useLocation } from "react-router-dom";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import loadingimg from "../../../images/loading.avif"

function StoreBrowserReport() {
    const location = useLocation();
    let storeId = location.state?.storeId;
    localStorage.setItem("storeId",storeId);
    let questionnaireId = location.state?.questionnaireId;
    localStorage.setItem("questionnaireId",questionnaireId);
    const [localStoreId,setLocalStoreId] = useState(null);
    const [localQuestionnaireId,setLocalQuestionnaireId] = useState(null);
    const navigate = useNavigate();
   
    const {data,getApiData:getTableData,isLoading} = useFetchinFunction();
 
    useEffect(()=>{
        // if(localStorage.getItem("storeId")){
        //  setLocalStoreId(localStorage.getItem("storeId"))
        // }
        // if(localStorage.getItem("questionnaireId")){
        //  setLocalQuestionnaireId(localStorage.getItem("questionnaireId"))
        // }
        if(storeId ){
            getTableData(`client/store/${storeId}/store_report`);
          }
       },[])
  
    const handleNavigation = (reportId) => {
      // navigate("/userdashboardmain/reportslistdetail",{state:reportId})
      navigate(`/userdashboardmain/reportslistdetail/${reportId}`)
        }
        const formatDate = (timestamp) => {
            const dateObj = new Date(timestamp);
            const day = dateObj.getDate();
            const month = dateObj.toLocaleString('default', { month: 'short' }); 
            const year = dateObj.getFullYear();
        
            // Function to get ordinal suffix
            const getOrdinalSuffix = (day) => {
                if (day >= 11 && day <= 13) return 'th';
                switch (day % 10) {
                    case 1: return 'st';
                    case 2: return 'nd';
                    case 3: return 'rd';
                    default: return 'th';
                }
            };
        
            return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
        };
        
    return (
        <div style={{marginTop:"3rem"}} className="table-responsive">
        <Table className="text-center table_border dash_tab_table report_table_mobile table-hover" >
              <thead className='dash_tab_table_head'>
                <tr>
                  <th className="fw-bold table_border">Questionnaire</th>
                  <th className="fw-bold table_border">Type</th>
                  <th className="fw-bold table_border">Report Date</th>
                  <th className="fw-bold table_border">Score</th>
                </tr>
              </thead>
              <tbody>
                {isLoading?
                 <tr>
                 <td colSpan={5}>
                   {" "}
                   <img
                     src={loadingimg}
                     alt="Loading..."
                     style={{ width: "150px" }}
                   />
                 </td>
               </tr>
                :
                data.length>0 ? data.map(report=>{
                    return(
                        <tr style={{cursor:"pointer"}} onClick={()=>handleNavigation(report?.id)} key={report?.id}>
                        <td className='table_border'>{report?.storemap?.questionnaire?.name}</td>
                        <td className='table_border'>{report?.storemap?.questionnaire?.type_name}</td>
                        <td className={['table_border', getColor(report?.color)].join(' ')}>{formatDate(report?.report_date)}</td>
                        <td className={['table_border', getColor(report?.color)].join(' ')}>{report?.store_report_percentage}%</td>
                        </tr>
                    )
                })
            
             
            :
            <tr><td>No Data</td></tr>
                }
              </tbody>
            </Table>
            </div>
    )
}

export default StoreBrowserReport;


