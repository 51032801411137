import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CommonCards from './CommonCards';
import byjuscard from "../../../images/byjus_img.jpg";
import garmincenter from "../../../images/byjus-demo-classes.png";
import garminside1 from "../../../images/garmainside1.png";
import garminside2 from "../../../images/garmainside2.png";
import { Helmet } from 'react-helmet';

export default function Byjus() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
               <Helmet>
        <title>
        FloorInsights | NPS Measurement for EdTech Global Enterprise
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Learn how our NPS solution helped an EdTech company improve customer experience and loyalty worldwide"
        />
        <meta
          name="keywords"
          content="EdTech, NPS measurement, global enterprise"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={byjuscard} alt="byjus_logo" style={{ width: "150px", marginBottom:"2rem", marginTop:"2rem"}} />
                <div className='lead manyavar_heading_text'>
                    <span>BYJU'S, a leading education technology company, faced challenges in accurately understanding and enhancing user satisfaction and engagement. The existing user feedback mechanisms were insufficient in providing actionable insights, making it difficult to identify specific areas needing improvement. This gap hindered their ability to make informed, data-driven decisions to elevate the learning experience. To address these issues, BYJU'S aimed to measure their Net Promoter Score (NPS) and implement strategies to boost user satisfaction, loyalty, and engagement.</span>
                </div>
                <h3 className='lead manyavar_heading'>BYJU'S struggled to enhance user satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement strategies to boost user satisfaction, loyalty, and engagement.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={garminside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={garmincenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={garminside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                The primary objective was to help BYJU'S, a leading education technology company, measure its Net Promoter Score (NPS) and implement strategies to improve overall user satisfaction. The goal was to understand user sentiments better, identify areas for improvement, and increase user loyalty and engagement.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Initial Assessment:</b> <br/>
                                    - Conducted an initial assessment of BYJU'S current user feedback mechanisms and overall user satisfaction levels.
                                    <br />
                                    - Set up the NPS measurement software provided by our SAAS company to collect and analyze user feedback.
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                             <b> 2. NPS Survey Implementation:</b> <br/>
                                    - Developed and distributed an NPS survey to BYJU'S users via email and in-app notifications to gather feedback on their learning experiences.
                                    <br />
                                    - Ensured the survey was easy to understand and complete, focusing on key aspects of the user experience, including content quality, platform usability, and customer support.
                                    <br/>
                                    <b> 3. Data Collection and Analysis:</b> <br/>
                                    - Collected responses over a three-month period to gather a significant amount of data for analysis.
                                    <br />
                                    - Used our NPS software to analyze the data, identifying promoters, passives, and detractors.
                                    <br/>
                                    - Examined feedback for common themes and specific issues raised by users. <br/>
                                    <b>4. Actionable Insights:</b> <br/>
                                    - Provided BYJU'S with detailed reports highlighting key areas of strength and weakness.
                                    <br />
                                    - Identified specific actions BYJU'S could take to improve user satisfaction, such as enhancing content quality, improving platform navigation, and offering better customer support.
                                    <br/>
                                    <b>5. Implementation of Improvements:</b> <br/>
                                    - Worked with BYJU'S management to implement recommended changes based on the NPS feedback.
                                    <br />
                                    - Conducted training sessions for customer support staff to enhance service quality and address identified gaps.
                                    <br/>
                                    - Collaborated with the content development team to refine educational materials and improve overall content delivery.
                                    <br/>
                                    - Worked with the technical team to improve platform usability and user interface design.
                                    <br/>
                                    <b>6. Continuous Monitoring:
                                    </b> <br/>
                                    - Set up a continuous feedback loop using our NPS software to regularly measure user satisfaction and track the impact of implemented changes.
                                    <br />
                                    - Provided ongoing support to BYJU'S to help them respond to new feedback and make continuous improvements.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Initial NPS Score:</b> <br/> 
                                    - BYJU'S initial NPS score was 50, indicating a satisfactory level of user satisfaction but with room for improvement.<br />
                                    <b> 2. Post-Implementation NPS Score:</b> <br/>
                                    - After six months of implementing the recommended changes,   
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           BYJU'S NPS score increased to 75, reflecting a substantial improvement in user satisfaction. <br/>
                                           <b> 3. User Feedback:</b> <br />
                                           - Positive feedback increased, with many users highlighting improvements in content quality, platform usability, and customer support. <br/>
                                           - Negative feedback decreased, particularly regarding issues that were addressed, such as navigation difficulties and content relevance. <br/>
                                           <b> 4. Business Impact:</b> <br />
                                           - Increased user loyalty, evidenced by a higher number of active users and positive reviews.<br/>
                                           - Improved user engagement, with more users spending longer periods on the platform and engaging with more content. <br/>
                                           - Enhanced brand reputation, leading to an increase in new user registrations driven by positive word-of-mouth referrals.
                                           <br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By leveraging our NPS measurement software and implementing targeted improvements based on user feedback, BYJU'S successfully enhanced its overall user satisfaction and achieved a higher NPS score. This case study demonstrates the value of regular user feedback and the positive impact of data-driven improvements on user engagement and business performance.</span>
                </div>
            </div>
            <CommonCards />
        </div>
    )
}
