import { Link } from "react-router-dom";

export default function TemplateSmallScreen ({ handleClickSidebar }) {
    function handleAllTemplates(){
      handleClickSidebar();
      // navigate("/templates") // it didn't work due to position fixed
    //   window.location.href = '/templates'; 
    
    }
  return(
    <div className="templet_dropdown_smallscrn">
    <div className="template_row_small">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div className="template_row_small">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div className="template_row_small">
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
      <div className="template_box">
        <h2>Net Promoter Score</h2>
        <p><span><i class="fa-solid fa-thumbs-up"></i></span>Measure customer satisfaction and loyality for your business</p>
      </div>
    </div>
    <div>
      <div className="template_down">
        <Link onClick={handleAllTemplates} to="/templates"><i class="fa-solid fa-list"></i>See all templates</Link>
        </div>
    </div>
    </div>
  )
  }