import React, { useEffect, useState } from 'react';
import { Col, Table,Alert } from 'react-bootstrap';
import ClientUserAddModal from './ClientUserAddModal';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import { useSelector } from 'react-redux';
import { useFetchAuthCondition } from '../../../utils/hooks/useFetchAuthCondition';
import UserByStore from './UserByStore';
import loadingimg from "../../../images/loading.avif";

const ClientUserList = () => {
    const [addUserModal, setAddUserModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [modalTitle, setModalTitle] = useState("Add User");
    const [assignModalTitke,setAssignModalTitle] = useState("Assign Stores")
    const [assignModal,setAssignModal] = useState(false);
    const [assignId,setAssignId] = useState(null);
    const clientuserRedux =useSelector((store) => store.clientuseradd);
    const clientId = localStorage.getItem("client_id");
    const {data:userData, isLoading: userDataLoading, getApiData} = useFetchAuthCondition(`client/${clientId}/client_user/`,[clientuserRedux]);
    const [message,setMessage] = useState("");
    const [showAlert,setShowalert] = useState(false);
    // useEffect(()=>{
    //     getApiData(`client/${clientId}/client_user/`)
    // },[clientuserRedux])
    
    const handleAddUserModal = () =>{
   setAddUserModal(true);
   setModalTitle("Add User");
   setSelectedUserId(null);
    }

    const handleUserEditModal = (userId) => {
        setSelectedUserId(userId);
        setModalTitle("Edit User");
        setAddUserModal(true);
      }
      const handleUserByStoreAdd = (userId) => {
        console.log("37777",userId)
        setAssignId(userId);
        setAssignModalTitle("Assign Stores")
        setAssignModal(true);
      }
  return (
    <div>
          {showAlert && (
        <Alert
          variant={
            message === "User details is Added Successfully" || message === "User Details is Editted Successfully" ||  message === "Added Successfully"
              ? "success"
              : "danger"
          }
          onClose={() => setShowalert(false)}
          dismissible
          style={{ textAlign: "center" }}
        >
          {message}
        </Alert>
      )}
       <div className="d-flex project_setup_storeadd">
        <Col md="9">
          <h3>
            <b>Client Users</b>
          </h3>
        </Col>
        <Col md="3">
          <button
            className="btn btn-md text-center btn-size create_ac_btn"
            onClick={handleAddUserModal}
          >
            <i className="fa-solid fa-plus"></i> Add User
          </button>
          <ClientUserAddModal
           modalShow={addUserModal}
           setModalShow={setAddUserModal}
           editUserId = {selectedUserId}
           modalTitle={modalTitle}
           setMessage={setMessage}
           setShowalert={setShowalert}
          />
        </Col>
        </div>
        <div
                  className="dash_report_table table-responsive"
                  style={{ marginTop: "1rem", paddingTop: "0rem" }}
                >
                  {/* <Table className="text-left store_report_table_mobile pricing_table"> */}
                  <Table 
                  className=" store_report_table_mobile text-center">
                    <thead>
                      <tr className="ques_sec_row_thead">
                        <th className="fw-bold ">Full Name</th>
                        <th className="fw-bold">Email Address</th>
                        <th className="fw-bold">Client Admin</th>
                        <th className="fw-bold">Active</th>
                        <th className="fw-bold"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        userDataLoading ?(
                        <tr>
                        <td colSpan={5}>
                          <img
                            src={loadingimg}
                            alt="Loading..."
                            style={{ width: "150px" }}
                          />
                        </td>
                      </tr>
                        )
                        :
                      userData && userData.length > 0 ? (
                      userData
                          .map((user) => {
                            return (
                              <tr
                                className="ques_sec_row_tbody"
                                key={user?.id}
                                
                              >
                                <td className="fw-bold">{user?.full_name}</td>
                                <td className="fw-bold">{user?.user?.email}</td>
                                <td className="fw-bold">{user?.is_client_admin ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-xmark"></i>}</td>
                                <td className="fw-bold">{user?.user?.is_active ? <i className="fa-solid fa-check"></i> : <i className="fa-solid fa-xmark"></i>}</td>
                                <td
                                  className="fw-bold"
                                  style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    gap: ".5rem",
                                    // marginTop: "1rem",
                                  }}
                                >
                                  <button className="proof_tag_btn" 
                                  onClick={()=> handleUserEditModal(user?.id)}
                                  >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                  </button>
                                  {user?.is_client_admin ?
                                  null :
                                  <button className="proof_tag_btn" 
                                  onClick={()=> handleUserByStoreAdd(user?.id)}
                                  >
                                   <i className="fa-solid fa-location-dot"></i>
                                  </button>
                                   }
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Client Users for now...
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <UserByStore
                   modalShow={assignModal}
                   setModalShow={setAssignModal}
                   setAssignId={setAssignId}
                   assignUserId = {assignId}
                   modalTitle={assignModalTitke}
                   setMessage={setMessage}
                   setShowalert={setShowalert}
                  />
                </div>
    </div>
  )
}

export default ClientUserList
