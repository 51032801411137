// import React,{useEffect, useState} from 'react'
// import { Modal, Row, Col } from 'react-bootstrap'
// import { postAddClientUser, postAssignClientUserByStore } from '../../../utils/services/PostdataHandle';
// import { addClientUserData } from '../../../utils/reduxcomponents/clientUserAddSlice';
// import { useDispatch } from 'react-redux';
// import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
// import { useFetchAuthCondition } from '../../../utils/hooks/useFetchAuthCondition';
// import loadingImg from "../../../images/loading.avif";
// import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';

// const UserByStore = ({modalShow,setModalShow,modalTitle,setAssignId,assignUserId,setMessage,setShowalert}) => {
//     const token = localStorage.getItem("token");
//     const clientId = localStorage.getItem("client_id");
//     const [hasError, setHasError] = useState(false);
//     const dispatch = useDispatch();
//   const [loadingButton, setLoadingButton] = useState(false);
//   const {data:userDetailData, getApiData, error:userDetailError} = useFetchinFunction();
//   const {data: storeDetailData, getApiData:getStoreApiData} = useFetchinFunction() ;
//   const {data: stores, getApiData:getStores} = useFetchinFunction() ;
//     const [formData, setFormData] = useState({
//         stores_list: []
//     });
//     const [errorMessages, setErrorMessages] = useState({
//         stores_listError: "",
//     });
// console.log("23333333",userDetailData)
//     useEffect(()=>{
//         console.log("2555",assignUserId)
//         if(assignUserId){
//             getApiData(`client/client_user/${assignUserId}`);
//             setFormData({  stores_list: []})
//             getStoreApiData(`client/store_get/${clientId}`);
//             getStores(`client/client_user/${assignUserId}/assign_stores/`)
//         }
//           },[assignUserId])

//   useEffect(()=>{
//     console.log("399999",stores)
//     if (stores && stores?.store_list?.length > 0) {
//         const storeIds = stores?.store_list.filter(store => store.present).map(store => store.id);
//         setFormData({ stores_list: storeIds });
//     }
//   },[stores])
//           const handleChange = (e) => {
//             const { value, checked } = e.target;
//             setFormData((prevState) => {
//                 const stores_list = checked
//                     ? [...prevState.stores_list, +value]
//                     : prevState.stores_list.filter((id) => id !== value);
//                 return { ...prevState, stores_list };
//             });
//         };
    
//       const handleSubmit = (e) => {
//         console.log(formData);
//         e.preventDefault();
//         let hasErrors = false;
    
//         let payload = {
//           stores_list : formData?.stores_list,
//         };
    
//         setLoadingButton(true);
//         postAssignClientUserByStore(
//         //   `client/client_user/${editUserId ? `${editUserId}` : ""}`,
//         `client/client_user/${assignUserId}/assign_stores/`,
//           payload,token)
//           .then((response) => {
//             if (!response.ok) {
//               throw new Error(`HTTP error! status: ${response.status}`);
//             }
//             return response.json();
//           })
//           .then((data) => {
        
//             setFormData({});
//             setModalShow(false);
//             dispatch(addClientUserData(data));
//             setShowalert(true);
//             setMessage("Added Successfully");
//             setTimeout(()=>{
//                 setShowalert(false);
//                 setMessage("");
//               },1500)
//             // setMessage(editUserId ? "User Details is Editted Successfully" :"User details is Added Successfully");
//           })
//           .catch((error) => {
//             console.log(error);
//             setShowalert(true);
//             setMessage("Something went wrong. Please try again later.");
//             setTimeout(()=>{
//               setShowalert(false);
//               setMessage("");
//             },1500)
//           })
//           .finally(() => {
//             setLoadingButton(false);
//           });
//       };
//       const hideModal = () => {
//         setModalShow(false);
//         setAssignId(null);
//       }
//   return (
//     <Modal
//     show={modalShow}
//     // centered
//     size="lg"
//      dialogClassName="modal-dialog-scrollable"
//         className=' p-6 modal_mobile'
//         // onHide={() => setModalShow(false)}
//         onHide={hideModal}
//   >
//     <div
//     className="modal_inner_main"
//     >
//       <div
//         style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }}
//         onClick={() => setModalShow(false)}
//       >
//         <i className="fa-solid fa-x"></i>
//       </div>
//       <form onSubmit={handleSubmit}>
//         <Col>
//           <Row>
//             <h5 className="setup_regs_modal_heading">{modalTitle}</h5>
//           </Row>
         
//           {Object.entries(userDetailData).length>0 ?
//                 <>
//                   <Row>
//             <Col md="12">
//              <h6>Full Name: <span className='fw-bold'>{userDetailData?.full_name}</span></h6>
//             </Col>
//           </Row>
//           <Row>
//             <Col md="12">
//              <h6>Email: <span className='fw-bold'>{userDetailData?.user?.email}</span></h6>
//             </Col>
//           </Row>
//                 </>
//                 :
//                 <div className='getStarted'>
//                 <img src={loadingImg} alt="Loading..." style={{ width: "100px" }} />
//             </div>
//             }
//              <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:"1rem", flexWrap:"wrap"}}>
//            {Array.isArray(storeDetailData) && storeDetailData.map((store) => (
//            <>
//             <input 
//             type="checkbox" 
//             style={{height:"20px", width:"20px"}} 
//             id={store?.id} 
//             name="stores_list" 
//             value={store?.id} 
//             checked={formData?.stores_list?.includes(store.id)}
//             onChange={handleChange}/>
//             <label htmlFor={store?.id} style={{ cursor: "pointer" }}>{store?.name}, {store?.city?.name}</label>
//             </>
//           ))}
//            </p>
//           <div className="d-flex justify-content-center align-items-center">
//             {loadingButton ? (
//               <button
//                 className="btn mb-5 btn-md text-center btn-size create_ac_btn"
//                 type="button"
//                 disabled
//                 style={{ fontSize: "1rem", background: "#007DC1", color: "white" }}
//               >
//                 <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
//                 Loading...
//               </button>
//             ) : (
//               <button className="btn mt-3 btn-md text-center btn-size create_ac_btn">
//                 Save
//               </button>
//             )}
//           </div>
//         </Col>
//       </form>
//     </div>
//   </Modal>
//   )
// }

// export default UserByStore


import React, { useEffect, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { postAddClientUser, postAssignClientUserByStore } from '../../../utils/services/PostdataHandle';
import { addClientUserData } from '../../../utils/reduxcomponents/clientUserAddSlice';
import { useDispatch } from 'react-redux';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';
import loadingImg from "../../../images/loading.avif";

const UserByStore = ({ modalShow, setModalShow, modalTitle, setAssignId, assignUserId, setMessage, setShowalert }) => {
    const token = localStorage.getItem("token");
    const clientId = localStorage.getItem("client_id");
    const [hasError, setHasError] = useState(false);
    const dispatch = useDispatch();
    const [loadingButton, setLoadingButton] = useState(false);
    const { data: userDetailData, getApiData } = useFetchinFunction();
    const { data: storeDetailData, getApiData: getStoreApiData } = useFetchinFunction();
    const { data: stores, getApiData: getStores } = useFetchinFunction();
    const [formData, setFormData] = useState({
        stores_list: []
    });
    const [errorMessages, setErrorMessages] = useState({
        stores_listError: "",
    });

    useEffect(() => {
        if (assignUserId) {
            getApiData(`client/client_user/${assignUserId}`);
            setFormData({ stores_list: [] });
            getStoreApiData(`client/store_get/${clientId}`);
            getStores(`client/client_user/${assignUserId}/assign_stores/`);
        }
    }, [assignUserId]);

    useEffect(() => {
          if (stores && stores?.store_list?.length > 0) {
        const storeIds = stores?.store_list.filter(store => store.present).map(store => store.id);
        setFormData({ stores_list: storeIds });
    }
    }, [stores]);

    const handleChange = (e) => {
        const { value, checked } = e.target;
        setFormData((prevState) => {
            const stores_list = checked
                ? [...prevState?.stores_list, +value]
                : prevState?.stores_list?.filter((id) => id !== +value);
            return { ...prevState, stores_list };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoadingButton(true);
        const payload = {
            stores_list: formData.stores_list,
        };

        postAssignClientUserByStore(`client/client_user/${assignUserId}/assign_stores/`, payload, token)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setFormData({});
                setAssignId(null);
                setModalShow(false);
                dispatch(addClientUserData(data));
                setShowalert(true);
                setMessage("Added Successfully");
                setTimeout(() => {
                    setShowalert(false);
                    setMessage("");
                }, 1500);
            })
            .catch((error) => {
                console.error(error);
                setShowalert(true);
                setMessage("Something went wrong. Please try again later.");
                setTimeout(() => {
                    setShowalert(false);
                    setMessage("");
                }, 1500);
            })
            .finally(() => {
                setLoadingButton(false);
            });
    };

    const hideModal = () => {
        setModalShow(false);
        setAssignId(null);
    };

    return (
        <Modal
            show={modalShow}
            size="lg"
            dialogClassName="modal-dialog-scrollable"
            className='p-6 modal_mobile'
            onHide={hideModal}
        >
            <div className="modal_inner_main">
                <div
                    style={{ position: "absolute", right: "2rem", top: "2rem", cursor: "pointer" }}
                    onClick={hideModal}
                >
                    <i className="fa-solid fa-x"></i>
                </div>
                <form onSubmit={handleSubmit}>
                    <Col>
                        <Row>
                            <h5 className="setup_regs_modal_heading">{modalTitle}</h5>
                        </Row>
                        {Object.entries(userDetailData).length > 0 ?
                            <>
                                <Row>
                                    <Col md="12">
                                        <h6>Full Name: <span className='fw-bold'>{userDetailData?.full_name}</span></h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <h6>Email: <span className='fw-bold'>{userDetailData?.user?.email}</span></h6>
                                    </Col>
                                </Row>
                            </>
                            :
                            <div className='getStarted'>
                                <img src={loadingImg} alt="Loading..." style={{ width: "100px" }} />
                            </div>
                        }
                               <p style={{display:"flex",justifyContent:"flex-start", alignItems:"center", gap:"1rem", flexWrap:"wrap", marginTop:"3rem"}}>
                        {Array.isArray(storeDetailData) && storeDetailData.map((store) => (
                        <div  key={store?.id}>
                                <input
                                    type="checkbox"
                                    style={{ height: "20px", width: "20px" }}
                                    id={store.id}
                                    name="stores_list"
                                    value={store.id}
                                    checked={formData?.stores_list?.includes(store?.id)}
                                    onChange={handleChange}
                                />
                                <label htmlFor={store.id} style={{ cursor: "pointer", marginLeft:".5rem", fontWeight:"bold"}}>{store?.name}, {store?.city?.name}</label>
                                </div>
                           
                        ))}
                         </p>
                        <div className="d-flex justify-content-center align-items-center">
                            {loadingButton ? (
                                <button
                                    className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                                    type="button"
                                    disabled
                                    style={{ fontSize: "1rem", background: "#007DC1", color: "white" }}
                                >
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{ marginRight: ".5rem" }}></span>
                                    Loading...
                                </button>
                            ) : (
                                <button className="btn mt-3 btn-md text-center btn-size create_ac_btn">
                                    Save
                                </button>
                            )}
                        </div>
                    </Col>
                </form>
            </div>
        </Modal>
    );
}

export default UserByStore;
