import { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function HeaderMenu({templateShow, setTemplateShow}) {
    const navigate = useNavigate();
    const [dropdownVisible, setDropdownVisible] = useState(true);
    const [dropdownVisible1, setDropdownVisible1] = useState(true);
  
    function handleCategoryChange(categoryId, url) {
      localStorage.setItem('selectedCategoryId', categoryId);
      navigate(`/${url}`);
      setDropdownVisible(false);
      setDropdownVisible1(false);
    }
    function handleTemplateOpen (){
      setTemplateShow(!templateShow);
    }
    // function handleTemplateClose (){
    //   setTemplateShow(true);
    // }
    return (
      <ul className="nav menu-nav">
  
        <li className="nav-item product_head" style={{ position: "rtelative" }}
        //  onMouseEnter={handleTemplateOpen}
         onClick={handleTemplateOpen}
        //  onMouseLeave={handleTemplateOpen}
        >
          <p className="nav-link" style={{marginBottom:"0rem",cursor:"pointer"}}> Templates   {templateShow ? <i className="fa-solid fa-chevron-up"></i> : <i className="fa-solid fa-chevron-down"></i>} </p>
        </li>
        <li className="nav-item product_head" style={{ position: "rtelative" }} 
         onMouseEnter={() => setDropdownVisible1(true)}
         onMouseLeave={() => setDropdownVisible1(false)}
        >
          <p className="nav-link" style={{marginBottom:"0rem",cursor:"pointer"}}> Solutions </p>
          { dropdownVisible1 &&  <ul className="product_dropdown">
            <li className="dropdown_list" onClick={() => setDropdownVisible1(false)}>
              <Link to="/Net-promoter-Score">Net promoter Score</Link>
            </li>
            <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
              <Link to="/Customer-Satisfaction-Score">Customer Satisfaction Score</Link>
            </li>
            <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
            <Link to="/Customer-Effort-Score">Customer Effort Score</Link>
          </li>
          </ul>
  }
        </li>
        <li className="nav-item product_head" style={{ position: "rtelative" }} 
         onMouseEnter={() => setDropdownVisible1(true)}
         onMouseLeave={() => setDropdownVisible1(false)}
        >
          <p className="nav-link" style={{marginBottom:"0rem",cursor:"pointer"}}> Resources </p>
          { dropdownVisible1 &&  <ul className="product_dropdown">
            <li className="dropdown_list" onClick={() => setDropdownVisible1(false)}>
              <Link to="/blog">Blog</Link>
            </li>
            <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
              <Link to="/case-studies">Case Studies</Link>
            </li>
            <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
            <Link to="/terms-and-conditions">Terms & Conditions</Link>
          </li>
          <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
          <Link to="/refund-policy">Refund Policy</Link>
        </li>
        <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
          <Link to="/privacy-policy">Privacy Policy</Link>
        </li>
        <li className="dropdown_list"  onClick={() => setDropdownVisible1(false)}> 
        <Link to="/frequently-asked-questions">FAQs</Link>
      </li>
          </ul>
  }
        </li>
        <li className="nav-item">
        <Link to="/pricing" className="nav-link">
            Pricing
          </Link>
        </li>
        <li className="nav-item">
        <Link to="/contact-us" className="nav-link">
            Contact Us
          </Link>
        </li>
      </ul>
    )
  }