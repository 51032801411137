import React, { useState, useEffect, useRef } from "react";
import { Col, Table, Modal, Row, Alert } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import base_url from "../../../utils/config";
import { useDispatch, useSelector } from "react-redux";
import { setAuditCountData } from "../../../utils/orderdataslice";
import {
  postStoreData,
  deleteStoreData,
  updateStoreData,
  postUploadStoreData,
} from "../../../utils/services/PostdataHandle";
import loading from "../../../images/loading.avif";
import "../../../styles/dashboardbody.css";
import BulkModal from "./BulkModal";
import AddEditStoreModal from "./AddEditStoreModal";

export default function Stores() {
  const location = useLocation();
  const id = location.state;
  const fileInputRef = useRef(null);
  const navigate = useNavigate();
  const existingUser = localStorage.getItem("user_id");
  const existingClient = localStorage.getItem("client_id");
  const auditcountRedux = useSelector(
    (store) => store.orderdata.secondPage.totalAuditsPerStore
  );
  // const auditcountReduxwithId = useSelector((store) => store.orderdata.secondPage.totalAuditsPerStoreWithStoreId)
  const [storeUrl, setStoreUrl] = useState(
    base_url + `client/store_get/${existingClient}`
  );
  const cartItems = useSelector((store) => store.cart);
  const dispatch = useDispatch();
  const [storeModalShow, setStoreModalShow] = useState(false);
  const [bulkStoreModalShow, setBulkStoreModalShow] = useState(false);
  // const [responsesLeft, setResponsesLeft] = useState(cartItems.totalQuantity);
  const [storesList, setStoresList] = useState([]);
  const [fetchedStores, setFetchedStores] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [audit_count, setAudit_count] = useState(auditcountRedux || {});
  // const [audit_count, setAudit_count] = useState(auditcountReduxwithId || {});
  const [auditcountAlert, setAuditcountAlert] = useState(false);
  const [auditCountMoreAlert, setAuditCountMoreAlert] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    city: "",
    pincode: "",
    name: "",
    address: "",
    phone: "",
    map_location_link: "",
  });
  const [errorMessages, setErrorMessages] = useState({
    countryError: "",
    stateError: "",
    cityError: "",
    pincodeError: "",
    nameError: "",
    audit_countError: "",
    addressError: "",
    phoneError: "",
    map_location_linkError: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [showAddAlert, setShowAddAlert] = useState(false);
  const [showAddFailAlert, setShowAddFailAlert] = useState(false);
  const [showEditAlert, setShowEditAlert] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDeleteFailAlert, setShowDeleteFailAlert] = useState(false);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedStoreId, setSelectedStoreId] = useState(null);
  const [isFileAdded, setIsFileAdded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loadingButton, setloadingButton] = useState(false);
  const [searchFetchedCountryData, setSearchFetchedCountryData] = useState({});
  const [searchFetchedData, setSearchFetchedData] = useState({});
  const [searchFetchedCityData, setSearchFetchedCityData] = useState({});
  const [searchNamenotFound, setSearchNameNotFound] = useState("");
  const [searchStoreCount, setSearchStoreCount] = useState(0);
  const [uploadSheetError, setUploadSheetError] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 869) {
        window.location.reload();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // ------------------------------------DURING ALERT AUTOMATIC SCROLL TO TOP-------------------
  useEffect(() => {
    if (
      showAddAlert ||
      showAddFailAlert ||
      showDeleteAlert ||
      showDeleteFailAlert ||
      showEditAlert
    ) {
      window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }
  }, [
    showAddAlert,
    showAddFailAlert,
    showDeleteAlert,
    showDeleteFailAlert,
    showEditAlert,
  ]);
  //------------------------------------AUDIT COUNT VALIDATION----------------------------------
  useEffect(() => {
    const totalAuditsPerStore = {};
    storesList.forEach((store, idx) => {
      totalAuditsPerStore[idx] = audit_count[idx] || 0;
    });

    let totalAudits = 0;
    Object.values(totalAuditsPerStore).forEach((item) => {
      totalAudits += +item;
    });
   
  }, [audit_count]);

  const handleAuditCountChange = (e, index) => {
    const { value } = e.target;
    setAudit_count((prevAuditCounts) => ({
      ...prevAuditCounts,
      [index]: value,
    }));
    dispatch(
      setAuditCountData({
        totalAuditsPerStore: { ...audit_count, [index]: value },
      })
    );
  };

  //------------------------------ON MOUNT GETTING STATE AND STORES DETAILS TO SHOW--------------------
  useEffect(() => {
    getApiData();
    getStoreData();
  }, [isFileAdded]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    getcountryApiData();
  
    getSearchCountryData(`client/store/country_list`);
    getSearchStateData(`client/store/state_list`);
	getSearchCityData(`client/store/city_list`)
   
  
  }, []);
  useEffect(() => {
    // getSearchCountryData(`client/store/country_list`);
	// getSearchStateData(`client/store/state_list`);
	// getSearchCityData(`client/store/city_list`)
  }, [fetchedStores]);
  const getApiData = async () => {
    try {
      const response = await fetch(base_url + "manager/state");
      if (response.ok) {
        const result = await response.json();
        const stateDataArray = Object.entries(result).map(
          ([stateCode, stateName]) => ({
            stateCode,
            stateName,
          })
        );
        setStateData(stateDataArray);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  };
  const getcountryApiData = async () => {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(base_url + "client/country_list/", options);
      if (response.ok) {
        const result = await response.json();
        const countryDataArray = Object.entries(result).map(
          ([countryCode, countryName]) => ({
            countryCode,
            countryName,
          })
        );
        setCountryData(countryDataArray);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  };
  async function getSearchStateData(url) {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setSearchFetchedData(result);
        // setSearchFetchedCityData(result);
        if (result.error) {
          alert("error");
        }
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  }
  async function getSearchCityData(url) {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setSearchFetchedCityData(result);
        if (result.error) {
          alert("error");
        }
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  }
  //---------------------------------AFTER FORM SUBMISSION, GETTING THE STORE DATA-----------------

  useEffect(() => {
    if (formSubmitted) {
      getStoreData();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const token = localStorage.getItem("token");

  const getStoreData = async () => {
    setLoadingData(true);
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(storeUrl, options);
      if (response.ok) {
        const result = await response.json();
        // setFetchedStores(result);

        const sortedStores = result.sort((a, b) => a.id - b.id);
        setFetchedStores(sortedStores);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    } finally {
      setLoadingData(false);
    }
  };
  // -------------------------------FETCHING STATES WITH COUNTRY CODE--------------------

  const fetchStateData = async (countryCode) => {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(
        `${base_url}client/state_list/${countryCode}/`,
        options
      );
      if (response.ok) {
        const result = await response.json();
        const stateDataArray = Object.entries(result).map(
          ([stateCode, stateName]) => ({
            stateCode,
            stateName,
          })
        );
        setStateData(stateDataArray);
        return result;
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
        return [];
      }
    } catch (error) {
      console.error("An error occurred while fetching state data:", error);
      return [];
    }
  };

  // -------------------------------FETCHING CITIES WITH STATE CODE--------------------

  const fetchCityData = async (stateCode) => {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(
        `${base_url}client/city_list/${stateCode}`,
        options
      );
      if (response.ok) {
        const result = await response.json();
        return result;
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
        return [];
      }
    } catch (error) {
      console.error("An error occurred while fetching city data:", error);
      return [];
    }
  };

  // -------------------------------------ONCHANGE FUNCTIONALITY-------------------------------------

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
   setHasError(false);

    if (name === "state") {
      fetchCityData(value)
        .then((cities) => {
          setCityData(cities);
        })
        .catch((error) => {
          console.error("Error fetching city data:", error);
        });
    }
    if (name === "country") {
      fetchStateData(value)
        .then((states) => {
          // setStateData(states);
          const stateDataArray = Object.entries(states).map(
            ([stateCode, stateName]) => ({
              stateCode,
              stateName,
            })
          );
          setStateData(stateDataArray);
        })
        .catch((error) => {
          console.error("Error fetching state data:", error);
        });
    }
  };

  //  ------------------------------------------FORM SUBMISSION ADD---------------------------

  const handleSubmit = (e) => {
    e.preventDefault();
	const requiredFields = ['name', 'country', 'state', 'city', 'address'];
	let hasError = false;
  
	requiredFields.forEach((field) => {
	  if (!formData[field]) {
		setErrorMessages((prevErrors) => ({
		  ...prevErrors,
		  [`${field}Error`]: `${field.charAt(0).toUpperCase() + field.slice(1)} can't be empty`,
		}));
		hasError = true;
	  }
	});
	if (hasError) {
	  setHasError(true);
	  return;
	}

    if (
      selectedStoreId === null &&
      formData.country &&
      formData.state &&
      formData.city &&
      formData.name &&
      formData.address
    ) {
      setloadingButton(true);
      const newStore = {
        ...formData,
        audit_count: audit_count[storesList.length] || "",
      };
      setStoresList((prevStoresList) => [...prevStoresList, newStore]);
      setAudit_count((prevAuditCounts) => ({
        ...prevAuditCounts,
        [storesList.length]: "",
      }));
      setSelectedCountry(formData.country);
      setSelectedState(formData.state);
      setSelectedCity(formData.city);
      const requestData = {
        country: formData.country,
        state: formData.state,
        city: +formData.city,
        pincode: formData.pincode,
        name: formData.name,
        address: formData.address,
        phone: formData.phone,
        map_location_link: formData.map_location_link,
        client: existingClient,
      };
      const promise = postStoreData(requestData, `client/store/`);
      promise
        .then((response) => response.json())
        .then((data) => {
          setHasError(false);
          if (!data.detail) {
            setShowAddAlert(true);
            setTimeout(() => {
              setShowAddAlert(false);
            }, 1500);
          } else {
            setShowAddFailAlert(true);
            setTimeout(() => {
              setShowAddFailAlert(false);
            }, 1500);
          }

          setErrorMessage(data);
          setFormData({});
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.error("Error:", error);
        })
        .finally(() => {
          setloadingButton(false);
        });
      setStoreModalShow(false);
    }
  };

  const handleAddStore = () => {
    setSelectedStoreId(null);
    setFormData({});
    setStoreModalShow(true);
  };

  // ------------------------------DELETE FUNCTIONALITY-----------------------------------

  const handleDeleteStore = (storeId) => {
    const selectedStore = fetchedStores.find((store) => store.id === storeId);
    if (selectedStore) {
      const promise = deleteStoreData(`client/store/${selectedStore.id}`);
      promise
        .then((response) => {
          if (response.status === 200 || response.status === 204) {
            const updatedFetchedStores = fetchedStores.filter(
              (store) => store.id !== selectedStore.id
            );
            setFetchedStores(updatedFetchedStores);
            setShowDeleteAlert(true);
            setTimeout(() => {
              setShowDeleteAlert(false);
            }, 1000);
          } else {
            setShowDeleteFailAlert(true);
            setTimeout(() => {
              setShowDeleteFailAlert(false);
            }, 1000);
          }
        })
        .catch(() => {
          setShowDeleteFailAlert(true);
          setTimeout(() => {
            setShowDeleteFailAlert(false);
          }, 1000);
          // alert("Deletion failed");
        });
    }
  };

  // -------------------------------------EDIT FUNCTIONALITY------------------------------

  const handleEditStore = (store) => {
    setSelectedStoreId(store.id);
    getEditStoreData(store.id);
    setStoreModalShow(true);
  };

  const getEditStoreData = async (storeId) => {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };
      const response = await fetch(
        base_url + `client/store/${storeId}`,
        options
      );
      if (response.ok) {
        const result = await response.json();
        fetchCityData(result.city.state)
          .then((cities) => {
            setCityData(cities);
          })
          .catch((error) => {
            console.error("Error fetching city data:", error);
          });
        fetchStateData(result.city.country);
        setFormData({
          country: result.city.country,
          state: result.city.state,
          city: result.city.id,
          pincode: result.pincode,
          name: result.name,
          address: result.address,
          phone: result.phone,
          map_location_link: result.map_location_link,
        });
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  };
  const handleUpdateStore = () => {
    setloadingButton(true);
    const requestData = {
      country: formData.country,
      state: formData.state,
      city: formData.city,
      pincode: formData.pincode,
      name: formData.name,
      address: formData.address,
      phone: formData.phone,
      map_location_link: formData.map_location_link,
      client: existingClient,
    };

    const promise = updateStoreData(
      requestData,
      `client/store/${selectedStoreId}`
    ); // Use the updateStoreData function
    promise
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setShowEditAlert(true);
          setTimeout(() => {
            setShowEditAlert(false);
          }, 1500);
          setFormSubmitted(true);
        } else {
          alert("Not Editted");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setloadingButton(false);
      });

    setStoreModalShow(false);
  };

  const [searchDetailData, setSearchDetailData] = useState([]);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // if (value === "" && (name === 'state' || name === 'name')) {
    // 	getSearchApiData(`client/mp/storesearch?status=ALL`);
    // }
    if (value === "" && name === "country") {
      getSearchApiData(`client/store/search?status=ALL`);
    }
    if (value === "" && name === "state") {
      getSearchApiData(`client/store/search?status=ALL`);
    }
    if (value !== "" && !formData.state && name === "city") {
      getSearchApiData(`client/store/search?city=${value}`);
    }
    if (value !== "" && !formData.country && name === "city") {
      getSearchApiData(`client/store/search?city=${value}`);
    }
    if (value !== "" && formData.state && name === "city") {
      getSearchApiData(
        `client/store/search?state=${formData.state}&city=${value}`
      );
    }
    if (value !== "" && formData.country && name === "city") {
      getSearchApiData(
        `client/store/search?country=${formData.country}&city=${value}`
      );
    }
    if (formData.state && value === "" && name === "city") {
      getSearchApiData(`client/store/search?state=${formData.state}`);
    }
    if (formData.country && value === "" && name === "city") {
      getSearchApiData(`client/store/search?state=${formData.country}`);
    }
    if (!formData.state && value === "" && name === "city") {
      getSearchApiData(`client/store/search?status=ALL`);
    }
    if (name === "country") {
      getSearchApiData(`client/store/search?country=${value}`);
      getSearchStateData(`client/store/country_list?country_code=${value}`);
      // getSearchStateData(`client/mp/storecountrylist?country_code=${value}`);
      // getSearchCityData(`client/mp/storestatelist?country_code=${value}`)
    }
    if (name === "state") {
      getSearchApiData(`client/store/search?state=${value}`);
      getSearchCityData(`client/store/state_list?state_code=${value}`);
    }

    if (
      value !== "" &&
      !formData.country &&
      !formData.state &&
      !formData.city &&
      name === "name"
    ) {
      getSearchApiData(`client/store/search?name=${value}`);
    }
    if (value !== "" && formData.country && !formData.city && name === "name") {
      getSearchApiData(
        `client/store/search?country=${formData.country}&name=${value}`
      );
    }
    if (
      value !== "" &&
      formData.country &&
      formData.state &&
      !formData.city &&
      name === "name"
    ) {
      getSearchApiData(
        `client/store/search?country=${formData.country}&state=${formData.state}&name=${value}`
      );
    }
    if (
      value !== "" &&
      formData.country &&
      !formData.state &&
      formData.city &&
      name === "name"
    ) {
      getSearchApiData(
        `client/store/search?country=${formData.country}&city=${formData.city}&name=${value}`
      );
    }
    if (value !== "" && formData.state && !formData.city && name === "name") {
      getSearchApiData(
        `client/store/search?state=${formData.state}&name=${value}`
      );
    }
    if (value !== "" && formData.city && !formData.state && name === "name") {
      getSearchApiData(
        `client/store/search?city=${formData.city}&name=${value}`
      );
    }
    if (value !== "" && formData.city && formData.state && name === "name") {
      getSearchApiData(
        `client/store/search?state=${formData.state}&city=${formData.city}&name=${value}`
      );
    }
    if (formData.state && !formData.city && value === "" && name === "name") {
      getSearchApiData(`client/store/search?state=${formData.state}`);
    }
    if (
      formData.country &&
      !formData.state &&
      !formData.city &&
      value === "" &&
      name === "name"
    ) {
      getSearchApiData(`client/store/search?country=${formData.country}`);
    }
    if (formData.city && !formData.state && value === "" && name === "name") {
      getSearchApiData(`client/store/search?city=${formData.city}`);
    }
    if (formData.city && formData.state && value === "" && name === "name") {
      getSearchApiData(
        `client/store/search?state=${formData.state}&city=${formData.city}`
      );
    }
    if (
      !formData.country &&
      !formData.state &&
      !formData.city &&
      value === "" &&
      name === "name"
    ) {
      getSearchApiData(`client/store/search?status=ALL`);
    }
  };
  const getSearchApiData = async (url) => {
    setLoadingData(true);
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(base_url + url, options);

      if (response.status === 404) {
        const result = await response.json();
        if (
          result.error === "No records found for the given query parameters."
        ) {
          setSearchNameNotFound(result.error);
          setTimeout(() => {
            setSearchNameNotFound("");
          }, 1800);
        }
      }

      if (response.ok) {
        const result = await response.json();
        if (result.error) {
          alert("error");
        }
        // setFetchedStores(result);
        const sortedStores = result?.data.sort((a, b) => a.id - b.id);
        setFetchedStores(sortedStores);
        setSearchStoreCount(result?.store_count);
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    } finally {
      setLoadingData(false);
    }
  };
  async function getSearchCountryData(url) {
    try {
      const headers = {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      };

      const options = {
        method: "GET",
        headers: headers,
      };

      const response = await fetch(base_url + url, options);

      if (response.ok) {
        const result = await response.json();
        setSearchFetchedCountryData(result);
        // setSearchFetchedData(result);
        // setSearchFetchedCityData(result);
        if (result.error) {
          alert("error");
        }
      } else {
        console.error(
          "API request failed:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("An error occurred while fetching the API:", error);
    }
  }
  function handleBulkStoreClick() {
    setBulkStoreModalShow(true);
    setUploadSheetError("");
    setUploading(false);
  }
  return (
    <div>
      <div className="d-flex project_setup_storeadd">
        <Col md="8">
          <h5>
            <b>Store List</b>
          </h5>
        </Col>
        <Col md="4" className="store_uploadbtn_box">
          <button
            className="btn btn-md text-center btn-size bulk_upload_btn"
            onClick={handleBulkStoreClick}
          >
            Bulk Upload Store Data
          </button>
          <button
            className="btn btn-md text-center btn-size create_ac_btn"
            onClick={handleAddStore}
          >
            Add a Store
          </button>
        </Col>
      <AddEditStoreModal
	   storeModalShow={storeModalShow}
	   handleClose={() => setStoreModalShow(false)}
	   handleSubmit={handleSubmit}
	   handleChange={handleChange}
	   formData={formData}
	   errorMessages={errorMessages}
	   hasError={hasError}
	   countryData={countryData}
	   stateData={stateData}
	   cityData={cityData}
	   selectedStoreId={selectedStoreId}
	   loadingButton={loadingButton}
	   handleUpdateStore={handleUpdateStore}
	  />

        {/* ----------------------------------------------------------------------------------------------------- */}
        <BulkModal
          uploading={uploading}
          setUploading={setUploading}
          uploadSheetError={uploadSheetError}
          setUploadSheetError={setUploadSheetError}
          bulkStoreModalShow={bulkStoreModalShow}
          setBulkStoreModalShow={setBulkStoreModalShow}
          isFileAdded={isFileAdded}
          setIsFileAdded={setIsFileAdded}
        />
      
      </div>
      <div>
        <Col md="12">
          {isFileAdded && (
            <Alert
              variant="success"
              onClose={() => setIsFileAdded(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store Added Successfully
            </Alert>
          )}
          {showEditAlert && (
            <Alert
              variant="success"
              onClose={() => setShowEditAlert(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store is EDITTED successfully
            </Alert>
          )}
          {showAddAlert && (
            <Alert
              variant="success"
              onClose={() => setShowAddAlert(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store is ADDED successfully
            </Alert>
          )}
          {showAddFailAlert && (
            <Alert
              variant="danger"
              onClose={() => setShowAddFailAlert(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store could not be ADDED
            </Alert>
          )}
          {showDeleteAlert && (
            <Alert
              variant="success"
              onClose={() => setShowDeleteAlert(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store is DELETED successfully
            </Alert>
          )}
          {showDeleteFailAlert && (
            <Alert
              variant="danger"
              onClose={() => setShowDeleteAlert(false)}
              dismissible
              style={{ textAlign: "center" }}
            >
              Store is not DELETABLE now
            </Alert>
          )}
        </Col>
      </div>
      <div>
        <Col md="7">
          <p>
            When building a project,add new stores for the project or list from
            the following list of stores added. You can also bulk upload or add
            individual stores in the database for the next time you want to
            start a project
          </p>
        </Col>
      </div>
      {fetchedStores.length > 0 || searchStoreCount ? (
        <Row>
          <Col md="3">
            <p className="registration_form_content">
              <label style={{ fontWeight: "bold" }}>Country</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleSearchChange}
              >
                <option value="">All Country</option>
                {searchFetchedCountryData.countries
                  ? Object.keys(searchFetchedCountryData.countries).map(
                      (countryCode) => (
                        <option key={countryCode} value={countryCode}>
                          {searchFetchedCountryData.countries[countryCode]}
                        </option>
                      )
                    )
                  : null}
              </select>
            </p>
          </Col>
          <Col md="3">
            <p className="registration_form_content">
              <label style={{ fontWeight: "bold" }}>State</label>
              <select
                name="state"
                value={formData.state}
                onChange={handleSearchChange}
              >
                <option value="">All States</option>
                {searchFetchedData.states
                  ? Object.keys(searchFetchedData.states).map((stateCode) => (
                      <option key={stateCode} value={stateCode}>
                        {searchFetchedData.states[stateCode]}
                      </option>
                    ))
                  : null}
              </select>
            </p>
          </Col>
          <Col md="3">
            <p className="registration_form_content">
              <label style={{ fontWeight: "bold" }}>City</label>
              <select
                name="city"
                value={formData.city}
                onChange={handleSearchChange}
              >
                <option value="">All Cities</option>
                {searchFetchedCityData.cities
                  ? searchFetchedCityData.cities.map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))
                  : null}
              </select>
            </p>
          </Col>
          <Col md="3">
            <p className="registration_form_content">
              <label style={{ fontWeight: "bold" }}>Store Name</label>
              <input
                className="form-control mr-sm-2"
                type="search"
                placeholder="Search"
                aria-label="Search"
                name="name"
                onChange={handleSearchChange}
              />
            </p>
          </Col>
        </Row>
      ) : null}
      <div className="table-responsive" style={{ marginTop: "2rem" }}>
        <Table className="text-center table">
          <thead className="store_details_table_head">
            <tr>
              <th className="fw-bold">Sr. Number</th>
              <th className="fw-bold">Address</th>
              <th className="fw-bold">Country</th>
              <th className="fw-bold">State</th>
              <th className="fw-bold">City</th>
              <th className="fw-bold">Zip Code</th>
              <th className="fw-bold">Store Name</th>
              {/* <th className="fw-bold">Website</th> */}
              {/* <th className="fw-bold">Phone Number</th> */}
              <th className="fw-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            {loadingData ? (
              <tr>
                {/* <td colSpan="9" className="loading_cell_big"><img src={loading} alt="Loading..." height="180px" /></td>
						<td colSpan="5" className="loading_cell_small"><img src={loading} alt="Loading..." height="180px" /></td> */}
                <td colSpan="9">
                  <img src={loading} alt="Loading..." height="180px" />
                </td>
              </tr>
            ) : fetchedStores.length > 0 ? (
              fetchedStores.map((store, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{store?.address}</td>
                  <td>{store?.city?.country_name}</td>
                  <td>{store?.city?.state_name}</td>
                  <td>{store?.city?.name}</td>
                  <td>{store?.pincode ? store.pincode : "---"}</td>
                  <td>{store?.name}</td>
                  {/* <td>{store?.map_location_link ? store?.map_location_link : "---"}</td> */}
                  {/* <td>{store?.phone ? store.phone : "---"}</td> */}
                  <td>
                    <i
                      className="fa-solid fa-pen-to-square"
                      style={{ color: "green" }}
                      onClick={() => handleEditStore(store)}
                    ></i>{" "}
                    <span style={{ marginLeft: ".5rem" }}>
                      <i
                        className="fa-solid fa-trash"
                        style={{ color: "#ff6f6f" }}
                        onClick={() => handleDeleteStore(store?.id)}
                      ></i>
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="9" style={{ color: "red" }}>
                  No Store Found...
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
