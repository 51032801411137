import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Table, Col, Modal } from 'react-bootstrap';
import DetailsStepper from './DetailsStepper';
import base_url from '../../../utils/config';
import loadingimg from "../../../images/loading.avif";
import { getCarCost,getIncomeText,getGender } from '../../../utils/constant';
import pdfDummy from "./../../../images/pdfDownload.png";

export default function DetailsProject({project,setDetailsModalShow}) {
    const token = localStorage.getItem("token");
    const [selectedFile, setSelectedFile] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [fileVisibility, setFileVisibility] = useState({});
    const [viewModalShow, setViewModalShow] = useState(false);
    const [isAlignment, setIsAlignment] = useState(false);
    const arrays = {};
    const incomeOptionsarr = [];
    const carownedarr = [];
    const genderarr = [];
    const [occupationarr, setOccupationArr] = useState([]);
    const [interestarr, setInterestArr] = useState([]);
    const [incomearr, setIncomeArr] = useState([]);
    const [agerange, setAgeRange] = useState("");
    const [carownedarrfirst,setCarownedArrayFirst] = useState([]);
    const [genderArray,setGenderArray] = useState([]);
    useEffect(() => {
        if (project.audit_cycle_data.order.id) {
            getFileData(`client/mp_order/${project.audit_cycle_data.order.id}/attachment`)
        }
       setIsAlignment( project.audit_cycle_data.order.alignment_factors.some(factor => {
            if (Array.isArray(factor.value)) {
                return factor.value.length > 0;
            } else {
                return factor.value !== '';
            }
        }))
        project.audit_cycle_data.order.alignment_factors.forEach((factor) => {
            if (factor.type === 'str' && Array.isArray(factor.value)) {
              arrays[factor.key] = factor.value;
            }else if(factor.type === 'func'){
               setAgeRange(factor.value);
            }
          });
          const occupationArray = arrays["occupation"] || [];
          setOccupationArr(occupationArray);
          const interestArray = arrays["interest_area"] || [];
          setInterestArr(interestArray);
          const incomeArray = arrays["income"] || [];
          setIncomeArr(incomeArray);
          const carArray = arrays["car_cost"] || [];
          setCarownedArrayFirst(carArray);
          const genArr = arrays["gender"] || [];
          setGenderArray(genArr);
          
    }, [])


    const getFileData = async (url) => {
        try {
            const headers = {
                'Authorization': `Token ${token}`,
                'Content-Type': 'application/json'
            };

            const options = {
                method: 'GET',
                headers: headers
            };

            const response = await fetch(base_url + url, options);

            if (response.ok) {
                const result = await response.json();
               
                if (result.error) {
                    alert("error");
                }else if (Array.isArray(result) && result.length === 0) {
                    setSelectedFile([]);
                }
                else {
                    setUploading(true);
                        setSelectedFile([ {
                            attachmentId: result.id,
                            fileUrl: result.direct_url,
                            fileName: result.file_name,
                            fileType: result.mime_type,
                        }]);
                  
                }
                ;
            } else {
                console.error('API request failed:', response.status, response.statusText);
            }
        } catch (error) {
            console.error('An error occurred while fetching the API:', error);
        }finally {
            setUploading(false); 
        }
    };
    const toggleFileVisibility = (fileUrl) => {
        setFileVisibility((prevState) => ({
            ...prevState,
            [fileUrl]: !prevState[fileUrl],
        }));
    };

    // ------------------------------------------------------------------------------------

    for (let option of incomearr ){
        incomeOptionsarr.push(getIncomeText(option));
    }
    for (let option of carownedarrfirst) {
        carownedarr.push(getCarCost(option));
    }
    for (let option of genderArray) {
        genderarr.push(getGender(option));
    }
    return (
        <div>
            <div style={{ marginTop: "2rem" }}>
                <div style={{position:"relative"}}>
                <Col md="12" style={{ marginBottom: "1rem" }}>
                    <h4 style={{textAlign:"center"}}>Order Details</h4>
                </Col>
                   <div style={{position:"absolute",right:"2rem", top:".1rem", cursor:"pointer"}} onClick={() => setDetailsModalShow(false)}><i className="fa-solid fa-x"></i></div>
                </div>
                  <div className='final_check_container'>
                        <Col md="6" className='final_check_container_left' style={{alignItems:"flex-start"}}>
                            <h6>Estimated Date of Project Delivery : <b>{project?.audit_cycle_data?.audit_cycle?.start_date}</b></h6>
                            <h5><b>{project?.audit_cycle_data?.order?.solution?.name}</b></h5>
                            <div className='final_check_oneline'>
                                <h6>Total <b>{project?.audit_cycle_data?.audit_cycle?.audit_count} Audits</b> </h6>
                            </div>
                            <h6>Audience Demographics: 
                                <b>{isAlignment ?
                                <button
                                    style={{ background: "transparent", border: "none", outline: "none", color: "#007DC1", fontWeight: "bold" }}
                                    onClick={() =>
                                        setViewModalShow(
                                            true,
                                        )}
                                >
                                    View</button>
                                : "NA"}</b>
                                 </h6>
                            <h6>Stores Added: 
                                <b>{project?.audit_cycle_data?.store_count}</b>
                                </h6>
                            <div className='final_review_description' 
                            style={{overflow:"hidden", width:"400px"}}
                            >
                            <h6>Description:</h6>
                                <p> <b>{project?.audit_cycle_data?.order?.describe}</b></p>
                               </div>
                               <div> <b> Uploaded Files:</b>
                            {uploading && (
                                <div>
                                    <img src={loadingimg} alt="Loading..." height="120px" />
                                </div>
                            )}
                            {selectedFile.length > 0 ? selectedFile.map((file, index) => (
                                <div key={index}>
                                    <div className='d-flex' style={{ marginTop: "1rem",cursor:"pointer" }}>
                                        <p onClick={() => toggleFileVisibility(file.fileName)} className='selected_files_name'  style={{cursor:"pointer"}}>
                                            {file.fileName}
                                        </p>
                                    </div>
                                    { file.fileType === "application/pdf" ? (
                                        <a href={file.fileUrl} download>
                                            <img
                                                src={pdfDummy}
                                                alt={file?.fileName}
                                                style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover",}}
                                            />
                                        </a>
                                    ) : (
                                        <>
                                            <img
                                                src={file?.fileUrl}
                                                alt={file?.fileName}
                                                width="300"
                                                style={{ display: fileVisibility[file.fileName] ? 'none' : 'block', objectFit: "cover" }}
                                            />
                                        </>
                                    )}   
                                </div>
                            )) : <p> No files found... </p>}
                            </div>
                        </Col>
                        <Col md="6" className='final_check_container_right'>
                            <h5><b>Grand Total</b></h5>
                            <p>1 ITEM</p>
                            <div className='final_check_total_price_details table-responsive'>
                                <Table className="text-center table">
                                    <thead>
                                        <tr>
                                            <th>Sr. No.</th>
                                            <th>Item</th>
                                            <th>Qty </th>
                                            <th>Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                        <td>1</td>
                                        <td>{project?.audit_cycle_data?.order?.solution?.name} </td>
                                        <td>{project.audit_cycle_data?.audit_cycle?.audit_count}</td>
                                        <td>INR. {project?.audit_cycle_data?.order?.solution?.price}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className='final_check_gst_price_box'>
                                <div className='final_check_gst_price'>
                                    <p>Total</p>
                                    <p>INR. {project.audit_cycle_data?.audit_cycle?.audit_count * project?.audit_cycle_data?.order?.solution?.price} /-</p>
                                </div>
                                <div className='final_check_gst_price'>
                                    <p>GST {project?.audit_cycle_data?.order?.solution?.tax?.rate}%</p>
                                    <p>INR {(project.audit_cycle_data?.audit_cycle?.audit_count * project?.audit_cycle_data?.order?.solution?.price * project?.audit_cycle_data?.order?.solution?.tax?.rate)/100} /-</p>
                                </div>
                                </div>
                                <div className='final_check_gst_price_box'>
                                <div className='final_check_gst_price'>
                                    <p><b>Grand Total</b></p>
                                    <p><b>INR. {(project.audit_cycle_data?.audit_cycle?.audit_count * project?.audit_cycle_data?.order?.solution?.price) + (project?.audit_cycle_data?.audit_cycle?.audit_count * project?.audit_cycle_data?.order?.solution?.price * project?.audit_cycle_data?.order?.solution?.tax?.rate)/100} /-</b></p>
                                </div>
                            </div>
                        </Col>
                    </div>
            </div>
            <Modal
                show={viewModalShow}
                centered
                size='lg'
                style={{ background: "rgba(0,0,0,.7)" }}
                className='modal-dialog-scrollable p-6 modal_mobile'
                onHide={() => setViewModalShow(false)}
            >
                <div
                    style={{
                        width: '100%',
                        padding: "3rem",
                        border: 'none',
                    }}
                    className='preferenceviewbox'
                >

                    <div>
                    <div style={{position:"absolute",right:"2rem", top:"1rem", cursor:"pointer"}} onClick={() => setViewModalShow(false)}><i className="fa-solid fa-x"></i></div>
                        <h6><b>Occupation</b></h6>
                        {occupationarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                        }
                    </div>
                    <div>
                        <h6><b>Age Range</b></h6>
                        {agerange && <p style={{ fontSize: ".8rem" }}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{agerange}</span></p>}
                    </div>
                    <div>
                        <h6><b>Income Range</b></h6>
                        {incomeOptionsarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                        }
                    </div>
                    <div>
                        <h6><b>Car Owned</b></h6>
                        {carownedarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                        }
                    </div>
                    <div>
                        <h6><b>Gender</b></h6>
                        {genderarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                        }
                    </div>
                    <div>
                        <h6><b>Must Have Interest/ Experience</b></h6>
                        {interestarr.map((item, index) => {
                            return (
                                <p style={{ fontSize: ".8rem" }} key={index}><i className="fa-solid fa-circle" style={{ fontSize: ".4rem" }} /><span style={{ marginLeft: ".5rem" }}>{item}</span></p>
                            )
                        })
                        }
                    </div>
                </div>
            </Modal>
        </div>
        
    )
}
