import { createSlice } from '@reduxjs/toolkit';

const clientuserAddSlice = createSlice({
  name: 'clientuserAdd',
  initialState: [],
  reducers: {
    addClientUserData: (state, action) => {
      state.push(action.payload);
    },
  },
});

export const { addClientUserData} =clientuserAddSlice.actions;
export default clientuserAddSlice.reducer;
