import React, { useEffect, useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Shaadi.com-Success.webp";
import shaadiLogo from "../../../images/shaadi_logo_img.png";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';
import { Helmet } from 'react-helmet';

export default function Shaadi() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
            	 <Helmet>
        <title>
        FloorInsights | NPS Measurement for Online Matrimonial Site
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Learn how we helped an online matrimonial site improve customer satisfaction and loyalty through NPS measurement"
        />
        <meta
          name="keywords"
          content="online matrimonial, NPS measurement, customer satisfaction"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={shaadiLogo} alt="shaddi_logo" />
                <div className='lead manyavar_heading_text'>
                    <span>Shaadi.com, a leading online matrimonial platform, faced challenges in understanding and enhancing user satisfaction and loyalty. Despite having a large user base, the company struggled to identify specific areas for improvement and address user concerns effectively. The lack of actionable insights from user feedback hindered their ability to make data-driven decisions. To address these issues, Shaadi.com sought to measure their Net Promoter Score (NPS) and implement targeted strategies to improve overall user satisfaction and engagement.</span>
                </div>
                <h3 className='lead manyavar_heading'>Shaadi.com struggled to identify and address specific user concerns and improve satisfaction due to a lack of actionable insights from user feedback, prompting the need to measure their Net Promoter Score (NPS) and implement targeted improvement strategies.</h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={side1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={centerimg} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={side2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                The primary objective was to help Shaadi.com, a leading online matrimonial platform, measure its Net Promoter Score (NPS) and implement strategies to improve overall user satisfaction. The goal was to understand user sentiments better, identify areas for improvement, and increase user loyalty and engagement.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Initial Assessment:</b> <br/>
                                    - Conducted an initial assessment of Shaadi.com's current user feedback mechanisms and overall user satisfaction levels.
                                    <br />
                                    - Set up the NPS measurement software provided by our SAAS company to collect and analyze user feedback.
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                             <b> 2. NPS Survey Implementation:</b> <br/>
                                    - Developed and distributed an NPS survey to Shaadi.com's users via email and in-app notifications to gather feedback on their experiences.
                                    <br />
                                    - Ensured the survey was easy to understand and complete, focusing on key aspects of the user experience, including ease of use, profile quality, and customer support.
                                    <br/>
                                    <b> 3. Data Collection and Analysis:</b> <br/>
                                    - Collected responses over a three-month period to gather a significant amount of data for analysis.
                                    <br />
                                    - Used our NPS software to analyze the data, identifying promoters, passives, and detractors.
                                    <br/>
                                    - Examined feedback for common themes and specific issues raised by users.
                                    <b>4. Actionable Insights:</b> <br/>
                                    - Provided Shaadi.com with detailed reports highlighting key areas of strength and weakness.
                                    <br />
                                    - Identified specific actions Shaadi.com could take to improve user satisfaction, such as enhancing profile verification processes, improving match algorithms, and offering better customer support.
                                    <br/>
                                    <b>5. Implementation of Improvements:</b> <br/>
                                    - Worked with Shaadi.com’s management to implement recommended changes based on the NPS feedback.
                                    <br />
                                    - Conducted training sessions for customer support staff to enhance service quality and address identified gaps.
                                    <br/>
                                    - Collaborated with the technical team to refine the match algorithms and improve the overall user interface.
                                    <br/>
                                    <b>6. Continuous Monitoring:
                                    </b> <br/>
                                    - Set up a continuous feedback loop using our NPS software to regularly measure user satisfaction and track the impact of implemented changes.
                                    <br />
                                    - Provided ongoing support to Shaadi.com to help them respond to new feedback and make continuous improvements.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() =>setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Initial NPS Score:</b> <br/> 
                                    - Shaadi.com's initial NPS score was 40, indicating a satisfactory level of user satisfaction but with significant room for improvement. <br />
                                    <b> 2. Post-Implementation NPS Score:</b> <br/>
                                    - After six months of implementing the recommended changes, 
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                            Shaadi.com's NPS score increased to 65, reflecting a substantial improvement in user satisfaction. <br/>
                                           <b> 3. User Feedback:</b> <br />
                                           - Positive feedback increased, with many users highlighting improvements in the matching process and overall platform usability. <br/>
                                           - Negative feedback decreased, particularly regarding issues that were addressed, such as customer support responsiveness and profile verification. <br/>
                                           <b> 4. Business Impact:</b> <br />
                                           - Increased user loyalty, evidenced by a higher number of active users and positive reviews.<br/>
                                           - Improved user engagement, with more users spending longer periods on the platform and engaging with more profiles. <br/>
                                           - Enhanced brand reputation, leading to an increase in new user registrations driven by positive word-of-mouth referrals.
                                           <br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By leveraging our NPS measurement software and implementing targeted improvements based on user feedback, Shaadi.com successfully enhanced its overall user satisfaction and achieved a higher NPS score. This case study demonstrates the value of regular user feedback and the positive impact of data-driven improvements on user engagement and business performance.</span>
                </div>
            </div>
          <NewCommonCards/>
        </div>
    )
}
