import React, { useEffect,useState } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import manyavarlogo from "../../../images/manyavar_logo_case.png";
import jmccard from "../../../images/jmccard.jpg";
import jmccenter from "../../../images/jmccenter.jpg";
import jmcside1 from "../../../images/jmcside1.png";
import jmcside2 from "../../../images/jmcside2.png";
import side1 from "../../../images/side1.png";
import side2 from "../../../images/side2.png";
import centerimg from "../../../images/Centre.jpg";
import CommonCards from './CommonCards';
import NewCommonCards from './NewCommonCards';
import { Helmet } from 'react-helmet';

export default function JMC() {
    const [isReadMoreApproach, setIsReadMoreApproach] = useState(false);
    const [isReadMoreOutcome, setIsReadMoreOutcome] = useState(false);
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
    }, []);

    return (
        <div>
             <Helmet>
        <title>
        FloorInsights | NPS Measurement for Jewellery Brand
        {" "}
        </title>
        <meta
          name="description"
          content="FloorInsights | Discover how our NPS measurement solution helped a jewellery brand enhance customer experience and loyalty"
        />
        <meta
          name="keywords"
          content="jewellery brand, NPS measurement, customer loyalty"
        />
      </Helmet>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "6rem" }}>
                <img src={jmccard} alt="jmc_logo" style={{width:"220px"}}/>
                <div className='lead manyavar_heading_text'>
                    <span>JMC, a leading jewelry brand, faced difficulties in accurately gauging and enhancing customer satisfaction and loyalty. Existing feedback mechanisms did not provide actionable insights, hindering JMC's ability to identify and address specific customer concerns. This lack of detailed understanding affected their ability to make informed decisions to improve the shopping experience. To overcome these challenges, JMC sought to measure their Net Promoter Score (NPS) and implement targeted strategies to enhance customer satisfaction and drive sales.
                    </span>
                </div>
                <h3 className='lead manyavar_heading'>JMC struggled to enhance customer satisfaction due to ineffective feedback mechanisms. To address this, they aimed to measure their Net Promoter Score (NPS) and implement targeted strategies to improve customer satisfaction and drive sales. </h3>
            </div>
            <div className='container'>
                <div className='row row-cols-1 row-cols-md-3'>
                    <div className='col-4'>
                        <div style={{ marginRight: "-20px" }}>
                            <img src={jmcside1} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-30px", marginTop: "-16px" }}>
                            <img src={jmccenter} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                    <div className='col-4'>
                        <div style={{ marginLeft: "-24px" }}>
                            <img src={jmcside2} alt="" style={{ width: "100%", height: "100%" }} className='card-img-top img-float' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='manyavar_heading_next_box'>
                <div className='row row-cols-1 row-cols-lg-3 g-5'>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Objective </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                The primary objective was to help JMC, a leading jewelry brand, measure its Net Promoter Score (NPS) and implement strategies to improve overall customer satisfaction. The goal was to understand customer sentiments better, identify areas for improvement, and increase customer loyalty and sales.
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Approach </h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b> 1. Initial Assessment:</b> <br/>
                                    - Conducted an initial assessment of JMC’s current customer feedback mechanisms and overall customer satisfaction levels. <br/>
                                    - Set up the NPS measurement software provided by our SAAS company to collect and analyze customer feedback.
                                    <br />
                                    - Set up the NPS measurement software provided by our SAAS company to collect and analyze user feedback.
                                    <br/>
                                    {isReadMoreApproach ?
                                        <><span>
                                             <b> 2. NPS Survey Implementation:</b> <br/>
                                    - Developed and distributed an NPS survey to JMC’s customers through in-store tablets to gather feedback on their shopping experiences.
                                    <br />
                                    - Ensured the survey was easy to understand and complete, focusing on key aspects of the customer experience, including product quality, store ambiance, and customer service.
                                    <br/>
                                    <b> 3. Data Collection and Analysis:</b> <br/>
                                    - Collected responses over a three-month period to gather a significant amount of data for analysis.
                                    <br />
                                    - Used our NPS software to analyze the data, identifying promoters, passives, and detractors.
                                    <br/>
                                    - Examined feedback for common themes and specific issues raised by customers.
                                    <b>4. Actionable Insights:</b> <br/>
                                    - Provided JMC with detailed reports highlighting key areas of strength and weakness.
                                    <br />
                                    - Identified specific actions JMC could take to improve customer satisfaction, such as enhancing staff training, improving store layout, and expanding product offerings.
                                    <br/>
                                    <b>5. Implementation of Improvements:</b> <br/>
                                    - Worked with JMC’s management to implement recommended changes based on the NPS feedback.
                                    <br />
                                    - Conducted training sessions for store staff to enhance customer service skills and address identified gaps.
                                    <br/>
                                    - Collaborated with the merchandising team to refine product displays and improve the overall shopping experience.
                                    <br/>
                                    <b>6. Continuous Monitoring:
                                    </b> <br/>
                                    - Set up a continuous feedback loop using our NPS software to regularly measure customer satisfaction and track the impact of implemented changes.
                                    <br />
                                    - Provided ongoing support to JMC to help them respond to new feedback and make continuous improvements.
                                    <br/>
                                             </span> <br />
                                            <span onClick={() => setIsReadMoreApproach(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br />
                                        </>
                                        :
                                        <>  <span onClick={() => setIsReadMoreApproach(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br /></>}
                                   
                                </p>

                            </div>

                        </div>

                    </div>
                    <div className='col-lg-4'>
                        <div className='card h-100' style={{ borderRadius: "20px" }}>
                            <div className='card-body shadow' style={{ borderRadius: "20px" }}>
                                <h3 className='card-title mt-sm-4 text-center' style={{ color: "#007DC1" }}>Outcome</h3>
                                <p className='card-text mt-sm-4' style={{ padding: "0rem 1rem 1rem 1rem" }}>
                                    <b>  1. Initial NPS Score:</b> <br/> 
                                    - JMC’s initial NPS score was 30, indicating a moderate level of customer satisfaction with significant room for improvement. <br />
                                    <b> 2. Post-Implementation NPS Score:</b> <br/>
                                    - After six months of implementing the recommended changes,  JMC’s NPS score increased to 60, reflecting a substantial
                                    <br />
                                    
                                    {isReadMoreOutcome ?
                                        <><span>
                                           improvement in customer satisfaction. <br/>
                                           <b> 3. Customer Feedback:</b> <br />
                                           - Positive feedback increased, with many customers highlighting improvements in staff friendliness and product displays. <br/>
                                           - Negative feedback decreased, particularly regarding issues that were addressed, such as store ambiance and product variety. <br/>
                                           <b> 4. Business Impact:</b> <br />
                                           - Increased customer loyalty, evidenced by a higher number of repeat customers and positive reviews.
                                           <br/>
                                           - Improved sales figures, correlating with the increase in customer satisfaction and loyalty. <br/>
                                           - Enhanced brand reputation, leading to an increase in new customer visits driven by positive word-of-mouth referrals.
                                           <br/>
                                          </span>
                                            <span onClick={() => setIsReadMoreOutcome(false)} style={{
                                                color: "blue", cursor: "pointer", fontSize: "14px",
                                                textDecoration: "underline", textUnderlineOffset: "5px",
                                                position: "absolute", right: "3rem",
                                                paddingTop: ".5rem"
                                            }}>Read Less</span> <br /></>
                                        :
                                        <> <br /> <span onClick={() => setIsReadMoreOutcome(true)} style={{
                                            color: "blue", cursor: "pointer", fontSize: "14px",
                                            textDecoration: "underline", textUnderlineOffset: "5px",
                                            position: "absolute", right: "3rem",
                                            paddingTop: ".5rem"
                                        }}>Read More</span> <br />
                                        </>
                                    }
                                </p>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "3rem", marginTop: "1rem" }}>
              
                <div className='lead manyavar_heading_text'>
                    <h3 style={{fontWeight:"bold"}}>Conclusion</h3>
                    <span>By leveraging our NPS measurement software and implementing targeted improvements based on customer feedback, JMC successfully enhanced its overall customer satisfaction and achieved a higher NPS score. This case study demonstrates the value of regular customer feedback and the positive impact of data-driven improvements on customer loyalty and business performance.
                    </span>
                </div>
            </div>
              
         <NewCommonCards/>
        </div>
    )
}
