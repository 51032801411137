import React, { useState, useEffect } from "react";
import { getColor } from "../../../utils/constant.js";
import { useLocation } from "react-router-dom";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction.js";
import loadingimg from "../../../images/loading.avif";

const StoreBrowserScorePerformancw = ({dropdownId}) => {
  const location = useLocation();
  let storeId = location.state?.storeId;
  localStorage.setItem("storeId",storeId);
  let questionnaireId = location.state?.questionnaireId;
  localStorage.setItem("questionnaireId",questionnaireId);
  const [localStoreId,setLocalStoreId] = useState(null);
  const [localQuestionnaireId,setLocalQuestionnaireId] = useState(null);
 
  const {data,getApiData:getTableData,isLoading} = useFetchinFunction();
 
  useEffect(()=>{
   if(localStorage.getItem("storeId")){
    setLocalStoreId(localStorage.getItem("storeId"))
   }
   if(localStorage.getItem("questionnaireId")){
    setLocalQuestionnaireId(localStorage.getItem("questionnaireId"))
   }
  },[])

  
  useEffect(()=>{
    if ( localStoreId && (localQuestionnaireId || dropdownId)) {
      const quesIdentifier = dropdownId || localQuestionnaireId;
      getTableData(`client/report/questionnaire_type/${quesIdentifier}/store/${localStoreId}/marking`);
    }
  },[localStoreId,localQuestionnaireId, dropdownId])
  if(isLoading){
    <div className='getStarted'>
    <img src={loadingimg} alt="Loading..." style={{ width: "150px" }} />
</div>
  }

  let rows = [];
  let prevSection;
  if(data?.scores && data?.scores.length>0){
    for (let score of data.scores) {
      if (
        score?.scores?.length === data?.questionnaire?.length &&
        score.max_marks > 0
      ) {
        rows.push(
          <tr key={score.question_id}>
            <td className="text-right">
              {prevSection !== score.sections_name ? score.sections_sequence : null}
            </td>
            <td>
              {prevSection !== score.sections_name ? score.sections_name : null}
            </td>
            <td>{score.question_text}</td>
            {score.scores.map((s, i) => (
              <td key={i} className={getColor(s.marks) + " text-right"}>
                {parseFloat(s.marks) ? s.marks.toFixed(2) : s.marks}
              </td>
            ))}
            {/* <td className={getColor(score.max_marks) + " text-right"}>{score.max_marks}</td> */}
            <td className=" text-right">{score.max_marks}</td>
          </tr>
        );
        prevSection = score.sections_name;
      }
    }
  
  }
 
  let table;
  if (rows && rows.length > 0) {
    let cycles = [];
    for (let ac of data?.questionnaire) {
      cycles.push(
        <th key={ac} className="text-right">
          {ac}
        </th>
      );
    }
    table = (
      <table className="table table-striped table-bordered table-hover mb-5 mt-5">
        <thead>
          <tr>
            <th className="text-right">#</th>
            <th>Section</th>
            <th>Question</th>
            {cycles}
            <th className="text-right">Max. Marks</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  } else {
    table = <table className="table table-striped table-bordered table-hover mb-5 mt-5">
    <thead>
      <tr>
        <th className="text-right">#</th>
        <th>Section</th>
        <th>Question</th>
        <th className="text-right">Max. Marks</th>
      </tr>
    </thead>
    <tbody><tr><td colSpan={4} className="text-center">There is no data...</td></tr></tbody>
  </table>
  }
  return (
    <div>
      <div
        className="branch_bar_box_head"
        style={{ borderBottom: "1px solid #ddd",marginTop:"4rem" }}
      >
        <h3 style={{fontSize:"1.5rem"}}>Score Performance Details</h3>
      </div>
      <div className="table-responsive">
      {table}
      </div>
    </div>
  );
};

export default StoreBrowserScorePerformancw;
