// import React, { useEffect, useState } from 'react';
// import { Col, Row, Table } from 'react-bootstrap';
// import { Link, useLocation, useParams } from 'react-router-dom';
// import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
// import base_url from '../../../utils/config';
// import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from 'recharts';

// export default function ReportListDetail() {
//   const token = localStorage.getItem("token");
//   const [storeReportDetail, setStoreReportDetail] = useState({});

//   useEffect(() => {
//     const currentUrl = window.location.href;
//     getApiData(`client/mp/reportlistdetail/${+currentUrl.split("=").slice(1)}`);
//   }, []);
//   const graphdata = [
//     {
//       name: 'Completed',
//       value: 70,
//       fill: '#82ca9d', // Green color for completed work
//     },
//     {
//       name: 'Remaining',
//       value: 30,
//       fill: '#8884d8', // Blue color for remaining work
//     },
//   ];
//   const getApiData = async (url) => {
//     try {
//       const headers = {
//         'Authorization': `Token ${token}`,
//         'Content-Type': 'application/json'
//       };

//       const options = {
//         method: 'GET',
//         headers: headers
//       };

//       const response = await fetch(base_url + url, options);

//       if (response.ok) {
//         const result = await response.json();
//         setStoreReportDetail(result);
//       } else {
//         console.error('API request failed:', response.status, response.statusText);
//       }
//     } catch (error) {
//       console.error('An error occurred while fetching the API:', error);
//     }
//   };

//   return (
//     <div>
//       <div className='back_market' style={{ padding: "0rem" }}>
//         <Link to="/userdashboardmain/reports">
//           <i className="fa-solid fa-arrow-left"></i>
//           <span>Back to Reports</span>
//         </Link>
//       </div>
//       {storeReportDetail && (
//         <>
//           <div className='report_details_head_box'>
//             <h5><b>{storeReportDetail.store_audit_data?.order?.solution?.name}</b></h5>
//             <div className='report_details_head_btn_container'>
//               <button>Excel Export</button>
//               <button>Send PDF</button>
//               <button>Print</button>
//             </div>
//           </div>

//           <Row className='d-flex'>
//             <Col md="5">
//               <h5><b>Results</b></h5>
//               <div className='report_details_result_box'>
//                 <h6>Sample details</h6>
//                 <div className='report_details_result'>
//                   <p>Project Name: <b>{storeReportDetail.store_name}</b></p>
//                   <p>Store Name: <b> {storeReportDetail.store_audit_data?.audit_stores?.store_name}</b></p>
//                   <p>Execution Date: <b> {storeReportDetail.store_audit_data?.audit_stores?.audit_date}</b></p>
//                   <p>City: <b> {storeReportDetail.store_audit_data?.audit_stores?.city_name}</b></p>
//                   <p>State: <b> {storeReportDetail.store_audit_data?.audit_stores?.state}</b></p>
//                   <p>Zip Code: <b> {storeReportDetail.store_audit_data?.store_code}</b></p>
//                 </div>
//               </div>
//             </Col>
//             <Col md="6" className='report_details_chart_box'>
//               <div>
//                 {/* <p>Report Percentage: 48%</p> */}
//                 {/* <ResponsiveContainer width="100%" aspect={5}> */}
//                   <PieChart width={750} height={300} margin={{ top: 50, right: 150, left: 20, bottom: 5 }}>
//                     <Legend />
//                     <Tooltip />
//                     <Pie
//                       data={graphdata}
//                       dataKey="value"
//                       nameKey="name"
//                       cx="50%"
//                       cy="50%"
//                       innerRadius={60}
//                       outerRadius={80}
//                       fill="#8884d8"
//                       label
//                     />
//                   </PieChart>
//                 {/* </ResponsiveContainer> */}
//               </div>
//             </Col>
//           </Row>

//           <div className='report_details_score_box'>
//             <h5><b>Report Score:</b> {storeReportDetail.total_score?.percentage}</h5>
//             <Table className="text-center table table-responsive">
//               <thead className='report_details_score_table_head'>
//                 <tr>
//                   <th className="fw-bold">Sr. Number</th>
//                   <th className="fw-bold">Question</th>
//                   <th className="fw-bold">Answer</th>
//                   <th className="fw-bold">Comments</th>
//                   <th className="fw-bold">Marks</th>
//                   <th className="fw-bold">Total</th>
//                   <th className="fw-bold">Proof</th>
//                 </tr>
//               </thead>
//               <tbody className='report_details_score_table_body'>
//                 {/* {storeReportDetail.store_audit_data?.answers.map((answer, index) => (
//                   <tr key={index}>
//                     <td>{index + 1}</td>
//                     <td>{answer.question}</td>
//                     <td>{answer.answer}</td>
//                     <td>{answer.comments}</td>
//                     <td>{answer.marks}</td>
//                     <td>{answer.total}</td>
//                     <td><Link to={answer.proof_link}>view</Link></td>
//                   </tr>
//                 ))} */}
//               </tbody>
//             </Table>
//           </div>
//         </>
//       )}
//     </div>
//   );
// }


import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useFetchAuth } from '../../../utils/hooks/useFetchAuth';
import base_url from '../../../utils/config';
import { ResponsiveContainer, PieChart, Pie, Legend, Tooltip } from 'recharts';
import "../../../styles/reportlistdetail.css";
import Storedetail from './Storedetail';
import QuestionReport from './QuestionReport';
import { useFetchinFunction } from '../../../utils/hooks/useFetchinFunction';

export default function ReportListDetail() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const location = useLocation();
  const {id} = useParams();
  console.log("16222",location)
  const [reportId, setReportId] = useState(null);
  const {data:storeReportDetail,isLoding,getApiData} = useFetchinFunction();
  const {data:reportSummary,getApiData: getReportSummaryData,isLoading} = useFetchinFunction()
  // useEffect(() => {
  
  //   if(location.state){
  //     setReportId(location.state);
  //     getApiData(`client/store_report/${location.state}`);
  //     getReportSummaryData(`client/store_report/${location.state}/report_section`);
  //   }
   
  // }, []);
  useEffect(() => {
  
    if(id){
      console.log(id)
      setReportId(id)
      getApiData(`client/store_report/${id}`);
      getReportSummaryData(`client/store_report/${id}/report_section`);
    }
   
  }, [id]);

  
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };
  return (
    <div>
      <div className='back_market' style={{ padding: "0rem" }}>
        <Link 
        onClick={handleGoBack}
        >
          <i className="fa-solid fa-arrow-left"></i>
          <span>Back to Reports</span>
        </Link>
      </div>
      <div className='report_detail_first'>
        <h2>Questionnaire Report</h2>
        <div className='report_detail_first_btncontainer'>
          <button className="btn create_ac_btn" onClick={window.print}>
            <i class="fa-solid fa-print"></i> Print Report
          </button>
        </div>

      </div>
      {/* <Row className='d-flex'> */}
        <Storedetail storeReportDetail={storeReportDetail} reportSummary={reportSummary} isLoading={isLoading}/>
      {/* </Row> */}
      {/* <Row> */}
        <QuestionReport reportId={reportId}/>
      {/* </Row> */}

    </div>
  );
}
