import React from 'react'

export default function UploadStore({ updateStep, currentStep }) {
    return (
        <div className='dash_stepper_box_right'>
            <div className='dash_stepper_box_right_up'>
                <i className="fa-solid fa-image"></i>
                <h2>Define the 'Where'</h2>
                <p>Add city locations and number of audits for each location to get an appropriate and distributed sample results</p>
            </div>
            <div className='dash_stepper_btn_container'>
                <button onClick={() => updateStep(currentStep + 1)} className='dash_stepper_btn_container_btn'>Next Step</button>
            </div>
        </div>
    )
}
