

import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "../../../styles/quessectionrows.css";
import { questionTypes } from "../../../utils/constant";
import OptionBuilder from "./OptionBuilder"; // Adjust import path as needed
import SingleSelectOptionBuilder from "./SingleSelectOptionBuilder";
import { postAddQuestion } from "../../../utils/services/PostdataHandle";
import { useDispatch } from "react-redux";
import { addQuestionData } from "../../../utils/reduxcomponents/questionAddSlice";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";
import loadingImg from "../../../images/loading.avif";

const RatingInput = ({ rating, onChange }) => (
  <Row style={{ marginTop: "1rem" }}>
    <Col md="6">
      <p>Star Rating</p>
      <div
        className="star-rating"
        style={{ display: "flex", flexDirection: "column", gap: "1.8rem" }}
      >
        {[1, 2, 3, 4, 5].map((star) => (
          <div
            key={star}
            style={{ display: "flex", alignItems: "center", gap: ".5rem" }}
          >
            {Array.from({ length: star }, (_, index) => (
              <i className="fa-solid fa-star" key={index}></i>
            ))}
          </div>
        ))}
      </div>
    </Col>
    <Col md="6">
      <p className="registration_form_content">
        <label>Rating Marks</label>
        {[1, 2, 3, 4, 5].map((number, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <input
              type="number"
              name={`rating-${number}`}
              // value={rating[index]}
              value={rating[number - 1]}
              onChange={onChange}
              // min="1"
              // max="5"
              style={{ width: "100%" }}
            />
          </div>
        ))}
      </p>
    </Col>
  </Row>
);




const OptionalScaleInput = ({ scaling, onChange }) => (
  <Row style={{ marginTop: "1rem" }}>
    <Col md="6">
      <p>Scale</p>
      <div style={{ display: "flex", flexDirection: "column", gap: "1.3rem" }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((star) => (
          <div key={star}>
            <span>{star}</span>
          </div>
        ))}
      </div>
    </Col>
    <Col md="6">
      <p className="registration_form_content">
        <label>Opinion Scale Marks</label>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((number, index) => (
          <div
            key={index}
            style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
          >
            <input
              type="number"
              name={`scaling-${number}`}
              value={scaling[index]}
              onChange={onChange}
              // min="1"
              // max="10"
              style={{ width: "100%" }}
            />
          </div>
        ))}
      </p>
    </Col>
  </Row>
);

const QuestionTypeField = ({ type, formData, setFormData, errorMessages }) => {
  switch (type) {
    case "EMAIL":
      return ( 
        <Col md="12">
          <p className="registration_form_content">
            <label>Email</label>
            <input
              type="text"
              name="email"
              value=""
              placeholder="johndoe@gmail.com"
              contentEditable={false}
            />
            {errorMessages.emailError && (
              <span className="error_message">{errorMessages.emailError}</span>
            )}
          </p>
        </Col>
      );
    case "PLAIN_TEXT":
    case "DATE":
      return (
        <>
            {type === "DATE" && (
              <Col md="12">
                <p className="registration_form_content">
                  <label>Date</label>
                  <input
                    type="text"
                    name="date"
                    value=""
                    contentEditable={false}
                    placeholder="24/01/2024"
                  />
                  {errorMessages.dateError && (
                    <span className="error_message">
                      {errorMessages.dateError}
                    </span>
                  )}
                </p>
              </Col>
            )}
        </>
      );
    case "PHONE_NUMBER":
      return (
          <Col md="12">
            <p className="registration_form_content">
              <label>Phone Number</label>
              <input
                type="text"
                name="phone"
                className="audit_count_numberbox"
                value=""
                contentEditable={false}
                placeholder="9876543211"
              />
              {errorMessages.phoneError && (
                <span className="error_message">
                  {errorMessages.phoneError}
                </span>
              )}
            </p>
          </Col>
      );
    case "RATING":
      return (
          <RatingInput
            rating={formData.rating}
            onChange={(e) => {
              const index = parseInt(e.target.name.split("-")[1], 10) - 1;
              setFormData((prevState) => {
                const newRating = [...prevState.rating];
                newRating[index] = parseInt(e.target.value, 10);
                return { ...prevState, rating: newRating };
              });
            }}
          />
      );
    case "OPINION_SCALE":
      return (
          <OptionalScaleInput
            scaling={formData.scaling}
            onChange={(e) => {
              const index = parseInt(e.target.name.split("-")[1], 10) - 1;
              setFormData((prevState) => {
                const newScaling = [...prevState.scaling];
                newScaling[index] = parseInt(e.target.value, 10);
                return { ...prevState, scaling: newScaling };
              });
            }}
          />
      );
    case "MUTEX":
      return (
          <Col md="12">
            <OptionBuilder
              options={formData.options}
              onChange={(newOptions) =>
                setFormData((prevState) => ({
                  ...prevState,
                  options: newOptions,
                }))
              }
            />
          </Col>
      );
    case "MULTIPLE_CHOICE":
      return (
          <Col md="12">
            <OptionBuilder
              options={formData.options}
              onChange={(newOptions) =>
                setFormData((prevState) => ({
                  ...prevState,
                  options: newOptions,
                }))
              }
            />
          </Col>
      );
    case "SINGLESELECT":
      return (
          <Col md="12">
            <SingleSelectOptionBuilder
              options={formData.singleoptions}
              onChange={(newOptions) =>
                setFormData((prevState) => ({
                  ...prevState,
                  options: newOptions,
                }))
              }
            />
          </Col>
      );
    default:
      return null;
  }
};

const AddQuestionModal = ({ modalShow, setModalShow, sectionId, questionId,setSelectedQuestionId }) => {
  const dispatch = useDispatch();
  const { data: questionData, getApiData, isLoading: initialLoading } = useFetchinFunction();
  const [formData, setFormData] = useState({
    question_type: "",
    max_marks: "",
    question_text: "",
    email: "",
    date: "",
    phone: "",
    rating: [1, 2, 3, 4, 5],
    scaling: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    options: [], // Initialize options for MUTEX & multi
    singleoptions: [],
  });

  const [errorMessages, setErrorMessages] = useState({
    question_typeError: "",
    max_marksError: "",
    question_textError: "",
    emailError: "",
    dateError: "",
    phoneError: "",
    commonError: "",
  });

  const [loadingButton, setLoadingButton] = useState(false);
  const [modalTitle,setModalTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    // if (!modalShow) return;
    if (questionId) {
      // Fetch question data if editing
      getApiData(`client/questions/${questionId}`);
      setModalTitle("Edit Question");
    } else {
    setModalTitle("Add Question")
    setFormData({
      question_type: "",
      max_marks: "",
      question_text: "",
      email: "",
      date: "",
      phone: "",
      rating: [1, 2, 3, 4, 5],
      scaling: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      options: [],
      singleoptions: [],
    });
  }
    setErrorMessages({});
  // }, [modalShow,questionId]);
}, [questionId]);

  useEffect(() => {
    if (questionData) {
      const questionDataArray = Array.isArray(questionData?.question_data?.options) ? questionData.question_data.options : [];
      // console.log("30888888888888888888888888",questionDataArray)
      setFormData((prevState) => ({
        ...prevState,
        question_type: questionData.question_type,
        max_marks: questionData.max_marks,
        question_text: questionData.question_text,
        email: questionData.email || "",
        date: questionData.date || "",
        phone: questionData.phone || "",
        rating: questionData.question_type === 'RATING' ? 
          questionDataArray.map(q => q.marks) : [1, 2, 3, 4, 5],
        scaling: questionData.question_type === 'OPINION_SCALE' ? 
          questionDataArray.map(q => q.marks) : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        options: questionData.question_type === 'MUTEX' || questionData.question_type === 'MULTIPLE_CHOICE' ? 
          questionDataArray : [],
        singleoptions: questionData.question_type === 'SINGLESELECT' ? 
          questionDataArray : [],
      }));
    }
  }, [questionData]);
  
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("332",name,value)
    if (name.startsWith("rating")) {
      const index = parseInt(name.split("-")[1], 10) - 1;
      setFormData((prevState) => {
        const newRating = [...prevState.rating];
        newRating[index] = parseInt(value, 10) || ''; 
        return { ...prevState, rating: newRating };
      });
    } else if (name.startsWith("scaling")) {
      const index = parseInt(name.split("-")[1], 10) - 1;
      setFormData((prevState) => {
        const newScaling = [...prevState.scaling];
        newScaling[index] = parseInt(value, 10) || ''; 
        return { ...prevState, scaling: newScaling };
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setErrorMessages((prevErrors) => ({
      ...prevErrors,
      [`${name}Error`]: "",
    }));
    
    
  };


const handleSubmit = (e) => {
  e.preventDefault();
  let hasError = false;
  const newErrors = {};

  if (!formData.question_type) {
    newErrors.question_typeError = "Question type can't be empty";
    hasError = true;
  }
  if (!formData.question_text) {
    newErrors.question_textError = "Question can't be empty";
    hasError = true;
  }
  if (!formData.max_marks) {
    newErrors.max_marksError = "Max marks can't be empty";
    hasError = true;
  }
  
// let totalOptionMarks;
// let totalRatingMarks;
// // console.log("3811",formData.rating)
// // console.log("3822222",formData.options)
//   if(formData?.options.length > 0 || formData?.singleoptions.length > 0){
//   formData.rating = [];
//   formData.scaling = [];
//     let newArr = formData?.options || formData?.singleoptions;
//      totalOptionMarks = newArr.some((option) =>  option.marks == formData.max_marks) && newArr.every((option)=> option?.marks <= formData.max_marks);
//     if(!totalOptionMarks){
//       newErrors.commonError = "Max marks should match";
//       hasError = true;
//       setLoadingButton(false);
//     }
//     // if (!totalOptionMarks && formData.question_type !== 'RATING' && formData.question_type !== 'OPINION_SCALE' && formData.question_type !== 'EMAIL' && formData.question_type !== 'DATE' && formData.question_type !== 'PLAIN_TEXT') {
//     //   newErrors.commonError = "Max marks should match";
//     //   hasError = true;
//     //   setLoadingButton(false);
//     // }
//   }
// if(formData?.rating.length > 0 ){
//   formData.scaling = [];
//   let newArr = formData?.rating ;
//   totalRatingMarks = newArr.some((option) =>  option == formData.max_marks) && newArr.every((option)=> option <= formData.max_marks);
//   if(!totalRatingMarks){
//     newErrors.commonError = "Max marks should match";
//     hasError = true;
//     setLoadingButton(false);
//   }
//   // if(!totalRatingMarks  && formData.question_type === 'RATING' && formData.question_type !== 'EMAIL' && formData.question_type !== 'DATE' && formData.question_type !== 'PLAIN_TEXT'){
//   //   newErrors.commonError = "Max marks should match";
//   //   hasError = true;
//   //   setLoadingButton(false);
//   // }
// }
// // if(formData?.scaling.length > 0 ){
// //   formData.rating = [];
// //   let newArr = formData?.scaling ;
// //   totalRatingMarks = newArr.some((option) =>  option == formData.max_marks);
// //   if(!totalRatingMarks){
// //     newErrors.commonError = "Max marks should match";
// //     hasError = true;
// //     setLoadingButton(false);
// //   }
// // }

  if (hasError) {
    setErrorMessages(newErrors);
    setTimeout(() => setErrorMessages({}), 2000);
    return;
  }

  if (formData.question_type && formData.max_marks && formData.question_text) {
  
    const payload = {
      sections: sectionId,
      max_marks: +formData.max_marks,
      question_text: formData.question_text,
      question_type: formData.question_type,
      question_data: formData.question_type === 'RATING' 
        ? {"options" : formData.rating.map((value, index) => ({
            sequence: index + 1,
            value: index + 1, 
            marks: value || 0 
          })) }
          :
          formData.question_type === 'OPINION_SCALE' 
        ? {"options" : formData.scaling.map((value, index) => ({
            sequence: index + 1,
            value: index + 1, 
            marks: value || 0 
          })) 
        }
          // : formData.options ? formData.options : formData.singleoptions ? formData.singleoptions : "",
          : formData.options ? {"options" : formData.options} : formData.singleoptions ? {"options" : formData.singleoptions} : "",
    };
    setLoadingButton(true);
    const promise = postAddQuestion(questionId ? `client/questions/${questionId}` : "client/question/", payload);
    promise
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if(data?.non_field_errors){
   setErrorMessage(data?.non_field_errors[0]);
   setTimeout(() => {
    setErrorMessage("");
   }, 2000);
        }else{
        dispatch(addQuestionData(data));
        setFormData({
          question_type: "",
          max_marks: "",
          question_text: "",
          email: "",
          date: "",
          phone: "",
          rating: [1, 2, 3, 4, 5],
          scaling: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          options: [],
          singleoptions: [],
        });
        modalClose()
      }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingButton(false);
      });
  }
};




const modalClose = () =>{
  setSelectedQuestionId(null);
  setModalShow(false);
}
  return (
    <Modal
      show={modalShow}
      centered
      size="lg"
      onHide={modalClose}
      dialogClassName="modal-dialog-scrollable"
        className=' p-6 modal_mobile'
    >
      <div className="modal_inner_main">
        <div
          style={{
            position: "absolute",
            right: "2rem",
            top: "2rem",
            cursor: "pointer",
          }}
          onClick={modalClose}
        >
          <i className="fa-solid fa-x"></i>
        </div>
        <form onSubmit={handleSubmit}>
          <Col>
            <Row>
              <h5 className="setup_regs_modal_heading">{modalTitle}</h5>
            </Row>
            {initialLoading ? 
            <Row>
<div className='getStarted'>
         <img src={loadingImg} alt="Loading..." style={{ width: "150px" }} />
     </div>
          </Row> 
          :
          <>
            <Row>
              <Col md="12">
                <p className="registration_form_content">
                  <label>Max Marks</label>
                  <input
                    type="number"
                    name="max_marks"
                    value={formData.max_marks}
                    onChange={handleChange}
                    min="0"
                  />
                  {errorMessages.max_marksError && (
                    <span className="error_message">
                      {errorMessages.max_marksError}
                    </span>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <p className="registration_form_content">
                  <label>Question Type</label>
                  <select
                    name="question_type"
                    onChange={handleChange}
                    value={formData.question_type}
                  >
                    <option value="">Select...</option>
                    {questionTypes.map((question, index) => (
                      <option key={index} value={question.value}>
                        {question.label}
                      </option>
                    ))}
                  </select>
                  {errorMessages.question_typeError && (
                    <span className="error_message">
                      {errorMessages.question_typeError}
                    </span>
                  )}
                </p>
              </Col>
            </Row>
            <Row>
            <Col md="12">
              <p className="registration_form_content">
                <label>Question</label>
                <input
                  type="text"
                  name="question_text"
                  value={formData.question_text}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      question_text: e.target.value,
                    }))
                  }
                />
                {errorMessages.question_textError && (
                  <span className="error_message">
                    {errorMessages.question_textError}
                  </span>
                )}
              </p>
            </Col>
            </Row>
            <QuestionTypeField
              type={formData.question_type}
              formData={formData}
              setFormData={setFormData} // Pass setFormData here
              errorMessages={errorMessages}
            />
            {errorMessages.commonError &&
             <Col md="12">
             <p className="registration_form_content">
             <span className="error_message">
                    {errorMessages.commonError}
                  </span>
              </p>
              </Col>
}
<p className="registration_form_content">
{errorMessage && (
                    <span className="error_message">
                      {errorMessage}
                    </span>
                  )}
                  </p>
            <div className="d-flex justify-content-center align-items-center">
              {loadingButton ? (
                <button
                  className="btn mb-5 btn-md text-center btn-size create_ac_btn"
                  type="button"
                  disabled
                  style={{
                    fontSize: "1rem",
                    background: "#007DC1",
                    color: "white",
                  }}
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                    style={{ marginRight: ".5rem" }}
                  ></span>
                  Loading...
                </button>
              ) : (
                <button className="btn mt-3 btn-md text-center btn-size create_ac_btn">
                  Submit
                </button>
              )}
            </div>
            </>
}
          </Col>
        </form>
      </div>
    </Modal>
  );
};

export default AddQuestionModal;
