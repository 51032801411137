// import React, { useState, useEffect } from "react";
// import { Modal } from "react-bootstrap";
// import { useNavigate, useParams } from "react-router-dom";
// import { ResponsiveContainer, BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Label } from "recharts";
// import WordCloud from "./WordCloud"; 
// import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";

// const ReportAIInsightModal = () => {
//   const [loading, setLoading] = useState(true);
//   const [sentiments, setSentiments] = useState({});
//   const navigate = useNavigate();
//   const [show, setShow] = useState(true);
//   const {id} = useParams()
//   console.log("1333",id)
//  const {data , getApiData,isLoading} = useFetchinFunction();
//   useEffect(()=>{
//     if(id){
//       getApiData(`client/sentiment_data/${id}`);
//     }
//   },[id])
//   useEffect(() => {
//     // Mock fetching data with a timeout
//     setTimeout(() => {
//       const mockData = {
//         bullet_points: {
//           1: "Point 1",
//           2: "Point 2",
//           3: "Point 3"
//         },
//         sentiment_positive_words: {
//           "Good": 10,
//           "Great": 15,
//           "Awesome": 20
//         },
//         sentiment_negative_words: {
//           "Bad": 5,
//           "Terrible": 3,
//           "Horrible": 2
//         },
//         sentiment_emotions: {
//           "Happy": 0.7,
//           "Sad": 0.2,
//           "Angry": 0.1
//         }
//       };
//       setSentiments(mockData);
//       setLoading(false);
//     }, 1000);
//   }, []);

//   const renderBulletPoints = (bulletPoints) => {
//     return Object.values(bulletPoints).map((point, index) => <li key={index}>{point}</li>);
//   };

//   const renderPositiveKeywords = (keywords) => {
//     return Object.entries(keywords).map(([word, count], index) => (
//       <div className="ai_words_smallbox" key={index}>
//         <p style={{marginBottom: "0rem"}}>{word} : <span>{count}</span></p>
//       </div>
//     ));
//   };

//   const renderNegativeKeywords = (keywords) => {
//     return Object.entries(keywords).map(([word, count], index) => (
//       <div className="ai_words_smallbox ai_negative" key={index}>
//         <p style={{marginBottom: "0rem"}}>{word} : <span>{count}</span></p>
//       </div>
//     ));
//   };

//   const renderPositiveKeywordsWordCloud = (keywords) => {
//     if (!keywords || typeof keywords !== "object") return [];
//     return Object.entries(keywords).map(([word, count]) => ({
//       text: word,
//       value: count,
//       color: "green"
//     }));
//   };

//   const renderNegativeKeywordsWordCloud = (keywords) => {
//     if (!keywords || typeof keywords !== "object") return [];
//     return Object.entries(keywords).map(([word, count]) => ({
//       text: word,
//       value: count,
//       color: "red"
//     }));
//   };

//   const prepareEmotionData = (emotions) => {
//     return Object.entries(emotions).map(([emotion, value]) => ({ name: emotion, value }));
//   };

//   const bulletPoints = sentiments.bullet_points || "";
//   const positiveKeywords = sentiments.sentiment_positive_words || {};
//   const negativeKeywords = sentiments.sentiment_negative_words || {};
//   const emotionData = prepareEmotionData(sentiments.sentiment_emotions || {});
//   const wordCloudData = [
//     ...renderPositiveKeywordsWordCloud(positiveKeywords),
//     ...renderNegativeKeywordsWordCloud(negativeKeywords)
//   ];
//   const handleClose = () => {
//     setShow(false);
//     navigate(-1); // Go back to the previous page when the modal is closed
//   };
//   return (
//     <Modal
//     show={show}
//     centered
//     size='lg'
//     className='modal-dialog-scrollable p-6 modal_mobile'
//     onHide={handleClose}
// >
//     <div
//         style={{
//             width: '100%',
//             padding: "1rem",
//             border: 'none',
//             // position: "relative"
//         }}
//     >
//         <div style={{ display:"flex",justifyContent:"flex-end", cursor: "pointer",marginBottom:"1rem" }}
//             onClick={handleClose}
//         >
//             <i className="fa-solid fa-x"></i>
//         </div>
//         <div className="ai_modal_mainbox">
//             <div className="ai_modal_smbox ">
//               <h3>Audit Highlights</h3>
//               <ul className="bullet_box">
//                 {renderBulletPoints(bulletPoints)}
//               </ul>
//             </div>
//             <div className="ai_modal_smbox">
              
//               <h3>Customer emotions</h3>
//               <div style={{ marginTop: "2rem" }}>
//                 <ResponsiveContainer width="100%" height={120} >
//                   <BarChart margin={{ top: 0, right: 10, left: 0, bottom: 5 }} data={emotionData} style={{fontSize:".8rem"}}>
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="name" interval={0}>
//                       <Label value="Emotions" offset={-5} position="insideBottomRight" fill="#337ab7" style={{fontSize:".8rem"}}/>
//                     </XAxis>
//                     <YAxis domain={[0, 1]} tickFormatter={f => f}>
//                       <Label value="Values" angle={-90} offset={-5} position="left" fill="#337ab7" style={{fontSize:".8rem"}}/>
//                     </YAxis>
//                     <Tooltip formatter={v => v === null ? "N/A" : v} />
//                     <Bar dataKey="value" barSize={10} isAnimationActive={false} fill={"#337ab7"} />
//                   </BarChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>
//             <div className="ai_modal_smbox">
//               <h3>Positive keywords</h3>
//               <div className="ai_words_mainbox positive_mainbox">
//                 {renderPositiveKeywords(positiveKeywords)}
//               </div>
//               <h3>Negative keywords</h3>
//               <div className="ai_words_mainbox negative_mainbox">
//                 {renderNegativeKeywords(negativeKeywords)}
//               </div>
//             </div>
//           </div>
//     </div>

// </Modal>
//   );
// };

// export default ReportAIInsightModal;


import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { ResponsiveContainer, BarChart, CartesianGrid, Bar, XAxis, YAxis, Tooltip, Label } from "recharts";
import ReactWordcloud from "react-wordcloud";
import { useFetchinFunction } from "../../../utils/hooks/useFetchinFunction";

const options = {
	rotations: 0,
	fontSizes: [20, 60],
};
const callbacks = {
	onWordClick: null,
	onWordMouseOver: null,
	getWordTooltip: null,
	getWordColor: (word) => word.color,
};
const ReportAIInsightModal = () => {
  const [sentiments, setSentiments] = useState({});
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const { id } = useParams();

  const { data, getApiData, isLoading } = useFetchinFunction();

  useEffect(() => {
    if (id) {
      getApiData(`client/sentiment_data/${id}`);
    }
  }, [id]);

  useEffect(() => {
    if (data && data.length > 0) {
      const apiData = data[0];
      const transformedData = {
        bullet_points: apiData.sentiment_bullet_points || "{}",
        sentiment_positive_words: apiData.sentiment_positive_words || "{}",
        sentiment_negative_words: apiData.sentiment_negative_words || "{}",
        sentiment_emotions: apiData.sentiment_emotions || "{}",
      };
      console.log(transformedData)
      setSentiments(transformedData);
    }
  }, [data]);

  const renderBulletPoints = (bulletPoints) => {
    return Object.values(bulletPoints).map((point, index) => <li key={index} style={{listStyle:"inside"}}>{point}</li>);
  };

  const renderPositiveKeywords = (keywords) => {
    return Object.entries(keywords).map(([word, count], index) => (
      <div className="ai_words_smallbox" key={index}>
        <p style={{ marginBottom: "0rem" }}>{word} : <span>{count}</span></p>
      </div>
    ));
  };

  const renderNegativeKeywords = (keywords) => {
    return Object.entries(keywords).map(([word, count], index) => (
      <div className="ai_words_smallbox ai_negative" key={index}>
        <p style={{ marginBottom: "0rem" }}>{word} : <span>{count}</span></p>
      </div>
    ));
  };

  const renderPositiveKeywordsWordCloud = (keywords) => {
    if (!keywords || typeof keywords !== "object") return [];
    return Object.entries(keywords).map(([word, count]) => ({
      text: word,
      value: count,
      color: "green"
    }));
  };

  const renderNegativeKeywordsWordCloud = (keywords) => {
    if (!keywords || typeof keywords !== "object") return [];
    return Object.entries(keywords).map(([word, count]) => ({
      text: word,
      value: count,
      color: "red"
    }));
  };

  const prepareEmotionData = (emotions) => {
    return Object.entries(emotions).map(([emotion, value]) => ({ name: emotion, value }));
  };

  const bulletPoints = sentiments.bullet_points || {};
  const positiveKeywords = sentiments.sentiment_positive_words || {};
  const negativeKeywords = sentiments.sentiment_negative_words || {};
  const emotionData = prepareEmotionData(sentiments.sentiment_emotions || {});
  const wordCloudData = [
    ...renderPositiveKeywordsWordCloud(positiveKeywords),
    ...renderNegativeKeywordsWordCloud(negativeKeywords)
  ];

  const handleClose = () => {
    setShow(false);
    navigate(-1); // Go back to the previous page when the modal is closed
  };

  return (
    <Modal
      show={show}
      centered
      size="lg"
      className="modal-dialog-scrollable p-6 modal_mobile"
      onHide={handleClose}
    >
      <div style={{ width: '100%', padding: "1rem", border: 'none' }}>
        <div style={{ display: "flex", justifyContent: "flex-end", cursor: "pointer", marginBottom: "1rem" }}
          onClick={handleClose}
        >
          <i className="fa-solid fa-x"></i>
        </div>
        <div className="ai_modal_mainbox">
          <div className="ai_modal_smbox">
            <h3>Audit Highlights</h3>
            <ul className="bullet_box">
              {renderBulletPoints(bulletPoints)}
            </ul>
          </div>
          <div className="ai_modal_smbox">
          <h3>Report word cloud</h3>
							<div className="word_cloud_box">
								<ReactWordcloud options={options} words={wordCloudData} callbacks={callbacks}/>
							</div>
            <h3>Customer emotions</h3>
            <div style={{ marginTop: "2rem" }}>
              <ResponsiveContainer width="100%" height={120}>
                <BarChart margin={{ top: 0, right: 10, left: 0, bottom: 5 }} data={emotionData} style={{ fontSize: ".8rem" }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" interval={0}>
                    <Label value="Emotions" offset={-5} position="insideBottomRight" fill="#337ab7" style={{ fontSize: ".8rem" }} />
                  </XAxis>
                  {/* <YAxis domain={[0, 1]} tickFormatter={f => f}> */}
                  <YAxis domain={[0, 1]} tickFormatter={(value) => String(value)}>
                    <Label value="Values" angle={-90} offset={-5} position="left" fill="#337ab7" style={{ fontSize: ".8rem" }} />
                  </YAxis>
                  <Tooltip formatter={v => (v === null ? "N/A" : v)} />
                  <Bar dataKey="value" barSize={10} isAnimationActive={false} fill={"#337ab7"} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
          <div className="ai_modal_smbox">
            <h3>Positive keywords</h3>
            <div className="ai_words_mainbox positive_mainbox">
              {renderPositiveKeywords(positiveKeywords)}
            </div>
            <h3>Negative keywords</h3>
            <div className="ai_words_mainbox negative_mainbox">
              {renderNegativeKeywords(negativeKeywords)}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReportAIInsightModal;
